const base = "";

export const ROUTE_LOGIN = base + "/login";
export const ROUTE_SET_NEW_PASSWORD = base + "/recoverpassword/:token";

export const ROUTE_HOME = base + "/";

export const ROUTE_START_ALERT = base + "/home/alert";
export const ROUTE_START_PENDING = base + "/home/pending";

export const ROUTE_DASHBOARD = base + "/dashboards";
export const ROUTE_DASHBOARD_DETAIL = base + '/dashboards/edit/:id';

export const ROUTE_IFRAME = base + "/dashboards/iframe/:url/title/:title";
// Comento esta línea para poder hacer la impresión de los dashboards, únicamente de los que aparecen en cada página
//export const ROUTE_IFRAMES_SCREEN = base + "/dashboards/iframesscreen/id/:id/request/:request";
export const ROUTE_IFRAMES_SCREEN = base + "/dashboards/iframesscreen/id/:id/request/:request/page/:page";

export const ROUTE_FORM = base + "/forms";
export const ROUTE_FORM_RESULT = base + "/forms/:formId";
export const ROUTE_FORM_RESULT_DETAIL = base + "/forms/:formId/edit/:id";
export const ROUTE_FORM_RESULT_ADD = base + "/forms/:formId/add";
export const ROUTE_DOCUMENTATION = base + "/documentation";

export const ROUTE_ADMIN_CURRENT_USER_DETAIL = base + "/admin/current/edit/:id";

export const ROUTE_ADMIN_USERS = base + "/admin/users";
export const ROUTE_ADMIN_USER_DETAIL = base + "/admin/users/edit/:id";
export const ROUTE_ADMIN_USER_ADD = base + "/admin/users/add";

export const ROUTE_ADMIN_DASHBOARDS = base + "/admin/dashboards";
export const ROUTE_ADMIN_DASHBOARD_DETAIL = base + "/admin/dashboards/edit/:id";
export const ROUTE_ADMIN_DASHBOARD_LAYOUT = base + "/admin/dashboards/layout/:id";
export const ROUTE_ADMIN_DASHBOARD_ADD = base + "/admin/dashboards/add";

export const ROUTE_ADMIN_DOCUMENTS = base + "/admin/documents";
export const ROUTE_ADMIN_DOCUMENT_DETAIL = base + "/admin/documents/edit/:id";
export const ROUTE_ADMIN_DOCUMENT_ADD = base + "/admin/documents/add";

export const ROUTE_ADMIN_FORMS = base + "/admin/forms";
export const ROUTE_ADMIN_FORM_DETAIL = base + "/admin/forms/edit/:id";
export const ROUTE_ADMIN_FORM_ADD = base + "/admin/forms/add";
export const ROUTE_ADMIN_FORM_RESULT = base + "/admin/forms/results/:formId";
export const ROUTE_ADMIN_FORM_RESULT_DETAIL = base + "/admin/forms/results/:formId/edit/:id";

export const ROUTE_ADMIN_ALERTS = base + "/admin/alerts";
export const ROUTE_ADMIN_ALERT_DETAIL = base + "/admin/alerts/edit/:id";
export const ROUTE_ADMIN_ALERT_ADD = base + "/admin/alerts/add";

export const ROUTE_ADMIN_LOG = base + "/admin/logs";
export const ROUTE_ADMIN_LOG_DETAIL = base + "/admin/logs/edit/:id";

export const ROUTE_ADMIN_EXPLOITATIONS = base + "/admin/exploitations";
export const ROUTE_ADMIN_EXPLOITATION_DETAIL = base + "/admin/exploitations/edit/:id";
export const ROUTE_ADMIN_EXPLOITATION_ADD = base + "/admin/exploitations/add";

export const ROUTE_ADMIN_USER_GROUPS = base + "/admin/usergroups";
export const ROUTE_ADMIN_USER_GROUP_DETAIL = base + "/admin/usergroups/edit/:id";
export const ROUTE_ADMIN_USER_GROUP_ADD = base + "/admin/usergroups/add";

export const ROUTE_ADMIN_ZONES = base + "/admin/zones";
export const ROUTE_ADMIN_ZONE_DETAIL = base + "/admin/zones/edit/:id";
export const ROUTE_ADMIN_ZONE_ADD = base + "/admin/zones/add";

export const ROUTE_ADMIN_BUSINESS_TYPES = base + "/admin/businesstypes";
export const ROUTE_ADMIN_BUSINESS_TYPE_DETAIL = base + "/admin/businesstypes/edit/:id";
export const ROUTE_ADMIN_BUSINESS_TYPE_ADD = base + "/admin/businesstypes/add";

export const ROUTE_LINKS = base + "/admin/links";
export const ROUTE_LINK_DETAIL = base + "/admin/links/edit/:id";
export const ROUTE_LINK_ADD = base + "/admin/links/add";

export const ROUTE_APP_LAUNCHER = base + "/app-launcher";

export const ROUTE_COMPARE_GRAPHICS = base + "/comparegraphics/:graphic/:dashboard";

export const ROUTE_ALERT_NOTIFICATION_USER_LIST = base + "/admin/alert/notification/user";

export const ROUTE_ADMIN_ALERT_NOTIFICATIONS = base + "/admin/alerts-notifications";
export const ROUTE_ADMIN_ALERT_NOTIFICATION_DETAIL = base + "/admin/alerts-notifications/detail/:id";
export const ROUTE_FORM_RESULT_ALL_LIST = base + "/form/result";
export const ROUTE_FORM_RESULT_ALL_DETAIL= base + "/form/result/:formId/:id";

