import {ReducerBuilder} from "co-redux-builders";
import DashboardDetailActions from "./FormResultFormScreenActions";
import FormModel from "../../../model/Form";
import TaskFormDetail from "../../../ws/form/TaskFormDetail";
import FormResult from "../../../model/FormResult";
import TaskFormResultDetail from "../../../ws/formResult/TaskFormResultDetail";
import FormResultFormScreenActions from "./FormResultFormScreenActions";
import {ROUTE_ALERT_NOTIFICATION_USER_LIST, ROUTE_HOME} from "../../../routing/Routes";

interface State {
    loading: boolean,
    formModel?: FormModel
    formResult?: FormResult,
    path?: string
}

const INITIAL_STATE: State = {
    loading: false,
    formModel: undefined,
    formResult: undefined,
    path: undefined
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskFormDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskFormDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskFormDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, formModel: payload.data};
    })

    .onEvent(TaskFormResultDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskFormResultDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskFormResultDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, formResult: payload.data};
    })

    .onEvent(FormResultFormScreenActions.goToRouteAlertNotificationUser, (oldState) => ({
        ...oldState, path: ROUTE_ALERT_NOTIFICATION_USER_LIST
    }))

    .onEvent(FormResultFormScreenActions.goToRouteHomeScreen, (oldState) => ({...oldState, path: ROUTE_HOME}))

    .onEvent(FormResultFormScreenActions.clearPath, (oldState) => ({
        ...oldState, path: undefined
    }))

    .onEvent(DashboardDetailActions.clearReducer, () => INITIAL_STATE)

    .build();