import {ReducerBuilder} from "co-redux-builders";
import LinkCreateAdminActions from "./LinkCreateAdminActions";
import TaskLinkAdd from "../../../../ws/links/TaskLinkAdd";

interface ReducerBuilderState {
    loading: boolean,
    errors: string
}

const INITIAL_STATE : ReducerBuilderState = {
    loading: false,
    errors: ''
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskLinkAdd.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
    .onEvent(TaskLinkAdd.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
    .onEvent(TaskLinkAdd.REDUX_ACTION_OBJECT_ON_FAIL, (oldState,payload) => ({...oldState, errors: payload}))
    .onEvent(TaskLinkAdd.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, errors: ''}))

    // ComponentWillUnmount
    .onEvent(LinkCreateAdminActions.dispatchClearReducer, () => INITIAL_STATE)

    .build()