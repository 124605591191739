import React, {Component, ReactNode} from "react";
import {BaseModalProps} from "../../base/modal/BaseModal";
import {Modal} from "react-bootstrap";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_ACEPTAR, TR_CAMBIOS_REALIZADOS} from "../../I18n/constants";
import {ChangeLog} from "../../model/Log";
import DateFormatter from "../../utils/DateFormatter";
import Row from "../Row";
import Col from "../Col";
import Button from "../Button";
import {MenuIcon} from "../../Config";

interface ChangeLogsModalProps extends BaseModalProps{
    changedLogs: Array<ChangeLog>,
    date: string
}


export default class ChangeLogsModal extends Component<ChangeLogsModalProps> {
    public render(): ReactNode {
        const {show, onClose, changedLogs, date} = this.props;

        return (
            <Modal onHide={onClose} show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18nUtils.tr(TR_CAMBIOS_REALIZADOS)}
                    </Modal.Title>
                   {DateFormatter.formatDatetime(date)}
                </Modal.Header>
                <Modal.Body>

                    {
                        changedLogs.map(changeLog => {
                            return (
                                <Col>
                                    <div style={{
                                        fontWeight: "bold"
                                    }}>
                                        {changeLog.column_name}
                                    </div>

                                    <div className={"changed-logs"}>
                                        <p className={changeLog.old_value ? "" : "empty-old-value"}>
                                            {changeLog.old_value ? changeLog.old_value : "Vacío"}
                                        </p>
                                        <i className={"material-icons"}>{MenuIcon.ARROW_RIGHT}</i>
                                        {changeLog.new_value}
                                    </div>
                                </Col>
                            )
                        })
                    }

                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col xs={0} sm={0} md={9} lg={9}/>
                        <Col sm={6} md={3} lg={3}>
                            <Button
                                text={I18nUtils.tr(TR_ACEPTAR).toUpperCase()}
                                onClick={() => onClose()}
                                block={true}
                                className={'btn-lg btn-primary'}
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}
 