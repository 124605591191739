import * as React from 'react';
import DashboardListCardAdminReducer from "./DashboardListCardAdminReducer";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_DASHBOARDS, TR_ELIMINAR_DASHBOARD,
    TR_NO_EXISTEN_DASHBOARDS,
} from "../../../../I18n/constants";
import TaskDashboardList from "../../../../ws/dashboard/TaskDashboardList";
import {ROUTE_ADMIN_DASHBOARD_ADD} from "../../../../routing/Routes";
import {connect} from "react-redux";
import {DashboardListTable} from "./DashboardListTable";
import DashboardListcardAdminActions from "./DashboardListcardAdminActions";
import TaskDashboardDelete from "../../../../ws/dashboard/TaskDashboardDelete";

const mapStateToProps = DashboardListCardAdminReducer.autoMapToProps();
const mapActionsToProps = DashboardListcardAdminActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

class DashboardListCardAdmin extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render() {
        const {dashboards, error, loading, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_DASHBOARDS)}
                      sort={{column: 'name'}}
                      TaskList={TaskDashboardList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_DASHBOARDS)}}
                      data={dashboards}
                      error={error}
                      ItemsTable={DashboardListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_DASHBOARD),
                          Task: TaskDashboardDelete
                      }}
                      addRoute={ROUTE_ADMIN_DASHBOARD_ADD}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(DashboardListCardAdmin as unknown as React.ComponentType<void>)