import {ReducerBuilder} from "co-redux-builders";
import UserGroup from "../../../../model/UserGroup";
import TaskUserGroupList from "../../../../ws/userGroup/TaskUserGroupList";
import {PagerBackendProps} from "../../../../components/Pager";
import UserGroupListAdminScreenActions from "./UserGroupListCardAdminActions";
import TaskUserGroupDelete from "../../../../ws/userGroup/TaskUserGroupDelete";

interface InitialState {
    loading: boolean,
    userGroups: UserGroup[],
    error: string,
    pager: PagerBackendProps,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    userGroups: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1
    },
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskUserGroupDelete.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskUserGroupDelete.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })

    .onEvent(TaskUserGroupList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskUserGroupList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskUserGroupList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskUserGroupList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            userGroups: payload.data,
            pager: payload.pager || oldState.pager,
        }
    })

    .onEvent(UserGroupListAdminScreenActions.clearReducer, ((oldState, payload) => INITIAL_STATE))

    .build()