import * as React from 'react';
import DashboardListCardReducer from "./DashboardListCardReducer";
import CardList, {EmptyListOptions} from "../../../components/card/CardList";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_ANADE_TUS_DASHBOARDS_FAVORITOS, TR_DASHBOARDS, TR_MIS_DASHBOARDS, TR_NO_EXISTEN_DASHBOARDS,} from "../../../I18n/constants";
import TaskDashboardList, {DashboardCriteria} from "../../../ws/dashboard/TaskDashboardList";
import {connect} from "react-redux";
import {DashboardListMiniature} from "./DashboardListMiniature";
import DashboardListCardActions from "./DashboardListCardActions";
import TaskBusinessTypeList from "../../../ws/businessType/TaskBusinessTypeList";
import {goToRoute} from "../../../utils/Router";
import {ROUTE_DASHBOARD} from "../../../routing/Routes";

const mapStateToProps = DashboardListCardReducer.autoMapToProps();
const mapActionsToProps = DashboardListCardActions.autoMapToProps();

interface DashboardListCardProps {
    showFavorites?: boolean,
}

type Props = typeof mapStateToProps & typeof mapActionsToProps & DashboardListCardProps

interface State {
    currentCriteria: DashboardCriteria
}

class DashboardListCard extends React.Component<Props, State> {

    public state: State = {
        currentCriteria: {
            fav: this.props.showFavorites ? true : undefined,
            enabled: true,
        },
    };

    public UNSAFE_componentWillMount(): void {
        new TaskBusinessTypeList().execute();
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    /*
    private onExploitationSelect = (selectedExploitation?: AutocompleteOption<Exploitation>): void => {
        const newCriteria: DashboardCriteria = {
            ...this.state.currentCriteria,
            exploitation_ids: selectedExploitation ? [selectedExploitation.value] : undefined
        };
        this.setState({currentCriteria: newCriteria});
    };

    private onExploitationSearch = (newSearch: string): void => {
        new TaskExploitationList({
            ...this.state.currentCriteria,
            search: newSearch,
        }).execute();
    };

    private onTypeSelect = (selectedTypeId: string): void => {
        const newCriteria: DashboardCriteria = {
            ...this.state.currentCriteria,
            business_type_ids: (selectedTypeId !== '-1') ? [selectedTypeId] : undefined
        };
        this.setState({currentCriteria: newCriteria});
    };
    */

    private onAddFavorites = (): void => {
        goToRoute(ROUTE_DASHBOARD);
    };

    public render() {
        const {dashboards, error, loading, pager, showFavorites} = this.props;

        const emptyListOptions: EmptyListOptions = showFavorites ?
            {
                message: I18nUtils.tr(TR_ANADE_TUS_DASHBOARDS_FAVORITOS),
                addHandler: this.onAddFavorites,
            } :
            {
                message: I18nUtils.tr(TR_NO_EXISTEN_DASHBOARDS)
            };

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(showFavorites ? TR_MIS_DASHBOARDS : TR_DASHBOARDS)}
                      sort={{column: 'name'}}
                      TaskList={TaskDashboardList}
                      emptyListOptions={emptyListOptions}
                      data={dashboards}
                      error={error}
                      ItemsTable={DashboardListMiniature}
                      addToFavorites={!showFavorites}
                      removeFromFavorites={showFavorites}
                      favoritesManagerByList={false}
                      customCriteria={this.state.currentCriteria}
            >
                {/*{!showFavorites && this.renderHeaderContent()}*/}
            </CardList>
        )
    }

    /*
    private renderHeaderContent = (): React.ReactNode => {
        const {exploitationsLoading, exploitations, businessTypes} = this.props;

        const searchPlaceholder = I18nUtils.tr(TR_BUSCAR);
        const exploitationOptions = exploitations.map((exploitation) => ExploitationAutocompleteBuilder.buildOption(exploitation));

        const businessTypeOptions: FormInputOption[] = businessTypes.map((businessType) => ({value: businessType.id, name: businessType.name}));

        return (
            <Row>
                <FormCol md={6} lg={6}>
                    <div className={'form-group'}>
                        <div className={'form-line'}>
                            <label>{I18nUtils.tr(TR_EXPLOTACION)}</label>
                            <AsyncTypeahead
                                placeholder={searchPlaceholder}
                                promptText={searchPlaceholder}
                                options={exploitationOptions}
                                onChange={(selected) => {
                                    this.onExploitationSelect(selected[0])
                                }}
                                onInputChange={
                                    (newText: string, event: Event) => newText === '' && this.onExploitationSelect(undefined)
                                }
                                labelKey={'name'}
                                filterBy={() => true}
                                isLoading={false}
                                onSearch={this.onExploitationSearch}
                                searchText={exploitationsLoading ?
                                    I18nUtils.tr(TR_BUSCANDO) :
                                    I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                            />
                        </div>
                    </div>
                </FormCol>
                <FormCol md={6} lg={6}>
                    <div className={'form-group'}>
                        <label>{I18nUtils.tr(TR_TIPO_DE_EXPLOTACION)}</label>
                        <select
                            className={'form-control show-tick bootstrap-select'}
                            onChange={(e) => this.onTypeSelect(e.target.value)}
                        >
                            <option key={'-1'} value={'-1'}/>
                            {businessTypeOptions.map((option: FormInputOption, index) => {
                                return (<option key={index} value={option.value}>{option.name}</option>);
                            })}
                        </select>
                    </div>
                </FormCol>
            </Row>
        )
    };
    */

}

export default connect(mapStateToProps, mapActionsToProps)(DashboardListCard as unknown as React.ComponentType<DashboardListCardProps>)
