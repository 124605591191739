import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import DocumentModel from "../../model/Document";

export default class TaskDocumentDetail extends BaseGenericRequest<{}, TaskResponse<DocumentModel>> {

    public constructor(documentId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_DOCUMENT_DETAIL, {uuid: documentId}));
    }
}
