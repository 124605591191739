import BaseGenericRequest from "../BaseGenericRequest";
import Dashboard, {DashboardData} from "../../model/Dashboard";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";

export default class TaskDashboardEdit extends BaseGenericRequest<DashboardData, TaskResponse<Dashboard>> {

    private readonly data: DashboardData;

    public constructor(dashboardId: string, dashboardData: DashboardData) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_DASHBOARD_EDIT, {uuid: dashboardId}));
        this.data = dashboardData;
    }

    protected getRequest(): DashboardData {
        return this.data;
    }
}