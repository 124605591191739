import * as React from "react";
import {connect} from "react-redux";
import UnloggedScreen from "../../../base/screens/UnloggedScreen";
import SetNewPasswordReducer from "./SetNewPasswordReducer";
import SetNewPasswordActions from "./SetNewPasswordActions";
import imgLogo from "../../../res/img/logo.png";
import Icon from "../../../components/Icon";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_CONTRASENA_MODIFICADA_CORRECTAMENTE,
    TR_LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES,
    TR_LA_SOLICITUD_HA_CADUCADO_SI_ES_NECESARIO_SOLICITAR_DE_NUEVO_EL_CAMBIO_DE_CONTRASENA,
    TR_LAS_CONTRASENAS_NO_COINCIDEN,
    TR_MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE,
    TR_NUEVA_CONTRASENA,
    TR_REPITE_LA_CONTRASENA,
    TR_RESTABLECE_LA_CONTRASENA,
    TR_RESTABLECER_CONTRASENA,
} from "../../../I18n/constants";
import Card from "../../../components/card/Card";
import CardBody from "../../../components/card/CardBody";
import Row from "../../../components/Row";
import Col from "../../../components/Col";
import Button from "../../../components/Button";
import TaskSetPassword from "../../../ws/auth/TaskSetPassword";
import {RouteComponentProps} from "react-router";
import {executeItemTask} from "../../../utils/FormUtils";
import {goToRoute} from "../../../utils/Router";
import {ROUTE_HOME} from "../../../routing/Routes";
import {isValidPassword} from "../../../utils/StringUtils";

const mapStateToProps = SetNewPasswordReducer.autoMapToProps();
const mapActionsToProps = SetNewPasswordActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps & RouteComponentProps<{ token: string }>

interface State {
    showErrorMsg: boolean,
}

class SetNewPasswordScreen extends UnloggedScreen<Props, State> {

    public state: State = {
        showErrorMsg: false,
    };

    public componentDidMount() {
        document.body.classList.add('login-page');
    }

    public componentWillUnmount() {
        this.props.clearReducer();
        document.body.classList.remove('login-page');
    }

    private changePassword = () => {
        const {password, passwordsAreEqual, newPasswordIsValid} = this.props;

        if (passwordsAreEqual && newPasswordIsValid) {
            executeItemTask(
                new TaskSetPassword(this.props.match.params.token, password),
                () => goToRoute(ROUTE_HOME),
                I18nUtils.tr(TR_CONTRASENA_MODIFICADA_CORRECTAMENTE),
                I18nUtils.tr(TR_LA_SOLICITUD_HA_CADUCADO_SI_ES_NECESARIO_SOLICITAR_DE_NUEVO_EL_CAMBIO_DE_CONTRASENA)
            )
        } else {
            this.setState({
                showErrorMsg: true,
            })
        }
    };

    private handleKeyboard = (event) => {
        if (event.key === 'Enter') {
            this.changePassword();
        }
    };

    public renderScreen(): React.ReactNode {
        const {
            loading, password, setPassword, rePassword, setRePassword, newPasswordIsValid, passwordsAreEqual
        } = this.props;
        const {showErrorMsg} = this.state;

        return (
            <div className="login-box">
                <div className="logo">
                    <a>
                        <img src={imgLogo} alt="Pavasal" className="img-responsive center-block m-b-15"/>
                    </a>
                    <small>{I18nUtils.tr(TR_MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE)}</small>
                </div>
                <Card loading={loading}>
                    <CardBody>
                        <div className="msg">{I18nUtils.tr(TR_RESTABLECE_LA_CONTRASENA)}</div>
                        <div className="input-group">
                            <span className="input-group-addon"><Icon icon={'lock'}/></span>
                            <div className="form-line">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder={I18nUtils.tr(TR_NUEVA_CONTRASENA)}
                                    required={true}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyPress={this.handleKeyboard}
                                />
                            </div>
                        </div>
                        {showErrorMsg && <div>
                            <p className={'error-message'}>{isValidPassword(password) ? '' :
                                I18nUtils.tr(TR_LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES)}</p>
                        </div>}

                        <div className="input-group">
                            <span className="input-group-addon"><Icon icon={'lock'}/></span>
                            <div className="form-line">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder={I18nUtils.tr(TR_REPITE_LA_CONTRASENA)}
                                    required={true}
                                    value={rePassword}
                                    onChange={(e) => setRePassword(e.target.value)}
                                    onKeyPress={this.handleKeyboard}
                                />
                            </div>
                        </div>
                        {showErrorMsg && <div>
                            <p className={'error-message'}>{password === rePassword ? '' :
                                I18nUtils.tr(TR_LAS_CONTRASENAS_NO_COINCIDEN)}</p>
                        </div>}

                        <Row>
                            <Col xs={12}>
                                <Button
                                    className={'bg-blue-grey'}
                                    text={I18nUtils.tr(TR_RESTABLECER_CONTRASENA)}
                                    onClick={this.changePassword}
                                    block={true}
                                    disabled={showErrorMsg && (!newPasswordIsValid || !passwordsAreEqual)}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }

}

export default connect(mapStateToProps, mapActionsToProps)(SetNewPasswordScreen);