import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import Exploitation from "../../model/Exploitation";

export default class TaskExploitationDetail extends BaseGenericRequest<{}, TaskResponse<Exploitation>> {

    public constructor(exploitationId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_EXPLOITATION_DETAIL, {uuid: exploitationId}));
    }
}
