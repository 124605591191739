import moment from 'moment';
import I18nUtils from "../I18n/I18nUtils";

export default class DateFormatter {

    public static formatDate(date: Date | string): string {
        if (date) {
            return moment(date).format('DD/MM/YYYY');
        }
        return '';
    }

    public static formatDay(date: Date | string): string {
        if (date) {
            moment.locale(I18nUtils.getLocale());
            return moment(date).format('dddd');
        }
        return '';
    }

    public static formatDatetime(date: Date | string): string {
        if (date) {
            return moment(date).format('HH:mm DD/MM/YYYY');
        }
        return '';
    }

    public static formatTime(date: Date | string): string {
        if (date) {
            return moment(date).format('HH:mm');
        }
        return '';
    }

    public static formatInputDate(date?: Date | string): string {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        }
        return '';
    }

}
