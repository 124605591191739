import Config from "../Config";

const BASE_URL = Config.BASE_URL;

export default class {
    public static URL_LOGIN = `${BASE_URL}/auth/login`;
    public static URL_RECOVER_PWD = `${BASE_URL}/auth/recoverpassword`;
    public static URL_SET_PASSWORD = `${BASE_URL}/auth/setpassword`;

    public static URL_USER_CURRENT_DETAIL = `${BASE_URL}/user/current`;

    public static URL_USER_LIST = `${BASE_URL}/user`;
    public static URL_USER_ADD = `${BASE_URL}/user/add`;
    public static URL_USER_DETAIL = `${BASE_URL}/user/:uuid`;
    public static URL_USER_EDIT = `${BASE_URL}/user/:uuid`;
    public static URL_USER_DELETE = `${BASE_URL}/user/:uuid`;
    public static URL_USER_DELETE_MULTIPLE = `${BASE_URL}/user`;

    public static URL_PASSWORD_EDIT = `${BASE_URL}/user/password/:uuid`;
    public static URL_CURRENT_PASSWORD_EDIT = `${BASE_URL}/user/password`;

    public static URL_USER_GROUP_LIST = `${BASE_URL}/group`;
    public static URL_USER_GROUP_ADD = `${BASE_URL}/group/add`;
    public static URL_USER_GROUP_DETAIL = `${BASE_URL}/group/:uuid`;
    public static URL_USER_GROUP_EDIT = `${BASE_URL}/group/:uuid`;
    public static URL_USER_GROUP_DELETE = `${BASE_URL}/group/:uuid`;
    public static URL_USER_GROUP_DELETE_MULTIPLE = `${BASE_URL}/group`;

    public static URL_FORM_FIELD_TYPE_LIST = `${BASE_URL}/formfiedtype`;

    public static URL_FORM_LIST = `${BASE_URL}/form`;
    public static URL_FORM_ADD = `${BASE_URL}/form/add`;
    public static URL_FORM_DETAIL = `${BASE_URL}/form/:uuid`;
    public static URL_FORM_EDIT = `${BASE_URL}/form/:uuid`;
    public static URL_FORM_DELETE = `${BASE_URL}/form/:uuid`;
    public static URL_FORM_DELETE_MULTIPLE = `${BASE_URL}/form`;

    public static URL_FORM_RESULT_LIST_ALL = `${BASE_URL}/form/result`;
    public static URL_FORM_RESULT_LIST = `${BASE_URL}/form/result/:formId`;
    public static URL_FORM_RESULT_ADD = `${BASE_URL}/form/result/:formId/add`;
    public static URL_FORM_RESULT_DETAIL = `${BASE_URL}/form/result/:formId/:uuid`;
    public static URL_FORM_RESULT_EDIT = `${BASE_URL}/form/result/:formId/:uuid`;
    public static URL_FORM_RESULT_DELETE = `${BASE_URL}/form/result/:formId/:uuid`;
    public static URL_FORM_RESULT_DELETE_MULTIPLE = `${BASE_URL}/form/result/:formId`;
    public static URL_FORM_RESULT_CHECK_FREQUENCY = `${BASE_URL}/form/result/:formId/checkfrequency`;

    public static URL_EXPLOITATION_LIST = `${BASE_URL}/exploitation`;
    public static URL_EXPLOITATION_ADD = `${BASE_URL}/exploitation/add`;
    public static URL_EXPLOITATION_DETAIL = `${BASE_URL}/exploitation/:uuid`;
    public static URL_EXPLOITATION_EDIT = `${BASE_URL}/exploitation/:uuid`;
    public static URL_EXPLOITATION_DELETE = `${BASE_URL}/exploitation/:uuid`;
    public static URL_EXPLOITATION_DELETE_MULTIPLE = `${BASE_URL}/exploitation`;
    public static URL_EXPLOITATION_CHARACTERISTICS_EDIT = `${BASE_URL}/exploitation/:uuid/characteristics`;
    public static URL_EXPLOITATION_REVERSION_EDIT = `${BASE_URL}/exploitation/:uuid/reversion`;
    public static URL_EXPLOITATION_SERVICES_EDIT = `${BASE_URL}/exploitation/:uuid/services`;
    public static URL_EXPLOITATION_DATE_EDIT = `${BASE_URL}/exploitation/:uuid/date`;

    public static URL_ZONE_LIST = `${BASE_URL}/zone`;
    public static URL_ZONE_ADD = `${BASE_URL}/zone/add`;
    public static URL_ZONE_DETAIL = `${BASE_URL}/zone/:uuid`;
    public static URL_ZONE_EDIT = `${BASE_URL}/zone/:uuid`;
    public static URL_ZONE_DELETE = `${BASE_URL}/zone/:uuid`;
    public static URL_ZONE_DELETE_MULTIPLE = `${BASE_URL}/zone`;

    public static URL_BUSINESS_TYPE_LIST = `${BASE_URL}/businesstype`;
    public static URL_BUSINESS_TYPE_ADD = `${BASE_URL}/businesstype/add`;
    public static URL_BUSINESS_TYPE_DETAIL = `${BASE_URL}/businesstype/:uuid`;
    public static URL_BUSINESS_TYPE_EDIT = `${BASE_URL}/businesstype/:uuid`;
    public static URL_BUSINESS_TYPE_DELETE = `${BASE_URL}/businesstype/:uuid`;
    public static URL_BUSINESS_TYPE_DELETE_MULTIPLE = `${BASE_URL}/businesstype`;

    public static URL_DOCUMENT_LIST = `${BASE_URL}/document`;
    public static URL_DOCUMENT_ADD = `${BASE_URL}/document/add`;
    public static URL_DOCUMENT_DETAIL = `${BASE_URL}/document/:uuid`;
    public static URL_DOCUMENT_EDIT = `${BASE_URL}/document/:uuid`;
    public static URL_DOCUMENT_DELETE = `${BASE_URL}/document/:uuid`;
    public static URL_DOCUMENT_DELETE_MULTIPLE = `${BASE_URL}/document`;

    public static URL_ALERT_NOTIFICATION_LIST = `${BASE_URL}/alert/notification`;
    public static URL_ALERT_NOTIFICATION_DETAIL = `${BASE_URL}/alert/notification/:uuid`;

    public static URL_ALERT_LIST = `${BASE_URL}/alert`;
    public static URL_ALERT_ADD = `${BASE_URL}/alert/add`;
    public static URL_ALERT_DETAIL = `${BASE_URL}/alert/:uuid`;
    public static URL_ALERT_EDIT = `${BASE_URL}/alert/:uuid`;
    public static URL_ALERT_DELETE = `${BASE_URL}/alert/:uuid`;
    public static URL_ALERT_DELETE_MULTIPLE = `${BASE_URL}/alert`;

    public static URL_ALERT_STATE_LIST = `${BASE_URL}/alert/state`;
    public static URL_ALERT_TYPE_LIST = `${BASE_URL}/alert/type`;
    public static URL_ALERT_SOURCE_LIST = `${BASE_URL}/alert/source`;
    public static URL_ALERT_FREQUENCY_LIST = `${BASE_URL}/alert/frequency`;

    public static URL_DASHBOARD_LAYOUT_LIST = `${BASE_URL}/dashboard/layouts`;

    public static URL_ROLE_LIST = `${BASE_URL}/role`;

    public static URL_FILE_UPLOAD = `${BASE_URL}/upload`;

    public static URL_LOG_LIST = `${BASE_URL}/log`;

    public static URL_LOG_TYPE_LIST = `${BASE_URL}/log/type`;

    public static URL_DASHBOARD_LIST = `${BASE_URL}/dashboard`;
    public static URL_DASHBOARD_ADD = `${BASE_URL}/dashboard/add`;
    public static URL_DASHBOARD_GET = `${BASE_URL}/dashboard/:uuid`;
    public static URL_DASHBOARD_DETAIL = `${BASE_URL}/dashboard/detail/:uuid`;
    public static URL_DASHBOARD_EDIT = `${BASE_URL}/dashboard/:uuid`;
    public static URL_DASHBOARD_DELETE = `${BASE_URL}/dashboard/:uuid`;
    public static URL_DASHBOARD_DELETE_MULTIPLE = `${BASE_URL}/dashboard`;
    public static URL_DASHBOARD_COMPARE = `${BASE_URL}/dashboard/compare/:uuid/:itemUuid`;
    public static URL_DASHBOARD_SET_DEFAULT = `${BASE_URL}/dashboard/default/:uuid/:itemUuid`;

    public static URL_FAVORITE_ADD = `${BASE_URL}/fav/add`;
    public static URL_FAVORITE_REMOVE = `${BASE_URL}/fav/remove`;

    public static URL_LINK_LIST = `${BASE_URL}/link`;
    public static URL_LINK_ADD = `${BASE_URL}/link/add`;
    public static URL_LINK_EDIT = `${BASE_URL}/link/:uuid`;
    public static URL_LINK_DETAIL = `${BASE_URL}/link/:uuid`;
    public static URL_LINK_DELETE = `${BASE_URL}/link/:uuid`;
    public static URL_LINK_DELETE_MULTIPLE = `${BASE_URL}/link`;

    public static URL_COMPANY_TYPE_LIST = `${BASE_URL}/companytype`;

    public static URL_USER_ACTION = `${BASE_URL}/user/action`;

    public static URL_FORM_FREQUENCIES = `${BASE_URL}/form/frequencytype`;

    public static URL_FORM_SCOPES = `${BASE_URL}/form/scopetype`;

    public static URL_ALERT_TYPES_LIST = `${BASE_URL}/alert/type`;

    public static URL_ALERT_SCOPES_LIST = `${BASE_URL}/alert/scope`;

    public static URL_ALERT_NOTIFICATION_USER_LIST = `${BASE_URL}/alert/notification/user`;
    public static URL_ALERT_NOTIFICATION_USER_SOLVE = `${BASE_URL}/alert/notification/user/:uuid/solve`;

    public static URL_DOWLOAD_DOCUMENT = `${BASE_URL}/upload/:uuid`;
}
