import {ReducerBuilder} from "co-redux-builders";
import TaskUserCurrentDetail from "../ws/auth/TaskUserCurrentDetail";
import User from "../model/User";
import UserAction from "../model/UserAction";
import TaskUserActionsList from "../ws/useractions/TaskUserActionsList";

interface State {
    currentUser: User | null,
    loadingCurrentUser: boolean,

    userActions: Array<UserAction>,
    loadingUserActions: boolean
}

const INITIAL_STATE: State = {
    currentUser: null,
    loadingCurrentUser: false,

    userActions: [],
    loadingUserActions: false
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskUserCurrentDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, currentUser: payload.data}))
    .onEvent(TaskUserCurrentDetail.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, currentUser: null}))
    .onEvent(TaskUserCurrentDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loadingCurrentUser: true}))
    .onEvent(TaskUserCurrentDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loadingCurrentUser: false}))

    .onEvent(TaskUserActionsList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loadingUserActions: true}))
    .onEvent(TaskUserActionsList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loadingUserActions: false}))
    .onEvent(TaskUserActionsList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({...oldState, userActions: payload.data}))

    .build();
