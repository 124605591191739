import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Dashboard, {DashboardDetailFilterData} from "../../model/Dashboard";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";

export default class TaskDashboardDetail extends BaseGenericRequest<DashboardDetailFilterData, TaskResponse<Dashboard>> {

    private readonly data: DashboardDetailFilterData;

    public constructor(dashboardId: string, itemId: string) {
        super(METHOD.METHOD_POST, generateRoute(Urls.URL_DASHBOARD_SET_DEFAULT, {uuid: dashboardId, itemUuid: itemId}));
    }

    protected getRequest(): DashboardDetailFilterData {
        return this.data;
    }
}
