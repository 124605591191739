import {AutocompleteOption} from "../components/form/FormAutocomplete";

export default interface Zone {
    id: string,
    name: string,
    elk_value: string,
}

export interface ZoneData {
    name: string,
    elk_value: string,
}

export class ZoneAutocompleteBuilder {

    public static buildOption(zone: Zone): AutocompleteOption<Zone> {
        return ({
            name: zone.name,
            value: zone.id,
            data: zone
        });
    }

}
