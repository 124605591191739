import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import Zone, {ZoneData} from "../../model/Zone";

export default class TaskZoneAdd extends BaseGenericRequest<ZoneData, TaskResponse<Zone>> {

    private readonly data: ZoneData;

    public constructor(data: ZoneData) {
        super(METHOD.METHOD_POST, Urls.URL_ZONE_ADD);
        this.data = data;
    }

    protected getRequest(): ZoneData {
        return this.data;
    }
}