import * as React from 'react';
import { ChangeEventHandler, Component, ReactNode } from 'react';
import FormCol, { FormColProps } from './FormCol';
import { WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';
import { AutocompleteOption } from './FormAutocomplete';

type Options = Array<AutocompleteOption<any>>;

interface Props extends WrappedFieldProps {
    col: FormColProps
    name: string
    label?: string
    options: Options
    required?: boolean
    disabled?: boolean
    className?: string
    button?: ReactNode
}

interface FormReduxSelectState {
    touched: boolean
}

export default class FormReduxSelect extends Component<Props, FormReduxSelectState> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            touched: false
        }
    }

    public render(): ReactNode {
        const {
            input,
            meta,
            name,
            label = '',
            required = false,
            disabled = false,
            col,
            options,
            button,
        } = this.props;

        const {touched} = this.state;

        const styleFormGroup = classNames('form-group');
        const styleSelect = classNames('form-control', 'show-tick');
        const styleFormLine = classNames('form-line', {
            focused: meta.touched && meta.error,
            error: meta.touched && meta.error,
        });

        this.handleGetValue();

        return (
            <FormCol {...col} >
                <div className={styleFormGroup}>
                    <div
                        style={{
                            display: 'flex',
                            flexFlow: 'row nowrap',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                        }}
                    >
                        <div className={styleFormLine}>
                            <label>
                                {label || ''}{required && ' *'}
                            </label>
                            <select
                                className={styleSelect}
                                name={name}
                                disabled={disabled}
                                value={this.handleGetValue()}
                                onChange={this.handleChange}
                                onFocus={() => {
                                    input.onFocus;
                                    this.setState({...this.state, touched: true})
                                }}
                                // onBlur={input.onBlur} https://redux-form.com/5.2.3/#/examples/complex?_k=pvcqyi
                            >
                                <option key={'0'} value={0}/>
                                {
                                    options.map(
                                        (option: AutocompleteOption<any>) =>
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.name}
                                            </option>
                                    )
                                }
                            </select>
                        </div>
                        {
                            button
                        }
                    </div>
                    <label className="error">
                        {touched ? meta.error : ''}
                    </label>
                </div>
            </FormCol>
        );
    }

    private handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
        const {input, options} = this.props;
        const valueSelected = options.filter((op) => String(op.value) === String(event.target.value))[0];
        input.onChange(valueSelected ? [valueSelected] : []);
    };

    private handleGetValue = (): string => {
        const value: Options | undefined = this.props.input.value;
        return value && value.length > 0 ? String(value[0].value) : '0';
    };

}
