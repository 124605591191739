import {ReducerBuilder} from "co-redux-builders";
import {DashboardDetail} from "../../../model/Dashboard";
import TaskDashboardDetail from "../../../ws/dashboard/TaskDashboardDetail";
import TaskDashboardSerDefault from "../../../ws/dashboard/TaskDashboardSerDefault";
import DashboardDetailActions from "./DashboardDetailActions";

interface State {
    loading: boolean,
    dashboard?: DashboardDetail
}

const INITIAL_STATE: State = {
    loading: false,
    dashboard: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskDashboardDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskDashboardDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskDashboardDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        const newState = {...oldState};
        const dashboard = {...payload.data};
        const sortedItems = [...dashboard.items];

        sortedItems.sort((a,b) => (a.order - b.order));
        dashboard.items = sortedItems;
        newState.dashboard = dashboard;

        return newState;
    })

    .onEvent(DashboardDetailActions.clearReducer, () => INITIAL_STATE)

    .onEvent(TaskDashboardSerDefault.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => ({
        ...oldState,
        loading: true,
    }))
    .onEvent(TaskDashboardSerDefault.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => ({
        ...oldState,
        loading: false,
    }))
    .onEvent(TaskDashboardSerDefault.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        const newState = {...oldState};
        const dashboard = {...payload.data};
        const sortedItems = [...dashboard.items];

        sortedItems.sort((a,b) => (a.order - b.order));
        dashboard.items = sortedItems;
        newState.dashboard = dashboard;

        return newState;
    })

    .build();
