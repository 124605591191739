import * as React from 'react';
import AlertListAdminCardReducer from "./AlertListAdminCardReducer";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ALERTAS,
    TR_ELIMINAR_ALERTAS,
    TR_NO_EXISTEN_ALERTAS,
} from "../../../../I18n/constants";
import AlertListAdminCardActions from "./AlertListAdminCardActions";
import {connect} from "react-redux";
import {ROUTE_ADMIN_ALERT_ADD} from "../../../../routing/Routes";
import CardList from "../../../../components/card/CardList";
import AlertListTable from "./AlertListTable";
import TaskAlertList from "../../../../ws/alert/TaskAlertList";
import TaskAlertDelete from "../../../../ws/alert/TaskAlertDelete";

const mapStateToProps = AlertListAdminCardReducer.autoMapToProps();
const mapActionsToProps = AlertListAdminCardActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

class AlertListAdminCard extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render() {
        const {alerts, error, loading, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_ALERTAS)}
                      sort={{column: 'datetime'}}
                      TaskList={TaskAlertList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_ALERTAS)}}
                      data={alerts}
                      error={error}
                      ItemsTable={AlertListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_ALERTAS),
                          Task: TaskAlertDelete
                      }}
                      addRoute={ROUTE_ADMIN_ALERT_ADD}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(AlertListAdminCard as unknown as React.ComponentType<{}>);
