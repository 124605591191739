import * as React from 'react';
import Icon from "./Icon";
import {MenuIcon} from "../Config";
import Button from "./Button";
import Col from "./Col";
import I18nUtils from "../I18n/I18nUtils";
import {TR_CANCELAR, TR_DE} from "../I18n/constants";
import ProgressBar from "./ProgressBar";
import {getMBfromBytes} from "../utils/StringUtils";
import {SyntheticEvent} from "react";

export interface FileProgressProps {
    cancelHandler: (event: SyntheticEvent<HTMLButtonElement>) => void,
    percentLoaded: number,
    size: number,
    icon?: string,
}

export default class FileProgress extends React.Component<FileProgressProps> {

    public render() {
        const {size, percentLoaded, cancelHandler, icon = MenuIcon.DOCUMENTATION} = this.props;

        const currentMB = getMBfromBytes(size * percentLoaded / 100);

        return (
            <Col xs={2} sm={2} md={2} lg={2} className={'text-center'}>
                <div className={'progress-icon'}>
                    <Icon icon={icon}/>
                    <p>{`${currentMB} ${I18nUtils.tr(TR_DE)} ${getMBfromBytes(size)}MB`}</p>
                    <ProgressBar now={percentLoaded} />
                    <Button
                        text={I18nUtils.tr(TR_CANCELAR)}
                        onClick={cancelHandler}
                        type={'button'}
                        block={true}
                        className={'btn-primary btn'}
                    />
                </div>
            </Col>
        )
    }

}