import * as React from 'react';
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_DATOS,
    TR_DATOS_DEL_FORMULARIO,
    TR_DATOS_DEL_FORMULARIO_MODIFICADOS_CORRECTAMENTE,
    TR_FORMULARIO_RELLENADO_CORRECTAMENTE,
    TR_FORMULARIOS,
    TR_NUEVOS_DATOS
} from "../../../I18n/constants";
import {
    ROUTE_ADMIN_FORM_RESULT,
    ROUTE_ADMIN_FORM_RESULT_DETAIL,
    ROUTE_ADMIN_FORMS,
    ROUTE_FORM,
    ROUTE_FORM_RESULT,
    ROUTE_FORM_RESULT_ADD,
    ROUTE_FORM_RESULT_DETAIL
} from "../../../routing/Routes";
import {MenuIcon} from "../../../Config";
import ScreenCard from "../../../components/screen/ScreenCard";
import FormResultFormScreenReducer from "./FormResultFormScreenReducer";
import FormResultFormScreenActions from "./FormResultFormScreenActions";
import {RouteComponentProps} from "react-router";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../utils/FormUtils";
import {generateRoute, goToRoute} from "../../../utils/Router";
import TaskFormDetail from "../../../ws/form/TaskFormDetail";
import FormResultCardForm, {FormResultCardFormData} from "./FormResultCardForm";
import TaskFormResultEdit from "../../../ws/formResult/TaskFormResultEdit";
import TaskFormResultAdd from "../../../ws/formResult/TaskFormResultAdd";
import TaskFormResultDetail from "../../../ws/formResult/TaskFormResultDetail";
import TaskResponse from "../../../ws/TaskResponse";
import FormModel from "../../../model/Form";
import {connect} from "react-redux";
import {BreadcrumbItem} from "../../../components/breadcrumb/BreadcrumbManager";
import {FormFieldArrayTypes, FormFieldTypeValue} from "../../../model/FormFieldType";
import {FormResultData} from "../../../model/FormResult";
import DateFormatter from "../../../utils/DateFormatter";
import AlertNotificationUserActions from "../../admin/alertnotificationuser/list/AlertNotificationUserActions";

const mapStateToProps = FormResultFormScreenReducer.autoMapToProps();
const mapActionsToProps = FormResultFormScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps & RouteComponentProps<{ id?: string, formId: string }>

class FormResultFormScreen extends React.Component<Props> {
    public UNSAFE_componentWillMount(): void {
        const id = this.props.match.params.id;

        const loadFormResultIfExist = id ?
            (response: TaskResponse<FormModel>) => loadItemDetailsOrGoBack(
                new TaskFormResultDetail(id, response.data.id),
                this.props
            ) :
            undefined;

        loadItemDetailsOrGoBack(new TaskFormDetail(this.props.match.params.formId), this.props, loadFormResultIfExist);
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private isAdminMode = (): boolean => {
        return this.props.match.path === ROUTE_ADMIN_FORM_RESULT_DETAIL;
    };
    private getGoBackRoute = (): string => {
        const {path} = this.props;
        if ( path ) return path;

        const rawRoute = this.isAdminMode() ?
            ROUTE_ADMIN_FORM_RESULT :
            ROUTE_FORM_RESULT;
        return generateRoute(rawRoute, {formId: this.props.match.params.formId});
    };

    private onSubmit = (values: FormResultCardFormData): void => {
        const {formModel} = this.props;
        const id = this.props.match.params.id;

        if (!formModel) {
            throw new Error('the Form must be loaded')
        }

        // soporte para items de lista
        // Si el campo formDate es un año, lo parseamos a fechas
        let datetime_value = values.formDate;
        if (values.formDate && values.formDate.length === 4)  {
            const yearParsed = new Date();
            yearParsed.setFullYear(Number(values.formDate));
            datetime_value = String(yearParsed);
        }
        const submitData: FormResultData = {
            datetime_value,
            exploitation_id: values.exploitationId,
        };

        formModel.form_fields.forEach((formField) => {
            const {key, form_field_type: {id: typeId}} = formField;
            const currentValue = values[key];
            // especial cuidado con mantener los valores de false

            // En el caso de que sea un fichero se hace un parseo especial para un solo fichero
            if (typeId === FormFieldTypeValue.FILE) {
                if (Array.isArray(currentValue) && currentValue && currentValue.length > 0) {
                    submitData[key] = currentValue[0];
                }

            } else if (typeof currentValue === 'boolean' || currentValue) {
                if (Array.isArray(currentValue)) {
                    submitData[key] = JSON.stringify(currentValue);
                } else {
                    submitData[key] = currentValue
                }
            }

            // Formateo de un Number/Currency Input
            if (typeId === FormFieldTypeValue.NUMBER || typeId === FormFieldTypeValue.CURRENCY) {
                submitData[key] = Number(currentValue);
            }
        });

        if (id) {
            executeItemTask(
                new TaskFormResultEdit(id, submitData, formModel.id),
                () => goToRoute(this.getGoBackRoute()),
                I18nUtils.tr(TR_DATOS_DEL_FORMULARIO_MODIFICADOS_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskFormResultAdd(submitData, formModel.id),
                (response) => {
                    goToRoute(this.getGoBackRoute());
                    // Si se ha llegado al formulario desde la pantalla de notificaciones de usuario
                    // Se envia a su reductor el id del form result creado
                    if (this.props.path) {
                        this.props.clearPath();
                        AlertNotificationUserActions.sendFormResultToResolve.asConnectedAction(response.data.id);
                    }
                },
                I18nUtils.tr(TR_FORMULARIO_RELLENADO_CORRECTAMENTE),
            )
        }
    };

    public render() {
        const breadcrumbs: BreadcrumbItem[] = [
            {
                name: I18nUtils.tr(TR_FORMULARIOS),
                url: this.isAdminMode() ? ROUTE_ADMIN_FORMS : ROUTE_FORM,
                icon: MenuIcon.FORM,
            },
            {
                name: I18nUtils.tr(TR_DATOS_DEL_FORMULARIO),
                url: generateRoute(this.getGoBackRoute()),
                icon: MenuIcon.FORM,
            },
        ];

        if (this.props.match.params.id) {
            const rawRoute = this.isAdminMode() ? ROUTE_ADMIN_FORM_RESULT_DETAIL : ROUTE_FORM_RESULT_DETAIL;
            breadcrumbs.push({
                name: I18nUtils.tr(TR_DATOS),
                url: generateRoute(rawRoute, {
                    id: this.props.match.params.id,
                    formId: this.props.match.params.formId,
                }),
                icon: MenuIcon.FORM,
            })
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVOS_DATOS),
                url: generateRoute(ROUTE_FORM_RESULT_ADD, {formId: this.props.match.params.formId}),
                icon: MenuIcon.FORM,
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderForm()}
            </ScreenCard>
        )
    }

    private renderForm(): React.ReactNode {
        const {formResult, formModel, loading} = this.props;

        let initialValues: Partial<FormResultCardFormData> = {};

        if (formResult) {
            initialValues = formResult.values || {};
            initialValues.formDate = DateFormatter.formatDate(formResult.datetime_value);
            initialValues.exploitationId = formResult.exploitation ? formResult.exploitation.id : undefined;
        }

        // es necesario esperar que este listo loading para no mostrar valores por defecto mientras carga formResult
        if (formModel && !loading) {
            formModel.form_fields.forEach((formField) => {
                const currentValue = initialValues[formField.key];

                // especial cuidado con mantener los valores de false
                if (!currentValue && formField.default_value && typeof currentValue !== 'boolean') {
                    initialValues[formField.key] = formField.default_value;
                }

                // soporte para items de lista
                if (typeof currentValue === 'string' && FormFieldArrayTypes.includes(formField.form_field_type.id)) {
                    try {
                        initialValues[formField.key] = JSON.parse(String(currentValue)); // Pic: ¿En qué casos necesita un parse JSON?
                    } catch ( e ) {
                        initialValues[formField.key] = [String(currentValue)];
                    }
                }
            });
        }
        return (
            <FormResultCardForm
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                goBackRoute={this.getGoBackRoute()}
            />)
    }
}

export default connect(mapStateToProps, mapActionsToProps)(FormResultFormScreen)
