import * as React from 'react';
import {ROUTE_ADMIN_USER_GROUPS} from "../../../../routing/Routes";
import UserGroupListCardAdmin from "./UserGroupListCardAdmin";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_GRUPOS_DE_USUARIOS} from "../../../../I18n/constants";
import {MenuIcon} from "../../../../Config";
import ScreenCard from "../../../../components/screen/ScreenCard";

export default class UserGroupListAdminScreen extends React.Component<{}> {

    public render() {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_GRUPOS_DE_USUARIOS),
                    url: ROUTE_ADMIN_USER_GROUPS,
                    icon: MenuIcon.USER_GROUP,
                },
            ]}
            >
                <UserGroupListCardAdmin/>
            </ScreenCard>
        )
    }
}