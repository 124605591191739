export const TR_PAVAPARK = 'TR_PAVAPARK';
export const TR_GENERIC_WS_ERROR = 'TR_GENERIC_WS_ERROR';
export const TR_ACEPTAR = 'TR_ACEPTAR';
export const TR_CANCELAR = 'TR_CANCELAR';
export const TR_CARGANDO = 'TR_CARGANDO';
export const TR_NOMBRE = 'TR_NOMBRE';
export const TR_APELLIDO = 'TR_APELLIDO';
export const TR_EMAIL = 'TR_EMAIL';
export const TR_EXPLOTACION = 'TR_EXPLOTACION';
export const TR_ROL = 'TR_ROL';
export const TR_ANADIR = 'TR_ANADIR';
export const TR_NO = 'TR_NO';
export const TR_SI = 'TR_SI';
export const TR_GUARDAR = 'TR_GUARDAR';
export const TR_HOME = 'TR_HOME';
export const TR_ANADIR_A_FAVORITOS = 'TR_ANADIR_A_FAVORITOS';
export const TR_QUITAR_DE_FAVORITOS = 'TR_QUITAR_DE_FAVORITOS';
export const TR_CREAR = 'TR_CREAR';
export const TR_ELIMINAR = 'TR_ELIMINAR';
export const TR_ELIMINADOS_CORRECTAMENTE = 'TR_ELIMINADOS_CORRECTAMENTE';
export const TR_ANADIDOS_A_FAVORITOS_CORRECTAMENTE = 'TR_ANADIDOS_A_FAVORITOS_CORRECTAMENTE';
export const TR_QUITADOS_DE_FAVORITOS_CORRECTAMENTE = 'TR_QUITADOS_DE_FAVORITOS_CORRECTAMENTE';
export const TR_ANADIDO_A_FAVORITOS_CORRECTAMENTE = 'TR_ANADIDO_A_FAVORITOS_CORRECTAMENTE';
export const TR_QUITADO_DE_FAVORITOS_CORRECTAMENTE = 'TR_QUITADO_DE_FAVORITOS_CORRECTAMENTE';
export const TR_BUSCANDO = 'TR_BUSCANDO';
export const TR_NO_SE_HAN_ENCONTRADO_RESULTADOS = 'TR_NO_SE_HAN_ENCONTRADO_RESULTADOS';
export const TR_ROLES = 'TR_ROLES';
export const TR_DE = 'TR_DE';
export const TR_NUEVO = 'TR_NUEVO';
export const TR_VALOR_MAXIMO = 'TR_VALOR_MAXIMO';
export const TR_VALOR_MINIMO = 'TR_VALOR_MINIMO';
export const TR_LIMITE_DE_CARACTERES = 'TR_LIMITE_DE_CARACTERES';
export const TR_CERRAR = 'TR_CERRAR';
export const TR_FILTROS = 'TR_FILTROS';
export const TR_MENU = 'TR_MENU';
export const TR_PERFIL = 'TR_PERFIL';
export const TR_SALIR = 'TR_SALIR';
export const TR_INICIO = 'TR_INICIO';
export const TR_MOVIMIENTOS = 'TR_MOVIMIENTOS';
export const TR_NUEVA_ENTRADA = 'TR_NUEVA_ENTRADA';
export const TR_NUEVA_SALIDA = 'TR_NUEVA_SALIDA';
export const TR_TRANSPORTISTAS = 'TR_TRANSPORTISTAS';
export const TR_SESIONES = 'TR_SESIONES';
export const TR_BUSCAR = 'TR_BUSCAR';
export const TR_VER_TODAS_LAS_ALERTAS = 'TR_VER_TODAS_LAS_ALERTAS';
export const TR_CERRAR_SESSION = 'TR_CERRAR_SESSION';
export const TR_ESTA_SEGURO_QUE_DESEA_CERRAR_SESSION = 'TR_ESTA_SEGURO_QUE_DESEA_CERRAR_SESSION';
export const TR_IR_A_LA_PAGINA = 'TR_IR_A_LA_PAGINA';
export const TR_DATOS_DE_AUTENTICACION_INCORRECTOS = 'TR_DATOS_DE_AUTENTICACION_INCORRECTOS';
export const TR_MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE = 'TR_MOVILIDAD_INTELIGENTE_Y_SOSTENIBLE';
export const TR_ABRIR_SESION = 'TR_ABRIR_SESION';
export const TR_EMAIL_O_NOMBRE_DE_USUARIO = 'TR_EMAIL_O_NOMBRE_DE_USUARIO';
export const TR_CONTRASENA = 'TR_CONTRASENA';
export const TR_RECUERDAME = 'TR_RECUERDAME';
export const TR_LOGIN = 'TR_LOGIN';
export const TR_OLVIDASTE_TU_CONTRASENA = 'TR_OLVIDASTE_TU_CONTRASENA';
export const TR_RESTABLECE_LA_CONTRASENA = 'TR_RESTABLECE_LA_CONTRASENA';
export const TR_TE_MANDAREMOS_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA = 'TR_TE_MANDAREMOS_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA';
export const TR_TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA = 'TR_TE_HEMOS_MANDADO_UN_MAIL_CON_LAS_INSTRUCCIONES_PARA_RESTABLECER_TU_CONTRASENA';
export const TR_LA_SOLICITUD_HA_CADUCADO_SI_ES_NECESARIO_SOLICITAR_DE_NUEVO_EL_CAMBIO_DE_CONTRASENA = 'TR_LA_SOLICITUD_HA_CADUCADO_SI_ES_NECESARIO_SOLICITAR_DE_NUEVO_EL_CAMBIO_DE_CONTRASENA';
export const TR_USUARIO_NO_ENCONTRADO = 'TR_USUARIO_NO_ENCONTRADO';
export const TR_INTRODUCE_TU_CONTRASENA = 'TR_INTRODUCE_TU_CONTRASENA';
export const TR_POR_RAZONES_DE_SEGURIDAD_ES_NECESARIO_CAMBIAR_LA_CONTRASENA = 'TR_POR_RAZONES_DE_SEGURIDAD_ES_NECESARIO_CAMBIAR_LA_CONTRASENA';
export const TR_LOS_DATOS_ENVIADOS_NO_SON_CORRECTOS = 'TR_LOS_DATOS_ENVIADOS_NO_SON_CORRECTOS';
export const TR_USUARIO_BLOQUEADO_NO_SE_PUEDE_HACER_LOGIN_DURANTE_LAS_SIGUIENTES_2_HORAS = 'TR_USUARIO_BLOQUEADO_NO_SE_PUEDE_HACER_LOGIN_DURANTE_LAS_SIGUIENTES_2_HORAS';
export const TR_ALERTAS = 'TR_ALERTAS';
export const TR_PENDIENTES = 'TR_PENDIENTES';
export const TR_FAVORITOS = 'TR_FAVORITOS';
export const TR_DASHBOARDS = 'TR_DASHBOARDS';
export const TR_FORMULARIOS = 'TR_FORMULARIOS';
export const TR_RESULTADO_DE_FORMULARIO = 'TR_RESULTADO_DE_FORMULARIO';
export const TR_DOCUMENTACION = 'TR_DOCUMENTACION';
export const TR_ENTIDAD = 'TR_ENTIDAD';
export const TR_FECHA_OBLIGATORIA = 'TR_FECHA_OBLIGATORIA';
export const TR_HORA_OBLIGATORIA = 'TR_HORA_OBLIGATORIA';
export const TR_VER_ENTRADA = 'TR_VER_ENTRADA';
export const TR_USUARIOS = 'TR_USUARIOS';
export const TR_NO_EXISTEN_USUARIOS = 'TR_NO_EXISTEN_USUARIOS';
export const TR_NUEVO_USUARIO = 'TR_NUEVO_USUARIO';
export const TR_USUARIO = 'TR_USUARIO';
export const TR_CREAR_USUARIO = 'TR_CREAR_USUARIO';
export const TR_USUARIO_CREADO_CORRECTAMENTE = 'TR_USUARIO_CREADO_CORRECTAMENTE';
export const TR_USUARIO_MODIFICADO_CORRECTAMENTE = 'TR_USUARIO_MODIFICADO_CORRECTAMENTE';
export const TR_CAMBIAR_CONTRASENA = 'TR_CAMBIAR_CONTRASENA';
export const TR_RESTABLECER_CONTRASENA = 'TR_RESTABLECER_CONTRASENA';
export const TR_NUEVA_CONTRASENA = 'TR_NUEVA_CONTRASENA';
export const TR_REPITE_LA_CONTRASENA = 'TR_REPITE_LA_CONTRASENA';
export const TR_LA_CONTRASENA_NO_PUEDE_ESTAR_VACIA = 'TR_LA_CONTRASENA_NO_PUEDE_ESTAR_VACIA';
export const TR_LA_CONTRASENA_TIENE_POCOS_CARACTERES = 'TR_LA_CONTRASENA_TIENE_POCOS_CARACTERES';
export const TR_LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES = 'TR_LA_CONTRASENA_DEBE_CONTENER_AL_MENOS_UN_NUMERO_UNA_MAYUSCULA_Y_8_CARACTERES';
export const TR_CONTRASENA_MODIFICADA_CORRECTAMENTE = 'TR_CONTRASENA_MODIFICADA_CORRECTAMENTE';
export const TR_NO_SE_HA_PODIDO_MODIFICAR_LA_CONTRASENA = 'TR_NO_SE_HA_PODIDO_MODIFICAR_LA_CONTRASENA';
export const TR_ANADE_AL_MENOS_UN_USUARIO = 'TR_ANADE_AL_MENOS_UN_USUARIO';
export const TR_GRUPOS = 'TR_GRUPOS';
export const TR_YA_HA_SIDO_ANADIDO_ESTE_GRUPO = 'TR_YA_HA_SIDO_ANADIDO_ESTE_GRUPO';
export const TR_ELIMINAR_USUARIOS = 'TR_ELIMINAR_USUARIOS';
export const TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES = 'TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES';
export const TR_EL_USUARIO_NO_EXISTE = 'TR_EL_USUARIO_NO_EXISTE';
export const TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE = 'TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE';
export const TR_EXPLOTACION_YA_EXISTE = 'TR_EXPLOTACION_YA_EXISTE';
export const TR_EXPLOTACIONES = 'TR_EXPLOTACIONES';
export const TR_LOG = 'TR_LOG';
export const TR_ADMINISTRACION = 'TR_ADMINISTRACION';
export const TR_NOMBRE_OBLIGATORIO = 'TR_NOMBRE_OBLIGATORIO';
export const TR_APELLIDO_OBLIGATORIO = 'TR_APELLIDO_OBLIGATORIO';
export const TR_EMAIL_OBLIGATORIO = 'TR_EMAIL_OBLIGATORIO';
export const TR_CONTRASENA_OBLIGATORIA = 'TR_CONTRASENA_OBLIGATORIA';
export const TR_EXPLOTACION_OBLIGATORIO = 'TR_EXPLOTACION_OBLIGATORIO';
export const TR_ROL_OBLIGATORIO = 'TR_ROL_OBLIGATORIO';
export const TR_GRUPO_DE_USUARIOS = 'TR_GRUPO_DE_USUARIOS';
export const TR_GRUPOS_DE_USUARIOS = 'TR_GRUPOS_DE_USUARIOS';
export const TR_NUEVO_GRUPO_DE_USUARIOS = 'TR_NUEVO_GRUPO_DE_USUARIOS';
export const TR_CREAR_GRUPO_DE_USUARIOS = 'TR_CREAR_GRUPO_DE_USUARIOS';
export const TR_NO_EXISTEN_GRUPOS_DE_USUARIOS = 'TR_NO_EXISTEN_GRUPOS_DE_USUARIOS';
export const TR_GRUPO_DE_USUARIOS_CREADO_CORRECTAMENTE = 'TR_GRUPO_DE_USUARIOS_CREADO_CORRECTAMENTE';
export const TR_GRUPO_DE_USUARIOS_MODIFICADO_CORRECTAMENTE = 'TR_GRUPO_DE_USUARIOS_MODIFICADO_CORRECTAMENTE';
export const TR_AL_MENOS_DEBE_TENER_UN_ROL_ASIGNADO = 'TR_AL_MENOS_DEBE_TENER_UN_ROL_ASIGNADO';
export const TR_YA_HA_SIDO_ANADIDO_ESTE_USUARIO = 'TR_YA_HA_SIDO_ANADIDO_ESTE_USUARIO';
export const TR_YA_HAS_ASIGNADO_ESTE_PERMISO = 'TR_YA_HAS_ASIGNADO_ESTE_PERMISO';
export const TR_SELECCIONA_UN_USUARIO = 'TR_SELECCIONA_UN_USUARIO';
export const TR_ELIMINAR_GRUPOS_DE_USUARIOS = 'TR_ELIMINAR_GRUPOS_DE_USUARIOS';
export const TR_ZONA = 'TR_ZONA';
export const TR_ZONAS = 'TR_ZONAS';
export const TR_NUEVA_ZONA = 'TR_NUEVA_ZONA';
export const TR_CREAR_ZONA = 'TR_CREAR_ZONA';
export const TR_NO_EXISTEN_ZONAS = 'TR_NO_EXISTEN_ZONAS';
export const TR_ZONA_CREADA_CORRECTAMENTE = 'TR_ZONA_CREADA_CORRECTAMENTE';
export const TR_ZONA_MODIFICADA_CORRECTAMENTE = 'TR_ZONA_MODIFICADA_CORRECTAMENTE';
export const TR_ELIMINAR_ZONAS = 'TR_ELIMINAR_ZONAS';
export const TR_TIPO_DE_EXPLOTACION = 'TR_TIPO_DE_EXPLOTACION';
export const TR_TIPOS_DE_EXPLOTACION = 'TR_TIPOS_DE_EXPLOTACION';
export const TR_NUEVO_TIPO_DE_EXPLOTACION = 'TR_NUEVO_TIPO_DE_EXPLOTACION';
export const TR_CREAR_TIPO_DE_EXPLOTACION = 'TR_CREAR_TIPO_DE_EXPLOTACION';
export const TR_NO_EXISTEN_TIPOS_DE_EXPLOTACION = 'TR_NO_EXISTEN_TIPOS_DE_EXPLOTACION';
export const TR_TIPO_DE_EXPLOTACION_CREADO_CORRECTAMENTE = 'TR_TIPO_DE_EXPLOTACION_CREADO_CORRECTAMENTE';
export const TR_TIPO_DE_EXPLOTACION_MODIFICADO_CORRECTAMENTE = 'TR_TIPO_DE_EXPLOTACION_MODIFICADO_CORRECTAMENTE';
export const TR_ELIMINAR_TIPOS_DE_EXPLOTACION = 'TR_ELIMINAR_TIPOS_DE_EXPLOTACION';
export const TR_ESTADO = 'TR_ESTADO';
export const TR_NOMBRE_FORMULARIO = 'TR_NOMBRE_FORMULARIO';
export const TR_VALIDADO = 'TR_VALIDADO';
export const TR_PERIODICIDAD = 'TR_PERIODICIDAD';
export const TR_NO_EXISTEN_FORMULARIOS = 'TR_NO_EXISTEN_FORMULARIOS';
export const TR_FORMULARIO = 'TR_FORMULARIO';
export const TR_TIPOLOGIA = 'TR_TIPOLOGIA';
export const TR_REQUIERE_VALIDACION = 'TR_REQUIERE_VALIDACION';
export const TR_SELECCIONAR_USUARIO_PARA_VALIDAR = 'TR_SELECCIONAR_USUARIO_PARA_VALIDAR';
export const TR_PERMISOS = 'TR_PERMISOS';
export const TR_POR_ROL = 'TR_POR_ROL';
export const TR_POR_EXPLOTACION = 'TR_POR_EXPLOTACION';
export const TR_POR_USUARIO = 'TR_POR_USUARIO';
export const TR_ESTADO_OBLIGATORIO = 'TR_ESTADO_OBLIGATORIO';
export const TR_TIPOLOGIA_OBLIGATORIA = 'TR_TIPOLOGIA_OBLIGATORIA';
export const TR_PERIODICIDAD_OBLIGATORIA = 'TR_PERIODICIDAD_OBLIGATORIA';
export const TR_USUARIO_OBLIGATORIO = 'TR_USUARIO_OBLIGATORIO';
export const TR_CREAR_FORMULARIO = 'TR_CREAR_FORMULARIO';
export const TR_NUEVO_FORMULARIO = 'TR_NUEVO_FORMULARIO';
export const TR_LAS_CONTRASENAS_NO_COINCIDEN = 'TR_LAS_CONTRASENAS_NO_COINCIDEN';
export const TR_TIPO_DE_CAMPO = 'TR_TIPO_DE_CAMPO';
export const TR_NOMBRE_DEL_CAMPO = 'TR_NOMBRE_DEL_CAMPO';
export const TR_CLAVE = 'TR_CLAVE';
export const TR_NO_EXISTEN_TIPOS_DE_CAMPOS_DE_FORMULARIO = 'TR_NO_EXISTEN_TIPOS_DE_CAMPOS_DE_FORMULARIO';
export const TR_CLAVE_OBLIGATORIA = 'TR_CLAVE_OBLIGATORIA';
export const TR_FORMULARIO_MODIFICADO_CORRECTAMENTE = 'TR_FORMULARIO_MODIFICADO_CORRECTAMENTE';
export const TR_FORMULARIOS_ELIMINADOS_CORRECTAMENTE = 'TR_FORMULARIOS_ELIMINADOS_CORRECTAMENTE';
export const TR_ERROR_AL_ELIMINAR_LOS_FORMULARIOS = 'TR_ERROR_AL_ELIMINAR_LOS_FORMULARIOS';
export const TR_ELIMINAR_FORMULARIOS = 'TR_ELIMINAR_FORMULARIOS';
export const TR_YA_HA_SIDO_ANADIDA_ESTA_EXPLOTACION = 'TR_YA_HA_SIDO_ANADIDA_ESTA_EXPLOTACION';
export const TR_SELECCIONA_UNA_EXPLOTACION = 'TR_SELECCIONA_UNA_EXPLOTACION';
export const TR_ANADE_AL_MENOS_UNA_EXPLOTACION = 'TR_ANADE_AL_MENOS_UNA_EXPLOTACION';
export const TR_ANADE_AL_MENOS_UN_ROL = 'TR_ANADE_AL_MENOS_UN_ROL';
export const TR_YA_HA_SIDO_ANADIDO_ESTE_ROL = 'TR_YA_HA_SIDO_ANADIDO_ESTE_ROL';
export const TR_SELECCIONA_UN_ROL = 'TR_SELECCIONA_UN_ROL';
export const TR_VALOR_POR_DEFECTO = 'TR_VALOR_POR_DEFECTO';
export const TR_TEXTO_VALORES = 'TR_TEXTO_VALORES';
export const TR_USUARIO_CREADOR = 'TR_USUARIO_CREADOR';
export const TR_ANADE_TUS_FORMULARIOS_FAVORITOS = 'TR_ANADE_TUS_FORMULARIOS_FAVORITOS';
export const TR_EL_VALOR_NO_DEBE_SER_MAYOR_DE = 'TR_EL_VALOR_NO_DEBE_SER_MAYOR_DE';
export const TR_EL_VALOR_NO_DEBE_SER_MENOR_DE = 'TR_EL_VALOR_NO_DEBE_SER_MENOR_DE';
export const TR_EL_TEXTO_NO_DEBE_CONTENER_MAS_DE = 'TR_EL_TEXTO_NO_DEBE_CONTENER_MAS_DE';
export const TR_CARACTERES = 'TR_CARACTERES';
export const TR_FORMULARIO_RELLENADO_CORRECTAMENTE = 'TR_FORMULARIO_RELLENADO_CORRECTAMENTE';
export const TR_DATOS_DEL_FORMULARIO_MODIFICADOS_CORRECTAMENTE = 'TR_DATOS_DEL_FORMULARIO_MODIFICADOS_CORRECTAMENTE';
export const TR_EL_MAIL_NO_ES_VALIDO = 'TR_EL_MAIL_NO_ES_VALIDO';
export const TR_DATOS_DEL_FORMULARIO = 'TR_DATOS_DEL_FORMULARIO';
export const TR_NO_EXISTEN_DATOS_DEL_FORMULARIO = 'TR_NO_EXISTEN_DATOS_DEL_FORMULARIO';
export const TR_RESULTADO = 'TR_RESULTADO';
export const TR_NUEVOS_DATOS = 'TR_NUEVOS_DATOS';
export const TR_DATOS = 'TR_DATOS';
export const TR_ELIMINAR_DATOS_DEL_FORMULARIO = 'TR_ELIMINAR_DATOS_DEL_FORMULARIO';
export const TR_VER_DATOS = 'TR_VER_DATOS';
export const TR_TIPO = 'TR_TIPO';
export const TR_NO_EXISTEN_LOGS = 'TR_NO_EXISTEN_LOGS';
export const TR_LOGS = 'TR_LOGS';
export const TR_EMPRESA = 'TR_EMPRESA';
export const TR_ACTIVIDAD = 'TR_ACTIVIDAD';
export const TR_FECHA_ACCESO = 'TR_FECHA_ACCESO';
export const TR_TIPO_DE_ACCESO = 'TR_TIPO_DE_ACCESO';
export const TR_FORMULARIO_CONSULTADO = 'TR_FORMULARIO_CONSULTADO';
export const TR_LA_CLAVE_NO_DEBE_CONTENER_ESPACIOS_EN_BLANCO = 'TR_LA_CLAVE_NO_DEBE_CONTENER_ESPACIOS_EN_BLANCO';
export const TR_NOMBRE_DEL_FORMULARIO = 'TR_NOMBRE_DEL_FORMULARIO';
export const TR_ANADIR_NUEVO_CAMPO = 'TR_ANADIR_NUEVO_CAMPO';
export const TR_SELECCIONA_UN_TIPO_DE_CAMPO = 'TR_SELECCIONA_UN_TIPO_DE_CAMPO';
export const TR_OBLIGATORIO = 'TR_OBLIGATORIO';
export const TR_ES_NECESARIO_INCLUIR_COMO_MINIMO_UNA_OPCION = 'TR_ES_NECESARIO_INCLUIR_COMO_MINIMO_UNA_OPCION';
export const TR_ENUNCIADO_DEL_CAMPO_OBLIGATORIO = 'TR_ENUNCIADO_DEL_CAMPO_OBLIGATORIO';
export const TR_ENUNCIADO_DE_LA_OPCION_OBLIGATORIO = 'TR_ENUNCIADO_DE_LA_OPCION_OBLIGATORIO';
export const TR_OPCIONES = 'TR_OPCIONES';
export const TR_TEXTO = 'TR_TEXTO';
export const TR_TEXTO_TEXTAREA = 'TR_TEXTO_TEXTAREA';
export const TR_TEXTO_NUMBER = 'TR_TEXTO_NUMBER';
export const TR_TEXTO_EMAIL = 'TR_TEXTO_EMAIL';
export const TR_TEXTO_URL = 'TR_TEXTO_URL';
export const TR_TEXTO_DATE = 'TR_TEXTO_DATE';
export const TR_TEXTO_TIME = 'TR_TEXTO_TIME';
export const TR_TEXTO_DATETIME = 'TR_TEXTO_DATETIME';
export const TR_TEXTO_IMAGE = 'TR_TEXTO_IMAGE';
export const TR_TEXTO_AUDIO = 'TR_TEXTO_AUDIO';
export const TR_TEXTO_VIDEO = 'TR_TEXTO_VIDEO';
export const TR_TEXTO_OBLIGATORIO = 'TR_TEXTO_OBLIGATORIO';
export const TR_PREDETERMINADO = 'TR_PREDETERMINADO';
export const TR_ENUNCIADO = 'TR_ENUNCIADO';
export const TR_IDENTIFICADOR_DEL_CAMPO = 'TR_IDENTIFICADOR_DEL_CAMPO';
export const TR_NO_HAY_CAMPOS_DEFINIDOS = 'TR_NO_HAY_CAMPOS_DEFINIDOS';
export const TR_IDENTIFICADOR_OBLIGATORIO = 'TR_IDENTIFICADOR_OBLIGATORIO';
export const TR_EL_IDENTIFICADOR_NO_SE_PUEDE_REPETIR = 'TR_EL_IDENTIFICADOR_NO_SE_PUEDE_REPETIR';
export const TR_EL_IDENTIFICADOR_NO_DEBE_CONTENER_ESPACIOS = 'TR_EL_IDENTIFICADOR_NO_DEBE_CONTENER_ESPACIOS';
export const TR_DEBE_EMPEZAR_POR_UN_CARACTER_VALIDO = 'TR_DEBE_EMPEZAR_POR_UN_CARACTER_VALIDO';
export const TR_IDENTIFICADOR_DEL_FORMULARIO = 'TR_IDENTIFICADOR_DEL_FORMULARIO';
export const TR_FORMULARIO_CREADO_CORRECTAMENTE = 'TR_FORMULARIO_CREADO_CORRECTAMENTE';
export const TR_RESPONSABLE = 'TR_RESPONSABLE';
export const TR_FECHA_INICIO = 'TR_FECHA_INICIO';
export const TR_FECHA_FIN = 'TR_FECHA_FIN';
export const TR_TIPO_OBLIGATORIO = 'TR_TIPO_OBLIGATORIO';
export const TR_NOMBRE_DE_LA_EXPLOTACION = 'TR_NOMBRE_DE_LA_EXPLOTACION';
export const TR_DESCRIPCION_DE_LA_EXPLOTACION = 'TR_DESCRIPCION_DE_LA_EXPLOTACION';
export const TR_USUARIOS_DE_LA_EXPLOTACION = 'TR_USUARIOS_DE_LA_EXPLOTACION';
export const TR_NUEVA_EXPLOTACION = 'TR_NUEVA_EXPLOTACION';
export const TR_CREAR_EXPLOTACION = 'TR_CREAR_EXPLOTACION';
export const TR_HABILITADO = 'TR_HABILITADO';
export const TR_DESHABILITADO = 'TR_DESHABILITADO';
export const TR_NO_EXISTEN_EXPLOTACIONES = 'TR_NO_EXISTEN_EXPLOTACIONES';
export const TR_EXPLOTACION_MODIFICADA_CORRECTAMENTE = 'TR_EXPLOTACION_MODIFICADA_CORRECTAMENTE';
export const TR_EXPLOTACION_CREADA_CORRECTAMENTE = 'TR_EXPLOTACION_CREADA_CORRECTAMENTE';
export const TR_ZONA_OBLIGATORIA = 'TR_ZONA_OBLIGATORIA';
export const TR_ELIMINAR_EXPLOTACIONES = 'TR_ELIMINAR_EXPLOTACIONES';
export const TR_DESTINATARIO = 'TR_DESTINATARIO';
export const TR_FECHA_ENVIO = 'TR_FECHA_ENVIO';
export const TR_ALERTA = 'TR_ALERTA';
export const TR_NOMBRE_DE_LA_ALERTA = 'TR_NOMBRE_DE_LA_ALERTA';
export const TR_DESCRIPCION_DE_LA_ALERTA = 'TR_DESCRIPCION_DE_LA_ALERTA';
export const TR_ENLAZAR_CON = 'TR_ENLAZAR_CON';
export const TR_ENVIAR = 'TR_ENVIAR';
export const TR_INACTIVA = 'TR_INACTIVA';
export const TR_ACTIVA = 'TR_ACTIVA';
export const TR_ELIMINAR_ALERTA = 'TR_ELIMINAR_ALERTA';
export const TR_ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTA_ALERTA = 'TR_ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTA_ALERTA';
export const TR_CREAR_ALERTA = 'TR_CREAR_ALERTA';
export const TR_NUEVA_ALERTA = 'TR_NUEVA_ALERTA';
export const TR_HORA_ENVIO = 'TR_HORA_ENVIO';
export const TR_DESCRIPCION = 'TR_DESCRIPCION';
export const TR_URL = 'TR_URL';
export const TR_ACTIVO = 'TR_ACTIVO';
export const TR_INACTIVO = 'TR_INACTIVO';
export const TR_DESCRIPCION_OBLIGATORIA = 'TR_DESCRIPCION_OBLIGATORIA';
export const TR_URL_OBLIGATORIA = 'TR_URL_OBLIGATORIA';
export const TR_LA_URL_NO_TIENE_EL_FORMATO_ADECUADO = 'TR_LA_URL_NO_TIENE_EL_FORMATO_ADECUADO';
export const TR_TIPO_DE_EXPLOTACION_OBLIGATORIO = 'TR_TIPO_DE_EXPLOTACION_OBLIGATORIO';
export const TR_NUEVO_DASHBOARD = 'TR_NUEVO_DASHBOARD';
export const TR_NO_EXISTEN_DASHBOARDS = 'TR_NO_EXISTEN_DASHBOARDS';
export const TR_CREAR_DASHBOARD = 'TR_CREAR_DASHBOARD';
export const TR_CREADOR = 'TR_CREADOR';
export const TR_GRAFICO = 'TR_GRAFICO';
export const TR_PREDET = 'TR_PREDET';
export const TR_DASHBOARD_MODIFICADO_CORRECTAMENTE = 'TR_DASHBOARD_MODIFICADO_CORRECTAMENTE';
export const TR_DASHBOARD_CREADO_CORRECTAMENTE = 'TR_DASHBOARD_CREADO_CORRECTAMENTE';
export const TR_NO_SE_HA_INTRODUCIDO_NINGUNA_GRAFICA = 'TR_NO_SE_HA_INTRODUCIDO_NINGUNA_GRAFICA';
export const TR_PREDETERMINADO_OBLIGATORIO = 'TR_PREDETERMINADO_OBLIGATORIO';
export const TR_ELIMINAR_DASHBOARD = 'TR_ELIMINAR_DASHBOARD';
export const TR_ANADE_TUS_DASHBOARDS_FAVORITOS = 'TR_ANADE_TUS_DASHBOARDS_FAVORITOS';
export const TR_VER_DISENO = 'TR_VER_DISENO';
export const TR_DISENO_DEL_DASHBOARD = 'TR_DISENO_DEL_DASHBOARD';
export const TR_EDITAR_DISENO = 'TR_EDITAR_DISENO';
export const TR_SELECCIONAR_EL_DISENO_DEL_DASHBOARD = 'TR_SELECCIONAR_EL_DISENO_DEL_DASHBOARD';
export const TR_DOCUMENTO = 'TR_DOCUMENTO';
export const TR_DOCUMENTOS = 'TR_DOCUMENTOS';
export const TR_NO_EXISTEN_DOCUMENTOS = 'TR_NO_EXISTEN_DOCUMENTOS';
export const TR_CREAR_DOCUMENTO = 'TR_CREAR_DOCUMENTO';
export const TR_ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTE_DOCUMENTO = 'TR_ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTE_DOCUMENTO';
export const TR_NUEVO_DOCUMENTO = 'TR_NUEVO_DOCUMENTO';
export const TR_MIS_DOCUMENTOS = 'TR_MIS_DOCUMENTOS';
export const TR_SUBCONTRATA = 'TR_SUBCONTRATA';
export const TR_NOMBRE_DOCUMENTO = 'TR_NOMBRE_DOCUMENTO';
export const TR_FECHA = 'TR_FECHA';
export const TR_CADUCIDAD = 'TR_CADUCIDAD';
export const TR_DOCUMENTO_MODIFICADO_CORRECTAMENTE = 'TR_DOCUMENTO_MODIFICADO_CORRECTAMENTE';
export const TR_DOCUMENTO_CREADO_CORRECTAMENTE = 'TR_DOCUMENTO_CREADO_CORRECTAMENTE';
export const TR_DOCUMENTO_ELIMINADO_CORRECTAMENTE = 'TR_DOCUMENTO_ELIMINADO_CORRECTAMENTE';
export const TR_ELIMINAR_DOCUMENTOS = 'TR_ELIMINAR_DOCUMENTOS';
export const TR_ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO = 'TR_ARRASTRA_UN_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLO';
export const TR_ARRASTRA_LOS_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS = 'TR_ARRASTRA_LOS_ARCHIVO_AQUI_O_HAZ_CLICK_PARA_SELECCIONARLOS';
export const TR_FICHERO_CARGADO_CORRECTAMENTE = 'TR_FICHERO_CARGADO_CORRECTAMENTE';
export const TR_FICHERO_OBLIGATORIO = 'TR_FICHERO_OBLIGATORIO';
export const TR_FICHERO_ADJUNTO = 'TR_FICHERO_ADJUNTO';
export const TR_ANADE_TUS_DOCUMENTOS_FAVORITOS = 'TR_ANADE_TUS_DOCUMENTOS_FAVORITOS';
export const TR_MIS_FORMULARIOS = 'TR_MIS_FORMULARIOS';
export const TR_DASHBOARD = 'TR_DASHBOARD';
export const TR_MIS_DASHBOARDS = 'TR_MIS_DASHBOARDS';
export const TR_NOTIFICACIONES = 'TR_NOTIFICACIONES';
export const TR_NO_EXISTEN_NOTIFICACIONES = 'TR_NO_EXISTEN_NOTIFICACIONES';
export const TR_PENDIENTE = 'TR_PENDIENTE';
export const TR_TIPO_DE_NOTIFICATION = 'TR_TIPO_DE_NOTIFICATION';
export const TR_FECHA_RESOLUCION = 'TR_FECHA_RESOLUCION';
export const TR_LINK_FORMULARIO = 'TR_LINK_FORMULARIO';
export const TR_LINK_DOCUMENTACION = 'TR_LINK_DOCUMENTACION';
export const TR_NOTIFICACION = 'TR_NOTIFICACION';
export const TR_OBSERVACIONES = 'TR_OBSERVACIONES';
export const TR_FECHA_VALOR = 'TR_FECHA_VALOR';
export const TR_RESULTADOS_DE_FORMULARIOS = 'TR_RESULTADOS_DE_FORMULARIOS';
export const TR_NO_EXISTEN_RESULTADOS_DE_FORMULARIOS = 'TR_NO_EXISTEN_RESULTADOS_DE_FORMULARIOS';
export const TR_FECHA_SUBIDA_FORMULARIO = 'TR_FECHA_SUBIDA_FORMULARIO';
export const TR_ID = 'TR_ID';
export const TR_NO_EXISTEN_ALERTAS = 'TR_NO_EXISTEN_ALERTAS';
export const TR_ELIMINAR_ALERTAS = 'TR_ELIMINAR_ALERTAS';
export const TR_VER = 'TR_VER';
export const TR_RESOLVER = 'TR_RESOLVER';
export const TR_RESUELTA = 'TR_RESUELTA';
export const TR_MIS_ALERTAS = 'TR_MIS_ALERTAS';
export const TR_ALERTA_MODIFICADA_CORRECTAMENTE = 'TR_ALERTA_MODIFICADA_CORRECTAMENTE';
export const TR_ALERTA_CREADA_CORRECTAMENTE = 'TR_ALERTA_CREADA_CORRECTAMENTE';
export const TR_NO_HAY_ALERTAS = 'TR_NO_HAY_ALERTAS';
export const TR_CLAVE_ELK = 'TR_CLAVE_ELK';
export const TR_FILTRAR_POR_ZONA = 'TR_FILTRAR_POR_ZONA';
export const TR_FILTRAR_POR_TIPO_DE_SERVICIO = 'TR_FILTRAR_POR_TIPO_DE_SERVICIO';
export const TR_FILTRAR_POR_TIEMPO = 'TR_FILTRAR_POR_TIEMPO';
export const TR_PERSONALIZADO = 'TR_PERSONALIZADO';
export const TR_DESDE = 'TR_DESDE';
export const TR_HASTA = 'TR_HASTA';
export const TR_RECIENTE = 'TR_RECIENTE';
export const TR_ULTIMOS_7_DIAS = 'TR_ULTIMOS_7_DIAS';
export const TR_ULTIMO_MES = 'TR_ULTIMO_MES';
export const TR_ULTIMOS_3_MESES = 'TR_ULTIMOS_3_MESES';
export const TR_ULTIMO_ANO = 'TR_ULTIMO_ANO';
export const TR_ANO = 'TR_ANO';
export const TR_PERIODO = 'TR_PERIODO';
export const TR_TODO_EL_ANO = 'TR_TODO_EL_ANO';
export const TR_PRIMER_TRIMESTRE = 'TR_PRIMER_TRIMESTRE';
export const TR_SEGUNDO_TRIMESTRE = 'TR_SEGUNDO_TRIMESTRE';
export const TR_TERCER_TRIMESTRE = 'TR_TERCER_TRIMESTRE';
export const TR_CUARTO_TRIMESTRE = 'TR_CUARTO_TRIMESTRE';
export const TR_APLICAR = 'TR_APLICAR';
export const TR_LINKS = 'TR_LINKS';
export const TR_APLICACIONES = 'TR_APLICACIONES';
export const TR_NO_EXISTEN_LINKS = 'TR_NO_EXISTEN_LINKS';
export const TR_ERROR_LISTADO_LINKS = 'TR_ERROR_LISTADO_LINKS';
export const TR_IMAGEN = 'TR_IMAGEN';
export const TR_ELIMINAR_LINKS = 'TR_ELIMINAR_LINKS';
export const TR_LINK = 'TR_LINK';
export const TR_NUEVO_LINK = 'TR_NUEVO_LINK';
export const TR_ERROR_NUEVO_LINK = 'TR_ERROR_NUEVO_LINK';
export const TR_SUCCESS_NUEVO_LINK = 'TR_SUCCESS_NUEVO_LINK';
export const TR_IMAGEN_OBLIGATORIA = 'TR_IMAGEN_OBLIGATORIA';
export const TR_EMAIL_INCORRECTO = 'TR_EMAIL_INCORRECTO';
export const TR_SUCCES_EDITAR_LINK = 'TR_SUCCES_EDITAR_LINK';
export const TR_FAIL_EDITAR_LINK = 'TR_FAIL_EDITAR_LINK';
export const TR_LANZADOR_APPS = 'TR_LANZADOR_APPS';
export const TR_NO_EXISTEN_APLICACIONES = 'TR_NO_EXISTEN_APLICACIONES';
export const TR_URL_INCORRECTA = 'TR_URL_INCORRECTA';
export const TR_IMAGEN_INCORRECTA = 'TR_IMAGEN_INCORRECTA';
export const TR_IMAGEN_VISTA_PREVIA = 'TR_IMAGEN_VISTA_PREVIA';
export const TR_NOMBRE_DEL_CONTRATO = 'TR_NOMBRE_DEL_CONTRATO';
export const TR_NOMBRE_DEL_CONTRATO_OBLIGATORIO = 'TR_NOMBRE_DEL_CONTRATO_OBLIGATORIO';
export const TR_RESPONSABLES = 'TR_RESPONSABLES';
export const TR_RESPONSABLE_OBLIGATORIO = 'TR_RESPONSABLE_OBLIGATORIO';
export const TR_RESPONSABLE_DUPLICADO = 'TR_RESPONSABLE_DUPLICADO';
export const TR_CARACTERISTICAS_EXPLOTACION = 'TR_CARACTERISTICAS_EXPLOTACION';
export const TR_COMPANYIA = 'TR_COMPANYIA';
export const TR_COMPANYIA_PORCENTAJE = 'TR_COMPANYIA_PORCENTAJE';
export const TR_COMPANYIA_CIF = 'TR_COMPANYIA_CIF';
export const TR_APODERADOS = 'TR_APODERADOS';
export const TR_CARACTERISTICAS_EXPLOTACION_EDITADAS_CORRECTAMENTE = 'TR_CARACTERISTICAS_EXPLOTACION_EDITADAS_CORRECTAMENTE';
export const TR_CIF_INCORRECTO = 'TR_CIF_INCORRECTO';
export const TR_COMPANYA_OBLIGATORIA = 'TR_COMPANYA_OBLIGATORIA';
export const TR_PROXIE_OBLIGATORIO = 'TR_PROXIE_OBLIGATORIO';
export const TR_PORCENTAJE_OBLIGATORIO = 'TR_PORCENTAJE_OBLIGATORIO';
export const TR_PORCENTAJE_INCORRECTO = 'TR_PORCENTAJE_INCORRECTO';
export const TR_CIF_OBLIGATORIO = 'TR_CIF_OBLIGATORIO';
export const TR_REVERSION = 'TR_REVERSION';
export const TR_REVERSION_OBLIGATORIA = 'TR_REVERSION_OBLIGATORIA';
export const TR_REVERSION_EDITADA_CORRECTAMENTE = 'TR_REVERSION_EDITADA_CORRECTAMENTE';
export const TR_PLAZAS = 'TR_PLAZAS';
export const TR_PLAZAS_OFRECIDAS = 'TR_PLAZAS_OFRECIDAS';
export const TR_PLAZAS_OFRECIDAS_OBLIGATORIAS = 'TR_PLAZAS_OFRECIDAS_OBLIGATORIAS';
export const TR_PLAZAS_OFRECIDAS_INCORRECTAS = 'TR_PLAZAS_OFRECIDAS_INCORRECTAS';
export const TR_PLAZAS_REALES = 'TR_PLAZAS_REALES';
export const TR_PLAZAS_REALES_OBLIGATORIAS = 'TR_PLAZAS_REALES_OBLIGATORIAS';
export const TR_PLAZAS_REALES_INCORRECTAS = 'TR_PLAZAS_REALES_INCORRECTAS';
export const TR_CALENDARIO_SERVICIO_FESTIVOS = 'TR_CALENDARIO_SERVICIO_FESTIVOS';
export const TR_METODOS_PAGO = 'TR_METODOS_PAGO';
export const TR_METODOS_PAGO_OBLIGATORIOS = 'TR_METODOS_PAGO_OBLIGATORIOS';
export const TR_SERVICIO_EDITADO_CORRECTAMENTE = 'TR_SERVICIO_EDITADO_CORRECTAMENTE';
export const TR_FECHAS_EDITADAS_CORRECTAMENTE = 'TR_FECHAS_EDITADAS_CORRECTAMENTE';
export const TR_FECHA_LIMITE_PEDIR_EXTENSION = 'TR_FECHA_LIMITE_PEDIR_EXTENSION';
export const TR_FECHA_LIMITE_PEDIR_EXTENSION_OBLIGATORIA = 'TR_FECHA_LIMITE_PEDIR_EXTENSION_OBLIGATORIA';
export const TR_FECHA_INICIO_SERVICIO = 'TR_FECHA_INICIO_SERVICIO';
export const TR_FECHA_INICIO_SERVICIO_OBLIGATORIA = 'TR_FECHA_INICIO_SERVICIO_OBLIGATORIA';
export const TR_FECHA_FIN_SERVICIO = 'TR_FECHA_FIN_SERVICIO';
export const TR_FECHA_FIN_SERVICIO_OBLIGATORIA = 'TR_FECHA_FIN_SERVICIO_OBLIGATORIA';
export const TR_FECHA_INICIO_EXTENSION = 'TR_FECHA_INICIO_EXTENSION';
export const TR_FECHA_INICIO_EXTENSION_OBLIGATORIA = 'TR_FECHA_INICIO_EXTENSION_OBLIGATORIA';
export const TR_FECHA_FIN_EXTENSION = 'TR_FECHA_FIN_EXTENSION';
export const TR_FECHA_FIN_EXTENSION_OBLIGATORIA = 'TR_FECHA_FIN_EXTENSION_OBLIGATORIA';
export const TR_CARACTERISTICAS = 'TR_CARACTERISTICAS';
export const TR_FECHAS = 'TR_FECHAS';
export const TR_SERVICIOS = 'TR_SERVICIOS';
export const TR_CONFIRMACION_DE_CANCELACION = 'TR_CONFIRMACION_DE_CANCELACION';
export const TR_SE_PERDERAN_TODOS_LOS_DATOS = 'TR_SE_PERDERAN_TODOS_LOS_DATOS';
export const TR_TAREA_ELIMINADA_CORRECTAMENTE = 'TR_TAREA_ELIMINADA_CORRECTAMENTE';
export const TR_FALLO_ELIMINAR_TAREA = 'TR_FALLO_ELIMINAR_TAREA';
export const TR_FECHA_FIRMA_ACTA = 'TR_FECHA_FIRMA_ACTA';
export const TR_ALERTA_FECHA_FIRMA_ACTA = 'TR_ALERTA_FECHA_FIRMA_ACTA';
export const TR_FECHA_FIRMA_ACTA_OBLIGATORIA = 'TR_FECHA_FIRMA_ACTA_OBLIGATORIA';
export const TR_FECHA_FIRMA_REGISTRO = 'TR_FECHA_FIRMA_REGISTRO';
export const TR_ALERTA_FECHA_INICIO_SERVICIO = 'TR_ALERTA_FECHA_INICIO_SERVICIO';
export const TR_ALERTA_FECHA_FIN_SERVICIO = 'TR_ALERTA_FECHA_FIN_SERVICIO';
export const TR_ALERTA_FECHA_FIN_SERVICIO_OBLIGATORIA = 'TR_ALERTA_FECHA_FIN_SERVICIO_OBLIGATORIA';
export const TR_ALERTA_FECHA_INICIO_EXTENSION = 'TR_ALERTA_FECHA_INICIO_EXTENSION';
export const TR_ALERTA_FECHA_FIN_EXTENSION = 'TR_ALERTA_FECHA_FIN_EXTENSION';
export const TR_ALERTA_FECHA_EXTENSION_LIMITE = 'TR_ALERTA_FECHA_EXTENSION_LIMITE';
export const TR_ALERTA_ELIMINADA_CORRECTAMENTE = 'TR_ALERTA_ELIMINADA_CORRECTAMENTE';
export const TR_ALERTA_VACACIONES = 'TR_ALERTA_VACACIONES';
export const TR_ALERTA_FESTIVOS = 'TR_ALERTA_FESTIVOS';
export const TR_DOCUMENTO_FIRMA_REGISTRO = 'TR_DOCUMENTO_FIRMA_REGISTRO';
export const TR_POSICION = 'TR_POSICION';
export const TR_LA_POSICION_ES_OBLIGATORIA = 'TR_LA_POSICION_ES_OBLIGATORIA';
export const TR_RECEPTORES = 'TR_RECEPTORES';
export const TR_JEFES_DE_EXPLOTACION = 'TR_JEFES_DE_EXPLOTACION';
export const TR_CANAL = 'TR_CANAL';
export const TR_AVISO_CAMBIO_FORMULARIO_EXPLOTACION = 'TR_AVISO_CAMBIO_FORMULARIO_EXPLOTACION';
export const TR_AVISO_PERDIDA_DATOS = 'TR_AVISO_PERDIDA_DATOS';
export const TR_SALIR_TODOS_MODOS = 'TR_SALIR_TODOS_MODOS';
export const TR_RELLENE_CAMPOS_OBLIGATORIOS = 'TR_RELLENE_CAMPOS_OBLIGATORIOS';
export const TR_COMPARAR_GRAFICOS = 'TR_COMPARAR_GRAFICOS';
export const TR_INFORMACION = 'TR_INFORMACION';
export const TR_CAMBIOS_REALIZADOS = 'TR_CAMBIOS_REALIZADOS';
export const TR_LIMPIAR_FILTROS = 'TR_LIMPIAR_FILTROS';
export const TR_ALCANCE = 'TR_ALCANCE';
export const TR_ALCANCE_OBLIGATORIO = 'TR_ALCANCE_OBLIGATORIO';
export const TR_ERROR_YA_EXISTE_UNA_RESPUESTA = 'TR_ERROR_YA_EXISTE_UNA_RESPUESTA';
export const TR_NOTIFICACION_RESUELTA_CORRECTAMENTE = 'TR_NOTIFICACION_RESUELTA_CORRECTAMENTE';
export const TR_ERROR_RESOLVIENDO_NOTIFICACION = 'TR_ERROR_RESOLVIENDO_NOTIFICACION';
export const TR_RESOLVER_NOTIFICACIONES_USUARIO = 'TR_RESOLVER_NOTIFICACIONES_USUARIO';
export const TR_INTRODUZCA_OBSERVACIONES_PERTINENTES = 'TR_INTRODUZCA_OBSERVACIONES_PERTINENTES';
export const TR_WEBHOOK = 'TR_WEBHOOK';
export const TR_ANYO = 'TR_ANYO';
export const TR_YA_EXISTE_UNA_RESPUESTA_EN_ESTA_FECHA = 'TR_YA_EXISTE_UNA_RESPUESTA_EN_ESTA_FECHA';
export const TR_IMPRIMIR = 'TR_IMPRIMIR';
export const TR_ANCLAR = 'TR_ANCLAR';
export const TR_NO_EXISTEN_DATOS_DIA = 'TR_NO_EXISTEN_DATOS_DIA';
