import * as React from 'react';
import {Component, ReactNode} from 'react';
import {Dropdown, MenuItem} from "react-bootstrap";
// @ts-ignore
import DropDownCustomToggle from "./DropDownCustomToggle";
import Icon from "../Icon";

export interface DropDownOption {
    text?: string,
    icon?: string,
    onClick?: () => void,
    active?: boolean,
    divider?: boolean,
}
export interface DropDownProps {
    pullRight?: boolean,
    className?: string,
    options: DropDownOption[],
}

export default class DropDown extends Component<DropDownProps> {

    public render(): ReactNode {
        return (
            <Dropdown id="dropdown" pullRight={this.props.pullRight} className={this.props.className}>
                <DropDownCustomToggle bsRole="toggle">
                    {this.props.children}
                </DropDownCustomToggle>
                <Dropdown.Menu>
                    {this.props.options.map((option: DropDownOption, index: number) => {
                        if (option.divider) {
                            return <MenuItem key={index} divider/>;
                        } else {
                            return (
                                <MenuItem key={index} onClick={option.onClick} active={option.active}>
                                    {option.icon && <Icon icon={option.icon}/>}{option.text}
                                </MenuItem>
                            );
                        }
                    })}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

}

