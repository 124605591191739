import * as React from "react";
import {Component, ReactNode} from "react";
import Card from "../../components/card/Card";
import CardHeader from "../../components/card/CardHeader";
import CardBody from "../../components/card/CardBody";
import {Chart, DashboardCompareChartsRequest, DashboardDetail, DashboardDetailFilterData} from "../../model/Dashboard";
import Row from "../../components/Row";
import Col from "../../components/Col";
import LoadingView from "../../base/loading/LoadingView";
import FormInputOption from "../../components/form/FormInputOption";
import Select from "../../components/Select";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_FECHA, TR_LIMPIAR_FILTROS, TR_TIPO_DE_EXPLOTACION, TR_ZONA} from "../../I18n/constants";
import DateRangeInput from "../../components/date/DateRangeInput";
import Button from "../../components/Button";

interface CompareGraphicsViewProps {
	dashboard?: DashboardDetail,
	title: string,
	charts: Array<string> | any,
	applyFilters: (data: DashboardCompareChartsRequest) => void,
	loading: boolean,
	errors: string
}

interface CompareGraphicsViewState {
	filters: DashboardDetailFilterData []
}

export default class CompareGraphicsView extends Component<CompareGraphicsViewProps, CompareGraphicsViewState> {

	constructor(props: Readonly<CompareGraphicsViewProps>) {
		super(props);

		this.state = {
			filters: []
		}
	}

	public componentDidUpdate(prevProps: Readonly<CompareGraphicsViewProps>, prevState: Readonly<CompareGraphicsViewState>, snapshot?: any): void {
		if (this.props.charts !== prevProps.charts && this.props.charts.length > 0) {
			this.handleGetFiltersFromRedux();
		}
	}

	private handleOnFilterChange = () => {
		const {filters} = this.state;

		const newFilters: DashboardCompareChartsRequest = {
			items: {
				"1" : {
					zone: filters[0].zone,
					business_type: filters[0].business_type,
					start_time: filters[0].start_time,
					end_time: filters[0].end_time
				},
				"2": {
					zone: filters[1].zone,
					business_type: filters[1].business_type,
					start_time: filters[1].start_time,
					end_time: filters[1].end_time
				}
			}
		}

		this.props.applyFilters(newFilters);
	}

	private handleGetFiltersFromRedux(): void {
		const {charts} = this.props;

		const filters : DashboardDetailFilterData [] = [
			{
				end_time: charts["0"].filter.time.value.end,
				start_time: charts["0"].filter.time.value.start,
				business_type: charts["0"].filter.business_type.value,
				zone: charts["0"].filter.zone.value,
			},
			{
				end_time: charts["1"].filter.time.value.end,
				start_time: charts["1"].filter.time.value.start,
				business_type: charts["1"].filter.business_type.value,
				zone: charts["1"].filter.zone.value,
			}
		]

		this.setState({filters});
	}

	private handleResetFilter(id: number): void {
		const filters = {...this.state.filters};

		filters[id] = {
			zone: undefined,
			business_type: undefined,
			end_time: undefined,
			start_time: undefined
		}

		this.setState({filters}, () => this.handleOnFilterChange());
	}

	private handleOnZoneFilterChange(filterId: string, newZoneFilter: string): void {
		const newFilters = {...this.state.filters};
		const filter: DashboardDetailFilterData = {...newFilters[filterId]};
		filter.zone = newZoneFilter;
		newFilters[filterId] = filter;
		this.setState({filters: newFilters}, () => this.handleOnFilterChange());
	}

	private handleOnBusinessTypeFilterChange(filterId: string, newBusinessTypeFilter: string): void {
		const newFilters = {...this.state.filters};
		const filter: DashboardDetailFilterData = {...newFilters[filterId]};
		filter.business_type = newBusinessTypeFilter;
		newFilters[filterId] = filter;
		this.setState({filters: newFilters}, () => this.handleOnFilterChange());
	}

	private handleOnDateFilterChange(filterId: string, newStartTimeFilter, newEndTimeFilter): void {
		const newFilters = {...this.state.filters};
		const filter: DashboardDetailFilterData = {...newFilters[filterId]};
		filter.start_time = newStartTimeFilter;
		filter.end_time = newEndTimeFilter;
		newFilters[filterId] = filter;
		this.setState({filters: newFilters}, () => {this.handleOnFilterChange()});
	}

	public render(): ReactNode {
		return this.renderCharts();
	}

	private renderCharts(): ReactNode {
		const {dashboard, loading, title, charts} = this.props;
		const {filters} = this.state;

		if (dashboard) {
			const businessTypeOptions: FormInputOption[] = (dashboard.filter.business_type.options || []).map((option) => ({value: option, name: option}));
			const zoneOptions: FormInputOption[] = (dashboard.filter.zone.options || []).map((option) => ({value: option, name: option}));

			return(
				<Row>
					{
						charts.map((chart: Chart, index) => {
							const {url_iframe} = chart;

							return(
								<Col xs={12} sm={6} md={6} lg={6}
									 key={index}
								>
									<Card>
										<CardHeader title={dashboard.name}/>
										<CardBody>
											<Row>
												{zoneOptions.length > 0 &&
												<Col lg={6} md={6} className={'m-b-0'}>
													<Select
														name={"zone" + index}
														label={I18nUtils.tr(TR_ZONA)}
														value={filters[index] ? filters[index].zone as string : ""}
														options={zoneOptions}
														onChange={(e) => this.handleOnZoneFilterChange(index, e.target.value)}
													/>
												</Col>}

												{businessTypeOptions.length > 0 &&
												<Col lg={6} md={6} className={'m-b-0'}>
													<Select
														name={"businessType" + index}
														label={I18nUtils.tr(TR_TIPO_DE_EXPLOTACION)}
														value={filters[index] ? filters[index].business_type as string : ""}
														options={businessTypeOptions}
														onChange={(e) => this.handleOnBusinessTypeFilterChange(index, e.target.value)}
													/>
												</Col>}

												<Col lg={6} md={6} className={'m-b-0'}>
													<div className={'form-group'}>
														<div className={'form-line'}>
															<label>{I18nUtils.tr(TR_FECHA)}</label>
															<DateRangeInput
																startDate={filters[index] ? filters[index].start_time as Date : undefined}
																endDate={filters[index] ? filters[index].end_time as Date : undefined}
																onChange={(startDate, endDate) => this.handleOnDateFilterChange(index, startDate, endDate)}
															/>
														</div>
													</div>
												</Col>

												<Col lg={4} md={4} className={'pull-right m-t-40'}>
													<Button
														text={I18nUtils.tr(TR_LIMPIAR_FILTROS)}
														onClick={() => this.handleResetFilter(index)}
														className={'btn-primary'}
														block={true}
													/>
												</Col>
											</Row>

											<Row>
												<Col className='dashboard-item'>

													<h3 className={'m-l-15'}>{title}</h3>

													<div className={'iframe'}>
														<iframe src={url_iframe}
																height={'100%'}
																width={'100%'}/>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							);
						})
					}
				</Row>
			);
		}

		return <LoadingView loading={loading}/>
	}
}
