import * as React from 'react';
import {compose} from "redux";
import {Field, FieldArray, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import I18nUtils from "../../../../../I18n/I18nUtils";
import Row from "../../../../../components/Row";
import {connect} from "react-redux";
import {goToRoute} from "../../../../../utils/Router";
import FormCardFooter from "../../../../../components/form/FormCardFooter";
import BusinessType, {BusinessTypeAutocompleteBuilder} from "../../../../../model/BusinessType";
import Zone, {ZoneAutocompleteBuilder} from "../../../../../model/Zone";
import {
    TR_IMAGEN,
    TR_IMAGEN_OBLIGATORIA,
    TR_JEFES_DE_EXPLOTACION,
    TR_NOMBRE_DEL_CONTRATO,
    TR_POSICION, TR_RESPONSABLE_DUPLICADO, TR_RESPONSABLE_OBLIGATORIO,
    TR_TIPO_DE_EXPLOTACION,
    TR_TIPO_DE_EXPLOTACION_OBLIGATORIO,
    TR_ZONA,
    TR_ZONA_OBLIGATORIA
} from "../../../../../I18n/constants";
import {ROUTE_ADMIN_EXPLOITATIONS} from "../../../../../routing/Routes";
import FormSelect from "../../../../../components/form/FormSelect";
import FormInput from "../../../../../components/form/FormInput";
import FormDragFile from "../../../../../components/form/FormDragFile";
import UsersFieldArray from "../../../../../components/form/fieldarray/UsersFieldArray";
import TaskUserList from "../../../../../ws/user/TaskUserList";
import User, {UserAutocompleteBuilder} from "../../../../../model/User";
import {FieldArrayErrors, formStateAutoMapToPropsFactory} from "../../../../../utils/FormUtils";
import Col from "../../../../../components/Col";
import MapLocationPicker, {MarkerPosition} from "../../../../../components/MapLocationPicker";
import Config from "../../../../../Config";
import {UserAutocompleteOptions} from "../../../userGroups/form/UserGroupCardFormAdmin";

const FORM_NAME = 'ExploitationCardFormAdmin';

export interface ExploitationBaseFormAdminData {
    businessType: string,
    zone: string,
    contractName: string,
    image: string [],
    headOfServiceUsers: UserAutocompleteOptions[],
    coord: MarkerPosition
}

enum ExploitationCardFormAdminFields {
    BUSINESS_TYPE = "businessType",
    ZONE = "zone",
    CONTRACT_NAME = "contractName",
    IMAGE = "image",
    HEAD_OF_SERVICE_USERS = "headOfServiceUsers",
    COORD = "coord"
}

interface ExploitationCardFormAdminProps {
    users: User [],
    exploitationId?: string,
    zoneList: Zone [],
    businessTypeList: BusinessType [],
    loadingUsers: boolean,
    readonly?: boolean,
    onSubmit: (data: ExploitationBaseFormAdminData) => void,
    initialValues?: Partial<ExploitationBaseFormAdminData>,
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<ExploitationBaseFormAdminData>(FORM_NAME);

type Props = ExploitationCardFormAdminProps & typeof mapFormStateToProps & InjectedFormProps<ExploitationBaseFormAdminData>

class ExploitationBaseFormAdmin extends React.Component<Props> {

    private mapContainer;

    private handleOnUserSearch = (search: string): void => {
        new TaskUserList({search}).execute();
    };

    private handleOnChangeCoord = (newCoord: MarkerPosition): void => {
        this.props.change(ExploitationCardFormAdminFields.COORD, newCoord);
    };

    public render(): React.ReactNode {
        const {handleSubmit, onSubmit, initialValues, invalid, pristine, zoneList, businessTypeList, users, loadingUsers} = this.props;

        const businessTypeOptions = businessTypeList.map((businessType) => BusinessTypeAutocompleteBuilder.buildOption(businessType));
        const zoneOptions = zoneList.map((zone) => ZoneAutocompleteBuilder.buildOption(zone));
        const userOptions = users.map(user => UserAutocompleteBuilder.buildOption(user));

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_TIPO_DE_EXPLOTACION)}
                        name={ExploitationCardFormAdminFields.BUSINESS_TYPE}
                        component={FormSelect}
                        options={businessTypeOptions}
                        obligatory={true}
                        blankOptionText={' '}
                        col={{md: 6, lg: 6}}
                    />
                    <Field
                        label={I18nUtils.tr(TR_ZONA)}
                        name={ExploitationCardFormAdminFields.ZONE}
                        component={FormSelect}
                        options={zoneOptions}
                        obligatory={true}
                        blankOptionText={' '}
                        col={{md: 6, lg: 6}}
                    />
                </Row>

                <Row>
                    <Field
                        label={I18nUtils.tr(TR_NOMBRE_DEL_CONTRATO)}
                        name={ExploitationCardFormAdminFields.CONTRACT_NAME}
                        component={FormInput}
                        col={{md: 6, lg: 6}}
                    />
                </Row>

                <Row>
                    <Field
                        label={I18nUtils.tr(TR_IMAGEN)}
                        name={ExploitationCardFormAdminFields.IMAGE}
                        component={FormDragFile}
                        preview={true}
                        multiple={false}
                        obligatory={true}
                        col={{md: 12, lg: 12}}
                    />
                </Row>

                <Row>
                    <Col md={12} lg={12}>
                        <FieldArray
                            name={ExploitationCardFormAdminFields.HEAD_OF_SERVICE_USERS}
                            label={I18nUtils.tr(TR_JEFES_DE_EXPLOTACION)}
                            component={UsersFieldArray}
                            onUserSearch={this.handleOnUserSearch}
                            obligatory={true}
                            options={userOptions}
                            loading={loadingUsers}
                        />
                    </Col>
                </Row>

                <Row className={'gmap'}
                    ref={(node) => this.mapContainer = node}>

                    <Field
                        label={I18nUtils.tr(TR_POSICION)}
                        name={ExploitationCardFormAdminFields.COORD}
                        component={MapLocationPicker}
                        google={this.mapContainer}
                        changeCoordHandler={this.handleOnChangeCoord}
                        defaultPosition={Config.DEFAULT_MAP_POSITION}
                        detailMode={true}
                        obligatory={true}
                    />

                </Row>

                <Row />

                <FormCardFooter
                    invalid={invalid}
                    pristine={pristine}
                    isUpdate={!!initialValues}
                    cancelHandler={() => goToRoute(ROUTE_ADMIN_EXPLOITATIONS)}/>
            </form>
        )
    }
}


export function validateUserFieldArray (users: UserAutocompleteOptions[]): FieldArrayErrors {
    const errors : string [] = [];

    const currentUsers: UserAutocompleteOptions [] = [];

    users.forEach((user, index) => {
        if (!user || users.length === 0) {
            errors[index] = I18nUtils.tr(TR_RESPONSABLE_OBLIGATORIO);
        } else if (user){
            currentUsers.push(user);

            for (let i = 0; i < currentUsers.length -1; i++) {
                if (currentUsers[i][0].data.id === user[0].data.id){
                    errors[index] = I18nUtils.tr(TR_RESPONSABLE_DUPLICADO);
                }
            }
        }
    });

    return errors;
}

function validate(values: ExploitationBaseFormAdminData) {
    const errors: FormErrors<ExploitationBaseFormAdminData, FieldArrayErrors> = {};

    if (!values.businessType || values.businessType === '-1') {
        errors.businessType = I18nUtils.tr(TR_TIPO_DE_EXPLOTACION_OBLIGATORIO);
    }
    if (!values.zone || values.zone === '-1') {
        errors.zone = I18nUtils.tr(TR_ZONA_OBLIGATORIA);
    }
    if (!values.image || (values.image.length === 0 || !values.image[0])){
        errors.image = I18nUtils.tr(TR_IMAGEN_OBLIGATORIA);
    }
    if (values.headOfServiceUsers && values.headOfServiceUsers.length !== 0) {
        errors.headOfServiceUsers = validateUserFieldArray(values.headOfServiceUsers);
    }
    return errors;
}

export default compose(
    reduxForm<ExploitationBaseFormAdminData, ExploitationCardFormAdminProps, FieldArrayErrors>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapFormStateToProps as any)
)(ExploitationBaseFormAdmin);
