import * as React from 'react';
import DashboardLayoutAdminReducer from "./DashboardLayoutAdminReducer";
import {connect} from "react-redux";
import Row from "../../../../components/Row";
import Col from "../../../../components/Col";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import CardBody from "../../../../components/card/CardBody";
import {RouteComponentProps, withRouter} from "react-router";
import DashboardLayoutAdminActions from "./DashboardLayoutAdminActions";
import SelectLayoutModal from "./SelectLayoutModal";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_EDITAR_DISENO} from "../../../../I18n/constants";
import Button from "../../../../components/Button";
import {loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import TaskDashboardLayoutList from "../../../../ws/dashboardLayout/TaskDashboardLayoutList";
import {compose} from "redux";
import DashboardLayout from "../../../../model/DashboardLayout";
import TaskDashboardGet from "../../../../ws/dashboard/TaskDashboardGet";

const mapStateToProps = DashboardLayoutAdminReducer.autoMapToProps();
const mapActionsToProps = DashboardLayoutAdminActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps & RouteComponentProps<{ id: string }>

interface State {
    showSelectLayoutModal: boolean,
    selectedLayout?: DashboardLayout,
}

class DashboardLayoutAdminCard extends React.Component<Props, State> {
    public state: State = {
        showSelectLayoutModal: false,
        selectedLayout: undefined,
    };

    public UNSAFE_componentWillMount(): void {
        loadItemDetailsOrGoBack(new TaskDashboardGet(this.props.match.params.id), this.props);
        new TaskDashboardLayoutList().execute();
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private refreshDashboard = (): void => {
        new TaskDashboardGet(this.props.match.params.id).execute();
    };

    private showSelectLayoutModal = (): void => {
        this.setState({showSelectLayoutModal: true});
    };

    private hideSelectLayoutModal = (): void => {
        this.setState({showSelectLayoutModal: false});
    };

    private onSelectLayout = (layout: DashboardLayout): void => {
        this.setState({
            selectedLayout: layout,
            showSelectLayoutModal: false,
        })
    };

    public render(): React.ReactNode {
        const {dashboard, loading, dashboardLayouts} = this.props;

        const currentLayout = this.state.selectedLayout || dashboard && dashboard.dashboard_layout;

        console.warn("layout", currentLayout);

        return (
            <Card loading={loading}>
                <CardHeader
                    title={dashboard ? dashboard.name : ''}
                    onRefresh={this.refreshDashboard}
                >
                    <div className={'align-right'}>
                        <Button
                            text={I18nUtils.tr(TR_EDITAR_DISENO)}
                            onClick={this.showSelectLayoutModal}
                            block={false}
                            className={'btn-md btn-primary'}
                        />
                    </div>
                </CardHeader>
                <CardBody>
                    <Row>
                        {
                            dashboard && dashboard.items.map((dashboardItem, index) => (
                                <Col sm={6} md={6} lg={6} key={index} className='dashboard-donut-chart'>
                                    <iframe src={dashboardItem.url_iframe}
                                            height={'100%'}
                                            width={'100%'}/>
                                </Col>
                            ))
                        }
                    </Row>
                </CardBody>
                <SelectLayoutModal onSelectLayout={this.onSelectLayout}
                                   dashboardLayouts={dashboardLayouts}
                                   show={this.state.showSelectLayoutModal}
                                   onClose={this.hideSelectLayoutModal}/>
            </Card>
        )
    }
}

export default compose(
    connect(mapStateToProps, mapActionsToProps),
    withRouter
)(DashboardLayoutAdminCard)
