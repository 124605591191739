import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {FormFrequency} from "../../model/FormFrequency";

export default class TaskFormFrequencyTypeList extends BaseGenericRequest<{}, TaskResponse<FormFrequency []>> {

    public constructor() {
        super(METHOD.METHOD_GET, Urls.URL_FORM_FREQUENCIES);
    }
}