import * as React from 'react';
import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import classNames from 'classnames';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import I18nUtils from "../../I18n/I18nUtils";
import {TR_BUSCANDO, TR_NO_SE_HAN_ENCONTRADO_RESULTADOS} from '../../I18n/constants';
import {AutocompleteOption} from "./FormAutocomplete";

export interface FormAsyncAutocompleteProps extends WrappedFieldProps {
    name: string,
    label?: string,
    placeholder?: string,
    type: string,
    disabled?: boolean,
    className?: string,
    col: FormColProps,
    isLoading: boolean,
    options: Array<AutocompleteOption<any>>,
    onSelected: (selected: Array<AutocompleteOption<any>>) => void,
    onSearch: (search: string) => void,
    obligatory: boolean
}

export default class FormAsyncAutocomplete extends Component<FormAsyncAutocompleteProps, { search: string }> {
    constructor(props: FormAsyncAutocompleteProps) {
        super(props);
        this.state = {search: ''}
    }

    public render(): ReactNode {
        const {input, meta, label, placeholder, disabled, col, options, onSearch, onSelected, obligatory} = this.props;
        const classFormLine = classNames('form-line', {
            focused: meta.touched && meta.error,
            error: meta.touched && meta.error
        });

        return (
            <FormCol {...col} >
                <div className={'form-group'}>
                    <div className={classFormLine}>
                        {label && <label>{label}<label className={'obligatory'}>{(obligatory ? "*" : "")}</label></label>}
                        <AsyncTypeahead
                            placeholder={placeholder || ''}
                            promptText={placeholder || ''}
                            disabled={disabled}
                            options={options}
                            selected={input.value? input.value: []}
                            onChange={(selected) => {
                                input.onChange(selected);
                                if (onSelected) {
                                    onSelected(selected);
                                }
                            }}
                            onInputChange={(text: string, event: Event) => this.setState({search: text})}
                            onBlur={() => {
                                input.onBlur(undefined)
                            }}
                            labelKey={'name'}
                            filterBy={() => true}
                            isLoading={false}
                            onSearch={onSearch}
                            searchText={this.props.isLoading ? I18nUtils.tr(TR_BUSCANDO) : I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                        />
                    </div>
                    <label className="error">{meta.touched ? meta.error : ""}</label>
                </div>
            </FormCol>
        );
    }

}

