import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import BusinessType, {BusinessTypeData} from "../../model/BusinessType";

export default class TaskBusinessTypeAdd extends BaseGenericRequest<BusinessTypeData, TaskResponse<BusinessType>> {

    private readonly data: BusinessTypeData;

    public constructor(data: BusinessTypeData) {
        super(METHOD.METHOD_POST, Urls.URL_BUSINESS_TYPE_ADD);
        this.data = data;
    }

    protected getRequest(): BusinessTypeData {
        return this.data;
    }
}