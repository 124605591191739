import {ReducerBuilder} from "co-redux-builders";
import FormResultListCardActions from "./FormResultListCardActions";
import TaskFormResultDelete from "../../../ws/formResult/TaskFormResultDelete";
import TaskFormResultList from "../../../ws/formResult/TaskFormResultList";
import {PagerBackendProps} from "../../../components/Pager";
import FormResult from "../../../model/FormResult";

interface State {
    loading: boolean,
    formResults: FormResult[],
    error: string,
    pager: PagerBackendProps
}

const INITIAL_STATE: State = {
    loading: false,
    formResults: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1
    },
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskFormResultDelete.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskFormResultDelete.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })

    .onEvent(TaskFormResultList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskFormResultList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskFormResultList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskFormResultList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            formResults: payload.data,
            pager: payload.pager || oldState.pager,
        }
    })

    .onEvent(FormResultListCardActions.clearReducer, ((oldState, payload) => INITIAL_STATE))

    .build()