import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import TaskResponse from "../TaskResponse";
import BaseGenericRequest from "../BaseGenericRequest";
import {sha256} from "js-sha256";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE,
    TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES
} from "../../I18n/constants";

interface TaskSetPasswordRequest {
    token: string,
    password: string,
}

export default class TaskSetPassword extends BaseGenericRequest<TaskSetPasswordRequest, TaskResponse<{}>> {

    private readonly token: string;
    private readonly password: string;

    public constructor(token: string, password: string) {
        super(METHOD.METHOD_POST, Urls.URL_SET_PASSWORD);

        this.setErrorMessage(404, I18nUtils.tr(TR_EL_USUARIO_AL_QUE_SE_LE_INTENTA_CAMBIAR_LA_CONTRASENA_NO_EXISTE));
        this.setErrorMessage(490, I18nUtils.tr(TR_LA_CONTRASENA_DEBE_SER_DISTINTA_A_LAS_TRES_ANTERIORES));

        this.token = token;
        this.password = password;
    }

    protected getRequest(): TaskSetPasswordRequest {
        return {
            token: this.token,
            password: sha256(this.password),
        }
    }
}