import * as React from 'react';
import {compose} from "redux";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import I18nUtils from "../../../../I18n/I18nUtils";
import Row from "../../../../components/Row";
import {connect} from "react-redux";
import DocumentFormAdminScreenReducer from "./DocumentFormAdminScreenReducer";
import {
    TR_DESCRIPCION,
    TR_DESCRIPCION_OBLIGATORIA,
    TR_EXPLOTACION,
    TR_EXPLOTACION_OBLIGATORIO,
    TR_FICHERO_ADJUNTO,
    TR_FICHERO_OBLIGATORIO,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
} from "../../../../I18n/constants";
import {AutocompleteOption} from "../../../../components/form/FormAutocomplete";
import {ExploitationAutocompleteBuilder, ExploitationSimply} from "../../../../model/Exploitation";
import FormAsyncAutocomplete from "../../../../components/form/FormAsyncAutocomplete";
import FormInput from "../../../../components/form/FormInput";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";
import FormDragFile from "../../../../components/form/FormDragFile";
import {ReducerBuilder} from "co-redux-builders";
import CurrentUserReducer from "../../../../redux/CurrentUserReducer";
import {RouteProps} from "react-router";
import {UserRoleValue} from "../../../../model/UserRole";
import FormTextArea from "../../../../components/form/FormTextArea";
import FormCardFooter from "../../../../components/form/FormCardFooter";

const FORM_NAME = 'DocumentCardFormAdmin';

export interface DocumentFormAdminData {
    name: string,
    url: string[],
    exploitation: Array<AutocompleteOption<ExploitationSimply>>,
    description: string,
}

enum DocumentFormAdminFields {
    NAME = "name",
    URL = "url",
    EXPLOITATION = 'exploitation',
    DESCRIPTION = 'description',
}

interface DocumentFormAdminProps {
    documentId?: string,
    readonly?: boolean,
    onSubmit: (data: DocumentFormAdminData) => void,
    initialValues?: Partial<DocumentFormAdminData>,
    onCancel?: () => void,
    goBackHandler?: () => void,
}

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    DocumentFormAdminScreenReducer.autoMapToProps(),
    CurrentUserReducer.autoMapToProps(),
);

type Props = DocumentFormAdminProps & typeof mapStateToProps & InjectedFormProps<DocumentFormAdminData> & RouteProps

class DocumentFormAdmin extends React.Component<Props> {

    private onSearchExploitation = (search: string): void => {
        new TaskExploitationList({search, limit: 0}).execute();
    };

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, exploitationList, exploitationsLoading, onCancel, currentUser, initialValues,
            goBackHandler, readonly, invalid, pristine
        } = this.props;

        let isSuperAdmin = currentUser && currentUser.roles.find((role) => role.role.id === UserRoleValue.SUPER_ADMIN);
        let currentUserExploitationList = currentUser ? currentUser.roles.map((role) => role.exploitation) : [];
        let exploitationOptions = isSuperAdmin ?
            exploitationList.map((exploitation) => ExploitationAutocompleteBuilder.buildOption(exploitation)) :
            currentUserExploitationList.map((exploitation: ExploitationSimply) => ExploitationAutocompleteBuilder.buildOption(exploitation));

        let cancelEvent = goBackHandler ? goBackHandler : () => null;
        if (onCancel) {
            cancelEvent = onCancel
        }

        return (

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_NOMBRE)}
                        name={DocumentFormAdminFields.NAME}
                        component={FormInput}
                        col={{md: 12, lg: 6}}
                        disabled={readonly}
                    />
                    <Field
                        label={I18nUtils.tr(TR_EXPLOTACION)}
                        name={DocumentFormAdminFields.EXPLOITATION}
                        component={FormAsyncAutocomplete}
                        isLoading={exploitationsLoading}
                        options={exploitationOptions}
                        onSearch={this.onSearchExploitation}
                        col={{md: 12, lg: 6}}
                        disabled={readonly}
                    />
                </Row>
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_DESCRIPCION)}
                        name={DocumentFormAdminFields.DESCRIPTION}
                        component={FormTextArea}
                        rows={3}
                        col={{md: 12, lg: 12}}
                        disabled={readonly}
                    />
                </Row>
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_FICHERO_ADJUNTO)}
                        name={DocumentFormAdminFields.URL}
                        component={FormDragFile}
                        disabled={readonly}
                    />
                </Row>
                <FormCardFooter invalid={invalid}
                                pristine={pristine}
                                isUpdate={!!initialValues}
                                cancelHandler={cancelEvent}
                                readOnly={readonly}
                />
            </form>

        )
    }
}

function validate(values: DocumentFormAdminData) {
    const errors: FormErrors<DocumentFormAdminData> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.url || values.url.length === 0) {
        errors.url = I18nUtils.tr(TR_FICHERO_OBLIGATORIO);
    }
    if (!values.exploitation || values.exploitation.length === 0) {
        errors.exploitation = I18nUtils.tr(TR_EXPLOTACION_OBLIGATORIO);
    }
    if (!values.description || values.description.length === 0) {
        errors.description = I18nUtils.tr(TR_DESCRIPCION_OBLIGATORIA);
    }
    return errors;
}

export default compose(
    reduxForm<DocumentFormAdminData, DocumentFormAdminProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(DocumentFormAdmin);