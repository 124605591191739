import * as React from "react";
import {OnSortHandler, SortState} from "../../../utils/Sort";
import Th from "../../../components/table/Th";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_CANAL,
    TR_ESTADO,
    TR_FECHA_ENVIO,
    TR_FECHA_RESOLUCION,
    TR_LINK_DOCUMENTACION,
    TR_LINK_FORMULARIO,
    TR_NOMBRE,
    TR_PENDIENTE,
    TR_RESPONSABLE,
    TR_RESUELTA,
    TR_TIPO_DE_NOTIFICATION
} from "../../../I18n/constants";
import {AlertNotification} from "../../../model/AlertNotification";
import {truncateMiddle} from "../../../utils/StringUtils";
import {generateRoute, goToRoute} from "../../../utils/Router";
import {ROUTE_ADMIN_ALERT_NOTIFICATION_DETAIL, ROUTE_FORM_RESULT} from "../../../routing/Routes";
import {UserSimply} from "../../../model/User";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface AlertNotificationListTableProps {
    data: any[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class NotificationListTable extends React.Component<AlertNotificationListTableProps> {

    private goToItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_ALERT_NOTIFICATION_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((alertNotification: AlertNotification) => {

                    return (
                        <tr key={alertNotification.id}
                            onClick={() => this.goToItemDetail(alertNotification.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(alertNotification.id) : null}
                            <td>{alertNotification.alert ? alertNotification.alert.name : ''}</td>
                            <td>{alertNotification.solved ? I18nUtils.tr(TR_RESUELTA) : I18nUtils.tr(TR_PENDIENTE)}</td>
                            <td>{alertNotification.alert && alertNotification.alert.alert_type ? alertNotification.alert.alert_type.name : ''}</td>
                            <td>{new Date(alertNotification.creation_date).toLocaleString()}</td>
                            <td>{alertNotification.solve_date ? new Date(alertNotification.solve_date).toLocaleString() : ''}</td>
                            <td>{alertNotification.alert && alertNotification.alert.alert_source ? alertNotification.alert.alert_source.name : ''}</td>
                            <td>{alertNotification.users && alertNotification.users.length !== 0 ? this.renderUsers(alertNotification.users) : ''}</td>
                            <td>{alertNotification.form_result && alertNotification.form_result.form_id && alertNotification.form_result.form_id.length !== 0 ?
                                truncateMiddle(generateRoute(ROUTE_FORM_RESULT, {formId: alertNotification.form_result.form_id})) : ''}</td>
                            <td>{alertNotification.alert && alertNotification.alert.document && alertNotification.alert.document.url ? truncateMiddle(alertNotification.alert.document.url) : ''}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderUsers = (users: UserSimply[]): React.ReactNode => {
        const usersName = users.map((user) => `${user.name} ${user.last_name}`).join(', ');
        return (<OverlayTrigger
            placement="right"
            overlay={<Tooltip id={'tooltip'} key={`users-tooltip`} className={"tooltip-table"}>{usersName}</Tooltip>}
        >
            <span>{`${usersName.slice(0, 20)}...`}</span>
        </OverlayTrigger>)
    };

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_ESTADO)}
                    columnName={'solved'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_TIPO_DE_NOTIFICATION)}
                    columnName={'alert_type.name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA_ENVIO)}
                    columnName={'creation_date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA_RESOLUCION)}
                    columnName={'solved_date'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_CANAL)}
                    columnName={'alert_source'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_RESPONSABLE)}
                    columnName={'solved_user.name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_LINK_FORMULARIO)}
                    columnName={'link'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_LINK_DOCUMENTACION)}
                    columnName={'url'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
