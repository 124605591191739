import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Dashboard, {DashboardData} from "../../model/Dashboard";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";

export default class TaskDashboardAdd extends BaseGenericRequest<DashboardData, TaskResponse<Dashboard>> {

    private readonly data: DashboardData;

    public constructor(data: DashboardData) {
        super(METHOD.METHOD_POST, Urls.URL_DASHBOARD_ADD);
        this.data = data;
    }

    protected getRequest(): DashboardData {
        return this.data;
    }
}