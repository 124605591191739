import * as React from "react";
import {Component, ReactNode} from "react";
import CompareGraphicsDataContainer from "./CompareGraphicsDataContainer";
import I18nUtils from "../../I18n/I18nUtils";
import {ROUTE_COMPARE_GRAPHICS, ROUTE_DASHBOARD, ROUTE_DASHBOARD_DETAIL} from "../../routing/Routes";
import {MenuIcon} from "../../Config";
import ScreenCard from "../../components/screen/ScreenCard";
import {RouteComponentProps} from "react-router";
import {generateRoute} from "../../utils/Router";
import {TR_COMPARAR_GRAFICOS, TR_DASHBOARD, TR_DASHBOARDS} from "../../I18n/constants";

export default class CompareGraphicsScreen extends Component<RouteComponentProps<{graphic: string, dashboard: string}>>{
	public render(): ReactNode {
		const {graphic, dashboard} = this.props.match.params;
		return (
			<ScreenCard breadcrumbs={[
				{
					name: I18nUtils.tr(I18nUtils.tr(TR_DASHBOARDS)),
					url: ROUTE_DASHBOARD,
					icon: MenuIcon.DASHBOARD
				},
				{
					name: I18nUtils.tr(I18nUtils.tr(TR_DASHBOARD)),
					url: generateRoute(ROUTE_DASHBOARD_DETAIL, {id: dashboard}),
					icon: MenuIcon.DASHBOARD
				},
				{
					name: I18nUtils.tr(I18nUtils.tr(TR_COMPARAR_GRAFICOS)),
					url: generateRoute(ROUTE_COMPARE_GRAPHICS, {graphic, dashboard}),
					icon: MenuIcon.CHART
				}
			]}>
				<CompareGraphicsDataContainer
					chartId={graphic}
					dashboardId={dashboard}
				/>
			</ScreenCard>
		);
	}
}