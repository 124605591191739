import * as React from "react";
import {Component, ReactNode} from "react";
import {connect} from "react-redux";
import AppLauncherListView from "./AppLauncherListView";
import AppLauncherListReducer from "./AppLauncherListReducer";
import AppLauncherListActions from "./AppLauncherListActions";
import TaskLinkList from "../../../ws/links/TaskLinkList";

const mapStateToProps = AppLauncherListReducer.autoMapToProps();
const mapActionsToProps = AppLauncherListActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

class AppLauncherListDataContainer extends Component<Props> {
    public componentDidMount(): void {
        new TaskLinkList()
            .execute();
    }

    public render(): ReactNode {
        const {links, loading, errors} = this.props;
        return (
            <AppLauncherListView
                links={links}
                loading={loading}
                errors={errors}
            />
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(AppLauncherListDataContainer as unknown as React.ComponentType<{}>);
 