import * as React from 'react';
import {Field, FieldArray, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import {ReducerBuilder} from "co-redux-builders";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ANADIR,
    TR_CANAL,
    TR_CANCELAR,
    TR_DESCRIPCION_DE_LA_ALERTA, TR_DESCRIPCION_OBLIGATORIA,
    TR_EMAIL,
    TR_ESTADO,
    TR_ESTADO_OBLIGATORIO,
    TR_FECHA_ENVIO,
    TR_FECHA_OBLIGATORIA,
    TR_GUARDAR,
    TR_HORA_ENVIO,
    TR_HORA_OBLIGATORIA,
    TR_LINK,
    TR_NOMBRE_DE_LA_ALERTA,
    TR_NOMBRE_OBLIGATORIO,
    TR_PERIODICIDAD,
    TR_PERIODICIDAD_OBLIGATORIA,
    TR_RECEPTORES,
    TR_URL_INCORRECTA,
    TR_USUARIO,
} from "../../../../I18n/constants";
import {connect} from "react-redux";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import FormSelect from "../../../../components/form/FormSelect";
import FormSubmit from "../../../../components/form/FormSubmit";
import Col from "../../../../components/Col";
import Button from "../../../../components/Button";
import {withRouter} from "react-router";
import FormTime from "../../../../components/form/FormTime";
import {compose} from "redux";
import AlertFormScreenAdminReducer from "./AlertFormScreenAdminReducer";
import {FieldArrayErrors, formStateAutoMapToPropsFactory} from "../../../../utils/FormUtils";
import TaskAlertStateList from "../../../../ws/alertState/TaskAlertStateList";
import TaskAlertFrequencyList from "../../../../ws/alertFrequency/TaskAlertFrequencyList";
import TaskUserList from "../../../../ws/user/TaskUserList";
import {UserAutocompleteBuilder} from "../../../../model/User";
import {UserRoleFieldArrayOption} from "../../../../model/UserRole";
import FormSwitch from "../../../../components/form/FormSwitch";
import UsersFieldArray from "../../../../components/form/fieldarray/UsersFieldArray";
import ExploitationRoleFieldArray from "../../userGroups/form/ExploitationRoleFieldArray";
import ExploitationFieldArray from "../../forms/formdetail/cards/permission/ExploitationFieldArray";
import CardCollapsed from "../../../../components/card/CardCollapsed";
import {isValidURL} from "../../../../utils/StringUtils";
import {UserAutocompleteOptions} from "../../userGroups/form/UserGroupCardFormAdmin";
import {ExploitationAutocompleteOptions} from "../../forms/formdetail/cards/permission/FormPermissionSectionAdmin";
import FormTextArea from "../../../../components/form/FormTextArea";
import {validateUserFieldArray} from "../../exploitations/form/exploitation/ExploitationBaseFormAdmin";
import TaskAlertScopesList from "../../../../ws/alertscope/TaskAlertScopesList";
import TaskAlertTypeList from "../../../../ws/alertype/TaskAlertTypeList";
import TaskFormList from "../../../../ws/form/TaskFormList";
import TaskDocumentList from "../../../../ws/document/TaskDocumentList";
import DocumentModel from "../../../../model/Document";

const FORM_NAME = 'AlertFormAdmin';

export interface AlertFormAdminData {
    name: string,
    state: string,
    description: string,
    link: string,
    email: boolean,
    exploitations?: ExploitationAutocompleteOptions[],
    users?: UserAutocompleteOptions[],
    roles?: UserRoleFieldArrayOption[],
    channel: string,
    frequency: string,
    date: string,
    time: string,
    alertType: string,
    alertScope: string,
    linkedForm: string,
    linkedDocument: string
}

enum AlertFormAdminFields {
    NAME = "name",
    STATE = 'state',
    DESCRIPTION = 'description',
    LINK = 'link',
    FREQUENCY = 'frequency',
    DATE = 'date',
    TIME = 'time',
    EMAIL = 'email',
    EXPLOITATIONS = "exploitations",
    USERS = 'users',
    ROLES = "roles",
    CHANNEL= "channel",
    ALERT_TYPE = "alertType",
    ALERT_SCOPE = "alertScope",
    LINKED_FORM = "linkedForm",
    LINKED_DOCUMENT = "linkedDocument"
}

interface AlertFormAdminProps {
    parentLoading: boolean,
    readonly?: boolean,
    onSubmit: (data: AlertFormAdminData) => void,
    onCancel?: () => void
}

interface AlertFormAdminState {
    collapsed: boolean
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<AlertFormAdminData>(FORM_NAME);

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    mapFormStateToProps,
    AlertFormScreenAdminReducer.autoMapToProps(),
);

type Props = typeof mapStateToProps & AlertFormAdminProps & InjectedFormProps<AlertFormAdminData>

class AlertFormAdmin extends React.Component<Props, AlertFormAdminState> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            collapsed: false,
        }
    }

    public componentDidMount(): void {
        new TaskAlertStateList().execute();
        new TaskAlertFrequencyList().execute();
        new TaskUserList().execute();
        new TaskAlertScopesList().execute();
        new TaskAlertTypeList().execute();
        new TaskFormList({limit: 0}).execute();
        new TaskDocumentList({limit: 0}).execute();
    }

    private onCollapsedCard = () => {
        this.setState({collapsed: !this.state.collapsed})
    };

    private handleOnUserSearch = (search: string): void => {
        new TaskUserList({
            search,
            limit: 0
        }).execute();
    };

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues,
             valid, reset, alertStates, alertFrequencies, onCancel,
            userList, usersLoading, invalid, alertTypes, alertScopes,
            forms, documents
        } = this.props;

        const {collapsed} = this.state;

        // const showSaveButton = formState && formState.values && !equals(initialValues, formState.values);
        const frequencyOptions = alertFrequencies.map((frequency) => ({value: frequency.id, name: frequency.name}));
        const stateOptions = alertStates.map((state) => ({value: state.id, name: state.name}));
        const usersOptions = userList.map(user => UserAutocompleteBuilder.buildOption(user));
        const alertTypesOptions = alertTypes.map((type) => ({value: type.id, name: type.name})) || [];
        const alertScopesOptions = alertScopes.map((scope) => ({value: scope.id, name: scope.name})) || [];
        const formOptions = forms.map(form => ({value: form.id, name: form.name})) || [];
        const documentOptions = documents.map((document: DocumentModel) => ({value: document.id, name: document.name})) || [];

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_NOMBRE_DE_LA_ALERTA)}
                        name={AlertFormAdminFields.NAME}
                        component={FormInput}
                        col={{col: 12, lg: 12}}
                        obligatory={true}
                    />
                </Row>

                <Row>
                    <Field
                        label={I18nUtils.tr(TR_DESCRIPCION_DE_LA_ALERTA)}
                        name={AlertFormAdminFields.DESCRIPTION}
                        component={FormTextArea}
                        row={5}
                        col={{col: 12, lg: 12}}
                        obligatory={true}
                    />
                </Row>

                <Row>
                    <Field
                        label={I18nUtils.tr("Tipo de alerta")}
                        name={AlertFormAdminFields.ALERT_TYPE}
                        component={FormSelect}
                        options={alertTypesOptions}
                        blankOptionText={' '}
                        col={{md: 6, lg: 6}}
                        obligatory={true}
                    />
                    <Field
                        label={I18nUtils.tr("Alcance de alerta")}
                        name={AlertFormAdminFields.ALERT_SCOPE}
                        component={FormSelect}
                        options={alertScopesOptions}
                        blankOptionText={' '}
                        col={{md: 6, lg: 6}}
                        obligatory={true}
                    />
                </Row>

                <Row>
                    <Field
                        label={I18nUtils.tr("Formulario asociado")}
                        name={AlertFormAdminFields.LINKED_FORM}
                        component={FormSelect}
                        options={formOptions}
                        blankOptionText={' '}
                        col={{md: 6, lg: 6}}
                    />
                    <Field
                        label={I18nUtils.tr("Documento asociado")}
                        name={AlertFormAdminFields.LINKED_DOCUMENT}
                        component={FormSelect}
                        options={documentOptions}
                        blankOptionText={' '}
                        col={{md: 6, lg: 6}}
                    />
                </Row>
                <Row>
                    <Field
                        label={I18nUtils.tr(TR_LINK)}
                        name={AlertFormAdminFields.LINK}
                        component={FormInput}
                        col={{col: 12, lg: 12}}
                    />
                </Row>

                <Row>
                    <Field
                        label={I18nUtils.tr(TR_ESTADO)}
                        name={AlertFormAdminFields.STATE}
                        component={FormSelect}
                        options={stateOptions}
                        col={{col: 4, lg: 4}}
                        blankOptionText={' '}
                        obligatory={true}
                    />

                    {
                        initialValues && initialValues.channel ? <Field
                                            label={I18nUtils.tr(TR_CANAL)}
                                            name={AlertFormAdminFields.CHANNEL}
                                            component={FormInput}
                                            disabled={true}
                                            col={{col: 4, lg: 4}}
                                            />
                                       : null
                    }

                    <Field
                        label={I18nUtils.tr(TR_EMAIL)}
                        name={AlertFormAdminFields.EMAIL}
                        component={FormSwitch}
                        col={{col: 4, lg: 4}}
                        obligatory={true}
                    />
                </Row>


                <Row>
                    <Field
                        label={I18nUtils.tr(TR_FECHA_ENVIO)}
                        name={AlertFormAdminFields.DATE}
                        component={FormInput}
                        type={'date'}
                        col={{col: 4, lg: 4}}
                        obligatory={true}
                    />

                    <Field
                        label={I18nUtils.tr(TR_HORA_ENVIO)}
                        name={AlertFormAdminFields.TIME}
                        component={FormTime}
                        col={{col: 4, lg: 4}}
                        obligatory={true}
                    />

                    <Field
                        label={I18nUtils.tr(TR_PERIODICIDAD)}
                        name={AlertFormAdminFields.FREQUENCY}
                        component={FormSelect}
                        col={{col: 4, lg: 4}}
                        options={frequencyOptions}
                        blankOptionText={' '}
                        obligatory={true}
                    />

                </Row>

                <CardCollapsed
                    title={I18nUtils.tr(I18nUtils.tr(TR_RECEPTORES))}
                    collapsed={collapsed}
                    collapsedHandler={this.onCollapsedCard}
                    invalid={invalid}
                >
                    <Row>
                        <Col md={12} lg={12}>
                            <FieldArray
                                name={AlertFormAdminFields.USERS}
                                label={I18nUtils.tr(TR_USUARIO)}
                                component={UsersFieldArray}
                                onUserSearch={this.handleOnUserSearch}
                                options={usersOptions}
                                loading={usersLoading}
                            />
                        </Col>

                    </Row>

                    <Row>
                        <Col md={12} lg={12}>
                            <FieldArray
                                name={AlertFormAdminFields.ROLES}
                                component={ExploitationRoleFieldArray}
                                hideAdminRoles={false}
                            />
                        </Col>

                    </Row>

                    <Row>
                        <Col md={12} lg={12}>
                            <FieldArray
                                name={AlertFormAdminFields.EXPLOITATIONS}
                                component={ExploitationFieldArray}

                            />
                        </Col>
                    </Row>
                </CardCollapsed>

                <Row>
                    <Col xs={0} sm={0} md={6} lg={6}/>
                    <Col sm={6} md={3} lg={3}>
                        <Button
                            text={I18nUtils.tr(TR_CANCELAR)}
                            onClick={onCancel ? onCancel : reset}
                            type={'button'}
                            block={true}
                            className={'btn-lg'}
                        />
                    </Col>
                    <Field
                        label={initialValues && initialValues.name ? I18nUtils.tr(TR_GUARDAR) : I18nUtils.tr(TR_ANADIR)}
                        name={'submit'}
                        component={FormSubmit}
                        col={{sm: '6', md: '3', lg: '3'}}
                        disabled={!valid}
                    />
                </Row>
            </form>
        )
    }
}

function validate(values: AlertFormAdminData) {
    const errors: FormErrors<AlertFormAdminData, FieldArrayErrors> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.state || values.state === '-1') {
        errors.state = I18nUtils.tr(TR_ESTADO_OBLIGATORIO);
    }
    if (!values.description) {
        errors.description = I18nUtils.tr(TR_DESCRIPCION_OBLIGATORIA)
    }
    if (values.link && !isValidURL(values.link)) {
        errors.link = I18nUtils.tr(TR_URL_INCORRECTA);
    }
    if (!values.frequency || values.frequency === '-1') {
        errors.frequency = I18nUtils.tr(TR_PERIODICIDAD_OBLIGATORIA);
    }
    if (!values.date) {
        errors.date = I18nUtils.tr(TR_FECHA_OBLIGATORIA);
    }
    if (!values.time) {
        errors.time = I18nUtils.tr(TR_HORA_OBLIGATORIA);
    }
    if (values.users && values.users.length !== 0) {
        errors.users = validateUserFieldArray(values.users);
    }
    if (!values.alertScope || values.alertScope === "-1") {
        errors.alertScope = I18nUtils.tr("Alcance de alerta obligatorio");
    }
    if (!values.alertType || values.alertType === "-1") {
        errors.alertType = I18nUtils.tr("Tipo de alerta obligatorio");
    }

    return errors;
}

export default compose(
    reduxForm<AlertFormAdminData, AlertFormAdminProps, FieldArrayErrors>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps),
    withRouter
)(AlertFormAdmin);
