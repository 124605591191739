import {ReducerBuilder} from "co-redux-builders";
import NotificationFormAdminScreenActions from "./NotificationFormAdminScreenActions";
import {AlertNotification} from "../../../model/AlertNotification";
import TaskAlertNotificationDetail from "../../../ws/alertNotification/TaskAlertNotificationDetail";

interface InitialState {
    notificationFormScreenLoading: boolean,
    alertNotification?: AlertNotification,
}

const INITIAL_STATE: InitialState = {
    notificationFormScreenLoading: false,
    alertNotification: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskAlertNotificationDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        notificationFormScreenLoading: true
    }))
    .onEvent(TaskAlertNotificationDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        notificationFormScreenLoading: false
    }))
    .onEvent(TaskAlertNotificationDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, alertNotification: payload.data};
    })

    .onEvent(NotificationFormAdminScreenActions.clearNotificationFormAdminScreenReducer, () => INITIAL_STATE)

    .build();