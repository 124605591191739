import * as React from 'react';
import {ROUTE_ADMIN_USERS} from "../../../../routing/Routes";
import UserListCardAdmin from "./UserListCardAdmin";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_USUARIOS} from "../../../../I18n/constants";
import {MenuIcon} from "../../../../Config";
import ScreenCard from "../../../../components/screen/ScreenCard";

export default class UserListAdminScreen extends React.Component<{}> {

    public render(): React.ReactNode {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_USUARIOS),
                    url: ROUTE_ADMIN_USERS,
                    icon: MenuIcon.USER_GROUP
                }
            ]}>
                <UserListCardAdmin/>
            </ScreenCard>
        )
    }
}