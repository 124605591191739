import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import BusinessType from "../../model/BusinessType";

export default class TaskBusinessTypeDetail extends BaseGenericRequest<{}, TaskResponse<BusinessType>> {

    public constructor(businessTypeId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_BUSINESS_TYPE_DETAIL, {uuid: businessTypeId}));
    }
}
