export default interface FormFieldType {
    id: string,
    name: string,
}

export enum FormFieldTypeValue {
    NONE = '-1',
    TEXT = '1',
    TEXTAREA = '2',
    NUMBER = '3',
    EMAIL = '4',
    URL = '5',
    DATE = '6',
    TIME = '7',
    DATETIME = '8',
    IMAGE = '9',
    VIDEO = '10',
    AUDIO = '11',
    SELECT = '12',
    CHECKBOX = '13',
    CHECKBOXGROUP = '14',
    RADIOGROUP = '15',
    SWITCH = '16',
    FILE = '17',
    CURRENCY = '18',
    TOTALIZER = '19',
    CHECKDAY = '20',
}

export const FormFieldArrayTypes: string[] = [
    FormFieldTypeValue.AUDIO,
    FormFieldTypeValue.VIDEO,
    FormFieldTypeValue.IMAGE,
    FormFieldTypeValue.FILE,
    FormFieldTypeValue.CHECKBOXGROUP,
];
