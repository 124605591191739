import * as React from 'react';
import {Modal} from "react-bootstrap";
import Row from "../../../../components/Row";
import Col from "../../../../components/Col";
import Button from "../../../../components/Button";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_CERRAR, TR_EDITAR_DISENO, TR_SELECCIONAR_EL_DISENO_DEL_DASHBOARD} from "../../../../I18n/constants";
import {BaseModalProps} from "../../../../base/modal/BaseModal";
import DashboardLayout from "../../../../model/DashboardLayout";

interface SelectLayoutModalProps extends BaseModalProps {
    onSelectLayout: (layout: DashboardLayout) => void,
    dashboardLayouts: DashboardLayout[],
}

export default class SelectLayoutModal extends React.Component<SelectLayoutModalProps> {

    public render(): React.ReactNode {
        const {onClose, show, dashboardLayouts, onSelectLayout} = this.props;

        return (
            <Modal onHide={onClose} show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18nUtils.tr(TR_EDITAR_DISENO)}
                        <br/>
                        <small>{I18nUtils.tr(TR_SELECCIONAR_EL_DISENO_DEL_DASHBOARD)}</small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={'layout-images'}>
                        {dashboardLayouts.map((dashboardLayout, index) => {
                            return (
                                <img key={index}
                                     src={dashboardLayout.image}
                                     onClick={() => onSelectLayout(dashboardLayout)}/>
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col sm={6} md={9} lg={9}/>
                        <Col sm={6} md={3} lg={3}>
                            <Button
                                text={I18nUtils.tr(TR_CERRAR)}
                                onClick={onClose}
                                block={true}
                                className={'btn-lg btn-primary'}
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }
}