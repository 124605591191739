import {ReducerBuilder} from "co-redux-builders";
import Exploitation from "../../../../model/Exploitation";
import Role from "../../../../model/Role";
import TaskRoleList from "../../../../ws/role/TaskRoleList";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";

interface InitialState {
    exploitationListLoading: boolean,
    exploitationList: Exploitation[],

    roleListLoading: boolean,
    roleList: Role[],
}

const INITIAL_STATE: InitialState = {
    exploitationListLoading: false,
    exploitationList: [],

    roleListLoading: false,
    roleList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationListLoading: true
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationListLoading: false
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, exploitationList: payload.data};
        })

    .onEvent(TaskRoleList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskRoleList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskRoleList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, roleList: payload.data};
        })

    .build();