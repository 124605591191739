import * as React from 'react';
import {compose} from "redux";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_CLAVE_ELK,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO, TR_NUEVA_ZONA, TR_OBLIGATORIO,
    TR_ZONA,
} from "../../../../I18n/constants";
import CardBody from "../../../../components/card/CardBody";
import Row from "../../../../components/Row";
import FormInput from "../../../../components/form/FormInput";
import {connect} from "react-redux";
import ZoneFormAdminScreenReducer from "./ZoneFormAdminScreenReducer";
import {goToRoute} from "../../../../utils/Router";
import FormCardFooter from "../../../../components/form/FormCardFooter";
import {ROUTE_ADMIN_ZONES} from "../../../../routing/Routes";

const FORM_NAME = 'ZoneCardFormAdmin';

export interface ZoneCardFormAdminData {
    name: string,
    elk_value: string,
}

enum ZoneCardFormAdminFields {
    NAME = "name",
    ELK_VALUE = "elk_value",
}

interface UserGroupCardFormAdminProps {
    zoneId?: string,
    readonly?: boolean,
    onSubmit: (data: ZoneCardFormAdminData) => void,
    initialValues?: Partial<ZoneCardFormAdminData>,
}

const mapStateToProps = ZoneFormAdminScreenReducer.autoMapToProps();

type Props = UserGroupCardFormAdminProps & typeof mapStateToProps & InjectedFormProps<ZoneCardFormAdminData>

class ZoneCardFormAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {
            handleSubmit, onSubmit, initialValues, invalid, zoneFormScreenLoading, pristine
        } = this.props;

        return (
            <Card loading={zoneFormScreenLoading}>
                <CardHeader
                    title={I18nUtils.tr(initialValues ? TR_ZONA : TR_NUEVA_ZONA)}/>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Field
                                label={I18nUtils.tr(TR_NOMBRE)}
                                name={ZoneCardFormAdminFields.NAME}
                                // @ts-ignore
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                            <Field
                                label={I18nUtils.tr(TR_CLAVE_ELK)}
                                name={ZoneCardFormAdminFields.ELK_VALUE}
                                // @ts-ignore
                                component={FormInput}
                                col={{md: 6, lg: 6}}
                            />
                        </Row>
                        <FormCardFooter
                            invalid={invalid}
                            pristine={pristine}
                            isUpdate={!!initialValues}
                            cancelHandler={() => goToRoute(ROUTE_ADMIN_ZONES)}/>
                    </form>
                </CardBody>
            </Card>
        )
    }
}

function validate(values: ZoneCardFormAdminData) {
    const errors: FormErrors<ZoneCardFormAdminData> = {};

    if (!values.name || values.name.length === 0) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.elk_value || values.elk_value.length === 0) {
        errors.elk_value = I18nUtils.tr(TR_OBLIGATORIO);
    }
    return errors;
}

export default compose(
    reduxForm<ZoneCardFormAdminData, UserGroupCardFormAdminProps>({
        validate,
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps)
)(ZoneCardFormAdmin);
