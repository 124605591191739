import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import BusinessType, {BusinessTypeData} from "../../model/BusinessType";

export default class TaskBusinessTypeEdit extends BaseGenericRequest<BusinessTypeData, TaskResponse<BusinessType>> {

    private readonly data: BusinessTypeData;

    public constructor(businessTypeId: string, businessTypeData: BusinessTypeData) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_BUSINESS_TYPE_EDIT, {uuid: businessTypeId}));
        this.data = businessTypeData;
    }

    protected getRequest(): BusinessTypeData {
        return this.data;
    }
}