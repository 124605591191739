import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import FormResult from "../../model/FormResult";

export default class TaskFormResultDetail extends BaseGenericRequest<{}, TaskResponse<FormResult>> {

    public constructor(formResultId: string, formId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_FORM_RESULT_DETAIL, {formId, uuid: formResultId}))
    }
}