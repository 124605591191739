import * as React from 'react';
import {Component, ReactNode} from 'react';
import LoadingView from "../../base/loading/LoadingView";

export interface CardProps {
    loading?: boolean
    className?: string
}

export default class Card extends Component<CardProps> {

    public render(): ReactNode {
        return (
            <div className={`card ${this.props.className || ''}`}>
                {this.props.children}
                <LoadingView loading={this.props.loading || false}/>
            </div>
        );
    }

}

