import * as React from "react";
import FormResultListFilterReducer from "./FormResultListFilterReducer";
import FormResultListFilterActions from "./FormResultListFilterActions";

import {connect} from "react-redux";
import {debounce} from "lodash";
import Row from "../../../../components/Row";
import FormCol from "../../../../components/form/FormCol";
import {
    TR_BUSCANDO,
    TR_BUSCAR,
    TR_EXPLOTACION,
    TR_FECHA,
    TR_FILTROS,
    TR_FORMULARIO,
    TR_NO_SE_HAN_ENCONTRADO_RESULTADOS,
    TR_USUARIO
} from "../../../../I18n/constants";
import I18nUtils from "../../../../I18n/I18nUtils";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {CardSectionCollapsed} from "../../../../components/CardSectionCollapsed";
import FormInputOption from "../../../../components/form/FormInputOption";
import TaskFormList from "../../../../ws/form/TaskFormList";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";
import TaskUserList from "../../../../ws/user/TaskUserList";
import {FormResultAllCriteriaProps} from "../../../../ws/formResult/TaskFormResultListAll";
import {FormSimply} from "../../../../model/Form";
import {UserSimply} from "../../../../model/User";
import DateRangeInput from "../../../../components/date/DateRangeInput";

const mapStateToProps = FormResultListFilterReducer.autoMapToProps();
const mapActionsToProps = FormResultListFilterActions.autoMapToProps();

interface FormResultListFilterProps {
    criteriaHandler: (criteria: FormResultAllCriteriaProps) => void,
    criteria: FormResultAllCriteriaProps,
}

type Props = FormResultListFilterProps & typeof mapStateToProps & typeof mapActionsToProps

class FormResultListFilter extends React.Component<Props> {

    public componentDidMount(): void {
        new TaskFormList().execute();
    }

    public componentWillUnmount(): void {
        this.props.clearFormResultListFilterReducer();
    }

    private onFilterList = (newCriteria?: FormResultAllCriteriaProps): void => {
        const {criteriaHandler, criteria} = this.props;
        criteriaHandler({...criteria, ...newCriteria});
    };

    private onFilterDebounced = debounce(this.onFilterList, 500);

    private onFormSearch = (newSearch?: string): void => {
        new TaskFormList({search: newSearch}).execute();
    };

    private onExploitationSearch = (newSearch: string): void => {
        new TaskExploitationList({search: newSearch, limit: 0}).execute();
    };

    // private onStartDateSelect = (date?: Date): void => {
    //     const {criteria} = this.props;
    //     const newCriteria = {...criteria, start_date: date ? date.toISOString() : undefined};
    //     this.onFilterDebounced(newCriteria);
    // };
    //
    // private onEndDateSelect = (date?: Date): void => {
    //     const {criteria} = this.props;
    //     const newCriteria = {...criteria, end_date: date ? date.toISOString() : undefined};
    //     this.onFilterDebounced(newCriteria);
    // };

    private onDateSearch = (startDate?: Date, endDate?: Date): void => {
        const {criteria} = this.props;
        const newCriteria = {
            ...criteria,
            start_date: startDate ? startDate.toISOString() : undefined,
            end_date: endDate ? endDate.toISOString() : undefined
        };
        this.onFilterDebounced(newCriteria);
    };

    private onUserSearch = (newSearch: string): void => {
        new TaskUserList({search: newSearch, limit: 0}).execute();
    };

    public render(): React.ReactNode {
        const {criteria, exploitationsLoading, exploitations, users, usersLoading, forms, formsLoading} = this.props;
        const searchPlaceholder = I18nUtils.tr(TR_BUSCAR);

        // Realiza la búsqueda por nombre o key:
        const formOptions: FormInputOption[] = forms.map((form: FormSimply) => ({value: form.id, name: form.name}));
        formOptions.push(...forms.map((form: FormSimply) => ({value: form.id, name: form.key})));

        const formExploitationOptions: FormInputOption[] = exploitations.map((exploitation) => ({
            value: exploitation.id,
            name: exploitation.name
        }));
        // Realiza la búsqueda por nombre o apellido:
        const userOptions: FormInputOption[] = users.map((user) => ({value: user.id, name: user.name}));
        userOptions.push(...users.map((user: UserSimply) => ({value: user.id, name: user.last_name})));

        return (
            <CardSectionCollapsed title={I18nUtils.tr(TR_FILTROS)}
                                  initialCollapsed={!criteria}
                                  className={"card-filters"}
            >
                <Row>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FORMULARIO)}</label>
                                <AsyncTypeahead
                                    placeholder={searchPlaceholder}
                                    promptText={searchPlaceholder}
                                    options={formOptions}
                                    onChange={(selected) => {
                                        if (selected && selected[0]) {
                                            this.onFilterDebounced({form_ids: [selected[0].value]})
                                        }
                                    }}
                                    onInputChange={
                                        (newText: string) => newText === '' && this.onFilterDebounced({form_ids: undefined})
                                    }
                                    labelKey={'name'}
                                    filterBy={(option, props: any) => {
                                        if (option.value && option.value.length !== 0) {
                                            const optionName = option.name.toLowerCase();
                                            return optionName.includes(props.text.toLowerCase());
                                        }
                                        return false
                                    }}
                                    isLoading={false}
                                    onSearch={this.onFormSearch}
                                    searchText={formsLoading ?
                                        I18nUtils.tr(TR_BUSCANDO) :
                                        I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                                />
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_EXPLOTACION)}</label>
                                <AsyncTypeahead
                                    placeholder={searchPlaceholder}
                                    promptText={searchPlaceholder}
                                    options={formExploitationOptions}
                                    onChange={(selected) => {
                                        if (selected && selected[0])
                                            this.onFilterDebounced({exploitation_ids: [selected[0].value]})
                                    }}
                                    onInputChange={
                                        (newText: string) => newText === '' && this.onFilterDebounced({exploitation_ids: undefined})
                                    }
                                    labelKey={'name'}
                                    filterBy={() => true}
                                    isLoading={false}
                                    onSearch={this.onExploitationSearch}
                                    searchText={exploitationsLoading ?
                                        I18nUtils.tr(TR_BUSCANDO) :
                                        I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                                />
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_USUARIO)}</label>
                                <AsyncTypeahead
                                    placeholder={searchPlaceholder}
                                    promptText={searchPlaceholder}
                                    options={userOptions}
                                    onChange={(selected) => {
                                        if (selected && selected[0])
                                            this.onFilterDebounced({user_ids: [selected[0].value]})
                                    }}
                                    onInputChange={
                                        (newText: string) => newText === '' && this.onFilterDebounced({user_ids: undefined})
                                    }
                                    labelKey={'name'}
                                    filterBy={(option, props: any) => {
                                        if (option.value && option.value.length !== 0) {
                                            const optionName = option.name.toLowerCase();
                                            return optionName.includes(props.text.toLowerCase());
                                        }
                                        return false
                                    }}
                                    isLoading={false}
                                    onSearch={this.onUserSearch}
                                    searchText={usersLoading ?
                                        I18nUtils.tr(TR_BUSCANDO) :
                                        I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                                />
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FECHA)}</label>
                                <DateRangeInput
                                    startDate={criteria && criteria.start_date ? criteria.start_date as any : undefined}
                                    endDate={criteria && criteria.end_date ? criteria.end_date as any : undefined}
                                    onChange={(startDate, endDate) => this.onDateSearch(startDate, endDate)}
                                    onClear={() => this.onDateSearch(undefined, undefined)}
                                />
                            </div>
                        </div>
                    </FormCol>
                </Row>
            </CardSectionCollapsed>)
    }

}

export default connect(mapStateToProps, mapActionsToProps)(FormResultListFilter as unknown as React.ComponentType<FormResultListFilterProps>);
