import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import DashboardLayout from "../../model/DashboardLayout";

export default class TaskDashboardLayoutList extends BaseGenericRequest<{}, TaskResponse<DashboardLayout[]>> {

    public constructor() {
        super(METHOD.METHOD_POST, Urls.URL_DASHBOARD_LAYOUT_LIST);
    }
}