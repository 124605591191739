import {ReducerBuilder} from "co-redux-builders";
import TaskZoneDetail from "../../../../ws/zone/TaskZoneDetail";
import Zone from "../../../../model/Zone";
import ZoneFormAdminScreenActions from "./ZoneFormAdminScreenActions";

interface InitialState {
    zoneFormScreenLoading: boolean,
    zone?: Zone,
}

const INITIAL_STATE: InitialState = {
    zoneFormScreenLoading: false,
    zone: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskZoneDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        zoneFormScreenLoading: true
    }))
    .onEvent(TaskZoneDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        zoneFormScreenLoading: false
    }))
    .onEvent(TaskZoneDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, zone: payload.data};
        })

    .onEvent(ZoneFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();