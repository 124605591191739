import * as React from 'react';
import LoggedScreen from "../../../../base/screens/LoggedScreen";
import {RouteComponentProps} from "react-router";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_GRUPO_DE_USUARIOS,
    TR_GRUPO_DE_USUARIOS_CREADO_CORRECTAMENTE,
    TR_GRUPO_DE_USUARIOS_MODIFICADO_CORRECTAMENTE,
    TR_GRUPOS_DE_USUARIOS,
    TR_NUEVO_GRUPO_DE_USUARIOS,
} from "../../../../I18n/constants";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {
    ROUTE_ADMIN_USER_GROUP_ADD, ROUTE_ADMIN_USER_GROUP_DETAIL,
    ROUTE_ADMIN_USER_GROUPS,
} from "../../../../routing/Routes";
import {connect} from "react-redux";
import ScreenContent from "../../../../components/screen/ScreenContent";
import Row from "../../../../components/Row";
import Col from "../../../../components/Col";
import UserGroupFormAdminScreenReducer from "./UserGroupFormAdminScreenReducer";
import UserGroupCardFormAdmin, {UserGroupCardFormAdminData} from "./UserGroupCardFormAdmin";
import {MenuIcon} from "../../../../Config";
import TaskUserGroupDetail from "../../../../ws/userGroup/TaskUserGroupDetail";
import {UserGroupData} from "../../../../model/UserGroup";
import TaskUserGroupEdit from "../../../../ws/userGroup/TaskUserGroupEdit";
import TaskUserGroupAdd from "../../../../ws/userGroup/TaskUserGroupAdd";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {UserAutocompleteBuilder} from "../../../../model/User";
import {UserRoleAutocompleteBuilder} from "../../../../model/UserRole";
import UserGroupFormAdminScreenActions from "./UserGroupFormAdminScreenActions";
import {ReducerBuilder} from "co-redux-builders";
import ExploitationRoleFieldArrayReducer from "./ExploitationRoleFieldArrayReducer";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    UserGroupFormAdminScreenReducer.autoMapToProps(),
    ExploitationRoleFieldArrayReducer.autoMapToProps(),
);
const mapActionsToProps = UserGroupFormAdminScreenActions.autoMapToProps();

type UserGroupFormAdminScreenProps = RouteComponentProps<{ id: string }>

type Props = typeof mapStateToProps & typeof mapActionsToProps & UserGroupFormAdminScreenProps

class UserGroupFormAdminScreen extends LoggedScreen<Props> {

    constructor(props: Props, context: any) {
        super(props, context);
        this.addBreadcrumbScreen(I18nUtils.tr(TR_GRUPOS_DE_USUARIOS), ROUTE_ADMIN_USER_GROUPS, MenuIcon.USER_GROUP);
        if (this.props.match.params.id) {
            this.addBreadcrumbScreen(I18nUtils.tr(TR_GRUPO_DE_USUARIOS), generateRoute(
                ROUTE_ADMIN_USER_GROUP_DETAIL,
                {id: this.props.match.params.id}),
                MenuIcon.USER_GROUP);
        } else {
            this.addBreadcrumbScreen(I18nUtils.tr(TR_NUEVO_GRUPO_DE_USUARIOS), ROUTE_ADMIN_USER_GROUP_ADD, MenuIcon.USER_GROUP)
        }
    }

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskUserGroupDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: UserGroupCardFormAdminData): void => {
        const existUserGroup = this.props.match.params.id !== undefined;

        const submitData: UserGroupData = {
            name: values.name,
            roles: values.roles.map((userRole) => {
                const currentExploitation = userRole.exploitation[0];
                return {
                    role_id: userRole.role_id,
                    exploitation_id: currentExploitation && currentExploitation.value,
                }
            }),
            users: values.users.map((userAutocompleteOptions) => userAutocompleteOptions[0].value),
        };

        if (existUserGroup) {
            executeItemTask(
                new TaskUserGroupEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_ADMIN_USER_GROUPS),
                I18nUtils.tr(TR_GRUPO_DE_USUARIOS_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskUserGroupAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_USER_GROUPS),
                I18nUtils.tr(TR_GRUPO_DE_USUARIOS_CREADO_CORRECTAMENTE)
            )
        }
    };

    public renderScreen(): React.ReactNode {
        return (
            <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        {this.renderUserGroupForm()}
                    </Col>
                </Row>
            </ScreenContent>
        );
    }

    private renderUserGroupForm(): React.ReactNode {
        const {userGroupFormScreenLoading, userGroup, roleList, roleListLoading} = this.props;

        let initialValues: UserGroupCardFormAdminData | undefined;
        let userGroupId: string | undefined;

        if (userGroup) {
            initialValues = {
                name: userGroup.name ? userGroup.name : '',
                roles: userGroup.roles ?
                    userGroup.roles.map((userRole) => UserRoleAutocompleteBuilder.buildFieldArrayOption(userRole)) :
                    [],
                users: userGroup.users ?
                    userGroup.users.map((user) => [UserAutocompleteBuilder.buildOption(user)]) :
                    []
            };
            userGroupId = this.props.match.params.id ? this.props.match.params.id : '';
        }

        return (
            <UserGroupCardFormAdmin
                userGroupId={userGroupId}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                roleList={roleList}
                parentLoading={roleListLoading || userGroupFormScreenLoading}
            />)
    }
}

export default connect(mapStateToProps, mapActionsToProps)(UserGroupFormAdminScreen as unknown as React.ComponentType<UserGroupFormAdminScreenProps>);
