import {Int} from "./StringUtils";
import moment, {Moment} from "moment";

export class DateHelpers {

    public static getPreviousMonday(date: Date): Date {
        const mondayIndex = 1;
        return moment(date).isoWeekday(mondayIndex).toDate();
    }

    public static getTomorrow(date: Date = new Date()): Date {
        return moment(date).add(1, 'days').toDate();
    }

    public static getXDaysAgo(days: number, date: Date = new Date()): Date {
        return moment(date).subtract(days, 'days').toDate();
    }

    public static getXMonthsAgo(months: number, date: Date = new Date()): Date {
        return moment(date).subtract(months, 'months').toDate();
    }

    public static getXYearsAgo(years: number, date: Date = new Date()): Date {
        return moment(date).subtract(years, 'years').toDate();
    }

    public static getXWeeksAgo(weeks: number, date: Date = new Date()): Date {
        return moment(date).subtract(weeks, 'weeks').toDate();
    }

    public static getFirstDayOfYear(year: number): Date {
        return new Date(year, 0, 1);
    }

    public static getLastDayOfYear(year: number): Date {
        return new Date(year, 11, 31);
    }

    public static getFirstDayOfTrimester(year: number, trimester: 1 | 2 | 3 | 4): Date {
        const month = trimester * 3 - 3;
        return new Date(year, month, 1,15);
    }

    public static getLastDayOfTrimester(year: number, trimester: 1 | 2 | 3 | 4): Date {
        const month = trimester * 3;
        return new Date(year, month, 0);
    }

    public static getFirstSecondUtc(date?: Date): Date | undefined {
        if (!date) {
            return undefined;
        }

        date.setUTCHours(0);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);

        return date;
    }

    public static getFirstSecondLocal(date?: Date): Date | undefined {
        if (!date) {
            return undefined;
        }

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date;
    }

    public static getLastSecondUTC(date?: Date): Date | undefined {
        if (!date) {
            return undefined;
        }

        date.setUTCHours(23);
        date.setUTCMinutes(59);
        date.setUTCSeconds(59);
        date.setUTCMilliseconds(999);

        return date;
    }

    public static getLastSecondLocal(date?: Date): Date | undefined {
        if (!date) {
            return undefined;
        }

        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(999);
        return date;
    }

    public static stringify(date: Moment): string {
        return date.format('DD/MM/YYYY');
    }

    public static getDateFromInputs(date: string, time: string): Date {
        return moment(`${date} ${time}`).toDate();
    }

}

export default class DateUtils {

    public static isToday(date: Date): boolean {
        return moment(date).isSame(moment(), 'day');
    }

    public static getYesterday(): Date {
        const today = new Date();
        // @ts-ignore
        return today.setDate(today.getDate() - 1);
    }

    public static isYesterday(date: Date): boolean {
        return moment(date).isSame(this.getYesterday(), 'day');
    }

    public static isSameDay(firstDate: Date, secondDate: Date): boolean {
        return moment(firstDate).isSame(secondDate, 'day');
    }

    public static getCurrentDate(): Date {
        const today = new Date();
        today.setDate(today.getDate());
        // @ts-ignore
        return moment(today).utc().format();
    }

    public static parseStringToDate(date: string): Date {
        // @ts-ignore
        return moment(date).utc().format();
    }

    public static formattedStringToDate(dateString: string): Date {
        const dateData: string[] = dateString.split('/');
        const newDate = new Date();
        newDate.setFullYear(Int(dateData[2]));
        newDate.setMonth(Int(dateData[1]) - 1);
        newDate.setDate(Int(dateData[0]));
        return newDate;
    }

    public static isGreaterThan(firstDate: Date, secondDate: Date): boolean {
        return firstDate > secondDate
    }

    public static getDateFromISOString(ISODate: string): string {
        return ISODate.substring(0,10);
    }
}
