import * as React from 'react';
import {ROUTE_ADMIN_ALERTS} from "../../../../routing/Routes";
import AlertListAdminCard from "./AlertListAdminCard";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_ALERTAS} from "../../../../I18n/constants";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {MenuIcon} from "../../../../Config";

export default class AlertListAdminScreen extends React.Component<{}> {

    public render() {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_ALERTAS),
                    url: ROUTE_ADMIN_ALERTS,
                    icon: MenuIcon.ALERT,
                },
            ]}>
                <AlertListAdminCard/>
            </ScreenCard>
        )
    }
}