import Dashboard from "../../../../model/Dashboard";
import {ReducerBuilder} from "co-redux-builders";
import DashboardFormAdminScreenActions from "./DashboardFormAdminScreenActions";
import Exploitation from "../../../../model/Exploitation";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";
import TaskDashboardGet from "../../../../ws/dashboard/TaskDashboardGet";
import TaskBusinessTypeList from "../../../../ws/businessType/TaskBusinessTypeList";
import TaskZoneList from "../../../../ws/zone/TaskZoneList";
import BusinessType from "../../../../model/BusinessType";
import Zone from "../../../../model/Zone";

interface InitialState {
    loading: boolean,
    dashboard?: Dashboard,

    exploitations: Exploitation[],
    exploitationListLoading: boolean,

    businessTypeList: BusinessType[],
    loadingBusinessTypeList: boolean,

    zoneList: Zone [],
    loadingZoneList: boolean
}

const INITIAL_STATE: InitialState = {
    loading: false,
    dashboard: undefined,

    exploitations: [],
    exploitationListLoading: false,

    businessTypeList: [],
    loadingBusinessTypeList: false,

    zoneList: [],
    loadingZoneList: false
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskDashboardGet.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => ({
        ...oldState,
        loading: true,
    }))
    .onEvent(TaskDashboardGet.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => ({
        ...oldState,
        loading: false,
    }))
    .onEvent(TaskDashboardGet.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, dashboard: payload.data};
    })
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, exploitations: payload.data};
    })

    .onEvent(TaskBusinessTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loadingBusinessTypeList: true
    }))
    .onEvent(TaskBusinessTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loadingBusinessTypeList: false
    }))
    .onEvent(TaskBusinessTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, businessTypeList: payload.data};
    })

    .onEvent(TaskZoneList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loadingZoneList: true
    }))
    .onEvent(TaskZoneList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loadingZoneList: false
    }))
    .onEvent(TaskZoneList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, zoneList: payload.data};
    })

    .onEvent(DashboardFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build()
