import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {AlertNotification, AlertNotificationRequest} from "../../model/AlertNotification";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";

type AlertNotificationCriteriaProps = TaskCriteriaRequest<Partial<AlertNotificationRequest>>

export default class TaskAlertNotificationListFilter extends BaseGenericRequest<AlertNotificationCriteriaProps, TaskResponse<AlertNotification[]>> {

    private readonly data: AlertNotificationCriteriaProps;

    public constructor(criteria: AlertNotificationCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_ALERT_NOTIFICATION_LIST);
        this.data = criteria;
    }

    protected getRequest(): AlertNotificationCriteriaProps {
        return this.data;
    }

}