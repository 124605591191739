import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import {AlertScope} from "../../model/AlertScope";

type TaskAlertScopesListCriteria = TaskCriteriaRequest<{}>

export default class TaskAlertScopesList extends BaseGenericRequest<TaskAlertScopesListCriteria, TaskResponse<AlertScope[]>> {

    private readonly data: TaskAlertScopesListCriteria;

    public constructor(criteria: TaskAlertScopesListCriteria = {}) {
        super(METHOD.METHOD_POST, Urls.URL_ALERT_SCOPES_LIST);
        this.data = criteria;
    }

    protected getRequest(): TaskAlertScopesListCriteria {
        return this.data;
    }

}