import * as React from 'react';
import {Component, ReactNode} from 'react';
import Col from "../Col";
import Row from "../Row";
import Button from "../Button";
import I18nUtils from "../../I18n/I18nUtils";
import {TR_ANADIR, TR_CANCELAR, TR_GUARDAR} from "../../I18n/constants";
import {Field} from "redux-form";
import FormSubmit from "./FormSubmit";

export interface CardHeaderProps {
    invalid: boolean,
    pristine: boolean,
    isUpdate: boolean,
    cancelHandler: () => void,
    readOnly?: boolean,
}

interface FormCardFooterState {
    showModal: boolean
}

export default class FormCardFooter extends Component<CardHeaderProps, FormCardFooterState> {
    constructor(props: Readonly<CardHeaderProps>) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    public render(): ReactNode {
        const {invalid, pristine, isUpdate, cancelHandler, readOnly = false} = this.props;
        return (
            <>
                <Row className='p-t-20'>
                    <Col sm={4} md={6} lg={8}/>
                    {readOnly && <Col sm={4} md={3} lg={2}/>}
                    <Col sm={4} md={3} lg={2}>
                        <Button
                            text={I18nUtils.tr(TR_CANCELAR)}
                            onClick={() => cancelHandler()}
                            type={'button'}
                            block={true}
                            className={'btn-lg btn-default'}
                        />
                    </Col>
                    {!readOnly && <Field
                        label={I18nUtils.tr(isUpdate ? TR_GUARDAR : TR_ANADIR)}
                        name="submit"
                        component={FormSubmit}
                        col={{sm: 4, md: 3, lg: 2}}
                        disabled={invalid || pristine}
                    />}
                </Row>
            </>
        );
    }
}

