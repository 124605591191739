import * as React from "react";
import Dashboard from "../../../model/Dashboard";
import {OnSortHandler, SortState} from "../../../utils/Sort";
import Row from "../../../components/Row";
import MiniatureDashboardCard from "../components/MiniatureDashboardCard";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ANADIDO_A_FAVORITOS_CORRECTAMENTE,
    TR_ANADIR_A_FAVORITOS, TR_QUITADO_DE_FAVORITOS_CORRECTAMENTE,
    TR_QUITAR_DE_FAVORITOS
} from "../../../I18n/constants";
import {DropDownOption} from "../../../components/dropdown/DropDown";
import {executeItemTask} from "../../../utils/FormUtils";
import TaskFavoriteRemove from "../../../ws/favorite/TaskFavoriteRemove";
import TaskFavoriteAdd from "../../../ws/favorite/TaskFavoriteAdd";

interface DashboardListMiniatureProps {
    data: Dashboard[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
    addToFavorites?: boolean,
    removeFromFavorites?: boolean,
    refreshListHandler?: () => void,
}

export class DashboardListMiniature extends React.Component<DashboardListMiniatureProps> {
    private onRemoveFromFavorites = (itemId): void => {
        const {refreshListHandler} = this.props;

        executeItemTask(
            new TaskFavoriteRemove([itemId], 'dashboards'),
            refreshListHandler ? () => refreshListHandler() : undefined,
            I18nUtils.tr(TR_QUITADO_DE_FAVORITOS_CORRECTAMENTE));
    };

    private onAddToFavorites = (itemId): void => {
        executeItemTask(
            new TaskFavoriteAdd([itemId], 'dashboards'),
            undefined,
            I18nUtils.tr(TR_ANADIDO_A_FAVORITOS_CORRECTAMENTE));
    };

    public render(): React.ReactNode {
        const {data, addToFavorites, removeFromFavorites} = this.props;

        return (
            <Row clearfix>
                {
                    data.map((dashboard) => {
                        const headerOptions: DropDownOption[] = [];

                        if (addToFavorites) {
                            headerOptions.push({
                                text: I18nUtils.tr(TR_ANADIR_A_FAVORITOS),
                                onClick: () => this.onAddToFavorites(dashboard.id)
                            })
                        }
                        if (removeFromFavorites) {
                            headerOptions.push({
                                text: I18nUtils.tr(TR_QUITAR_DE_FAVORITOS),
                                onClick: () => this.onRemoveFromFavorites(dashboard.id)
                            })
                        }

                        return (
                            <MiniatureDashboardCard
                                key={dashboard.id}
                                dashboard={dashboard}
                                options={headerOptions.length > 0 ? headerOptions : undefined}
                            />
                        )
                    })
                }
            </Row>
        )
    }
}