import * as React from 'react';
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import Card from "../../../components/card/Card";
import CardHeader from "../../../components/card/CardHeader";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_CANAL,
    TR_DATOS_DEL_FORMULARIO,
    TR_DESCRIPCION,
    TR_ESTADO,
    TR_EXPLOTACION,
    TR_FECHA,
    TR_FECHA_ENVIO,
    TR_FECHA_RESOLUCION,
    TR_FECHA_VALOR,
    TR_LINK_DOCUMENTACION,
    TR_LINK_FORMULARIO,
    TR_NOMBRE,
    TR_NOTIFICACION,
    TR_OBSERVACIONES,
    TR_RESPONSABLES,
    TR_TIPO_DE_NOTIFICATION,
    TR_USUARIO
} from "../../../I18n/constants";
import CardBody from "../../../components/card/CardBody";
import Row from "../../../components/Row";
import FormInput from "../../../components/form/FormInput";
import {goToRoute} from "../../../utils/Router";
import {ROUTE_ADMIN_ALERT_NOTIFICATIONS} from "../../../routing/Routes";
import FormTextArea from "../../../components/form/FormTextArea";
import FormCardFooter from "../../../components/form/FormCardFooter";
import {FormFieldDic, FormResultFormFields} from "../../../model/FormResult";
import NotificationFormResultFields from "./NotificationFormResultFields";

const FORM_NAME = 'NotificationCardFormAdmin';

export interface NotificationCardFormAdminData {
    solved: string,
    solveDate: string,
    responsibleUser: string,
    observations: string,
    creationDate: string,

    alertName: string,
    alertDescription: string,
    alertTypeName: string,
    alertSourceName: string,
    alertFormRoute?: string,
    alertDocumentUrl: string,

    formResultDatetime: string,
    formResultDatetimeValue: string,
    formResultUser: string,
    formResultExploitation: string,

    formResultFormFields: FormResultFormFields[],
    formResultFormValues: FormFieldDic,
}

interface NotificationCardFormExtProps {
    alertNotificationId?: string,
    initialValues: NotificationCardFormAdminData,
    parentLoading: boolean,
}

enum NotificationCardFormAdminFields {
    SOLVED = "solved",
    SOLVE_DATE = "solveDate",
    RESPONSIBLE_USER = "responsibleUser",
    OBSERVATIONS = "observations",
    CREATION_DATE = "creationDate",

    ALERT_NAME = "alertName",
    ALERT_DESCRIPTION = "alertDescription",
    ALERT_TYPE_NAME = "alertTypeName",
    ALERT_SOURCE_NAME = "alertSourceName",
    ALERT_FORM_ROUTE = "alertFormRoute",
    ALERT_DOCUMENT_URL = "alertDocumentUrl",

    FORM_RESULT_DATETIME = "formResultDatetime",
    FORM_RESULT_DATETIME_VALUE = "formResultDatetimeValue",
    FORM_RESULT_USER = "formResultUser",
    FORM_RESULT_EXPLOITATION = "formResultExploitation",
}

interface NotificationCardFormAdminProps extends InjectedFormProps<NotificationCardFormAdminData> {
    initialValues: Partial<NotificationCardFormAdminData>,
}

type Props = NotificationCardFormExtProps & NotificationCardFormAdminProps;

class NotificationFormDetailCardAdmin extends React.Component<Props> {

    public render(): React.ReactNode {
        const {parentLoading, initialValues} = this.props;

        return (
            <React.Fragment>
                <Card loading={parentLoading}>
                    <CardHeader title={I18nUtils.tr(TR_NOTIFICACION)}/>
                    <CardBody>
                        <form>
                            <Row>
                                <Field
                                    label={I18nUtils.tr(TR_NOMBRE)}
                                    name={NotificationCardFormAdminFields.ALERT_NAME}
                                    component={FormInput}
                                    col={{md: 6, lg: 6}}
                                    disabled={true}
                                />

                                <Field
                                    label={I18nUtils.tr(TR_ESTADO)}
                                    name={NotificationCardFormAdminFields.SOLVED}
                                    component={FormInput}
                                    col={{md: 3, lg: 3}}
                                    disabled={true}
                                />
                                <Field
                                    label={I18nUtils.tr(TR_TIPO_DE_NOTIFICATION)}
                                    name={NotificationCardFormAdminFields.ALERT_TYPE_NAME}
                                    component={FormInput}
                                    col={{md: 3, lg: 3}}
                                    disabled={true}
                                />
                            </Row>
                            <Row>
                                <Field
                                    label={I18nUtils.tr(TR_CANAL)}
                                    name={NotificationCardFormAdminFields.ALERT_SOURCE_NAME}
                                    component={FormInput}
                                    col={{md: 4, lg: 4}}
                                    disabled={true}
                                />
                                <Field
                                    label={I18nUtils.tr(TR_FECHA_ENVIO)}
                                    name={NotificationCardFormAdminFields.CREATION_DATE}
                                    component={FormInput}
                                    col={{md: 4, lg: 4}}
                                    disabled={true}
                                />
                                <Field
                                    label={I18nUtils.tr(TR_FECHA_RESOLUCION)}
                                    name={NotificationCardFormAdminFields.SOLVE_DATE}
                                    component={FormInput}
                                    col={{md: 4, lg: 4}}
                                    disabled={true}
                                />
                            </Row>
                            <Row>
                                <Field
                                    label={I18nUtils.tr(TR_RESPONSABLES)}
                                    name={NotificationCardFormAdminFields.RESPONSIBLE_USER}
                                    component={FormTextArea}
                                    col={{md: 12, lg: 12}}
                                    disabled={true}
                                />
                            </Row>
                            <Row>
                                <Field
                                    label={I18nUtils.tr(TR_DESCRIPCION)}
                                    name={NotificationCardFormAdminFields.ALERT_DESCRIPTION}
                                    component={FormTextArea}
                                    col={{md: 6, lg: 6}}
                                    disabled={true}
                                />
                                <Field
                                    label={I18nUtils.tr(TR_OBSERVACIONES)}
                                    name={NotificationCardFormAdminFields.OBSERVATIONS}
                                    component={FormTextArea}
                                    col={{md: 6, lg: 6}}
                                    disabled={true}
                                />
                            </Row>
                            <Row>
                                <span className={"cursor-pointer"}
                                      onClick={() => initialValues.alertFormRoute ?
                                          window.open(initialValues.alertFormRoute, '_blank') : null}>
                                <Field
                                    label={I18nUtils.tr(TR_LINK_FORMULARIO)}
                                    name={NotificationCardFormAdminFields.ALERT_FORM_ROUTE}
                                    component={FormInput}
                                    col={{md: 6, lg: 6}}
                                    disabled={true}
                                />
                                </span>
                                <span className={"cursor-pointer"}
                                      onClick={() => initialValues.alertDocumentUrl && initialValues.alertDocumentUrl.length !== 0 ?
                                          window.open(initialValues.alertDocumentUrl, '_blank') : null}>
                                <Field
                                    label={I18nUtils.tr(TR_LINK_DOCUMENTACION)}
                                    name={NotificationCardFormAdminFields.ALERT_DOCUMENT_URL}
                                    component={FormInput}
                                    col={{md: 6, lg: 6}}
                                    disabled={true}
                                />
                                </span>
                            </Row>

                            <label>{I18nUtils.tr(TR_DATOS_DEL_FORMULARIO)}</label>

                            <Row>
                                <Field
                                    label={I18nUtils.tr(TR_FECHA)}
                                    name={NotificationCardFormAdminFields.FORM_RESULT_DATETIME}
                                    component={FormInput}
                                    col={{md: 3, lg: 3}}
                                    disabled={true}
                                />
                                <Field
                                    label={I18nUtils.tr(TR_FECHA_VALOR)}
                                    name={NotificationCardFormAdminFields.FORM_RESULT_DATETIME_VALUE}
                                    component={FormInput}
                                    col={{md: 3, lg: 3}}
                                    disabled={true}
                                />
                                <Field
                                    label={I18nUtils.tr(TR_USUARIO)}
                                    name={NotificationCardFormAdminFields.FORM_RESULT_USER}
                                    component={FormInput}
                                    col={{md: 3, lg: 3}}
                                    disabled={true}
                                />
                                <Field
                                    label={I18nUtils.tr(TR_EXPLOTACION)}
                                    name={NotificationCardFormAdminFields.FORM_RESULT_EXPLOITATION}
                                    component={FormInput}
                                    col={{md: 3, lg: 3}}
                                    disabled={true}
                                />
                            </Row>
                            <Row>
                                <NotificationFormResultFields
                                    formResultFormFields={initialValues.formResultFormFields}
                                    formResultFormValues={initialValues.formResultFormValues}
                                />
                            </Row>

                            <FormCardFooter invalid={false}
                                            pristine={false}
                                            isUpdate={false}
                                            cancelHandler={() => goToRoute(ROUTE_ADMIN_ALERT_NOTIFICATIONS)}
                                            readOnly={true}
                            />
                        </form>
                    </CardBody>
                </Card>

            </React.Fragment>
        )
    }
}

export default reduxForm<NotificationCardFormAdminData, NotificationCardFormExtProps>({
    form: FORM_NAME,
    enableReinitialize: true
})(NotificationFormDetailCardAdmin as any) as React.ComponentType<NotificationCardFormExtProps>;