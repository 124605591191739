import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import Role from "../../model/Role";

export default class TaskRoleList extends BaseGenericRequest<{}, TaskResponse<Role[]>> {

    public constructor() {
        super(METHOD.METHOD_GET, Urls.URL_ROLE_LIST);
    }
}