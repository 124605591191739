import * as React from 'react';
import {Component, ReactNodeArray} from 'react';
import DateFormatter from "../../utils/DateFormatter";
import DatePickerModal from "./DatePickerModal";
import {AppIcon} from "../../Config";
import Icon from "../Icon";

export interface DateRangeInputState {
    showModal: boolean,
}

export interface DateRangeInputProps {
    startDate?: Date,
    endDate?: Date,
    onChange: (startDate: Date | undefined, endDate: Date | undefined) => void,
    onClear?: () => void,
}

export default class DateRangeInput extends Component<DateRangeInputProps, DateRangeInputState> {

    public state: DateRangeInputState = {
        showModal: false,
    };

    public render(): ReactNodeArray {
        const showClearIcon = !!this.props.onClear && (this.props.startDate || this.props.endDate);

        return [
            <div className={"input-clear"}>
                <input
                    key={1}
                    className="form-control"
                    placeholder={''}
                    value={this.renderDates()}
                    onClick={() => this.setState({showModal: true})}
                />
                {showClearIcon &&
                <span onClick={this.props.onClear}
                      className={"icon-content"}>
                    <Icon className={"icon-close"} icon={AppIcon.CLOSE}/>
                </span>}
            </div>,
            <DatePickerModal
                key={2}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                onChange={(startDate, endDate) => {
                    this.props.onChange(startDate, endDate);
                    this.setState({showModal: false});
                }}
                show={this.state.showModal}
                onClose={() => this.setState({showModal: false})}
            />
        ];
    }

    private renderDates(): string {
        if (!this.props.startDate && !this.props.endDate) {
            return 'DD/MM/YYYY';
        }
        return `${DateFormatter.formatInputDate(this.props.startDate || '')} - ${DateFormatter.formatInputDate(this.props.endDate || '')}`;
    }

}

