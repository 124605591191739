import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import FormResult, {FormResultData} from "../../model/FormResult";

export default class TaskFormResultEdit extends BaseGenericRequest<FormResultData, TaskResponse<FormResult>> {

    private readonly data: FormResultData;

    public constructor(formResultId: string, formData: FormResultData, formId: string) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_FORM_RESULT_EDIT, {formId, uuid: formResultId}));
        this.data = formData;
    }

    protected getRequest(): FormResultData {
        return this.data;
    }
}