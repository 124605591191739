import * as React from 'react';
import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import classNames from "classnames";

export interface FormTimeProps extends WrappedFieldProps {
    name: string;
    label?: string;
    placeholder?: string;
    type: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    obligatory?: boolean
}

export default class FormTime extends Component<FormTimeProps> {

    public render(): ReactNode {
        const {input, meta, label, placeholder, disabled, col, obligatory} = this.props;
        const classFormLine = classNames('form-line', {
            focused: meta.touched && meta.error,
            error: meta.touched && meta.error
        });

        return (
            <FormCol {...col} >
                <div className={'form-group'}>
                    <div className={classFormLine}>
                        {label ? <label className='main-label'>{label || ''}<label className={'obligatory'}>{(obligatory ? "*" : "")}</label></label> : null}
                        <input
                            {...input}
                            id={name}
                            type="time"
                            className="form-control"
                            placeholder={placeholder || 'HH:MM'}
                            disabled={disabled}
                        />
                    </div>
                    <label className="error">{meta.touched ? meta.error : ""}</label>
                </div>
            </FormCol>
        );
    }
}
