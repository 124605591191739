import {ReducerBuilder} from "co-redux-builders";
import BusinessTypeFormAdminScreenActions from "./BusinessTypeFormAdminScreenActions";
import BusinessType from "../../../../model/BusinessType";
import TaskBusinessTypeDetail from "../../../../ws/businessType/TaskBusinessTypeDetail";

interface InitialState {
    businessTypeFormScreenLoading: boolean,
    businessType?: BusinessType,
}

const INITIAL_STATE: InitialState = {
    businessTypeFormScreenLoading: false,
    businessType: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskBusinessTypeDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        businessTypeFormScreenLoading: true
    }))
    .onEvent(TaskBusinessTypeDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        businessTypeFormScreenLoading: false
    }))
    .onEvent(TaskBusinessTypeDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, businessType: payload.data};
        })

    .onEvent(BusinessTypeFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();