import * as React from "react";
import {FormFieldDic, formFieldTypeValue, FormResultFormFields} from "../../../model/FormResult";
import FormInput from "../../../components/form/FormInput";
import {WrappedFieldMetaProps} from "redux-form";
import FormTextArea from "../../../components/form/FormTextArea";
import FormSwitch from "../../../components/form/FormSwitch";

interface Props {
    formResultFormFields: FormResultFormFields[],
    formResultFormValues: FormFieldDic,
}

export default class NotificationFormResultFields extends React.Component<Props> {

    private getInput(formField: FormResultFormFields, index: number): React.ReactNode | void {
        const {formResultFormValues} = this.props;

        const commonProps = {
            key: index,
            label: formField.label,
            name: formField.key,
            col: {md: 12, lg: 12},
            input: {value: formResultFormValues[formField.key]} as any,
            meta: {} as WrappedFieldMetaProps,
            disabled: true
        };

        if (formField.form_field_type === formFieldTypeValue.TEXT_AREA) {
            return <FormTextArea {...commonProps}
                                 rows={2}
            />
        }

        if (formField.form_field_type === formFieldTypeValue.SWITCH) {
            return <FormSwitch {...commonProps}/>

        } else {
            const urlProps = formField.form_field_type === formFieldTypeValue.URL ? {
                onClick: () => window.open(formResultFormValues[formField.key] as string, '_blank')
            } : {};

            let inputType: string = 'string';
            if (formField.form_field_type === formFieldTypeValue.NUMBER) {
                inputType = 'number';
            }
            if (formField.form_field_type === formFieldTypeValue.EMAIL) {
                inputType = 'email';
            }
            if (formField.form_field_type === formFieldTypeValue.DATE) {
                inputType = 'date';
            }
            if (formField.form_field_type === formFieldTypeValue.DATETIME) {
                inputType = 'datetime'
            }
            if (formField.form_field_type === formFieldTypeValue.TIME) {
                inputType = 'time'
            }

            return <FormInput {...commonProps}
                              {...urlProps}
                              type={inputType}
            />
        }
    }

    public render(): React.ReactNode {
        const {formResultFormFields} = this.props;

        return (
            formResultFormFields && formResultFormFields.length !== 0 && formResultFormFields.map((formField, index) => this.getInput(formField, index))
        )
    }
}