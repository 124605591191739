import {ReduxStorageUtils} from "co-redux-builders";
import {reducer as FormReducer} from "redux-form";

import AuthReducer from "../modules/auth/login/LoginReducer";
import HomeReducer from "../modules/home/HomeReducer";
import SideBarReducer from "../base/screens/menu/SideBar/SideBarReducer";
import NavBarReducer from "../base/screens/menu/SideBar/NavBarReducer";

import DashboardDetailModalReducer from "../modules/dashboard/detail/DashboardDetailReducer";
import PendingListCardReducer from "../modules/start/pending/list/PendingListCardReducer";

import UserListCardAdminReducer from "../modules/admin/users/list/UserListCardAdminReducer";
import UserFormScreenReducer from "../modules/admin/users/formscreen/UserFormScreenAdminReducer";

import FormFormAdminScreenReducer from "../modules/admin/forms/formscreen/FormFormAdminScreenReducer";
import FormDetailCardAdminReducer from "../modules/admin/forms/formdetail/FormDetailCardAdminReducer";

import FormPermissionCardAdminReducer
    from "../modules/admin/forms/formdetail/cards/permission/FormPermissionSectionAdminReducer";
import ExploitationFieldArrayReducer
    from "../modules/admin/forms/formdetail/cards/permission/ExploitationFieldArrayReducer";

import FormListCardAdminReducer from "../modules/admin/forms/list/FormListCardAdminReducer";

import LogListCardAdminReducer from "../modules/admin/log/list/LogListCardAdminReducer";

import AlertListAdminReducer from "../modules/admin/alerts/list/AlertListAdminCardReducer";
import AlertFormScreenAdminReducer from "../modules/admin/alerts/form/AlertFormScreenAdminReducer";

import UserGroupFormScreenAdminReducer from "../modules/admin/userGroups/form/UserGroupFormAdminScreenReducer";
import UserGroupListCardAdminReducer from "../modules/admin/userGroups/list/UserGroupListCardAdminReducer";
import UserFieldArrayReducer from "../modules/admin/userGroups/form/UserFieldArrayReducer";
import ExploitationRoleFieldArrayReducer from "../modules/admin/userGroups/form/ExploitationRoleFieldArrayReducer";
import ZoneListCardAdminReducer from "../modules/admin/zones/list/ZoneListCardAdminReducer";
import ZoneFormAdminScreenReducer from "../modules/admin/zones/form/ZoneFormAdminScreenReducer";
import BusinessTypeListCardAdminReducer from "../modules/admin/businessTypes/list/BusinessTypeListCardAdminReducer";
import BusinessTypeFormAdminScreenReducer from "../modules/admin/businessTypes/form/BusinessTypeFormAdminScreenReducer";
import ExploitationFormAdminReducer from "../modules/admin/exploitations/form/ExploitationFormAdminReducer";
import ExploitationListCardAdminReducer from "../modules/admin/exploitations/list/ExploitationListCardAdminReducer";

import DocumentListCardAdminReducer from "../modules/admin/documents/list/DocumentListCardAdminReducer";
import DocumentFormAdminScreenReducer from "../modules/admin/documents/form/DocumentFormAdminScreenReducer";

import DashboardListCardAdminReducer from "../modules/admin/dashboards/list/DashboardListCardAdminReducer";
import DashboardFormAdminScreenReducer from "../modules/admin/dashboards/form/DashboardFormAdminScreenReducer";
import GroupFieldArrayReducer from "../modules/admin/users/formdetail/cards/permission/GroupFieldArrayReducer";
import LogUserCardAdminReducer from "../modules/admin/log/user/LogUserCardAdminReducer";
import CurrentUserReducer from "./CurrentUserReducer";
import SetNewPasswordReducer from "../modules/auth/setNewPassword/SetNewPasswordReducer";
import DashboardListCardReducer from "../modules/dashboard/list/DashboardListCardReducer";
import FormFormScreenReducer from "../modules/form/formResult/FormResultFormScreenReducer";
import FormResultListCardReducer from "../modules/form/resultList/FormResultListCardReducer";
import DashboardLayoutAdminReducer from "../modules/admin/dashboards/layout/DashboardLayoutAdminReducer";

import LinkListAdminReducer from "../modules/admin/links/list/LinkListAdminReducer";
import LinkDetailAdminReducer from "../modules/admin/links/detail/LinkDetailAdminReducer";
import LinkCreateAdminReducer from "../modules/admin/links/create/LinkCreateAdminReducer";
import AppLauncherListReducer from '../modules/applauncher/list/AppLauncherListReducer';
import CompareGraphicsReducer from "../modules/comparegraphics/CompareGraphicsReducer";
import NotificationListCardAdminReducer from "../modules/notification/list/NotificationListCardAdminReducer";
import NotificationFormScreenAdminReducer
    from "../modules/notification/formscreen/NotificationFormScreenAdminReducer";
import NotificationListFilterReducer from "../modules/notification/list/listFilter/NotificationListFilterReducer";
import FormResultAllListCardReducer from "../modules/formResult/list/FormResultAllListCardReducer";
import FormResultListFilterReducer from "../modules/formResult/list/listFilter/FormResultListFilterReducer";
import FormResultAllFormScreenReducer from "../modules/formResult/formscreen/FormResultAllFormScreenReducer";
import DocumentationListFilterReducer from "../modules/documentation/list/listFilters/DocumentationListFilterReducer";

import AlertNotificationUserReducer from "../modules/admin/alertnotificationuser/list/AlertNotificationUserReducer";

(FormReducer as any).reactotronKey = "form";

export default ReduxStorageUtils.combineReducers([
    // GENERAL
    FormReducer as any,
    SideBarReducer,
    CurrentUserReducer,
    AuthReducer,
    HomeReducer,
    NavBarReducer,
    SetNewPasswordReducer,

    // MY DASHBOARDS
    DashboardDetailModalReducer,
    DashboardListCardReducer,

    // MY FORMS
    FormFormScreenReducer,
    FormResultListCardReducer,

    // MY PENDINS
    PendingListCardReducer,

    // ADMIN - USER
    UserListCardAdminReducer,
    UserFormScreenReducer,

    GroupFieldArrayReducer,

    // ADMIN - USER GROUP
    UserGroupListCardAdminReducer,
    UserGroupFormScreenAdminReducer,
    UserFieldArrayReducer,
    ExploitationRoleFieldArrayReducer,

    // ADMIN - FORM
    FormFormAdminScreenReducer,
    FormDetailCardAdminReducer,
    FormPermissionCardAdminReducer,
    ExploitationFieldArrayReducer,
    FormListCardAdminReducer,

    // ADMIN - DASHBOARD
    DashboardListCardAdminReducer,
    DashboardFormAdminScreenReducer,
    DashboardLayoutAdminReducer,

    // ADMIN - ALERT
    AlertListAdminReducer,
    AlertFormScreenAdminReducer,

    // ADMIN - LOG
    LogListCardAdminReducer,
    LogUserCardAdminReducer,

    // ADMIN - EXPLOITATION
    ExploitationFormAdminReducer,
    ExploitationListCardAdminReducer,

    // ADMIN - ZONES
    ZoneListCardAdminReducer,
    ZoneFormAdminScreenReducer,

    // ADMIN - BUSINESS TYPES
    BusinessTypeListCardAdminReducer,
    BusinessTypeFormAdminScreenReducer,

    // ADMIN - DOCUMENTS
    DocumentListCardAdminReducer,
    DocumentFormAdminScreenReducer,
    DocumentationListFilterReducer,

    // ADMIN - LINKS
    LinkListAdminReducer,
    LinkDetailAdminReducer,
    LinkCreateAdminReducer,

    // ADMIN - NOTIFICATIONS
    NotificationListCardAdminReducer,
    NotificationFormScreenAdminReducer,
    NotificationListFilterReducer,

    // APPLAUNCHER
    AppLauncherListReducer,

    // COMPAREGRAPHICS
    CompareGraphicsReducer,

    // NOTIFICATIONUSER
    AlertNotificationUserReducer,

    // FORM RESULTS
    FormResultAllListCardReducer,
    FormResultListFilterReducer,
    FormResultAllFormScreenReducer,
]);
