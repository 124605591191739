import * as React from 'react';
import { ChangeEventHandler, Component, ReactNode } from 'react';
import FormCol, { FormColProps } from './FormCol';
import { WrappedFieldProps } from 'redux-form';
import classNames from 'classnames';

export interface FormCurrencyProps extends WrappedFieldProps {
    name: string;
    label?: string;
    placeholder?: string;
    type: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps,
    min?: number,
    max?: number,
    maxLength?: number,
    showError?: boolean,
    step?: number,
    obligatory?: boolean
}

export default class FormCurrency extends Component<FormCurrencyProps> {

    private static REGEX_FORMAT_REPLACE = /(\d)(?=(?:\d{3})+(?:,|$))|(,\d*)$/g;
    private static REGEX_LEADING_ZEROS_REPLACE = /^(?:0+)(?!$)/g;
    private static REGEX_LAST_CHARACTER = /.$/;
    private static REGEX_DOTS_REPLACE = /\./g;
    private static REGEX_LEADING_ZEROS_TEST = /^0+,/;
    private static REGEX_VALID_TWO_DECIMALS_INPUT = new RegExp('\^(\\d\+)(\\\.)\?(\\d{1,2})\?\$');

    private static REGEX_FORMAT_REPLACER = (m, s1, s2) => s2 || ( s1 + '.' );
    private static REGEX_LEADING_ZEROS_REPLACER = (m, s1, s2) => FormCurrency.REGEX_LEADING_ZEROS_TEST.test(s2) ? '0' : '';
    private static REGEX_DECIMAL_DOT_REPLACER = (m) => m === '.' ? ',' : m;

    public render(): ReactNode {
        const {
            input,
            meta,
            label,
            disabled = false,
            col,
            showError,
            obligatory,
        } = this.props;

        const classFormLine = classNames('form-line', {
            focused: meta.touched && meta.error,
            error: meta.touched && meta.error
        });

        const valueFormatted: string = this.handleFormat(input.value);

        return (
            <FormCol {...col} >
                <div className={'form-group'}>
                    <div className={classFormLine}>
                        {
                            label ? (
                                <label className='main-label'>
                                    {label || ''}<label className={'obligatory'}>{( obligatory ? '*' : '' )}</label>
                                </label>
                            ) : null
                        }
                        <div
                            style={{
                                display: 'flex',
                                flexFlow: 'row nowrap',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <i
                                className="material-icons"
                                children={'euro'}
                                style={{
                                    color: '#8C8C8C',
                                    fontSize: '14px',
                                    marginRight: 5,
                                }}
                            />
                            <input
                                type={'text'}
                                className="form-control"
                                value={valueFormatted}
                                disabled={disabled}
                                onChange={this.handleChange}
                                name={input.name}
                            />
                        </div>
                    </div>
                    <label className="error">
                        {( meta.touched || showError ) ? meta.error : ''}
                    </label>
                </div>
            </FormCol>
        );
    }

    private handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const { input } = this.props;
        input.onChange(event);
        const valueFormatted: string = event.target.value;
        const valueDeformatted: string = this.handleDeformat(valueFormatted);
        const isValid: boolean = this.handleIsValidInput(valueDeformatted);
        if ( isValid ) input.onChange(valueDeformatted);
    };

    // Example: 1200023. -> true
    // Example: 1200023.5 -> true
    // Example: 1200023.50 -> true
    private handleIsValidInput = (currency: string): boolean => {
        return currency.length > 0
            ? FormCurrency.REGEX_VALID_TWO_DECIMALS_INPUT.test(currency)
            : true;
    };

    // Example: 1.200.023,5 -> 1200023.5
    private handleDeformat = (currency: string): string => {
        return currency
            .replace(FormCurrency.REGEX_LAST_CHARACTER, FormCurrency.REGEX_DECIMAL_DOT_REPLACER)
            .replace(FormCurrency.REGEX_DOTS_REPLACE, '')
            .replace(',', '.');
    };

    // Example: 1200023.5 -> 1.200.023,5
    private handleFormat = (currency: string): string => {
        return currency
            .replace('.', ',')
            .replace(FormCurrency.REGEX_LEADING_ZEROS_REPLACE, FormCurrency.REGEX_LEADING_ZEROS_REPLACER)
            .replace(FormCurrency.REGEX_FORMAT_REPLACE, FormCurrency.REGEX_FORMAT_REPLACER);
    };

}

