import BaseGenericRequest from "../BaseGenericRequest";
import FormModel, {FormModelData} from "../../model/Form";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";

export default class TaskFormEdit extends BaseGenericRequest<FormModelData, TaskResponse<FormModel>> {

    private readonly data: FormModelData;

    public constructor(formId: string, formData: FormModelData) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_FORM_EDIT, {uuid: formId}));
        this.data = formData;
    }

    protected getRequest(): FormModelData {
        return this.data;
    }
}