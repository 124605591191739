import {ReducerBuilder} from "co-redux-builders";
import AuthActions from "./LoginActions";
import {isEmailValid} from "../../../utils/StringUtils";
import TaskLogin from "../../../ws/auth/TaskLogin";

interface State {
    email: string,
    password: string,
    token: string,

    loading: boolean,
    allowLogin: boolean,

    isValidUser: boolean,
    isValidPassword: boolean,
}

const INITIAL_STATE: State = {
    email: '',
    password: '',
    token: '',

    loading: false,
    allowLogin: false,

    isValidUser: false,
    isValidPassword: false,
};

const buildState = (state: State): State => {
    const isValidUser = isEmailValid(state.email);
    const isValidPassword = state.password.length > 0;

    return ({...state, allowLogin: isValidUser && isValidPassword, isValidPassword, isValidUser });
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(AuthActions.setUsername, (oldState, payload) => buildState({ ...oldState, email: payload.email || '' }))
    .onEvent(AuthActions.setPassword, (oldState, payload) => buildState({ ...oldState, password: payload.password || '' }))
    .onEvent(AuthActions.setToken, (oldState, payload) => buildState({ ...oldState, token: payload.token || '' }))
    .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => buildState({ ...oldState, loading: true }))
    .onEvent(TaskLogin.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => buildState({ ...oldState, loading: false }))
    .build();
