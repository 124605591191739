import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {Link} from "../../model/Link";
import {generateRoute} from "../../utils/Router";


export default class TaskLinkDetail extends BaseGenericRequest<{uuid: string}, TaskResponse<Link>> {
    public constructor(uuid: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_LINK_DETAIL, {uuid: uuid}));
    }
}