import * as React from 'react';
import {FieldArray, FormErrors} from "redux-form";
import I18nUtils from "../../../../../../I18n/I18nUtils";
import {
    TR_PERMISOS,
    TR_SELECCIONA_UN_USUARIO,
    TR_SELECCIONA_UNA_EXPLOTACION,
    TR_YA_HA_SIDO_ANADIDA_ESTA_EXPLOTACION,
    TR_YA_HA_SIDO_ANADIDO_ESTE_USUARIO,
} from "../../../../../../I18n/constants";
import Row from "../../../../../../components/Row";
import BaseFormFieldsHelpers from "../../../../../../base/form/BaseFormFieldsHelpers";
import FormPermissionSectionAdminReducer from "./FormPermissionSectionAdminReducer";
import TaskExploitationList from "../../../../../../ws/exploitation/TaskExploitationList";
import TaskUserList from "../../../../../../ws/user/TaskUserList";
import TaskRoleList from "../../../../../../ws/role/TaskRoleList";
import {connect} from "react-redux";
import UserFieldArray from "../../../../userGroups/form/UserFieldArray";
import {AutocompleteOption} from "../../../../../../components/form/FormAutocomplete";
import {ExploitationAutocompleteBuilder, ExploitationSimply} from "../../../../../../model/Exploitation";
import ExploitationFieldArray from "./ExploitationFieldArray";
import {UserAutocompleteOptions} from "../../../../userGroups/form/UserGroupCardFormAdmin";
import {FieldArrayErrors} from "../../../../../../utils/FormUtils";
import Permission from "../../../../../../model/Permission";
import {UserAutocompleteBuilder} from "../../../../../../model/User";
import CardCollapsed from "../../../../../../components/card/CardCollapsed";
import ExploitationRoleFieldArray, {ExploitationRoleFieldArrayHelpers} from "../../../../userGroups/form/ExploitationRoleFieldArray";
import {UserRoleAutocompleteBuilder, UserRoleFieldArrayOption} from "../../../../../../model/UserRole";
import Role from "../../../../../../model/Role";

export type ExploitationAutocompleteOptions = Array<AutocompleteOption<ExploitationSimply>>;

export interface FormPermissionSectionData {
    roles: UserRoleFieldArrayOption[],
    exploitations: ExploitationAutocompleteOptions[],
    users: UserAutocompleteOptions[],
}

export enum FormPermissionSectionFields {
    ROLES = 'roles',
    EXPLOITATIONS = 'exploitations',
    USERS = 'users',
}

interface FormPermissionSectionProps {
    className?: string,
    invalid: boolean,
}

interface FormPermissionSectionAdminState {
    collapsed: boolean;
}

const mapStateToProps = FormPermissionSectionAdminReducer.autoMapToProps();

type Props = FormPermissionSectionProps & typeof mapStateToProps;
type State = FormPermissionSectionAdminState;

class FormPermissionSectionAdmin extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            collapsed: false,
        }
    }

    public componentWillMount(): void {
        new TaskExploitationList().execute();
        new TaskUserList().execute();
        new TaskRoleList().execute();
    }

    private onCollapsedCard = () => {
        this.setState({collapsed: !this.state.collapsed})
    };

    public render(): React.ReactNode {
        const {invalid, className} = this.props;
        const {collapsed} = this.state;

        return (
            <CardCollapsed title={I18nUtils.tr(TR_PERMISOS)}
                           collapsedHandler={this.onCollapsedCard}
                           collapsed={collapsed}
                           invalid={invalid}
                           className={className}
            >
                <Row className={'p-l-15 p-r-15'}>
                    <FieldArray
                        name={FormPermissionSectionFields.ROLES}
                        component={ExploitationRoleFieldArray}
                    />
                    <FieldArray
                        name={FormPermissionSectionFields.USERS}
                        component={UserFieldArray}
                    />
                    <FieldArray
                        name={FormPermissionSectionFields.EXPLOITATIONS}
                        component={ExploitationFieldArray}
                    />
                </Row>
            </CardCollapsed>
        )
    }
}

export default connect(mapStateToProps)(FormPermissionSectionAdmin) as unknown as React.ComponentType<FormPermissionSectionProps>;

export class FormPermissionCardAdminHelpers extends BaseFormFieldsHelpers {

    private static validateExploitationsArray(values: FormPermissionSectionData): string[] {
        const processedExploitationIds: string[] = [];

        const allExploitationsErrors: string[] = [];


        values.exploitations.forEach((exploitationsOptions, exploitationIndex) => {
            if (exploitationsOptions && exploitationsOptions.length > 0 && exploitationsOptions[0]) {

                const selectedExploitation = exploitationsOptions[0];

                if (processedExploitationIds.includes(selectedExploitation.value)) {
                    allExploitationsErrors[exploitationIndex] = I18nUtils.tr(TR_YA_HA_SIDO_ANADIDA_ESTA_EXPLOTACION)
                } else {
                    processedExploitationIds.push(selectedExploitation.value);
                }
            } else {
                allExploitationsErrors[exploitationIndex] = I18nUtils.tr(TR_SELECCIONA_UNA_EXPLOTACION);
            }
        });
        return allExploitationsErrors;
    }

    private static validateUsersArray(values: FormPermissionSectionData): string[] {
        const processedUserIds: string[] = [];

        const allUsersErrors: string[] = [];

        values.users.forEach((userOptions, userIndex) => {
            if (userOptions && userOptions[0]) {

                const selectedUser = userOptions[0];

                if (processedUserIds.includes(selectedUser.value)) {
                    allUsersErrors[userIndex] = I18nUtils.tr(TR_YA_HA_SIDO_ANADIDO_ESTE_USUARIO);
                } else {
                    processedUserIds.push(selectedUser.value);
                }
            } else {
                allUsersErrors[userIndex] = I18nUtils.tr(TR_SELECCIONA_UN_USUARIO);
            }
        });
        return allUsersErrors;
    }

    public static validate(values: FormPermissionSectionData, props: { roleList: Role[] }) {
        const errors: FormErrors<FormPermissionSectionData, FieldArrayErrors> = {};

        if (values.users) {
            errors.users = this.validateUsersArray(values);
        }
        if (values.exploitations) {
            errors.exploitations = this.validateExploitationsArray(values);
        }
        if (values.roles) {
            errors.roles = ExploitationRoleFieldArrayHelpers.validateWithoutExploitations(values, props);
        }

        return errors;
    }

    public static getFormData(formPermission: Permission): FormPermissionSectionData {
        const permissionRole: UserRoleFieldArrayOption[] = formPermission.roles ?
            formPermission.roles.map((userRole) => UserRoleAutocompleteBuilder.buildFieldArrayOption(userRole)) :
            [];
        const permissionUser: UserAutocompleteOptions[] = formPermission.users ?
            formPermission.users.map((user) => [UserAutocompleteBuilder.buildOption(user)]) :
            [];
        const permissionExploitation: ExploitationAutocompleteOptions[] = formPermission.exploitations ?
            formPermission.exploitations
                .filter((exploitation) => exploitation !== null && exploitation !== undefined)
                .map((exploitation) => [ExploitationAutocompleteBuilder.buildOption(exploitation)])
            :
            [];

        return {
            roles: permissionRole,
            users: permissionUser,
            exploitations: permissionExploitation,
        }
    }
}

