import * as React from 'react';
import {Component, ReactNode} from 'react';
import classNames from 'classnames';

export interface IconProps {
    className?: string,
    icon: string,
}

export default class Icon extends Component<IconProps> {

    public render(): ReactNode {
        const classComponent = classNames('material-icons', this.props.className);
        return (
            <i className={classComponent}>{this.props.icon || this.props.children}</i>
        );
    }

}

