import {AlertStateValues} from "../../../model/AlertState";
import {AlertFormAdminData} from "./form/AlertFormAdmin";

export default class AlertForm {

    public static INITIAL_VALUES: Partial<AlertFormAdminData> = {
        state: AlertStateValues.ACTIVE,
        email: true,
        frequency: '1',
        roles: [{
            role_id: "3",
            exploitation: []
        }],
        channel: "Beepark"
    };

}
