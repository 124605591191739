import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import FormFieldType from "../../model/FormFieldType";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";

export default class TaskFormFieldTypeList extends BaseGenericRequest<{}, TaskResponse<FormFieldType[]>> {

    public constructor() {
        super(METHOD.METHOD_GET, Urls.URL_FORM_FIELD_TYPE_LIST);
    }
}