import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import {AlertType} from "../../model/AlertType";

type TaskAlertTypeListCriteria = TaskCriteriaRequest<{}>

export default class TaskAlertTypeList extends BaseGenericRequest<TaskAlertTypeListCriteria, TaskResponse<AlertType[]>> {

    private readonly data: TaskAlertTypeListCriteria;

    public constructor(criteria: TaskAlertTypeListCriteria = {}) {
        super(METHOD.METHOD_POST, Urls.URL_ALERT_TYPES_LIST);
        this.data = criteria;
    }

    protected getRequest(): TaskAlertTypeListCriteria {
        return this.data;
    }

}