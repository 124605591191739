import * as React from 'react';
import {Component, ReactNode} from 'react';
import Icon from "../../../../components/Icon";
import classNames from 'classnames';
import {Link} from "react-router-dom";

export interface MenuItemProps {
    title: string,
    icon: string,
    active: boolean,
    route?: string,
    expanded?: boolean,
    onExpand?: (expand: boolean) => void,
    subItems?: Array<{title: string, route: string}>,
    visible?: boolean,
    selectedSubItemIndex?: number,
}

export default class MenuItem extends Component<MenuItemProps> {

    public render(): ReactNode {
        const {visible = true} = this.props;
        if (!visible) {
            return null;
        }
        const classComponent = classNames({active: this.props.active});
        return (
            <li className={classComponent}>
                {this.renderLink()}
                {this.renderSubItems()}
            </li>
        );
    }

    private renderLink() {
        const {title, icon} = this.props;
        if (this.props.subItems && this.props.onExpand) {
            const classA = classNames('menu-toggle', 'waves-effect', 'waves-block', { toggled: this.props.expanded });
            return (
                // @ts-ignore
                <a onClick={() => this.props.onExpand(!this.props.expanded)} className={classA}>
                    <Icon icon={icon}/>
                    <span>{title}</span>
                </a>
            );
        } else {
            const classA = classNames('waves-effect', 'waves-block', { toggled: this.props.expanded });
            return (
                <Link to={this.props.route || ''} className={classA}>
                    <Icon icon={icon}/>
                    <span>{title}</span>
                </Link>
            );
        }
    }

    private renderSubItems(): ReactNode {
        if (!this.props.subItems) {
            return null;
        }
        return (
            <ul className="ml-menu" style={{ display: this.props.expanded ? 'block' : 'none' }}>
                {this.props.subItems.map((subItem, index: number) => {
                    return (
                        <li key={index}
                        >
                            <Link to={subItem.route}
                                  className={this.props.selectedSubItemIndex === index ? 'active' : ''}
                            >
                                {subItem.title}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        );
    }

}

