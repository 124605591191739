import * as React from 'react';
import ActiveLabel from "../../../../components/label/ActiveLabel";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {ROUTE_ADMIN_ALERT_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_ESTADO, TR_FECHA_ENVIO, TR_NOMBRE, TR_PERIODICIDAD,} from "../../../../I18n/constants";
import Alert from "../../../../model/Alert";
import {OnSortHandler, SortState} from "../../../../utils/Sort";
import DateFormatter from "../../../../utils/DateFormatter";

interface AlertListTableProps {
    data: Alert[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class AlertListTable extends React.Component<AlertListTableProps> {

    private gotoItemDetail = (itemId: string): void => {
        goToRoute(generateRoute(ROUTE_ADMIN_ALERT_DETAIL, {id: itemId}))
    };

    public render(): React.ReactNode {
        const {data, renderSelectTd} = this.props;

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((alert: Alert) => {
                    // const localDatetime = new Date(alert.datetime).toLocaleString();
                    return (
                        <tr key={alert.id}
                            onClick={() => this.gotoItemDetail(alert.id)}
                            className={"tr-cursor"}>
                            {renderSelectTd ? renderSelectTd(alert.id) : null}
                            <td>
                                <ActiveLabel text={alert.alert_state.name} active={true}/>
                            </td>
                            <td>{alert.name}</td>
                            <td>{alert.alert_frequency.name}</td>
                            <td>{DateFormatter.formatDatetime(alert.datetime)}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_ESTADO)}
                    columnName={'state'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_PERIODICIDAD)}
                    columnName={'period'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA_ENVIO)}
                    columnName={'date'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
