import BusinessType from "./BusinessType";
import Zone from "./Zone";
import {AutocompleteOption} from "../components/form/FormAutocomplete";
import Alert from "./Alert";
import {CompanyType} from "./CompanyType";
import {UserSimply} from "./User";
import DocumentModel from "./Document";

export default interface Exploitation extends ExploitationSimply{
    contract_name: string
    image: string,
    latitude: number,
    longitude: number,
    head_of_service_user: Array<UserSimply>,
    contract_signing_date: string,
    record_signing_date: string,
    record_signing_date_alert: Alert,
    record_signing_document: DocumentModel,
    start_service_date: string,
    start_service_date_alert: Alert,
    contract_end_date: string,
    contract_end_date_alert: Alert,
    extension_request_limit_date: string,
    extension_request_limit_date_alert: Alert,
    extension_start_date: string,
    extension_start_date_alert : Alert,
    extension_end_date: string,
    extension_end_date_alert: Alert,
    company_type: CompanyType,
    company_name: string
    company_percentage: number
    company_cif: string
    company_proxies: string
    sealed_document_places: number
    offer_places: number
    real_places: number
    schedule_image: string
    payment_methods: string
    holidays_alert: Alert,
    rates_review_alert: Alert,
    reversion: string
}

export interface ExploitationRequest {
    business_type_id: string,
    zone_id: string,
    contract_name?: string,
    image?: string,
    latitude: number,
    longitude: number,
    head_of_service_user_ids: Array<string>
}

export interface ExploitationSimply {
    id: string,
    name: string,
    business_type: BusinessType
    zone: Zone
}

export interface ExploitationDateRequest {
    contract_signing_date: string,
    record_signing_date?: string,
    record_signing_date_alert_id?: string,
    record_signing_document_id?: string,
    start_service_date?: string,
    start_service_date_alert_id?: string,
    contract_end_date: string,
    contract_end_date_alert_id: string,
    extension_request_limit_date?: string,
    extension_request_limit_date_alert_id?: string,
    extension_start_date?: string,
    extension_start_date_alert_id?: string,
    extension_end_date?: string,
    extension_end_date_alert_id?: string
}

export interface ExploitationCharacteristicsRequest{
    company_type_id?: string,
    company_name?: string,
    company_percentage?: number,
    company_cif?: string,
    company_proxies?: string
}

export interface ExploitationServicesRequest{
    sealed_document_places: number,
    offer_places: number,
    real_places: number,
    schedule_image: string,
    payment_methods: string,
    holidays_alert_id?: string,
    rates_review_alert_id?: string,
}

export interface ExploitationReversionRequest{
    reversion: string
}

export class ExploitationAutocompleteBuilder {

    public static buildOption(exploitation: ExploitationSimply): AutocompleteOption<ExploitationSimply> {
        return ({
            name: `${exploitation.business_type ? exploitation.business_type.name : ''} - ${exploitation.zone ? exploitation.zone.name : ''}`,
            value: exploitation.id,
            data: exploitation
        });
    }
    public static buildSubtitle(exploitation: Exploitation): string {
        if (exploitation) {
            return `${exploitation.business_type ? exploitation.business_type.name : ''} (${exploitation.zone ? exploitation.zone.name : ''})`;
        }
        return '';
    }

}