import {ReducerBuilder} from "co-redux-builders";
import FormResultListFilterActions from "./FormResultListFilterActions";
import {ExploitationSimply} from "../../../../model/Exploitation";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";
import {UserSimply} from "../../../../model/User";
import TaskUserList from "../../../../ws/user/TaskUserList";
import {FormSimply} from "../../../../model/Form";
import TaskFormList from "../../../../ws/form/TaskFormList";

interface InitialState {
    formsLoading: boolean,
    forms: FormSimply[],
    exploitationsLoading: boolean,
    exploitations: ExploitationSimply[],
    usersLoading: boolean,
    users: UserSimply[],
}

const INITIAL_STATE: InitialState = {
    formsLoading: false,
    forms: [],
    exploitationsLoading: false,
    exploitations: [],
    usersLoading: false,
    users: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskFormList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        formsLoading: true
    }))
    .onEvent(TaskFormList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        formsLoading: false
    }))
    .onEvent(TaskFormList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            forms: payload.data
        }
    })

    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationsLoading: true
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationsLoading: false
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            exploitations: payload.data
        }
    })

    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        usersLoading: true
    }))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        usersLoading: false
    }))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            users: payload.data
        }
    })

    .onEvent(FormResultListFilterActions.clearFormResultListFilterReducer, () => INITIAL_STATE)

    .build()