import * as React from 'react';
import Card from "../../../components/card/Card";
import CardHeader from "../../../components/card/CardHeader";
import CardBody from "../../../components/card/CardBody";
import Col from "../../../components/Col";
import Dashboard from "../../../model/Dashboard";
import {generateRoute} from "../../../utils/Router";
import {ROUTE_DASHBOARD_DETAIL} from "../../../routing/Routes";
import {DropDownOption} from "../../../components/dropdown/DropDown";

interface MiniatureDashboardCardProps {
    dashboard: Dashboard,
    // onRefresh,
    options?: DropDownOption[],
}

export default class MiniatureDashboardCard extends React.Component<MiniatureDashboardCardProps> {

    public render(): React.ReactNode {
        const {dashboard, options} = this.props;
        const defaultItem = dashboard.items.find((dashboardItem) => dashboardItem.default);
        return (
            <Col md={6} lg={6}>
                <Card>
                    <CardHeader
                        titleRoute={generateRoute(ROUTE_DASHBOARD_DETAIL, {id: dashboard.id})}
                        title={dashboard.name}
                        onRefresh={() => console.warn('refresh')}
                        options={options}
                    />
                    <CardBody className='dashboard-donut-chart'>
                        <iframe src={defaultItem && defaultItem.url_iframe}
                                height={'100%'}
                                width={'100%'}/>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}
