import * as React from "react";
import ScreenCard from '../../../components/screen/ScreenCard';
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_DASHBOARDS} from "../../../I18n/constants";
import {ROUTE_DASHBOARD} from "../../../routing/Routes";
import {MenuIcon} from "../../../Config";
import DashboardListCardAdmin from "./DashboardListCard";

export default class DashboardListScreen extends React.Component<{}> {

    public render() {

        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_DASHBOARDS),
                    url: ROUTE_DASHBOARD,
                    icon: MenuIcon.DASHBOARD,
                }
            ]}>
                <DashboardListCardAdmin/>
            </ScreenCard>
        )
    }
}