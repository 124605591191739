import * as React from 'react';
import LoggedScreen from "../../../../base/screens/LoggedScreen";
import {RouteComponentProps} from "react-router";
import I18nUtils from "../../../../I18n/I18nUtils";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {connect} from "react-redux";
import ScreenContent from "../../../../components/screen/ScreenContent";
import Row from "../../../../components/Row";
import Col from "../../../../components/Col";
import ZoneFormAdminScreenReducer from "./ZoneFormAdminScreenReducer";
import ZoneCardFormAdmin, {ZoneCardFormAdminData} from "./ZoneCardFormAdmin";
import {MenuIcon} from "../../../../Config";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {
    TR_NUEVA_ZONA,
    TR_ZONA,
    TR_ZONA_CREADA_CORRECTAMENTE,
    TR_ZONA_MODIFICADA_CORRECTAMENTE,
    TR_ZONAS
} from "../../../../I18n/constants";
import {ROUTE_ADMIN_ZONE_ADD, ROUTE_ADMIN_ZONE_DETAIL, ROUTE_ADMIN_ZONES} from "../../../../routing/Routes";
import TaskZoneDetail from "../../../../ws/zone/TaskZoneDetail";
import {ZoneData} from "../../../../model/Zone";
import TaskZoneEdit from "../../../../ws/zone/TaskZoneEdit";
import TaskZoneAdd from "../../../../ws/zone/TaskZoneAdd";
import ZoneFormAdminScreenActions from "./ZoneFormAdminScreenActions";

const mapStateToProps = ZoneFormAdminScreenReducer.autoMapToProps();
const mapActionsToProps = ZoneFormAdminScreenActions.autoMapToProps();

type ZoneFormAdminScreenProps = RouteComponentProps<{ id: string }>

type Props = typeof mapStateToProps & ZoneFormAdminScreenProps & typeof mapActionsToProps

class ZoneFormAdminScreen extends LoggedScreen<Props> {

    constructor(props: Props, context: any) {
        super(props, context);
        this.addBreadcrumbScreen(I18nUtils.tr(TR_ZONAS), ROUTE_ADMIN_ZONES, MenuIcon.ZONE);
        if (this.props.match.params.id) {
            this.addBreadcrumbScreen(I18nUtils.tr(TR_ZONA), generateRoute(
                ROUTE_ADMIN_ZONE_DETAIL,
                {id: this.props.match.params.id}),
                MenuIcon.ZONE);
        } else {
            this.addBreadcrumbScreen(I18nUtils.tr(TR_NUEVA_ZONA), ROUTE_ADMIN_ZONE_ADD, MenuIcon.ZONE)
        }
    }

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskZoneDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: ZoneCardFormAdminData): void => {
        const existZone = this.props.match.params.id !== undefined;

        const submitData: ZoneData = {
            name: values.name,
            elk_value: values.elk_value,
        };

        if (existZone) {
            executeItemTask(
                new TaskZoneEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_ADMIN_ZONES),
                I18nUtils.tr(TR_ZONA_MODIFICADA_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskZoneAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_ZONES),
                I18nUtils.tr(TR_ZONA_CREADA_CORRECTAMENTE)
            )
        }
    };

    public renderScreen(): React.ReactNode {
        return (
            <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        {this.renderZoneForm()}
                    </Col>
                </Row>
            </ScreenContent>
        );
    }

    private renderZoneForm(): React.ReactNode {
        const {zoneFormScreenLoading, zone} = this.props;

        if (zoneFormScreenLoading) {
            return null;
        }

        let initialValues: ZoneCardFormAdminData | undefined;
        let zoneId: string | undefined;

        if (zone) {
            initialValues = {
                name: zone.name ? zone.name : '',
                elk_value: zone.elk_value ? zone.elk_value : '',
            };
            zoneId = this.props.match.params.id ? this.props.match.params.id : '';
        }

        return (
            <ZoneCardFormAdmin
                zoneId={zoneId}
                initialValues={initialValues}
                onSubmit={this.onSubmit}
            />)
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ZoneFormAdminScreen as unknown as React.ComponentType<ZoneFormAdminScreenProps>);
