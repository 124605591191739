import {ReducerBuilder} from "co-redux-builders";
import TaskFormResultDetail from "../../../ws/formResult/TaskFormResultDetail";
import FormResultAllFormScreenActions from "./FormResultAllFormScreenActions";
import FormResult from "../../../model/FormResult";

interface InitialState {
    formResultLoading: boolean,
    formResult?: FormResult,
}

const INITIAL_STATE: InitialState = {
    formResultLoading: false,
    formResult: undefined,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskFormResultDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        formResultLoading: true
    }))
    .onEvent(TaskFormResultDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        formResultLoading: false
    }))
    .onEvent(TaskFormResultDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, formResult: payload.data};
        })

    .onEvent(FormResultAllFormScreenActions.clearFormResultFormScreenReducer, () => INITIAL_STATE)

    .build();