import * as React from 'react';
import FormListCardAdminReducer from "./FormListCardAdminReducer";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ELIMINAR_FORMULARIOS,
    TR_FORMULARIOS,
    TR_NO_EXISTEN_FORMULARIOS,
} from "../../../../I18n/constants";
import FormListCardAdminActions from "./FormListCardAdminActions";
import {connect} from "react-redux";
import {ROUTE_ADMIN_FORM_ADD} from "../../../../routing/Routes";
import TaskFormList from "../../../../ws/form/TaskFormList";
import CardList from "../../../../components/card/CardList";
import FormListAdminTable from "./FormListAdminTable";
import TaskFormDelete from "../../../../ws/form/TaskFormDelete";

const mapStateToProps = FormListCardAdminReducer.autoMapToProps();
const mapActionsToProps = FormListCardAdminActions.autoMapToProps();

interface FormListCardAdminState {
    showDelete: boolean,
}

class FormListCardAdmin extends React.Component<typeof mapStateToProps & typeof mapActionsToProps, FormListCardAdminState> {

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const {loading, forms, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_FORMULARIOS)}
                      sort={{column: 'name'}}
                      TaskList={TaskFormList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_FORMULARIOS)}}
                      data={forms}
                      error={error}
                      ItemsTable={FormListAdminTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_FORMULARIOS),
                          Task: TaskFormDelete,
                      }}
                      addRoute={ROUTE_ADMIN_FORM_ADD}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(FormListCardAdmin as unknown as React.ComponentType<void>);