import React, {Component, ReactNode} from "react";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import {compose} from "redux";
import {FieldArrayErrors, formStateAutoMapToPropsFactory} from "../../utils/FormUtils";
import {connect} from "react-redux";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_IMAGEN,
    TR_IMAGEN_OBLIGATORIA,
    TR_LINK,
    TR_NOMBRE,
    TR_NOMBRE_OBLIGATORIO,
    TR_NUEVO_LINK,
    TR_URL,
    TR_URL_INCORRECTA,
    TR_URL_OBLIGATORIA
} from "../../I18n/constants";
import CardBody from "../card/CardBody";
import Row from "../Row";
import FormInput from "../form/FormInput";
import FormCardFooter from "../form/FormCardFooter";
import {goToRoute} from "../../utils/Router";
import {ROUTE_LINKS} from "../../routing/Routes";
import {isValidURL} from "../../utils/StringUtils";
import FormDragFile from "../form/FormDragFile";

const FORM_NAME = 'LinkForm';

enum LinkFormFields {
    NAME = "name",
    IMAGE = "image",
    URL = "url"
}

export interface LinkFormData {
    name: string,
    url: string,
    image: string []
}

interface LinkFormProps{
    onSubmit: (data: LinkFormData) => void,
    initialValues?: Partial<LinkFormData>,
    loading: boolean,
    errors: string,
    linkId?: string
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<LinkFormData>(FORM_NAME);

type Props = LinkFormProps & InjectedFormProps<LinkFormData> & typeof mapFormStateToProps;

class LinkForm extends Component<Props> {
    public render(): ReactNode {
        const {loading, handleSubmit, onSubmit, initialValues, invalid, pristine} = this.props;
        return (
            <React.Fragment>
                <Card loading={loading}>
                    <CardHeader title={I18nUtils.tr(initialValues ? TR_LINK : TR_NUEVO_LINK)}/>
                    <CardBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Field
                                    label={I18nUtils.tr(TR_NOMBRE)}
                                    name={LinkFormFields.NAME}
                                    component={FormInput}
                                    col={{md: 6, lg: 6}}
                                />
                                <Field
                                    label={I18nUtils.tr(TR_URL)}
                                    name={LinkFormFields.URL}
                                    component={FormInput}
                                    col={{md: 6, lg: 6}}
                                />
                            </Row>

                            <Row>
                                <Field
                                    label={I18nUtils.tr(TR_IMAGEN)}
                                    name={LinkFormFields.IMAGE}
                                    preview={true}
                                    multiple={false}
                                    component={FormDragFile}
                                    col={{md: 6, lg: 6}}
                                />
                            </Row>

                            <FormCardFooter
                                invalid={invalid}
                                pristine={pristine}
                                isUpdate={!!initialValues}
                                cancelHandler={() => goToRoute(ROUTE_LINKS)}/>
                        </form>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

function validate (values: LinkFormData): FormErrors<LinkFormData, FieldArrayErrors> {
    const errors: FormErrors<LinkFormData, FieldArrayErrors> = {};

    if (!values.name) {
        errors.name = I18nUtils.tr(TR_NOMBRE_OBLIGATORIO);
    }
    if (!values.url) {
        errors.url = I18nUtils.tr(TR_URL_OBLIGATORIA);
    }
    if(values.url && !isValidURL(values.url)){
        errors.url = I18nUtils.tr(TR_URL_INCORRECTA);
    }
    if(values.image && (values.image.length === 0 || !values.image[0])) {
        errors.image = I18nUtils.tr(TR_IMAGEN_OBLIGATORIA);
    }

    return errors;
}

export default compose(
    reduxForm<LinkFormData, LinkFormProps, FieldArrayErrors>({
        form: FORM_NAME,
        validate,
        enableReinitialize: true
    }),
    connect(mapFormStateToProps as any)
)(LinkForm) as unknown as React.ComponentType<LinkFormProps>