import I18nUtils from "../../I18n/I18nUtils";
import {generateRoute} from '../../utils/Router';
import {ROUTE_HOME} from "../../routing/Routes";
import {TR_INICIO} from "../../I18n/constants";

export interface BreadcrumbItem {
    id?: number,
    name: string,
    url: string,
    icon?: string,
}

export default class BreadcrumbManager {

    private readonly breadcrumbs: BreadcrumbItem[];

    constructor() {
        this.breadcrumbs = [];
        this.breadcrumbs.push({
            id: this.breadcrumbs.length + 1,
            name: I18nUtils.tr(TR_INICIO),
            url: generateRoute(ROUTE_HOME),
            icon: 'home'
        });
    }

    public addScreen(name: string, url: string, icon?: string){
        const breadcrumbItem: BreadcrumbItem = {name, url, icon};

        if (this.breadcrumbs.length === 0 || !this._isEquals(this.breadcrumbs[this.breadcrumbs.length - 1], breadcrumbItem) ) {
            this.breadcrumbs.push({id: this.breadcrumbs.length + 1, ...breadcrumbItem});
        }
    }

    private _isEquals(b1: BreadcrumbItem, b2: BreadcrumbItem): boolean {
        return b1.name === b2.name && b1.url === b2.url;
    }


    public getItems(): BreadcrumbItem[] {
        return this.breadcrumbs;
    }
}
