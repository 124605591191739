import * as React from 'react';
import UserListCardAdminReducer from "./UserListCardAdminReducer";
import {connect} from "react-redux";

import UserListCardAdminActions from "./UserListCardAdminActions";
import CardList from "../../../../components/card/CardList";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_ELIMINAR_USUARIOS, TR_NO_EXISTEN_USUARIOS, TR_USUARIOS} from "../../../../I18n/constants";
import TaskUserList from "../../../../ws/user/TaskUserList";
import UserListTable from "./UserListTable";
import {ROUTE_ADMIN_USER_ADD} from "../../../../routing/Routes";
import TaskUserDelete from "../../../../ws/user/TaskUserDelete";

const mapActionsToProps = UserListCardAdminActions.autoMapToProps();
const mapStateToProps = UserListCardAdminReducer.autoMapToProps();

class UserListCardAdmin extends React.Component<typeof mapStateToProps & typeof mapActionsToProps> {

    public componentWillMount(): void {
        this.props.clearReducer();
    }

    public render(): React.ReactNode {
        const {loading, users, error, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_USUARIOS)}
                      sort={{column: 'name'}}
                      TaskList={TaskUserList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_USUARIOS)}}
                      data={users}
                      error={error}
                      ItemsTable={UserListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_USUARIOS),
                          Task: TaskUserDelete,
                      }}
                      addRoute={ROUTE_ADMIN_USER_ADD}
                />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(UserListCardAdmin as unknown as React.ComponentType<void>);