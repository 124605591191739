import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import Urls from "../Urls";
import {METHOD} from "co-generic-request";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import Dashboard from "../../model/Dashboard";

interface DashBoardCustomCriteria {
    exploitation_ids?: string[],
    business_type_ids?: string[],
    fav?: boolean,
    enabled?: boolean,
}

export type DashboardCriteria = TaskCriteriaRequest<DashBoardCustomCriteria>

export default class TaskDashboardList extends BaseGenericRequest<DashboardCriteria, TaskResponse<Dashboard[]>> {

    private readonly data: DashboardCriteria;

    public constructor(criteria: DashboardCriteria = {}) {
        super(METHOD.METHOD_POST, Urls.URL_DASHBOARD_LIST);
        this.data = criteria;
    }

    protected getRequest(): DashboardCriteria {
        return this.data;
    }
}
