import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import {Link, LinkData} from "../../model/Link";

export default class TaskLinkEdit extends BaseGenericRequest<LinkData, TaskResponse<Link>> {

    private readonly data: LinkData;

    public constructor(linkId: string, linkData: LinkData) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_LINK_EDIT, {uuid: linkId}));
        this.data = linkData;
    }

    protected getRequest(): LinkData {
        return this.data;
    }
}