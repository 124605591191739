import React, {Component, ReactNode} from "react";
import Alert from "../../../../../model/Alert";
import FormCol, {FormColProps} from "../../../../../components/form/FormCol";
import Icon from "../../../../../components/Icon";
import Row from "../../../../../components/Row";
import CancelModal from "../../../../../components/modal/CancelModal";
import I18nUtils from "../../../../../I18n/I18nUtils";
import {
    TR_ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTA_ALERTA,
    TR_SE_PERDERAN_TODOS_LOS_DATOS
} from "../../../../../I18n/constants";
import {WrappedFieldProps} from "redux-form";

interface AlertFieldProps extends WrappedFieldProps{
    label: string,
    alert?: Alert,
    col: FormColProps,
    onDeleteAlert:() => void,
    onShowModal:() => void,
    obligatory?: boolean,
    showError?: boolean,
}

interface AlertFieldState {
    showAlertDeleteConfirmModal: boolean
}

export default class AlertField extends Component<AlertFieldProps, AlertFieldState> {
    constructor(props: Readonly<AlertFieldProps>) {
        super(props);
        this.state = {
            showAlertDeleteConfirmModal: false
        }
    }

    public render(): ReactNode {
        const {col, label, onDeleteAlert, obligatory, meta, showError} = this.props;
        return (
            <>
                <FormCol {...col}>
                    <Row className={'m-l-0 m-r-0'}>
                        <label>{label}<label className={'obligatory'}>{(obligatory ? "*" : "")}</label></label>
                    </Row>
                    {this.renderAlertFieldBody()}

                    <label className="error">{(meta.error || showError) ? meta.error : ""}</label>
                </FormCol>

                <CancelModal
                    title={I18nUtils.tr(TR_ESTA_SEGURO_QUE_DESEA_ELIMINAR_ESTA_ALERTA)}
                    message={I18nUtils.tr(TR_SE_PERDERAN_TODOS_LOS_DATOS)}
                    onConfirmAction={() => {
                        onDeleteAlert();
                        this.setState({showAlertDeleteConfirmModal: false});
                    }}
                    show={this.state.showAlertDeleteConfirmModal}
                    onClose={() => this.setState({showAlertDeleteConfirmModal: false})}
                />
            </>
        );
    }

    private renderAlertFieldBody(): ReactNode {
        const {alert, onShowModal} = this.props;

        if(alert) {
            return(
                <>
                    <label
                        className={"alert-info-label"}
                        onClick={() => onShowModal()}
                    >
                        {alert.name}
                    </label>
                    <Row className={'m-l-0 m-r-0'}>
                        <a className={'icon-field-remove'}
                           onClick={() => this.setState({showAlertDeleteConfirmModal: true})}>
                            <Icon icon={'remove_circle'}/>
                        </a>
                    </Row>
                </>
            );
        }
        return (
            <Row className={'m-l-0 m-r-0'}>
                <a className={'icon-field-add'}
                   onClick={() => onShowModal()}>
                    <Icon icon={'add_circle'}/>
                </a>
            </Row>
        );
    }
}