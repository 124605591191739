import * as React from 'react';
import PendingListCardReducer from "./PendingListCardReducer";
import Card from "../../../../components/card/Card";
import CardHeader from "../../../../components/card/CardHeader";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_ESTADO, TR_FORMULARIO, TR_PENDIENTES} from "../../../../I18n/constants";
import PendingListCardActions from "./PendingListCardActions";
import CardBody from "../../../../components/card/CardBody";
import Pager from "../../../../components/Pager";
import {connect} from "react-redux";
import Th from "../../../../components/table/Th";

const mapStateToProps = PendingListCardReducer.autoMapToProps();
const mapActionsToProps = PendingListCardActions.autoMapToProps();

class PendingListCard extends React.Component<typeof mapStateToProps & typeof mapActionsToProps> {

    public render(): React.ReactNode {
        const {loading, search, setSearch} = this.props;

        return (
            <Card loading={loading}>
                <CardHeader
                    title={I18nUtils.tr(TR_PENDIENTES)}
                    searchText={search}
                    onSearchChange={(search) => setSearch(search)}
                    onRefresh={() => console.warn('refresh')}
                />
                <CardBody className={'table-responsive'}>
                    {this.renderCardBody()}
                </CardBody>
            </Card>
        )
    }

    private renderCardBody(): React.ReactNode {
        const {pager, loading, error, sort, setPageNumber} = this.props;

        if (pager && pager.data && pager.data.length === 0 && loading) {
            return null;
        }
        if (error) {
            return <h3 className={'table-error'}>{error}</h3>;
        }
        return (
            <React.Fragment>
                <PendingListTable
                    data={pager.data}
                    error={error}
                    sort={sort}
                />
                <Pager
                    data={pager}
                    onPage={(page) => setPageNumber(page)}
                />
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(PendingListCard as unknown as React.ComponentType<void>);

interface PendingListTableProps {
    data: any[],
    error: string,
    sort: {
        column: string,
        asc: boolean
    }
}

class PendingListTable extends React.Component<PendingListTableProps> {

    public render(): React.ReactNode {

        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {this.props.data.map((pending: any) => {
                    return (
                        <tr key={pending.id}>
                            <td>
                                <span className={"pendiente"}/>
                                <small>{pending.estado}</small>
                            </td>
                            <td>{pending.formulario}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort} = this.props;
        const commonProps = {
            sort: sort,
            onSort: (asc: boolean, columnName: string) => PendingListCardActions.setSort.asConnectedAction(asc, columnName)
        };

        return (
         <tr>
             <Th
                 text={I18nUtils.tr(TR_ESTADO)}
                 columnName={'estado'}
                 {...commonProps}
             />
             <Th
                 text={I18nUtils.tr(TR_FORMULARIO)}
                 columnName={'formulario'}
                 {...commonProps}
             />
         </tr>
        )
    }
}