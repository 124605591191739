import {ReducerBuilder} from "co-redux-builders";
import AlertNotificationUserActions from "./AlertNotificationUserActions";
import TaskAlertNotificationUserList from "../../../../ws/alertnotificationuser/TaskAlertNotificationUserList";
import {AlertNotificationUser, AlertNotificationUserSolveRequest} from "../../../../model/AlertNotificationUser";
import {PagerBackendProps} from "../../../../components/Pager";
import {executeItemTask} from "../../../../utils/FormUtils";
import AlertFormScreenAdminActions from "../../alerts/form/AlertFormScreenAdminActions";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_ERROR_RESOLVIENDO_NOTIFICACION, TR_NOTIFICACION_RESUELTA_CORRECTAMENTE} from "../../../../I18n/constants";
import TaskAlertNotificacionUserSolve from "../../../../ws/alertnotificationuser/TaskAlertNotificacionUserSolve";

interface AlertNotificationUserReducerState {
	alertNotificationUser: AlertNotificationUser[],
	formResultId?: string,
	notificationId?: string,
	showResolveModal: boolean,
	observations: string,
	loading: boolean,
	errors: string,
	pager: PagerBackendProps
}

const INITIAL_STATE: AlertNotificationUserReducerState = {
	alertNotificationUser: [],
	formResultId: undefined,
	notificationId: undefined,
	showResolveModal: false,
	observations: '',
	loading: false,
	errors: '',
	pager: {
		current: 1,
		total_pages: 1
	},
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
	.onEvent(TaskAlertNotificationUserList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({...oldState, loading: true}))
	.onEvent(TaskAlertNotificationUserList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({...oldState, loading: false}))
	.onEvent(TaskAlertNotificationUserList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => ({...oldState, errors: payload}))
	.onEvent(TaskAlertNotificationUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => (
		{...oldState, alertNotificationUser: payload.data, pager: payload.pager || oldState.pager, errors: ''}))

	.onEvent(AlertNotificationUserActions.sendFormResultToResolve, (oldState, payload) => ({...oldState, formResultId: payload.id, showResolveModal: true}))

	.onEvent(AlertNotificationUserActions.clearFormResult, (oldState) => ({...oldState, formResultId: undefined}))

	.onEvent(AlertNotificationUserActions.setNotificationId, (oldState, payload) => ({...oldState, notificationId: payload.id}))

	.onEvent(AlertNotificationUserActions.clearNotificationId, (oldState, payload) => ({...oldState, notificationId: undefined}))

	.onEvent(AlertNotificationUserActions.dispatchResolveNotification, (oldState) => {
		const {formResultId, observations, notificationId } = oldState;

		const submitData: AlertNotificationUserSolveRequest = {
			observations,
			form_result_id: formResultId || undefined
		};

		executeItemTask(
		        new TaskAlertNotificacionUserSolve(submitData, notificationId as string),
		        () => {
		            AlertFormScreenAdminActions.dispatchReloadNotifications.asConnectedAction();
		            new TaskAlertNotificationUserList().execute();
		        },
		        I18nUtils.tr(TR_NOTIFICACION_RESUELTA_CORRECTAMENTE),
		        I18nUtils.tr(TR_ERROR_RESOLVIENDO_NOTIFICACION)
		    );

		return {...oldState, showResolveModal: false, formResultId: undefined, notificationId: undefined, observations: ''};
	})

	.onEvent(AlertNotificationUserActions.openModal, (oldState) => ({...oldState, showResolveModal: true}))
	.onEvent(AlertNotificationUserActions.closeModal, (oldState) => ({...oldState, showResolveModal: false}))

	.onEvent(AlertNotificationUserActions.setObservations, (oldState, payload) => ({...oldState, observations: payload.observations}))

	.onEvent(AlertNotificationUserActions.dispatchClearReducer, () => ({...INITIAL_STATE}))

	.build()