import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import AlertState from "../../model/AlertState";

export default class TaskAlertStateList extends BaseGenericRequest<{}, TaskResponse<AlertState[]>> {

    public constructor() {
        super(METHOD.METHOD_POST, Urls.URL_ALERT_STATE_LIST);
    }
}