import * as React from 'react';
import BusinessTypeListCardAdminReducer from "./BusinessTypeListCardAdminReducer";
import {connect} from "react-redux";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ELIMINAR_TIPOS_DE_EXPLOTACION,
    TR_NO_EXISTEN_TIPOS_DE_EXPLOTACION,
    TR_TIPOS_DE_EXPLOTACION,
} from "../../../../I18n/constants";
import TaskBusinessTypeList from "../../../../ws/businessType/TaskBusinessTypeList";
import {ROUTE_ADMIN_BUSINESS_TYPE_ADD} from "../../../../routing/Routes";
import BusinessTypeListTable from "./BusinessTypeListTable";
import CardList from "../../../../components/card/CardList";
import BusinessTypeListCardAdminActions from "./BusinessTypeListCardAdminActions";
import TaskBusinessTypeDelete from "../../../../ws/businessType/TaskBusinessTypeDelete";

const mapStateToProps = BusinessTypeListCardAdminReducer.autoMapToProps();
const mapActionsToProps = BusinessTypeListCardAdminActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

class BusinessTypeListCardAdmin extends React.Component<Props> {
    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render() {
        const {businessTypes, error, loading, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_TIPOS_DE_EXPLOTACION)}
                      sort={{column: 'name'}}
                      TaskList={TaskBusinessTypeList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_TIPOS_DE_EXPLOTACION)}}
                      data={businessTypes}
                      error={error}
                      ItemsTable={BusinessTypeListTable}
                      addRoute={ROUTE_ADMIN_BUSINESS_TYPE_ADD}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_TIPOS_DE_EXPLOTACION),
                          Task: TaskBusinessTypeDelete
                      }}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(BusinessTypeListCardAdmin as unknown as React.ComponentType<{}>);

