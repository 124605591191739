export interface SortState {
    column: string,
    asc: boolean,
}

export type OnSortHandler = (asc: boolean, columnName: string) => void

export function dynamicSort(asc: boolean, ...property: any) {
    return (a: any, b: any) => {
        let ax = a;
        let bx = b;
        property.forEach((prop: any) => {
            if (typeof prop === 'string') {
                ax = ax[prop] || ''
            } else {
                ax = ax[prop] || 0
            }
        });
        property.forEach((prop: any) => {
            if (typeof prop === 'string') {
                bx = bx[prop] || ''
            } else {
                bx = bx[prop] || 0
            }
        });
        if (typeof ax === 'string' && typeof bx === 'string') {
            if (asc) {
                return ax.localeCompare(bx);
            } else {
                return bx.localeCompare(ax);
            }
        } else {
            if (asc) {
                return Number(bx) - Number(ax);
            } else {
                return Number(ax) - Number(bx);
            }
        }
    }
}
