import * as React from 'react';
import AlertFormScreenAdminReducer from "./AlertFormScreenAdminReducer";
import {RouteComponentProps} from "react-router";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ALERTA,
    TR_ALERTA_CREADA_CORRECTAMENTE,
    TR_ALERTA_MODIFICADA_CORRECTAMENTE,
    TR_ALERTAS,
    TR_NUEVA_ALERTA,
} from "../../../../I18n/constants";
import {ROUTE_ADMIN_ALERT_ADD, ROUTE_ADMIN_ALERT_DETAIL, ROUTE_ADMIN_ALERTS,} from "../../../../routing/Routes";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {connect} from "react-redux";
import AlertFormAdmin, {AlertFormAdminData} from "./AlertFormAdmin";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import {MenuIcon} from "../../../../Config";
import ScreenCard from "../../../../components/screen/ScreenCard";
import AlertFormScreenAdminActions from "./AlertFormScreenAdminActions";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import TaskAlertDetail from "../../../../ws/alert/TaskAlertDetail";
import TaskAlertStateList from "../../../../ws/alertState/TaskAlertStateList";
import TaskAlertFrequencyList from "../../../../ws/alertFrequency/TaskAlertFrequencyList";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import {DateHelpers} from "../../../../utils/DateUtils";
import {AlertRequest} from "../../../../model/Alert";
import TaskAlertEdit from "../../../../ws/alert/TaskAlertEdit";
import TaskAlertAdd from "../../../../ws/alert/TaskAlertAdd";
import DateFormatter from "../../../../utils/DateFormatter";
import {UserRoleAutocompleteBuilder, UserRoleData} from "../../../../model/UserRole";
import {UserAutocompleteBuilder} from "../../../../model/User";
import {ExploitationAutocompleteBuilder} from "../../../../model/Exploitation";
import {AlertStateValues} from "../../../../model/AlertState";

const mapStateToProps = AlertFormScreenAdminReducer.autoMapToProps();
const mapActionsToProps = AlertFormScreenAdminActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps & RouteComponentProps<{ id: string }>

class AlertFormAdminScreen extends React.Component<Props> {

    public static INITIAL_VALUES: Partial<AlertFormAdminData> = {
        state: AlertStateValues.ACTIVE,
        email: true,
        frequency: '1',
    };

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskAlertDetail(id), this.props);
        }
        new TaskAlertStateList().execute();
        new TaskAlertFrequencyList().execute();
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: AlertFormAdminData): void => {
        const existAlert = this.props.match.params.id !== undefined;

        const datetimeObject = DateHelpers.getDateFromInputs(values.date, values.time);

        const submitData: AlertRequest = {
            name: values.name,
            description: values.description,
            link: values.link || undefined,
            notify_by_email: values.email || false,
            alert_state_id: values.state,
            datetime: datetimeObject.toISOString(),
            alert_frequency_id: values.frequency,
            alert_scope_id: values.alertScope,
            alert_type_id: values.alertType,
            document_id: values.linkedDocument && values.linkedDocument !== "-1" ? values.linkedDocument : undefined,
            form_id: values.linkedForm && values.linkedForm !== "-1" ? values.linkedForm : undefined,
            receivers: {
                users: values.users && values.users.length > 0 ? values.users.map(user => user[0].data.id) : [],
                exploitations: values.exploitations && values.exploitations.length > 0 ? values.exploitations.map(exploitation => exploitation[0].data.id) : [],
                roles: values.roles && values.roles.length > 0 ? values.roles.map(roleOption => {
                    const role: UserRoleData = {
                        role_id: roleOption.role_id,
                        exploitation_id: roleOption.exploitation && roleOption.exploitation[0] ? roleOption.exploitation[0].data.id : undefined
                    }
                    return role;
                }) : []
            }
        };

        if (existAlert) {
            executeItemTask(
                new TaskAlertEdit(this.props.match.params.id, submitData),
                () => goToRoute(ROUTE_ADMIN_ALERTS),
                I18nUtils.tr(TR_ALERTA_MODIFICADA_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskAlertAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_ALERTS),
                I18nUtils.tr(TR_ALERTA_CREADA_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_ALERTAS),
            url: ROUTE_ADMIN_ALERTS,
            icon: MenuIcon.ALERT
        }];

        if (this.props.match.params.id) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_ALERTA),
                url: generateRoute(ROUTE_ADMIN_ALERT_DETAIL, {id: this.props.match.params.id}),
                icon: MenuIcon.ALERT
            });
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVA_ALERTA),
                url: ROUTE_ADMIN_ALERT_ADD,
                icon: MenuIcon.ALERT
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderForm()}
            </ScreenCard>
        );
    }

    private renderForm(): React.ReactNode {
        const {loading, alert} = this.props;

        let initialValues: Partial<AlertFormAdminData> | undefined;

        initialValues = {
            date: DateFormatter.formatInputDate(new Date())
        }

        if (alert) {
            const datetime = new Date(alert.datetime);

            initialValues = {
                name: alert.name || '',
                state: alert.alert_state ? alert.alert_state.id : '-1',
                description: alert.description || '',
                link: alert.link || '',
                email: alert.notify_by_email,
                channel: alert.alert_source.name || '',
                exploitations: alert.receivers.exploitations.map(exploitation => [ExploitationAutocompleteBuilder.buildOption(exploitation)]) || [],
                users: alert.receivers.users.map(user => [UserAutocompleteBuilder.buildOption(user)]) || [],
                roles: alert.receivers.roles.map((userRole) => UserRoleAutocompleteBuilder.buildFieldArrayOption(userRole)) || [],
                frequency: alert.alert_frequency ? alert.alert_frequency.id : '-1',
                date: DateFormatter.formatInputDate(datetime),
                time: DateFormatter.formatTime(datetime),
                linkedForm: alert.form ? alert.form.id : '',
                linkedDocument: alert.document ? alert.document.id : '',
                alertType: alert.alert_type ? alert.alert_type.id : '',
                alertScope: alert.alert_scope ? alert.alert_scope.id : ''
            }
        }

        return (
            <React.Fragment>
                <Card loading={loading}>
                    <CardHeader title={I18nUtils.tr(initialValues ? TR_ALERTA : TR_NUEVA_ALERTA)}/>
                    <CardBody>
                        <AlertFormAdmin
                            parentLoading={loading}
                            initialValues={initialValues}
                            onSubmit={this.onSubmit}
                        />
                    </CardBody>
                </Card>
            </React.Fragment>

        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(AlertFormAdminScreen);
