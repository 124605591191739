import * as React from "react";
import {ReactNode} from "react";
import {Component} from "react";
import {connect} from "react-redux";
import AlertNotificationUserActions from "./AlertNotificationUserActions";
import AlertNotificationUserReducer from "./AlertNotificationUserReducer";
import AlertNotificationUserView from "./AlertNotificationUserView"
import TaskAlertNotificationUserList from "../../../../ws/alertnotificationuser/TaskAlertNotificationUserList";

const mapStateToProps = AlertNotificationUserReducer.autoMapToProps();
const mapActionsToProps = AlertNotificationUserActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

class AlertNotificationUserDataContainer extends Component<Props> {

	public componentDidMount() {
		new TaskAlertNotificationUserList().execute();
	}

	public render(): ReactNode {
		const {alertNotificationUser, loading, errors, pager} = this.props;
		return (
			<AlertNotificationUserView
				alertNotificationUser={alertNotificationUser}
				loading={loading}
				errors={errors}
				pager={pager}
			/>
		);
	}
}

export default connect(mapStateToProps, mapActionsToProps)(AlertNotificationUserDataContainer as unknown as React.ComponentType<{}>);