import React, {Component, ReactNode} from "react";
import {Link} from "../../../../model/Link";
import {OnSortHandler, SortState} from "../../../../utils/Sort";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {ROUTE_LINK_DETAIL} from "../../../../routing/Routes";
import Th from "../../../../components/table/Th";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_IMAGEN, TR_NOMBRE, TR_URL} from "../../../../I18n/constants";

interface LinkListAdminTableProps{
    data: Link [],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (linkId: string) => React.ReactNode
}


export default class LinkListAdminTable extends Component<LinkListAdminTableProps> {
    private handleGoToLinkDetail = (linkId: string): void => {
        goToRoute(generateRoute(ROUTE_LINK_DETAIL, {id: linkId}))
    };

    public render(): ReactNode {
        const {data, renderSelectTd} = this.props;
        return (
            <table className="table table-hover">
                <thead>{this.renderTH()}</thead>
                <tfoot>{this.renderTH()}</tfoot>
                <tbody>
                {data && data.map((link: Link) => {
                    return (
                        <tr key={link.id}
                            onClick={() => this.handleGoToLinkDetail(link.id)}
                            className={"tr-cursor"}
                        >
                            {renderSelectTd ? renderSelectTd(link.id) : null}
                            <td>
                                {link.name}
                            </td>
                            <td><a href={link.url} target={"_blank"}>{link.url}</a></td>
                            <td><img src={link.image} className={"td-icon"} alt={`Icon${link.id}`}/></td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, children, onSort} = this.props;
        const commonProps = {
            sort,
            onSort
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_URL)}
                    columnName={'last_name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_IMAGEN)}
                    columnName={'email'}
                    {...commonProps}
                />
            </tr>
        )
    }
}
 