import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import Exploitation, {ExploitationDateRequest} from "../../model/Exploitation";

export default class TaskExploitationDateEdit extends BaseGenericRequest<ExploitationDateRequest, TaskResponse<Exploitation>> {

    private readonly data: ExploitationDateRequest;

    public constructor(exploitationId: string, exploitationCharacteristicsData: ExploitationDateRequest) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_EXPLOITATION_DATE_EDIT, {uuid: exploitationId}));
        this.data = exploitationCharacteristicsData;
    }

    protected getRequest(): ExploitationDateRequest {
        return this.data;
    }
}