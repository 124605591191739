import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import FormResult from "../../model/FormResult";

interface FormResultAllCustomCriteria {
    form_ids?: string[],
    exploitation_ids?: string[],
    user_ids?: string[],
    start_date?: string,
    end_date?: string,
}

export type FormResultAllCriteriaProps = TaskCriteriaRequest<FormResultAllCustomCriteria>

export default class TaskFormResultListAll extends BaseGenericRequest<FormResultAllCriteriaProps, TaskResponse<FormResult[]>> {

    private readonly data: FormResultAllCriteriaProps;

    public constructor(criteria: FormResultAllCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_FORM_RESULT_LIST_ALL);
        this.data = criteria;
    }

    protected getRequest(): FormResultAllCriteriaProps {
        return this.data;
    }

}