import * as React from 'react';
import {connect} from "react-redux";
import CardList from "../../../components/card/CardList";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_NO_EXISTEN_NOTIFICACIONES, TR_NOTIFICACIONES} from "../../../I18n/constants";
import NotificationListCardAdminActions from "./NotificationListCardAdminActions";
import NotificationListCardAdminReducer from "./NotificationListCardAdminReducer";
import NotificationListTable from "./NotificationListTable";
import TaskAlertNotificationList from "../../../ws/alertNotification/TaskAlertNotificationList";
import NotificationListFilter from "./listFilter/NotificationListFilter";

const mapStateToProps = NotificationListCardAdminReducer.autoMapToProps();
const mapActionsToProps = NotificationListCardAdminActions.autoMapToProps();

class NotificationListCardAdmin extends React.Component<typeof mapStateToProps & typeof mapActionsToProps> {

    public componentWillMount(): void {
        this.props.clearNotificationListCardAdminReducer();
    }

    public render(): React.ReactNode {
        const {loading, notifications, error, pager, criteria, onSetCriteria} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_NOTIFICACIONES)}
                      sort={{column: 'id'}}
                      TaskList={TaskAlertNotificationList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_NOTIFICACIONES)}}
                      data={notifications}
                      error={error}
                      ItemsTable={NotificationListTable}
                      customCriteria={criteria}
                      hideSearcher={true}
                >
                <NotificationListFilter criteria={criteria}
                                        onCriteriaHandler={onSetCriteria}
                />
            </CardList>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(NotificationListCardAdmin as unknown as React.ComponentType<void>);