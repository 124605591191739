import * as React from 'react';
import I18nUtils from "../../../../../../I18n/I18nUtils";
import {
    TR_GRUPOS,
    TR_PERMISOS,
    TR_YA_HA_SIDO_ANADIDO_ESTE_GRUPO,
} from "../../../../../../I18n/constants";
import Row from "../../../../../../components/Row";
import {FieldArray, FormErrors} from "redux-form";
import GroupFieldArray from "../permission/GroupFieldArray";
import BaseFormFieldsHelpers from "../../../../../../base/form/BaseFormFieldsHelpers";
import {FieldArrayErrors} from "../../../../../../utils/FormUtils";
import ExploitationRoleFieldArray, {ExploitationRoleFieldArrayHelpers} from "../../../../userGroups/form/ExploitationRoleFieldArray";
import {UserRoleFieldArrayOption} from "../../../../../../model/UserRole";
import CardCollapsed from "../../../../../../components/card/CardCollapsed";
import {LinkData} from "../../../../../../model/User";
import Role from "../../../../../../model/Role";

export interface UserPermissionSectionAdminData {
    groups: string[],
    roles: UserRoleFieldArrayOption[],
}

export enum UserPermissionSectionFields {
    GROUPS = "groups",
    ROLES = "roles",
}

interface UserPermissionSectionProps {
    invalid: boolean,
    className?: string,
}

interface UserPermissionSectionAdminState {
    collapsed: boolean,
}

type Props = UserPermissionSectionProps;
type State = UserPermissionSectionAdminState;

export default class UserPermissionSectionAdmin extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            collapsed: false,
        }
    }

    private onCollapsedCard = () => {
        this.setState({collapsed: !this.state.collapsed})
    };

    public render(): React.ReactNode {
        const {invalid, className} = this.props;
        const {collapsed} = this.state;

        return (
            <div className={className}>
                <CardCollapsed
                    title={I18nUtils.tr(TR_PERMISOS)}
                    collapsed={collapsed}
                    collapsedHandler={this.onCollapsedCard}
                    invalid={invalid}
                >
                    <Row className={'p-l-15 p-r-15'}>
                        <FieldArray
                            label={I18nUtils.tr(TR_GRUPOS)}
                            name={UserPermissionSectionFields.GROUPS}
                            component={GroupFieldArray}
                        />
                    </Row>
                    <Row className={'p-l-15 p-r-15'}>
                        <FieldArray
                            name={UserPermissionSectionFields.ROLES}
                            component={ExploitationRoleFieldArray}
                            showTitle={false}
                            hideAdminRoles={false}
                        />
                    </Row>
                </CardCollapsed>
            </div>
        )
    }
}

export class UserPermissionSectionAdminHelpers extends BaseFormFieldsHelpers {

    private static validateGroupsArray(values: UserPermissionSectionAdminData): string[] {
        const processedGroupsIds: string[] = [];
        const allGroupsErrors: string[] = [];

        values.groups.forEach((groupId, groupIndex) => {
            if (processedGroupsIds.includes(groupId)) {
                allGroupsErrors[groupIndex] = I18nUtils.tr(TR_YA_HA_SIDO_ANADIDO_ESTE_GRUPO);
            } else {
                processedGroupsIds.push(groupId);
            }
        });

        return allGroupsErrors;
    }

    public static validate(values: UserPermissionSectionAdminData, props: { roleList: Role[] }) {
        const errors: FormErrors<UserPermissionSectionAdminData, FieldArrayErrors> = {};

        if (values.groups) {
            errors.groups = this.validateGroupsArray(values);
        }
        if (values.roles) {
            errors.roles = ExploitationRoleFieldArrayHelpers.validate(values, props);
        }
        return errors;
    }

    public static getModelData(formData: UserPermissionSectionAdminData): {
        roles: LinkData['roles'],
        groups: LinkData['groups']
    } {
        const roles = formData.roles ? formData.roles.map((userRole) => {
            const currentExploitation = userRole.exploitation[0];
            return {
                role_id: userRole.role_id,
                exploitation_id: currentExploitation && currentExploitation.value
            }
        }) : [];
        const groups = formData.groups ? formData.groups : []
        return {
            roles,
            groups,
        }
    }
}
