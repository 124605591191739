import * as React from 'react';
import {Component, ReactNode} from 'react';
import imgLogo from "../../../res/img/logo.png";
import {ROUTE_ALERT_NOTIFICATION_USER_LIST, ROUTE_FORM_RESULT_ADD, ROUTE_HOME} from "../../../routing/Routes";
import {Dropdown, MenuItem} from "react-bootstrap";
import NavBarDropDownToggle from "../../../components/dropdown/DropDownCustomToggle";
import Icon from "../../../components/Icon";
import I18nUtils from "../../../I18n/I18nUtils";
import {
    TR_ALERTAS,
    TR_INTRODUZCA_OBSERVACIONES_PERTINENTES,
    TR_NO_HAY_ALERTAS,
    TR_RESOLVER,
    TR_RESOLVER_NOTIFICACIONES_USUARIO, TR_VER,
    TR_VER_TODAS_LAS_ALERTAS
} from "../../../I18n/constants";
import {connect} from "react-redux";
import DateFormatter from "../../../utils/DateFormatter";
import {truncate} from "../../../utils/StringUtils";
import LoadingView from "../../loading/LoadingView";
import NavBarActions from "./SideBar/NavBarActions";
import TaskAlertNotificationUserList from "../../../ws/alertnotificationuser/TaskAlertNotificationUserList";
import {AlertNotificationUser} from "../../../model/AlertNotificationUser";
import Button from "../../../components/Button";
import AlertNotificationUserReducer
    from "../../../modules/admin/alertnotificationuser/list/AlertNotificationUserReducer";
import AlertNotificationUserActions
    from "../../../modules/admin/alertnotificationuser/list/AlertNotificationUserActions";
import SolveNotificationUserModal from "../../../components/modal/SolveNotificationUserModal";
import FormResultFormScreenActions from "../../../modules/form/formResult/FormResultFormScreenActions";
import {generateRoute, goToRoute} from "../../../utils/Router";

export interface NavBarProps {
    onClickBars: () => void;
    className: string
}

const mapStateToProps = AlertNotificationUserReducer.autoMapToProps();
const mapActionsToProps = AlertNotificationUserActions.autoMapToProps();

type Props = NavBarProps & typeof mapStateToProps & typeof mapActionsToProps;

class NavBar extends Component<Props> {

    private containerCollapse: HTMLDivElement;

    public componentDidMount(): void {
        new TaskAlertNotificationUserList().execute();
    }

    public componentWillUnmount(): void {
        NavBarActions.clearReducer();
    }

    private toggleContainer = (): void => {
        if (this.containerCollapse) {
            if (this.containerCollapse.classList.contains('collapse')) {
                this.containerCollapse.classList.remove('collapse');
            } else {
                this.containerCollapse.classList.add('collapse');
            }
        }
    };

    public render(): ReactNode {
        const {alertNotificationUser, showResolveModal} = this.props;

        return (
            <>
                <nav
                    className="navbar sidebar-closed">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <a onClick={this.toggleContainer} className="navbar-toggle collapsed"/>
                            <a className="bars" onClick={this.props.onClickBars}/>
                            <a className="navbar-brand" href={ROUTE_HOME}><img src={imgLogo} alt="Inicio"/></a>
                        </div>
                        <div className="collapse navbar-collapse"
                             ref={(ref: HTMLDivElement) => this.containerCollapse = ref}>
                            <ul className="nav navbar-nav navbar-right">

                                <Dropdown id="dropdown-custom-2" componentClass={'li'}>
                                    <NavBarDropDownToggle bsRole="toggle" bsClass={''}>
                                        <Icon icon={'notifications'}/>
                                        {alertNotificationUser.length > 0 ?
                                            <span className="label-count">{alertNotificationUser.length}</span> :
                                            null}
                                    </NavBarDropDownToggle>

                                    <Dropdown.Menu className="super-colors">
                                        {alertNotificationUser.length > 0 ?
                                            this.renderAlertsMenu() :
                                            <MenuItem>
                                                <b>{I18nUtils.tr(TR_NO_HAY_ALERTAS)}</b>
                                            </MenuItem>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ul>
                        </div>
                    </div>
                </nav>

                <SolveNotificationUserModal
                    title={I18nUtils.tr(TR_RESOLVER_NOTIFICACIONES_USUARIO)}
                    message={I18nUtils.tr(TR_INTRODUZCA_OBSERVACIONES_PERTINENTES)}
                    textConfirmButton={I18nUtils.tr(TR_RESOLVER)}
                    show={showResolveModal}
                    onClose={() => this.props.closeModal()}
                />
            </>
        );
    }

    private renderAlertsMenu = (): React.ReactNode => {
        const {alertNotificationUser, loading} = this.props;

        return (
            <>
                <MenuItem header={true}
                          bsClass="header"
                          className="align-center">
                    <b>{I18nUtils.tr(TR_ALERTAS).toUpperCase()}</b>
                </MenuItem>

                <MenuItem divider={true}/>

                <MenuItem header={true} bsClass="body" className={'body menuitem-body'}>
                    <LoadingView loading={loading}/>
                    {alertNotificationUser.map((alert: AlertNotificationUser) => {
                        const link = alert.alert_notification.alert.link;

                        return (
                            <React.Fragment key={alert.id}>
                                <a className={'menuitem-item p-r-5'}>
                                    <span className="title-alert align-right">
                                        <b>{alert.alert_notification.alert.name}</b>
                                    </span>
                                    <p className="m-t-5">{truncate(alert.alert_notification.alert.description, 120)}</p>
                                    <div className="time-alert">
                                        <Icon icon="query_builder"/>
                                        {DateFormatter.formatDatetime(alert.alert_notification.alert.datetime)}
                                    </div>
                                    {
                                        !alert.alert_notification.solved &&
                                        <Button
                                            className={"btn-xs btn-primary"}
                                            text={I18nUtils.tr(TR_RESOLVER)}
                                            onClick={() => {
                                                const linkedForm = alert.alert_notification.alert.form;
                                                this.props.setNotificationId(alert.id);

                                                if (linkedForm) {
                                                    FormResultFormScreenActions.goToRouteHomeScreen.asConnectedAction();
                                                    goToRoute(generateRoute(ROUTE_FORM_RESULT_ADD, {formId: linkedForm.id}));
                                                } else {
                                                    this.props.openModal();
                                                }
                                            }}
                                        />
                                    }
                                    {link && <Button
                                        className={"btn-xs btn-secondary m-l-5"}
                                        text={I18nUtils.tr(TR_VER)}
                                        onClick={() => {
                                            window.open(link, '_blank')!.focus();
                                        }}
                                    />}
                                </a>
                                <hr/>
                            </React.Fragment>
                        )
                    })}
                </MenuItem>

                <MenuItem divider={true}/>

                <MenuItem
                    href={ROUTE_ALERT_NOTIFICATION_USER_LIST}
                    bsClass="footer"
                    className="align-center"
                >
                    <b>{I18nUtils.tr(TR_VER_TODAS_LAS_ALERTAS).toUpperCase()}</b>
                </MenuItem>
            </>
        )
    };
}

export default connect(mapStateToProps, mapActionsToProps)(NavBar) as unknown as React.ComponentType<NavBarProps>;
