import * as React from "react";
import {Modal} from "react-bootstrap";
import BaseModal, {BaseModalProps} from "../../base/modal/BaseModal";
import I18nUtils from "../../I18n/I18nUtils";
import DateFormatter from "../../utils/DateFormatter";
import Row from "../Row";
import Col from "../Col";
import Button from "../Button";
import {
    TR_ANO,
    TR_APLICAR,
    TR_CANCELAR,
    TR_CUARTO_TRIMESTRE,
    TR_DESDE,
    TR_HASTA,
    TR_PERIODO,
    TR_PERSONALIZADO,
    TR_PRIMER_TRIMESTRE,
    TR_RECIENTE,
    TR_SEGUNDO_TRIMESTRE,
    TR_TERCER_TRIMESTRE,
    TR_TODO_EL_ANO,
    TR_ULTIMO_ANO,
    TR_ULTIMO_MES,
    TR_ULTIMOS_3_MESES,
    TR_ULTIMOS_7_DIAS
} from "../../I18n/constants";
import Checkbox from "../Checkbox";
import {DateHelpers} from "../../utils/DateUtils";

const filterYears: number[] = [];

const yearNow = new Date().getFullYear();

for (let i = 0; i < 5; i++) {
    filterYears.push(yearNow - i);
}

export interface DatePickerModalProps extends BaseModalProps {
    onChange: (startDate?: Date, endDate?: Date) => void,
    resetDateFilter?: boolean,
    startDate?: Date,
    endDate?: Date,
}

enum RecentState {
    LAST_SEVEN_DAYS = 1,
    LAST_MONTH = 2,
    LAST_THREE_MONTHS = 3,
    LAST_YEAR = 4,
}

enum PeriodState {
    ALL_YEAR = -1,
    FIRST_TRIMESTER = 1,
    SECOND_TRIMESTER = 2,
    THIRD_TRIMESTER = 3,
    FOURTH_TRIMESTER = 4,
}

interface State {
    currentRecent: RecentState | null,
    currentYear: number | null,
    currentPeriod: PeriodState | null,

    startDateSelected: Date | undefined,
    endDateSelected: Date | undefined,
}

const RESET_CHECKED_STATES = {
    currentRecent: null,
    currentPeriod: null,
    currentYear: null,
};

const INITIAL_STATE: State = {
    ...RESET_CHECKED_STATES,
    startDateSelected: undefined,
    endDateSelected: undefined,
};

export default class DatePickerModal extends BaseModal<DatePickerModalProps, State> {

    public state: State = INITIAL_STATE;

    public componentDidUpdate(prevProps: Readonly<DatePickerModalProps>, prevState: Readonly<State>): void {
        if (this.props.resetDateFilter !== prevProps.resetDateFilter) {
            if (this.props.resetDateFilter) {
                this.setState(INITIAL_STATE);
            }
        }
        if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
            const {startDate, endDate} = this.props;

            const newStartDate = startDate ? this.parseDateFromProps(startDate as unknown as string) : undefined;
            const newEndDate = endDate ? this.parseDateFromProps(endDate as unknown as string) : undefined;

            this.setState({
                startDateSelected: newStartDate,
                endDateSelected: newEndDate,
            })
        }
    }

    private parseDateFromProps = (date): Date => {
        /*
            Las fechas pueden llegar como string o tipo Date,
            Si es tipo string, será siempre con el siguiente formato --> 2020-05-21T22:00:00.000Z
         */


        const isString = typeof(date) === "string";

        if (!isString) {
            const fullDate = date.getFullYear() + "-0" + (date.getMonth() + 1) + "-" + date.getDate();
            const time = date.getHours() + ":" + date.getMinutes();
            const dateTimeObject = DateHelpers.getDateFromInputs(fullDate, time);

            date = dateTimeObject.toISOString();
        }

        const dateParsed = new Date();

        dateParsed.setUTCDate(Number(date.substring(8, 10)));
        dateParsed.setUTCMonth(Number(date.substring(5, 7)) - 1);
        dateParsed.setUTCFullYear(Number(date.substring(0, 4)));

        dateParsed.setUTCHours(Number(date.substring(11, 13)));
        dateParsed.setUTCMinutes(Number(date.substring(14, 16)));
        dateParsed.setUTCSeconds(Number(date.substring(17, 19)));
        dateParsed.setUTCMilliseconds(Number(date.substring(21, date.length - 1)));

        return dateParsed;
    }

    private onFromDateChange = (startDate: Date): void => {
        this.setState({
            ...RESET_CHECKED_STATES,
            startDateSelected: startDate,
        });
    };

    private onToDateChange = (endDate: Date): void => {
        this.setState({
            ...RESET_CHECKED_STATES,
            endDateSelected: endDate,
        });
    };

    private onRecentStateChange = (recentStateChanged: RecentState): void => {
        let startDate: Date = new Date();

        switch (recentStateChanged) {
            case RecentState.LAST_SEVEN_DAYS:
                startDate = DateHelpers.getXDaysAgo(7);
                break;
            case RecentState.LAST_MONTH:
                startDate = DateHelpers.getXMonthsAgo(1);
                break;
            case RecentState.LAST_THREE_MONTHS:
                startDate = DateHelpers.getXMonthsAgo(3);
                break;
            case RecentState.LAST_YEAR:
                startDate = DateHelpers.getXYearsAgo(1);
                break;
        }

        if (this.state.currentRecent !== recentStateChanged) {
            this.setState({
                ...INITIAL_STATE,
                startDateSelected: startDate,
                endDateSelected: new Date(),
                currentRecent: recentStateChanged,
            })
        } else {
            this.setState(INITIAL_STATE)
        }
    };

    private getStartDateAndEndDateFromYearAndPeriod = (currentYear: number | null, currentPeriod: PeriodState | null): Date[] | undefined[] => {
        if (!currentYear || !currentPeriod) {
            return [undefined, undefined];
        }

        if (currentPeriod === PeriodState.ALL_YEAR) {
            return [
                DateHelpers.getFirstDayOfYear(currentYear),
                DateHelpers.getLastDayOfYear(currentYear)];
        } else {
            return [
                DateHelpers.getFirstDayOfTrimester(currentYear, currentPeriod),
                DateHelpers.getLastDayOfTrimester(currentYear, currentPeriod)]
        }
    };

    private onYearStateChange = (yearChanged: number): void => {
        const newState = {
            ...INITIAL_STATE,
            currentPeriod: this.state.currentPeriod,
        };

        if (this.state.currentYear !== yearChanged) {
            const [startDate, endDate] = this.getStartDateAndEndDateFromYearAndPeriod(yearChanged, this.state.currentPeriod);
            newState.startDateSelected = startDate;
            newState.endDateSelected = endDate;
            newState.currentYear = yearChanged;
        }

        this.setState(newState);
    };

    private onPeriodStateChange = (periodStateChanged: PeriodState): void => {
        const newYear = this.state.currentYear ? this.state.currentYear : yearNow;
        const newState = {
            ...INITIAL_STATE,
            currentYear: newYear,
        };

        if (this.state.currentPeriod !== periodStateChanged) {
            const [startDate, endDate] = this.getStartDateAndEndDateFromYearAndPeriod(newYear, periodStateChanged);
            newState.startDateSelected = startDate;
            newState.endDateSelected = endDate;
            newState.currentPeriod = periodStateChanged;
        }
        this.setState(newState);

    };

    public render(): React.ReactNode {
        const {show, onClose, onChange} = this.props;
        const {startDateSelected, endDateSelected, currentRecent, currentYear, currentPeriod} = this.state;

        return (
            // @ts-ignore
            <Modal onHide={onClose} show={show} size='md'>
                <Modal.Body>

                    <h4 className='m-t-10'>{I18nUtils.tr(TR_PERSONALIZADO)}</h4>
                    <Row>
                        <Col lg={6} md={6}
                        >
                            <label className='m-b-0'>{I18nUtils.tr(TR_DESDE)}</label>
                            <input type="date"
                                   className="form-control"
                                   placeholder={I18nUtils.tr(TR_DESDE)}
                                   onChange={(event) => this.onFromDateChange(event.target.valueAsDate)}
                                   value={DateFormatter.formatInputDate(startDateSelected as Date)}
                            />
                        </Col>
                        <Col lg={6} md={6}
                        >
                            <label className='m-b-0'>{I18nUtils.tr(TR_HASTA)}</label>
                            <input type="date"
                                   className="form-control"
                                   placeholder={I18nUtils.tr(TR_HASTA)}
                                   onChange={(event) => this.onToDateChange(event.target.valueAsDate)}
                                   value={DateFormatter.formatInputDate(endDateSelected as Date)}
                            />
                        </Col>
                    </Row>

                    <h4 className='m-t-20'>{I18nUtils.tr(TR_RECIENTE)}</h4>
                    <Row>
                        <Col lg={6} md={6} className='checkbox-column'>
                            <Checkbox onChange={() => this.onRecentStateChange(RecentState.LAST_SEVEN_DAYS)}
                                      checked={currentRecent === RecentState.LAST_SEVEN_DAYS}
                                      label={I18nUtils.tr(TR_ULTIMOS_7_DIAS)}
                            />
                            <Checkbox onChange={() => this.onRecentStateChange(RecentState.LAST_MONTH)}
                                      checked={currentRecent === RecentState.LAST_MONTH}
                                      label={I18nUtils.tr(TR_ULTIMO_MES)}
                            />
                        </Col>
                        <Col lg={6} md={6} className='checkbox-column'>
                            <Checkbox onChange={() => this.onRecentStateChange(RecentState.LAST_THREE_MONTHS)}
                                      checked={currentRecent === RecentState.LAST_THREE_MONTHS}
                                      label={I18nUtils.tr(TR_ULTIMOS_3_MESES)}
                            />
                            <Checkbox onChange={() => this.onRecentStateChange(RecentState.LAST_YEAR)}
                                      checked={currentRecent === RecentState.LAST_YEAR}
                                      label={I18nUtils.tr(TR_ULTIMO_ANO)}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6} md={6} className='m-t-10'>
                            <h4 className='checkbox-title'>{I18nUtils.tr(TR_ANO)}</h4>
                            {filterYears.map(
                                (year, index) => <Checkbox
                                    key={index}
                                    onChange={() => this.onYearStateChange(year)}
                                    checked={currentYear === year}
                                    label={String(year)}
                                />)}
                        </Col>
                        <Col lg={6} md={6} className='m-t-10'>
                            <h4 className='checkbox-title'>{I18nUtils.tr(TR_PERIODO)}</h4>
                            <Checkbox onChange={() => this.onPeriodStateChange(PeriodState.ALL_YEAR)}
                                      checked={currentPeriod === PeriodState.ALL_YEAR}
                                      label={I18nUtils.tr(TR_TODO_EL_ANO)}
                            />
                            <Checkbox onChange={() => this.onPeriodStateChange(PeriodState.FIRST_TRIMESTER)}
                                      checked={currentPeriod === PeriodState.FIRST_TRIMESTER}
                                      label={I18nUtils.tr(TR_PRIMER_TRIMESTRE)}
                            />
                            <Checkbox onChange={() => this.onPeriodStateChange(PeriodState.SECOND_TRIMESTER)}
                                      checked={currentPeriod === PeriodState.SECOND_TRIMESTER}
                                      label={I18nUtils.tr(TR_SEGUNDO_TRIMESTRE)}
                            />
                            <Checkbox onChange={() => this.onPeriodStateChange(PeriodState.THIRD_TRIMESTER)}
                                      checked={currentPeriod === PeriodState.THIRD_TRIMESTER}
                                      label={I18nUtils.tr(TR_TERCER_TRIMESTRE)}
                            />
                            <Checkbox onChange={() => this.onPeriodStateChange(PeriodState.FOURTH_TRIMESTER)}
                                      checked={currentPeriod === PeriodState.FOURTH_TRIMESTER}
                                      label={I18nUtils.tr(TR_CUARTO_TRIMESTRE)}
                            />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer className={"modal-footer"}>
                    <Row className={`${"form-footer-container"}`}>
                        <Col sm={12} md={12} lg={12}>
                            <Button
                                text={I18nUtils.tr(TR_CANCELAR)}
                                onClick={onClose}
                                type={'button'}
                                className={'btn-default'}
                            />

                            <Button
                                text={I18nUtils.tr(TR_APLICAR)}
                                onClick={() => onChange(startDateSelected ? DateHelpers.getFirstSecondLocal(startDateSelected) : undefined, endDateSelected ? DateHelpers.getLastSecondLocal(endDateSelected) : undefined)}
                                type={'button'}
                                className={'btn-primary'}
                                disabled={!startDateSelected && !endDateSelected}
                            />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        )
    }

}
