import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import UserGroup from "../../model/UserGroup";

export default class TaskUserGroupDetail extends BaseGenericRequest<{}, TaskResponse<UserGroup>> {

    public constructor(userGroupId: string) {
        super(METHOD.METHOD_GET, generateRoute(Urls.URL_USER_GROUP_DETAIL, {uuid: userGroupId}));
    }
}
