import Role from "./Role";
import {ExploitationAutocompleteBuilder, ExploitationSimply} from "./Exploitation";
import {AutocompleteOption} from "../components/form/FormAutocomplete";

export default interface UserRole {
    role: Role,
    exploitation?: ExploitationSimply,
}

export interface UserRoleData {
    role_id: Role['id'],
    exploitation_id: ExploitationSimply['id'] | undefined,
}

export interface UserRoleFieldArrayOption {
    role_id: Role['id'],
    exploitation: Array<AutocompleteOption<ExploitationSimply>>,
}

export class UserRoleAutocompleteBuilder {

    public static buildFieldArrayOption(userRole: UserRole): UserRoleFieldArrayOption {
        return {
            role_id: userRole.role && userRole.role.id,
            exploitation: userRole.exploitation ? [ExploitationAutocompleteBuilder.buildOption(userRole.exploitation)] : [],
        };
    }
}

export enum UserRoleValue {
    SUPER_ADMIN = '1',
    ADMIN = '2',
    HEAD_SERVICE = '3',
    RESPONSIBLE_ADMINISTRATION = '4',
    RESPONSIBLE_SYSTEMS = '5',
    RESPONSIBLE_QUALITY = '6',
    RESPONSIBLE_HIRING = '7',
    RESPONSIBLE_LEGALLY = '8',
    RESPONSIBLE_FINANCIAL_AUDIT = '9',
    DIRECTOR = '10',
}
