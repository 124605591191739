import React from 'react';
import {GoogleApiWrapper, InfoWindow, Map, Marker, Polyline} from "google-maps-react";
import {WrappedFieldProps} from "redux-form";
import LoadingView from "../base/loading/LoadingView";
import Config from "../Config";
import {TR_LA_POSICION_ES_OBLIGATORIA} from "../I18n/constants";
import I18nUtils from "../I18n/I18nUtils";

export interface MarkerPosition {
    lat: number,
    lng: number,
}

export interface MarkerReportProps {
    position: MarkerPosition,
    draggable?: boolean
    reportData: ReportInfoWindowData,
}

export interface MarkerControllerProps {
    position: MarkerPosition,
    date: Date,
}

interface ReportInfoWindowData {
    controller: string,
    licensePlate: string,
    reportDate: string,
    reportHour: string,
}

interface MapStyle {
    width: string,
    height: string,
}

export interface MapLocationPickerProps {
    google: any,
    mapStyle?: MapStyle,
    zoom?: number,
    defaultPosition: MarkerPosition,
    draggableMap?: boolean,
    draggableMark?: boolean,
    changeCoordHandler?: (newCoord: MarkerPosition) => void,
    label?: string,
    invalid?: boolean,
    detailMode?: boolean,
    showMainMarker: boolean,
    reportMarkers?: MarkerReportProps[],
    controllerMarkers?: MarkerControllerProps[],
    currentPositionController?: MarkerControllerProps,
    iconMarker?: any,
    obligatory?: boolean
}

type Props = MapLocationPickerProps & WrappedFieldProps;

interface State {
    markerCoord?: MarkerPosition,
    currentInfoWindow: number,
    openInfoWindow: boolean,
}

const MARKER_NAME = 'ParkingMeterMarker';

class MapLocationPicker extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            markerCoord: undefined,
            currentInfoWindow: -1,
            openInfoWindow: false,
        }
    };

    public componentDidMount(): void {
        if (this.props.detailMode && this.props.input.value) {
            this.setMarkerCoord({lat: this.props.input.value.lat, lng: this.props.input.value.lng});
        } else {
            this.setMarkerCoord(this.props.defaultPosition);
        }
        // if (!this.props.detailMode) {
        //     this.setMarkerCoord(this.props.defaultPosition);
        // }
    }

    public componentWillUpdate(nextProps: Readonly<MapLocationPickerProps & WrappedFieldProps>, nextState: Readonly<State>, nextContext: any): void {
        if (this.props.input.value !== nextProps.input.value) {
            this.setMarkerCoord(nextProps.input.value);
        }

        if (!this.props.input.value && this.props.reportMarkers) {
            if (this.props.reportMarkers !== nextProps.reportMarkers) {
                if (nextProps.reportMarkers && nextProps.reportMarkers.length !== 0) {
                    this.setMarkerCoord(nextProps.reportMarkers[0].position);
                }
            }
        }
        if (!this.props.input.value && this.props.controllerMarkers) {
            if (this.props.controllerMarkers !== nextProps.controllerMarkers) {
                if (nextProps.controllerMarkers && nextProps.controllerMarkers.length !== 0) {
                    this.setMarkerCoord(nextProps.controllerMarkers[0].position);
                }
            }
        }
        if (!this.props.input.value && this.props.currentPositionController) {
            if (this.props.currentPositionController !== nextProps.currentPositionController) {
                if (nextProps.currentPositionController && nextProps.currentPositionController.position) {
                    this.setMarkerCoord(nextProps.currentPositionController.position);
                }
            }
        }
    }

    private setMarkerCoord(newMarkerCoord: MarkerPosition): void {
        this.setState({markerCoord: newMarkerCoord});
    }

    private onMarkerDragEnd = (coord) => {
        const {latLng} = coord;
        const newMarkerCoord: MarkerPosition = {lat: latLng.lat(), lng: latLng.lng()};

        this.setMarkerCoord(newMarkerCoord);

        if (this.props.changeCoordHandler) {
            this.props.changeCoordHandler(newMarkerCoord);
        }
    };

    private onShowInfoWindow = (infoWindowId: number) => {
        this.setState({
            currentInfoWindow: infoWindowId,
            openInfoWindow: true,
        })

    };

    private onHideInfoWindow = () => {
        this.setState({currentInfoWindow: -1, openInfoWindow: false})
    };

    public render(): React.ReactNode {

        const {
            google, zoom = 14, draggableMap = true, draggableMark = true, invalid, showMainMarker = true,
            iconMarker, label, obligatory,
            mapStyle = {
                width: '100%',
                height: '100%',
                borderRadius: '10px',
            }
        } = this.props;

        const style: any = invalid ? {
            ...mapStyle,
            border: '1px solid red',
        } : {...mapStyle};

        // @ts-ignore
        const iconMarkerProps = iconMarker ? {
            icon: {
                // url: "../res/img/icon-parkingmeter.png",
                url: "http://maps.google.com/mapfiles/ms/icons/caution.png",
                anchor: new google.maps.point(32, 32),
                scaledSize: new google.maps.Size(64, 64)
            }
        } : {};


        return (
            this.state.markerCoord ?
                <>
                    {label ? <label className='main-label'>{label || ''}<label className={'obligatory'}>{(obligatory ? "*" : "")}</label></label> : null}

                    {invalid &&
                    <label className={"error"}>{I18nUtils.tr(TR_LA_POSICION_ES_OBLIGATORIA)}</label>}

                    <Map
                        google={google}
                        zoom={zoom}
                        style={style}
                        initialCenter={this.state.markerCoord}
                        draggable={draggableMap}
                        center={this.state.markerCoord}
                    >

                        {showMainMarker && <Marker
                            draggable={draggableMark}
                            name={MARKER_NAME}
                            position={this.state.markerCoord}
                            onDragend={(markerProps, mapProps, coord) => this.onMarkerDragEnd(coord)}
                            // TODO: iconMarkerProps
                        />}

                        {this.renderControllerPositionMarkers()}
                        {this.renderMarkers()}
                        {this.renderInfoWindows()}

                    </Map>

                </> : <LoadingView loading={true}/>
        );
    }

    private renderControllerPositionMarkers(): React.ReactNode {
        const {currentPositionController, controllerMarkers, google} = this.props;

        if (currentPositionController) {
            return (
                <Marker draggable={false}
                        name={'controller-marker'}
                        position={currentPositionController.position}
                        icon={{
                            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                            scaledSize: new google.maps.Size(22, 22),
                        }}
                />
            )
        } else if (controllerMarkers) {
            const triangleCoords = controllerMarkers.map((marker) => (marker.position));

            return (
                <Polyline
                    path={triangleCoords}
                    strokeColor="#0063a2"
                    strokeOpacity={0.8}
                    strokeWeight={2}/>
            )
        }
        return null;
    }

    private renderMarkers(): React.ReactNode {
        const {reportMarkers, google} = this.props;

        return reportMarkers && reportMarkers.map((marker: MarkerReportProps, index) => {
            return (
                <Marker
                    key={index}
                    draggable={marker.draggable}
                    name={`report-marker-${index}`}
                    position={marker.position}
                    onClick={() => {
                        this.onShowInfoWindow(index)
                    }}
                    icon={{
                        // url: "http://maps.google.com/mapfiles/ms/icons/caution.png",
                        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                        scaledSize: new google.maps.Size(22, 22),
                    }}
                />
            )
        })
    }

    private renderInfoWindows(): React.ReactNode {
        const {reportMarkers, google} = this.props;
        const {openInfoWindow, currentInfoWindow} = this.state;

        return reportMarkers && reportMarkers.map((marker: MarkerReportProps, index) => {
            const showInfoWindow = openInfoWindow && currentInfoWindow === index;

            return (
                <InfoWindow
                    key={index}
                    position={marker.position}
                    visible={showInfoWindow}
                    onClose={this.onHideInfoWindow}
                    pixelOffset={new google.maps.Size(0, -25)}
                >
                    {/*<div>*/}
                    {/*    <h5>{I18nUtils.tr(TR_DATOS_DENUNCIA)}</h5>*/}
                    {/*    <span*/}
                    {/*        className={"font-bold"}>{I18nUtils.tr(TR_CONTROLADOR)}:</span> {marker.reportData.controller}*/}
                    {/*    <br/>*/}
                    {/*    <span*/}
                    {/*        className={"font-bold"}>{I18nUtils.tr(TR_MATRICULA)}:</span> {marker.reportData.licensePlate}<br/>*/}
                    {/*    <span*/}
                    {/*        className={"font-bold"}>{I18nUtils.tr(TR_FECHA_DENUNCIA)}:</span> {marker.reportData.reportDate}<br/>*/}
                    {/*    <span*/}
                    {/*        className={"font-bold"}>{I18nUtils.tr(TR_HORA_DENUNCIA)}:</span> {marker.reportData.reportHour}<br/>*/}
                    {/*</div>*/}
                </InfoWindow>
            )
        })
    }

}

export default GoogleApiWrapper({
    apiKey: Config.GOOGLE_API_KEY
})(MapLocationPicker) as unknown as React.ComponentType<MapLocationPickerProps>;