import * as React from "react";
import {Component, ReactNode} from "react";
import LinkListAdminReducer from "./LinkListAdminReducer";
import LinkListAdminActions from "./LinkListAdminActions";
import {connect} from "react-redux";
import LinkListAdminView from "./LinkListAdminView";
import TaskLinkList from "../../../../ws/links/TaskLinkList";
import AlertComponent from "../../../../base/alerts/AlertComponent";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_ERROR_LISTADO_LINKS} from "../../../../I18n/constants";

const mapStateToProps = LinkListAdminReducer.autoMapToProps();
const mapActionsToProps = LinkListAdminActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps;

class LinkListAdminDataContainer extends Component<Props> {
    public componentWillUnmount(): void {
        this.props.dispatchClearReducer();
    }

    public componentDidMount(): void {
        new TaskLinkList({})
            .onFail(() => setTimeout(() => {
                    AlertComponent.error(I18nUtils.tr(TR_ERROR_LISTADO_LINKS));
                }, 1)
            )

            .execute();
    }

    public render(): ReactNode {
        const {links, loading, errors, pager} = this.props;
        return (
            <LinkListAdminView
                links={links}
                loading={loading}
                errors={errors}
                pager={pager}
            />
        );
    }
}

export default connect(mapStateToProps, mapActionsToProps)(LinkListAdminDataContainer as unknown as React.ComponentType<{}>);