import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import User, {LinkData} from "../../model/User";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import {sha256} from "js-sha256";

export default class TaskUserAdd extends BaseGenericRequest<LinkData, TaskResponse<User>> {

    private readonly data: LinkData;

    public constructor(data: LinkData) {
        super(METHOD.METHOD_POST, Urls.URL_USER_ADD);
        this.data = data;
    }

    protected getRequest(): LinkData {
        return {
            ...this.data,
            password: this.data.password ? sha256(this.data.password) : '',
        }
    }
}