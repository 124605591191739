import * as React from 'react';
import FormFormAdminScreenReducer from "./FormFormAdminScreenReducer";
import {RouteComponentProps} from "react-router";
import {connect} from "react-redux";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_FORMULARIO,
    TR_FORMULARIO_CREADO_CORRECTAMENTE,
    TR_FORMULARIO_MODIFICADO_CORRECTAMENTE,
    TR_FORMULARIOS,
    TR_NUEVO_FORMULARIO
} from "../../../../I18n/constants";
import {ROUTE_ADMIN_FORM_ADD, ROUTE_ADMIN_FORM_DETAIL, ROUTE_ADMIN_FORMS} from "../../../../routing/Routes";
import FormDetailCardAdmin, {FormCardDetailAdminData} from "../formdetail/FormDetailCardAdmin";
import {FormFieldCreatorFormData} from "../formdetail/FormFieldCreator";
import FormField, {FormFieldData, FormFieldValidator} from "../../../../model/FormField";
import {FormModelData} from "../../../../model/Form";
import TaskFormAdd from "../../../../ws/form/TaskFormAdd";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import TaskFormDetail from "../../../../ws/form/TaskFormDetail";
import {FormFieldTypeValue} from "../../../../model/FormFieldType";
import TaskFormEdit from "../../../../ws/form/TaskFormEdit";
import {FormPermissionCardAdminHelpers,} from "../formdetail/cards/permission/FormPermissionSectionAdmin";
import {MenuIcon} from "../../../../Config";
import {PermissionData} from "../../../../model/Permission";
import FormFormAdminScreenActions from "./FormFormAdminScreenActions";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import {ReducerBuilder} from "co-redux-builders";
import ExploitationRoleFieldArrayReducer from "../../userGroups/form/ExploitationRoleFieldArrayReducer";
import {UserRoleFieldArrayOption} from "../../../../model/UserRole";
import {FormResultValue} from "../../../../model/FormResult";
import FormInputOption from "../../../../components/form/FormInputOption";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    FormFormAdminScreenReducer.autoMapToProps(),
    ExploitationRoleFieldArrayReducer.autoMapToProps(),
);
const mapActionsToProps = FormFormAdminScreenActions.autoMapToProps();

type FormFormAdminScreenProps = RouteComponentProps<{ id: string }>;

type Props = typeof mapStateToProps & FormFormAdminScreenProps & typeof mapActionsToProps;

class FormFormAdminScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskFormDetail(id), this.props);
        }
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: FormCardDetailAdminData): void => {
        const {id: formId} = this.props.match.params;
        const formFields: FormFieldData[] = values.formFields.map(
            (formFieldCreatorFormData: FormFieldCreatorFormData, index: number) => {
                const formFieldValues: FormInputOption[] = formFieldCreatorFormData.formFieldValues;
                const validator: FormFieldValidator = {};
                let defaultValue: FormResultValue = '';

                if (formFieldCreatorFormData.type === FormFieldTypeValue.CHECKBOX) {
                    defaultValue = false;
                }

                if (formFieldCreatorFormData.type === FormFieldTypeValue.SWITCH) {
                    formFieldValues[0] = {name: formFieldCreatorFormData.switchFirstOption, value: '0'};
                    formFieldValues[1] = {name: formFieldCreatorFormData.switchSecondOption, value: '1'};
                    defaultValue = !!formFieldCreatorFormData.defaultValue;
                }

                if (formFieldCreatorFormData.type === FormFieldTypeValue.NUMBER) {
                    validator.max = formFieldCreatorFormData.max;
                    validator.min = formFieldCreatorFormData.min;
                }

                if (formFieldCreatorFormData.type === FormFieldTypeValue.TEXT || formFieldCreatorFormData.type === FormFieldTypeValue.TEXTAREA) {
                    validator.maxLength = formFieldCreatorFormData.maxLength;
                }

                return {
                    label: formFieldCreatorFormData.inputStatement,
                    key: formFieldCreatorFormData.inputKey,
                    required: formFieldCreatorFormData.required ? formFieldCreatorFormData.required : false,
                    form_field_type_id: formFieldCreatorFormData.type.toString(),
                    form_field_values: formFieldValues,
                    default_value: defaultValue,
                    validator,
                    order: index + 1,
                };
            });

        const permissionsExploitations = values.exploitations ?
            values.exploitations.map((permissionExploitation) => {
                return permissionExploitation[0].value
            }) : [];

        const permissionsUsers = values.users ?
            values.users.map((permissionUser) => {
                return permissionUser[0].value
            }) : [];

        const permissionsRoles = values.roles ?
            values.roles.map((permissionRole: UserRoleFieldArrayOption) => {
                const currentExploitation = permissionRole.exploitation[0];
                return ({
                    role_id: permissionRole.role_id,
                    exploitation_id: currentExploitation ? currentExploitation.value : undefined,
                });
            }) : [];

        const permissions: PermissionData = {
            roles: permissionsRoles,
            exploitations: permissionsExploitations,
            users: permissionsUsers
        };

        const submitData: FormModelData = {
            name: values.formName,
            key: values.formKey,
            description: values.formDescription,
            enabled: values.formEnabled,
            form_fields: formFields,
            form_frequency_id: values.formFrequency,
            form_scope_id: values.formScope,
            webhook: values.formWebhook,
            permissions
        };

        if (formId) {
            executeItemTask(
                new TaskFormEdit(formId, submitData),
                () => goToRoute(ROUTE_ADMIN_FORMS),
                I18nUtils.tr(TR_FORMULARIO_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskFormAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_FORMS),
                I18nUtils.tr(TR_FORMULARIO_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_FORMULARIOS),
            url: ROUTE_ADMIN_FORMS,
            icon: MenuIcon.FORM
        }];

        if (this.props.match.params.id) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_FORMULARIO),
                url: generateRoute(ROUTE_ADMIN_FORM_DETAIL, {id: this.props.match.params.id}),
                icon: MenuIcon.FORM
            });
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_FORMULARIO),
                url: ROUTE_ADMIN_FORM_ADD,
                icon: MenuIcon.FORM
            });
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderForm()}
            </ScreenCard>
        )
    }

    private renderForm(): React.ReactNode {
        const {loading, form, roleList, roleListLoading} = this.props;

        let initialValues: Partial<FormCardDetailAdminData> | undefined;

        if (form) {
            const formFieldCreatorsFormData: FormFieldCreatorFormData[] = form.form_fields && form.form_fields.map(
                (formField: FormField) => {
                    const fieldTypeId = formField.form_field_type.id;

                    const formFieldDetailFormData: FormFieldCreatorFormData = {
                        inputKey: formField.key || '',
                        type: fieldTypeId,
                        inputStatement: formField.label || '',
                        required: formField.required,
                        formFieldValues: formField.form_field_values || [],
                        switchFirstOption: formField.form_field_values && formField.form_field_values[0] ?
                            formField.form_field_values[0].name :
                            '',
                        switchSecondOption: formField.form_field_values && formField.form_field_values[1] ?
                            formField.form_field_values[1].name :
                            '',
                        max: formField.validator.max,
                        min: formField.validator.min,
                        maxLength: formField.validator.maxLength,
                        defaultValue: formField.default_value,
                    };

                    return formFieldDetailFormData;
                });

            initialValues = {
                ...FormPermissionCardAdminHelpers.getFormData(form.permissions),
                formKey: form.key || '',
                formName: form.name || '',
                formDescription: form.description || '',
                formFields: formFieldCreatorsFormData,
                formEnabled: form.enabled,
                formWebhook: form.webhook || '',
                formScope: form.form_scope.id || undefined,
                formFrequency: form.form_frequency.id || undefined
            };

        } else {
            initialValues = {formEnabled: true};
        }

        return (
            <FormDetailCardAdmin
                onSubmit={this.onSubmit}
                initialValues={initialValues}
                roleList={roleList}
                parentLoading={roleListLoading || loading}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(FormFormAdminScreen as unknown as React.ComponentType<FormFormAdminScreenProps>)