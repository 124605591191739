import * as React from 'react';
import {Field, WrappedFieldArrayProps} from "redux-form";
import FormInput from "../../../../components/form/FormInput";
import Row from "../../../../components/Row";
import Icon from "../../../../components/Icon";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_OPCIONES} from "../../../../I18n/constants";
import FormInputOption from "../../../../components/form/FormInputOption";

type Props = WrappedFieldArrayProps<FormInputOption>

class FormInputOptionFieldArray extends React.Component<Props> {

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.fields.length === 0) {
            this.props.fields.push({} as FormInputOption);
        }
    }

    private createFieldValue(index: number): void {
        const {fields} = this.props;
        const fieldValue = fields.get(index);
        fields.remove(index);
        fields.push({
            name: fieldValue.name,
            value: String(index)
        })
    }

    public render(): React.ReactNode {
        const {fields} = this.props;
        const showRemoveField = fields.length !== 1;

        return (
            <React.Fragment>
                {fields && fields.map((field: string, index: number) => {

                    let fieldProps = {
                        label: index === 0 ? I18nUtils.tr(TR_OPCIONES) : null,
                        name: `${field}.name`,
                        component: FormInput,
                        col: {className: 'm-b-0 m-t--15 p-r-25 p-l-5'}
                    };
                    if (!fields.get(index).value) {
                        fieldProps = {
                            ...fieldProps,
                            // @ts-ignore
                            onChange: () => this.createFieldValue(index)
                        }
                    }

                    return (
                        <React.Fragment key={index}>
                            <Row className={'m-l-15 m-r-15 m-t--5 m-b-0 p-b-0'}>
                                <Field {...fieldProps}/>
                            </Row>
                            {showRemoveField ?
                                <a className={'icon-option-remove'}
                                   onClick={() => fields.remove(index)}>
                                    <Icon icon={'remove_circle'}/>
                                </a> : null
                            }
                        </React.Fragment>
                    )
                })}
                <a className={`icon-option-add`}
                   onClick={() => fields.push({} as FormInputOption)}>
                    <Icon icon={'add_circle'}/>
                </a>
            </React.Fragment>
        )
    }
}

export default FormInputOptionFieldArray;