import {ReducerBuilder} from "co-redux-builders";
import DashboardLayoutAdminActions from "./DashboardLayoutAdminActions";
import Dashboard from "../../../../model/Dashboard";
import TaskDashboardDetail from "../../../../ws/dashboard/TaskDashboardDetail";
import DashboardLayout from "../../../../model/DashboardLayout";
import TaskDashboardLayoutList from "../../../../ws/dashboardLayout/TaskDashboardLayoutList";

interface State {
    loading: boolean,
    dashboard?: Dashboard,

    dashboardLayouts: DashboardLayout[],
}

const INITIAL_STATE: State = {
    loading: false,
    dashboard: undefined,
    dashboardLayouts: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskDashboardDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskDashboardDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskDashboardDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, dashboard: payload.data};
    })

    .onEvent(TaskDashboardLayoutList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskDashboardLayoutList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskDashboardLayoutList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, dashboardLayouts: payload.data};
    })

    .onEvent(DashboardLayoutAdminActions.clearReducer, () => INITIAL_STATE)

    .build();