import BaseGenericRequest from "../BaseGenericRequest";
import {TaskDeleteResponse} from "../TaskDeleteResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import {TaskDeleteMultipleRequest} from "../TaskDeleteMultipleRequest";

export default class TaskFormResultDelete extends BaseGenericRequest<TaskDeleteMultipleRequest, TaskDeleteResponse> {

    public readonly data: TaskDeleteMultipleRequest;

    public constructor(itemIdOrIds: string | string[], formId: string) {
        if (typeof itemIdOrIds === 'string') {
            super(METHOD.METHOD_DELETE, generateRoute(Urls.URL_FORM_RESULT_DELETE, {formId, uuid: itemIdOrIds}));
        } else {
            super(METHOD.METHOD_DELETE, generateRoute(Urls.URL_FORM_RESULT_DELETE_MULTIPLE, {formId}));
            this.data = {ids: itemIdOrIds};
        }
    }

    protected getRequest(): TaskDeleteMultipleRequest {
        return this.data;
    }
}