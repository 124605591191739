import * as React from "react";
import {Component, ReactNode} from "react";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_LANZADOR_APPS} from "../../../I18n/constants";
import {Link} from "../../../model/Link";
import LoadingView from "../../../base/loading/LoadingView";

interface LauncherAppsViewProps{
    links: Link [],
    loading: boolean,
    errors: string,
}

export default class AppLauncherListView extends Component<LauncherAppsViewProps> {
    public render(): ReactNode {
        const {loading, links, errors} = this.props;
        if (!errors){
            return (
                <>
                    <div className={"header"}>
                        <h2>{I18nUtils.tr(TR_LANZADOR_APPS)}</h2>
                    </div>

                    <div className={"app-launcher-container"}>
                        {
                            links.map((link: Link, index) => {
                                return (
                                    <a href={link.url} target={"_blank"} key={index}>
                                        <div className={"app-card"}
                                             key={link.id}
                                        >
                                            <h5>{link.name}</h5>
                                            <img src={link.image} alt={`Image${link.id}`}/>
                                        </div>
                                    </a>
                                )
                            })
                        }
                    </div>

                    <LoadingView loading={loading}/>
                </>
            );
        }

        return <div className={"error"}>{errors}</div>

    }
}
 