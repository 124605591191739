import {ReducerBuilder} from "co-redux-builders";
import DocumentationListFilterActions from "./DocumentationListFilterActions";
import {ExploitationSimply} from "../../../../model/Exploitation";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";
import {UserSimply} from "../../../../model/User";
import TaskUserList from "../../../../ws/user/TaskUserList";

interface InitialState {
    exploitationList: ExploitationSimply[],
    exploitationLoading: boolean,
    userList: UserSimply[],
    userListLoading: boolean,
}

const INITIAL_STATE: InitialState = {
    exploitationList: [],
    exploitationLoading: false,
    userList: [],
    userListLoading: false,
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationLoading: true
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationLoading: false
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({
        ...oldState,
        exploitationList: payload.data
    }))

    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        userListLoading: true
    }))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        userListLoading: false
    }))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({
        ...oldState,
        userList: payload.data
    }))

    .onEvent(DocumentationListFilterActions.clearDocumentationListFilterReducer, () => INITIAL_STATE)

    .build()