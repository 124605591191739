import React, {Component, ReactNode} from "react";
import ExploitationFormAdminReducer from "./ExploitationFormAdminReducer";
import ExploitationFormAdminActions from "./ExploitationFormAdminActions";
import {connect} from "react-redux";
import ExploitationBaseFormAdmin, {ExploitationBaseFormAdminData} from "./exploitation/ExploitationBaseFormAdmin";
import {
    ExploitationAutocompleteBuilder,
    ExploitationCharacteristicsRequest,
    ExploitationDateRequest,
    ExploitationRequest,
    ExploitationReversionRequest,
    ExploitationServicesRequest
} from "../../../../model/Exploitation";
import {executeItemTask} from "../../../../utils/FormUtils";
import TaskExploitationEdit from "../../../../ws/exploitation/TaskExploitationEdit";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import {ROUTE_ADMIN_EXPLOITATION_DETAIL, ROUTE_ADMIN_EXPLOITATIONS} from "../../../../routing/Routes";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_AVISO_CAMBIO_FORMULARIO_EXPLOTACION,
    TR_AVISO_PERDIDA_DATOS,
    TR_CARACTERISTICAS,
    TR_CARACTERISTICAS_EXPLOTACION_EDITADAS_CORRECTAMENTE,
    TR_EXPLOTACION,
    TR_EXPLOTACION_CREADA_CORRECTAMENTE,
    TR_EXPLOTACION_MODIFICADA_CORRECTAMENTE,
    TR_EXPLOTACION_YA_EXISTE,
    TR_FECHAS,
    TR_FECHAS_EDITADAS_CORRECTAMENTE,
    TR_NUEVA_EXPLOTACION,
    TR_REVERSION,
    TR_REVERSION_EDITADA_CORRECTAMENTE,
    TR_SERVICIO_EDITADO_CORRECTAMENTE,
    TR_SERVICIOS
} from "../../../../I18n/constants";
import TaskExploitationAdd from "../../../../ws/exploitation/TaskExploitationAdd";
import TaskExploitationDetail from "../../../../ws/exploitation/TaskExploitationDetail";
import TaskBusinessTypeList from "../../../../ws/businessType/TaskBusinessTypeList";
import TaskZoneList from "../../../../ws/zone/TaskZoneList";
import {UserAutocompleteBuilder} from "../../../../model/User";
import TaskCompanyTypeList from "../../../../ws/companytype/TaskCompanyTypeList";
import ExploitationCharacteristicsCardFormAdmin, {ExploitationCharacteristicsCardFormAdminData} from "./characteristics/ExploitationCharacteristicsCardFormAdmin";
import TaskExploitationCharacteristicsEdit from "../../../../ws/exploitation/TaskExploitationCharacteristicsEdit";
import {CompanyTypeAutocompleteBuilder} from "../../../../model/CompanyType";
import ExploitationReversionFormAdmin, {ExploitationReversionFormAdminData} from "./reversion/ExploitationReversionFormAdmin";
import TaskExploitationReversionEdit from "../../../../ws/exploitation/TaskExploitationReversionEdit";
import ExploitationDateFormAdmin, {ExploitationDateFormAdminData} from "./date/ExploitationDateFormAdmin";
import ExploitationServiciesFormAdmin, {ExploitationServicesFormAdminData} from "./servicies/ExploitationServiciesFormAdmin";
import TaskExploitationServicesEdit from "../../../../ws/exploitation/TaskExploitationServicesEdit";
import TaskUserList from "../../../../ws/user/TaskUserList";
import TaskExploitationDateEdit from "../../../../ws/exploitation/TaskExploitationDateEdit";
import DateUtils from "../../../../utils/DateUtils";
import ExploitationFormAdminView from "./ExploitationFormAdminView";
import {
    areDatesCompleted,
    areServicesCompleted,
    isBaseCompleted,
    isCharacteristicsCompleted,
    isReversionCompleted
} from "../../../../utils/ExploitationHelper";
import ExitFormModal from "../../../../components/modal/ExitFormModal";
import Config from "../../../../Config";
import Card from "../../../../components/card/Card";
import CardBody from "../../../../components/card/CardBody";
import CardHeader from "../../../../components/card/CardHeader";
import TaskUserActionsList from "../../../../ws/useractions/TaskUserActionsList";
import TaskAlertDelete from "../../../../ws/alert/TaskAlertDelete";
import TaskDocumentDelete from "../../../../ws/document/TaskDocumentDelete";

interface ExploitationFormAdminDataContainerProps {
    id: string
}

const mapStateToProps = ExploitationFormAdminReducer.autoMapToProps();
const mapActionsToProps = ExploitationFormAdminActions.autoMapToProps();

type Props = ExploitationFormAdminDataContainerProps & typeof mapStateToProps & typeof mapActionsToProps;

interface ExploitationFormAdminDataContainerState {
    currentForm: string,
    nextForm?: string,
    showExitFormModal: boolean
}

enum FormTabs {
    BASE = "base",
    CHARACTERISTICS = "characteristics",
    DATE = "date",
    REVERSION = "reversion",
    SERVICES = "services"
}

class ExploitationFormAdminDataContainer extends Component<Props, ExploitationFormAdminDataContainerState> {

    private formRef;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            currentForm: FormTabs.BASE,
            nextForm: undefined,
            showExitFormModal: false
        };
    }

    public componentDidMount(): void {
        const {id} = this.props;

        // Si existe id, se está editando explotación existente, de lo contrario se está creando
        if (id) {
            new TaskExploitationDetail(id).execute();
        }
        new TaskBusinessTypeList({limit: 0}).execute();
        new TaskZoneList({limit: 0}).execute();
        new TaskCompanyTypeList({}).execute();
        new TaskUserList({}).execute();
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.id !== prevProps.id) {
            new TaskExploitationDetail(this.props.id).execute();
        }
    }

    private handleOnCancelDeleteAlerts = (ids: string []) => {
        if (ids.length > 0) {
            new TaskAlertDelete(ids).execute();
        }
    }

    private handleOnCancelDeleteDocuments = (id: string []) => {
        new TaskDocumentDelete(id).execute();
    }

    private handleOnExploitationChange = (response): void =>{
        // Volver a obtener userActions
        new TaskUserActionsList().execute();

        if (!this.formRef) {
            goToRoute(ROUTE_ADMIN_EXPLOITATIONS);

        } else {
            new TaskExploitationDetail(response.data.id)
                .onSuccess(() => {
                    const {nextForm} = this.state;

                    if (nextForm){
                        this.setState({currentForm: nextForm, nextForm: undefined});
                    }
                })
                .execute();
        }
    }

    private handleOnChangeForm(formName: string) {
        if (this.formRef) {
            const {dirty} = this.formRef;

            if (dirty) {
                this.setState({showExitFormModal: true, nextForm: formName});
            } else {
                this.setState({currentForm: formName})
            }
        }
    }

    private handleOnSubmitFormFromModal() {
        const {currentForm} = this.state;
        const {values, dirty} = this.formRef;

        if (dirty) {
            switch(currentForm) {
                default:
                    this.handleOnSubmitExploitationBase(values);
                    this.setState({currentForm: FormTabs.BASE});
                    break;

                case FormTabs.CHARACTERISTICS:
                    this.handleOnSubmitExploitationCharacteristics(values);
                    this.setState({currentForm: FormTabs.CHARACTERISTICS});
                    break;

                case FormTabs.REVERSION:
                    this.handleOnSubmitExploitationReversion(values);
                    this.setState({currentForm: FormTabs.REVERSION});
                    break;

                case FormTabs.DATE:
                    this.handleOnSubmitExploitationDate(values);
                    this.setState({currentForm: FormTabs.DATE});
                    break;

                case FormTabs.SERVICES:
                    this.handleOnSubmitExploitationServices(values);
                    this.setState({currentForm: FormTabs.SERVICES});
                    break;
            }

            this.formRef = null;
            this.setState({showExitFormModal: false})
        }
    }

    private handleOnSubmitExploitationBase = (values: ExploitationBaseFormAdminData): void => {
        const existExploitation = this.props.id !== undefined;

        const submitData: ExploitationRequest = {
            business_type_id: values.businessType,
            zone_id: values.zone,
            contract_name: values.contractName,
            image: Array.isArray(values.image) && values.image[0] ? values.image[0] : undefined,
            latitude: values.coord.lat,
            longitude: values.coord.lng,
            head_of_service_user_ids: values.headOfServiceUsers.map(user => user[0].data.id)
        };

        if (existExploitation) {
            executeItemTask(
                new TaskExploitationEdit(this.props.id, submitData),
                (response) => this.handleOnExploitationChange(response),
                I18nUtils.tr(TR_EXPLOTACION_MODIFICADA_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskExploitationAdd(submitData),
                (response) => {goToRoute(generateRoute(ROUTE_ADMIN_EXPLOITATION_DETAIL, {id: response.data.id}))},
                I18nUtils.tr(TR_EXPLOTACION_CREADA_CORRECTAMENTE),
                I18nUtils.tr(TR_EXPLOTACION_YA_EXISTE)
            )
        }
    };

    private handleOnSubmitExploitationCharacteristics = (values: ExploitationCharacteristicsCardFormAdminData): void => {
        const submitData: ExploitationCharacteristicsRequest = {
            company_type_id: values.companyType && values.companyType[0] ? values.companyType[0].data.id : undefined,
            company_name: values.companyType && values.companyType[0] ? values.companyType[0].data.name : undefined,
            company_cif: values.companyCif || undefined,
            company_percentage: Number(values.companyPercentage) / 100,
            company_proxies: values.companyProxies ? values.companyProxies : undefined,
        };

        executeItemTask(
            new TaskExploitationCharacteristicsEdit(this.props.id, submitData),
            (response) => this.handleOnExploitationChange(response),
            I18nUtils.tr(TR_CARACTERISTICAS_EXPLOTACION_EDITADAS_CORRECTAMENTE)
        );

    };

    private handleOnSubmitExploitationReversion = (values: ExploitationReversionFormAdminData): void => {
        const submitData: ExploitationReversionRequest = {
            reversion: values.reversion
        };

        executeItemTask(
            new TaskExploitationReversionEdit(this.props.id, submitData),
            (response) => this.handleOnExploitationChange(response),
            I18nUtils.tr(TR_REVERSION_EDITADA_CORRECTAMENTE)
        )
    };

    private handleOnSubmitExploitationDate = (values: ExploitationDateFormAdminData): void => {

        // Al enviar el formulario si se borraron alertas, se eliminan del listado de alertas
        if (this.props.alertsDeleteQueue) {
            new TaskAlertDelete(this.props.alertsDeleteQueue).execute();
        }

        // Al enviar el formulario si se borró el documento, se elimina del listado de documentos
        if (this.props.documentDelete) {
            new TaskDocumentDelete(this.props.documentDelete).execute();
        }

        const submitData: ExploitationDateRequest = {
            contract_signing_date: values.contractSigningDate,
            record_signing_date: values.recordSigningDate ? values.recordSigningDate : undefined,
            contract_end_date: values.contractEndDate,
            extension_end_date: values.extensionEndDate ? values.extensionEndDate : undefined,
            extension_start_date: values.extensionStartDate ? values.extensionStartDate : undefined,
            start_service_date: values.startServiceDate ? values.startServiceDate : undefined,
            extension_request_limit_date: values.extensionRequestLimitDate ? values.extensionRequestLimitDate : undefined,
            contract_end_date_alert_id: values.contractEndDateAlert.id,
            extension_end_date_alert_id: values.extensionEndDateAlert ? values.extensionEndDateAlert.id : undefined,
            extension_request_limit_date_alert_id: values.extensionRequestLimitDateAlert ? values.extensionRequestLimitDateAlert.id : undefined,
            extension_start_date_alert_id: values.extensionStartDateAlert ? values.extensionStartDateAlert.id : undefined,
            record_signing_date_alert_id: values.recordSigningDateAlert ? values.recordSigningDateAlert.id: undefined,
            record_signing_document_id: values.recordSigningDocument ? values.recordSigningDocument.id : undefined,
            start_service_date_alert_id: values.startServiceDateAlert ? values.startServiceDateAlert.id : undefined
        };

        executeItemTask(
            new TaskExploitationDateEdit(this.props.id, submitData),
            (response) => this.handleOnExploitationChange(response),
            I18nUtils.tr(TR_FECHAS_EDITADAS_CORRECTAMENTE)
        )
    };

    private handleOnSubmitExploitationServices = (values: ExploitationServicesFormAdminData): void => {

        // Al enviar el formulario si se borraron alertas, se eliminan del listado de alertas
        if (this.props.alertsDeleteQueue) {
            new TaskAlertDelete(this.props.alertsDeleteQueue).execute();
        }

        const submitData: ExploitationServicesRequest = {
            sealed_document_places: Number(values.sealedPlaces),
            offer_places: Number(values.offeredPlaces),
            payment_methods: values.paymentMethods,
            real_places: Number(values.realPlaces),
            holidays_alert_id: values.holidaysAlert ? values.holidaysAlert.id : undefined,
            rates_review_alert_id: values.ratesReviewAlert ? values.ratesReviewAlert.id : undefined,
            schedule_image: values.scheduleImage[0],
        };

        executeItemTask(
            new TaskExploitationServicesEdit(this.props.id, submitData),
            (response) => this.handleOnExploitationChange(response),
            I18nUtils.tr(TR_SERVICIO_EDITADO_CORRECTAMENTE)
        )
    };

    public render(): React.ReactNode {
        const {exploitation, loadingExploitation, id} = this.props;
        const {currentForm} = this.state;

        if (id) {
            return (
                <>
                    <ExploitationFormAdminView
                        currentFormName={currentForm}
                        form={this.renderForm()}
                        exploitation={exploitation}
                        loading={loadingExploitation}
                        tabs={
                            [
                                {   name: FormTabs.BASE,
                                    completed: isBaseCompleted(exploitation),
                                    header: I18nUtils.tr(TR_EXPLOTACION),
                                    onTabSelected: () => this.handleOnChangeForm(FormTabs.BASE)
                                },
                                {   name: FormTabs.CHARACTERISTICS,
                                    completed: isCharacteristicsCompleted(exploitation),
                                    header: I18nUtils.tr(TR_CARACTERISTICAS),
                                    onTabSelected: () => this.handleOnChangeForm(FormTabs.CHARACTERISTICS)
                                },
                                {   name: FormTabs.DATE,
                                    completed: areDatesCompleted(exploitation),
                                    header: I18nUtils.tr(TR_FECHAS),
                                    onTabSelected: () => this.handleOnChangeForm(FormTabs.DATE)
                                },
                                {   name: FormTabs.REVERSION,
                                    completed: isReversionCompleted(exploitation),
                                    header: I18nUtils.tr(TR_REVERSION),
                                    onTabSelected: () => this.handleOnChangeForm(FormTabs.REVERSION)
                                },
                                {   name: FormTabs.SERVICES,
                                    completed: areServicesCompleted(exploitation),
                                    header: I18nUtils.tr(TR_SERVICIOS),
                                    onTabSelected: () => this.handleOnChangeForm(FormTabs.SERVICES)
                                },
                            ]
                        }
                    />

                    <ExitFormModal
                        title={I18nUtils.tr(TR_AVISO_CAMBIO_FORMULARIO_EXPLOTACION)}
                        message={I18nUtils.tr(TR_AVISO_PERDIDA_DATOS)}
                        onConfirmAction={() => {
                            this.handleOnSubmitFormFromModal();
                        }}
                        show={this.state.showExitFormModal}
                        onExit={() => {
                            const {nextForm} = this.state;

                            this.setState({showExitFormModal: false, currentForm: nextForm as string});
                            this.formRef = null;
                        }}
                        onClose={() => this.setState({showExitFormModal: false})}
                    />
                </>
            );
        }

        return (
            <Card>
                <CardHeader title={I18nUtils.tr(TR_NUEVA_EXPLOTACION)}/>
                <CardBody>
                    {this.renderExploitationBaseForm()}
                </CardBody>
            </Card>
        );


    }

    private renderForm(): ReactNode {
        const {currentForm} = this.state;

        switch(currentForm) {
            default:
                 return this.renderExploitationBaseForm();

            case FormTabs.CHARACTERISTICS:
                 return this.renderExploitationCharacteristicsForm();

            case FormTabs.REVERSION:
                return this.renderExploitationReversionForm();

            case FormTabs.DATE:
                return this.renderExploitationDateForm();

            case FormTabs.SERVICES:
                return this.renderExploitationServicesForm();
        }
    }

    private renderExploitationBaseForm(): React.ReactNode {
        const {exploitation, id, users, zoneList, businessTypeList, loadingUsers} = this.props;

        let initialValues: Partial<ExploitationBaseFormAdminData> | undefined;
        let zoneId: string | undefined;

        if (exploitation) {

            initialValues = {
                businessType: exploitation.business_type.id,
                zone: exploitation.zone.id,
                contractName: exploitation.contract_name,
                image: exploitation.image ? [exploitation.image] : [],
                coord: exploitation.latitude ? {lat: exploitation.latitude, lng: exploitation.longitude} : Config.DEFAULT_MAP_POSITION,
                headOfServiceUsers: exploitation.head_of_service_user ? exploitation.head_of_service_user.map(user => [UserAutocompleteBuilder.buildOption(user)]) : []
            };

            zoneId = id || '';

        } else {
            initialValues = {
                coord: Config.DEFAULT_MAP_POSITION
            }
        }

        return (
            <ExploitationBaseFormAdmin
                ref={(ref) => this.formRef = ref}
                users={users}
                exploitationId={zoneId}
                zoneList={zoneList}
                businessTypeList={businessTypeList}
                loadingUsers={loadingUsers}
                initialValues={initialValues}
                onSubmit={this.handleOnSubmitExploitationBase}
            />

        );
    }

    private renderExploitationCharacteristicsForm(): ReactNode {
        const {loadingCompanies, companies, exploitation} = this.props;

        let initialValues: ExploitationCharacteristicsCardFormAdminData | undefined;

        if (exploitation) {
            initialValues = {
                companyCif: exploitation.company_cif ? exploitation.company_cif : '',
                companyProxies: exploitation.company_proxies ? exploitation.company_proxies : '',
                companyPercentage: exploitation.company_percentage ? exploitation.company_percentage * 100 : undefined,
                companyType: exploitation.company_type ? [CompanyTypeAutocompleteBuilder.buildOption(exploitation.company_type)]: [] as any
            }
        }

        return (
            <ExploitationCharacteristicsCardFormAdmin
                ref={(ref) => this.formRef = ref}
                companies={companies}
                loading={loadingCompanies}
                initialValues={initialValues}
                onSubmit={this.handleOnSubmitExploitationCharacteristics}
            />
        );
    }

    private renderExploitationReversionForm(): ReactNode {
        const {exploitation, loadingExploitation} = this.props;

        let initialValues: ExploitationReversionFormAdminData | undefined;

        if (exploitation) {
            initialValues = {
                reversion: exploitation.reversion ? exploitation.reversion : ''
            }
        }

        return (
            <ExploitationReversionFormAdmin
                ref={(ref) => this.formRef = ref}
                initialValues={initialValues}
                loading={loadingExploitation}
                onSubmit={this.handleOnSubmitExploitationReversion}
            />
        )
    };

    private renderExploitationDateForm(): ReactNode {
        const {loadingExploitation, exploitation} = this.props;

        let initialValues: ExploitationDateFormAdminData | undefined;

        if (exploitation) {
            initialValues = {
                contractSigningDate: exploitation.contract_signing_date ? DateUtils.getDateFromISOString(exploitation.contract_signing_date) : '',
                recordSigningDate: exploitation.record_signing_date ? DateUtils.getDateFromISOString(exploitation.record_signing_date) : '',
                extensionRequestLimitDate: exploitation.extension_request_limit_date ? DateUtils.getDateFromISOString(exploitation.extension_request_limit_date) : '',
                extensionStartDate: exploitation.extension_start_date ? DateUtils.getDateFromISOString(exploitation.extension_start_date) : '',
                contractEndDate: exploitation.contract_end_date ? DateUtils.getDateFromISOString(exploitation.contract_end_date) : '',
                startServiceDate: exploitation.start_service_date ? DateUtils.getDateFromISOString(exploitation.start_service_date) : '',
                extensionEndDate: exploitation.extension_end_date ? DateUtils.getDateFromISOString(exploitation.extension_end_date): '',

                recordSigningDateAlert: exploitation.record_signing_date_alert,
                startServiceDateAlert: exploitation.start_service_date_alert,
                contractEndDateAlert: exploitation.contract_end_date_alert,
                extensionRequestLimitDateAlert: exploitation.extension_request_limit_date_alert,
                extensionStartDateAlert: exploitation.extension_start_date_alert,
                extensionEndDateAlert: exploitation.extension_end_date_alert,

                recordSigningDocument: exploitation.record_signing_document
            }
        }

        return (
            <ExploitationDateFormAdmin
                exploitationData={exploitation ? ExploitationAutocompleteBuilder.buildOption(exploitation) : undefined}
                ref={(ref) => this.formRef = ref}
                initialValues={initialValues}
                loading={loadingExploitation}
                onSubmit={this.handleOnSubmitExploitationDate}
                onCancelDeleteAlerts={this.handleOnCancelDeleteAlerts}
                onCancelDeleteDocument={this.handleOnCancelDeleteDocuments}
            />
        )
    }

    private renderExploitationServicesForm(): ReactNode {
        const {loadingExploitation, exploitation} = this.props;

        let initialValues: ExploitationServicesFormAdminData | undefined;

        if (exploitation) {
            initialValues = {
                sealedPlaces: exploitation.sealed_document_places,
                realPlaces: exploitation.real_places,
                offeredPlaces: exploitation.offer_places,
                scheduleImage: exploitation.schedule_image ? [exploitation.schedule_image] : [],
                paymentMethods: exploitation.payment_methods,
                holidaysAlert: exploitation.holidays_alert,
                ratesReviewAlert: exploitation.rates_review_alert
            }
        }

        return (
            <ExploitationServiciesFormAdmin
                exploitationData={exploitation ? ExploitationAutocompleteBuilder.buildOption(exploitation) : undefined}
                ref={(ref) => this.formRef = ref}
                initialValues={initialValues}
                loading={loadingExploitation}
                onSubmit={this.handleOnSubmitExploitationServices}
                onCancelDeleteAlerts={this.handleOnCancelDeleteAlerts}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(ExploitationFormAdminDataContainer as unknown as React.ComponentType<ExploitationFormAdminDataContainerProps>);
 