import * as  React from "react";
import {ReactNode} from "react";
import {RouterProps} from "react-router";
import {Route, Router} from "react-router-dom";
import {
    ROUTE_ADMIN_ALERT_ADD,
    ROUTE_ADMIN_ALERT_DETAIL,
    ROUTE_ADMIN_ALERTS,
    ROUTE_ADMIN_BUSINESS_TYPE_ADD,
    ROUTE_ADMIN_BUSINESS_TYPE_DETAIL,
    ROUTE_ADMIN_BUSINESS_TYPES,
    ROUTE_ADMIN_CURRENT_USER_DETAIL,
    ROUTE_ADMIN_DASHBOARD_ADD,
    ROUTE_ADMIN_DASHBOARD_DETAIL,
    ROUTE_ADMIN_DASHBOARD_LAYOUT,
    ROUTE_ADMIN_DASHBOARDS,
    ROUTE_IFRAME,
    ROUTE_IFRAMES_SCREEN,
    ROUTE_ADMIN_DOCUMENT_ADD,
    ROUTE_ADMIN_DOCUMENT_DETAIL,
    ROUTE_ADMIN_DOCUMENTS,
    ROUTE_ADMIN_EXPLOITATION_ADD,
    ROUTE_ADMIN_EXPLOITATION_DETAIL,
    ROUTE_ADMIN_EXPLOITATIONS,
    ROUTE_ADMIN_FORM_ADD,
    ROUTE_ADMIN_FORM_DETAIL,
    ROUTE_ADMIN_FORM_RESULT,
    ROUTE_ADMIN_FORM_RESULT_DETAIL,
    ROUTE_ADMIN_FORMS,
    ROUTE_ADMIN_LOG,
    ROUTE_ADMIN_LOG_DETAIL, ROUTE_ADMIN_ALERT_NOTIFICATIONS,
    ROUTE_ADMIN_USER_ADD,
    ROUTE_ADMIN_USER_DETAIL,
    ROUTE_ADMIN_USER_GROUP_ADD,
    ROUTE_ADMIN_USER_GROUP_DETAIL,
    ROUTE_ADMIN_USER_GROUPS,
    ROUTE_ADMIN_USERS,
    ROUTE_ADMIN_ZONE_ADD,
    ROUTE_ADMIN_ZONE_DETAIL,
    ROUTE_ADMIN_ZONES, ROUTE_ALERT_NOTIFICATION_USER_LIST, ROUTE_APP_LAUNCHER, ROUTE_COMPARE_GRAPHICS,
    ROUTE_DASHBOARD,
    ROUTE_DASHBOARD_DETAIL,
    ROUTE_DOCUMENTATION,
    ROUTE_FORM,
    ROUTE_FORM_RESULT,
    ROUTE_FORM_RESULT_ADD, ROUTE_FORM_RESULT_ALL_DETAIL, ROUTE_FORM_RESULT_ALL_LIST,
    ROUTE_FORM_RESULT_DETAIL,
    ROUTE_HOME, ROUTE_LINK_ADD, ROUTE_LINK_DETAIL,
    ROUTE_LINKS,
    ROUTE_LOGIN,
    ROUTE_SET_NEW_PASSWORD,
    ROUTE_START_ALERT,
    ROUTE_START_PENDING, ROUTE_ADMIN_ALERT_NOTIFICATION_DETAIL,
} from "./Routes";
// Para mostrar los dialogos de alerta correctamente
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import AlertComponent from "../base/alerts/AlertComponent";
import LoginScreen from "../modules/auth/login/LoginScreen";
import HomeScreen from "../modules/home/HomeScreen";
import PendingScreen from "../modules/start/pending/list/PendingListScreen";
import FormListScreen from "../modules/form/list/FormListScreen";
import DocumentationListScreen from "../modules/documentation/list/DocumentationListScreen";

import UserListAdminScreen from "../modules/admin/users/list/UserListAdminScreen";
import LogListAdminScreen from "../modules/admin/log/list/LogListAdminScreen";
import FormListAdminScreen from "../modules/admin/forms/list/FormListAdminScreen";
import AlertListAdminScreen from "../modules/admin/alerts/list/AlertListAdminScreen";
import DashboardDetailScreen from "../modules/dashboard/detail/DashboardDetailScreen";
import UserFormAdminScreen from "../modules/admin/users/formscreen/UserFormAdminScreen";
import FormCreateAdminScreen from "../modules/admin/forms/formscreen/FormFormAdminScreen";
import AlertFormAdminScreen from "../modules/admin/alerts/form/AlertFormAdminScreen";
import UserGroupListAdminScreen from "../modules/admin/userGroups/list/UserGroupListAdminScreen";
import UserGroupFormAdminScreen from "../modules/admin/userGroups/form/UserGroupFormAdminScreen";
import ZoneListAdminScreen from "../modules/admin/zones/list/ZoneListAdminScreen";
import ZoneFormAdminScreen from "../modules/admin/zones/form/ZoneFormAdminScreen";
import BusinessTypeListAdminScreen from "../modules/admin/businessTypes/list/BusinessTypeListAdminScreen";
import BusinessTypeFormAdminScreen from "../modules/admin/businessTypes/form/BusinessTypeFormAdminScreen";
import ExploitationListAdminScreen from "../modules/admin/exploitations/list/ExploitationListAdminScreen";
import ExploitationFormAdminScreen from "../modules/admin/exploitations/form/ExploitationFormAdminScreen";
import DocumentListAdminScreen from "../modules/admin/documents/list/DocumentListAdminScreen";
import DocumentFormAdminScreen from "../modules/admin/documents/form/DocumentFormAdminScreen";
import DashboardListAdminScreen from "../modules/admin/dashboards/list/DashboardListAdminScreen";
import DashboardFormAdminScreen from "../modules/admin/dashboards/form/DashboardFormAdminScreen";
import LogUserAdminScreen from "../modules/admin/log/user/LogUserAdminScreen";
import SetNewPasswordScreen from "../modules/auth/setNewPassword/SetNewPasswordScreen";
import DashboardListScreen from "../modules/dashboard/list/DashboardListScreen";
import FormFormScreen from "../modules/form/formResult/FormResultFormScreen";
import FormResultListScreen from "../modules/form/resultList/FormResultListScreen";
import FormResultListAdminScreen from "../modules/admin/forms/resultList/FormResultListAdminScreen";
import AlertListHomeScreen from "../modules/start/alert/list/AlertListHomeScreen";
import DashboardLayoutAdminScreen from "../modules/admin/dashboards/layout/DashboardLayoutAdminScreen";
import IFrame from "../components/IFrame";
import IFramesScreen from "../modules/dashboard/components/IFramesScreen";
import LinkListAdminScreen from "../modules/admin/links/list/LinkListAdminScreen";
import LinkDetailAdminScreen from "../modules/admin/links/detail/LinkDetailAdminScreen";
import AppLauncherListScreen from "../modules/applauncher/list/AppLauncherListScreen";
import LinkCreateAdminScreen from "../modules/admin/links/create/LinkCreateAdminScreen";
import CompareGraphicsScreen from "../modules/comparegraphics/CompareGraphicsScreen";
import AlertNotificationUserScreen from "../modules/admin/alertnotificationuser/list/AlertNotificationUserScreen";
import NotificationListAdminScreen from "../modules/notification/list/NotificationListAdminScreen";
import NotificationFormAdminScreen from "../modules/notification/formscreen/NotificationFormAdminScreen";
import FormResultAllListScreen from "../modules/formResult/list/FormResultAllListScreen";
import FormResultAllFormScreen from "../modules/formResult/formscreen/FormResultAllFormScreen";

export default class Routing extends React.Component<RouterProps> {

    public render(): ReactNode {
        return (
            <Router history={this.props.history}>
                <div>
                    <Route path="/" component={AlertComponent}/>
                    <Route path={ROUTE_LOGIN} exact component={LoginScreen}/>
                    <Route path={ROUTE_SET_NEW_PASSWORD} exact component={SetNewPasswordScreen}/>

                    <Route path={ROUTE_HOME} exact component={HomeScreen}/>

                    <Route path={ROUTE_START_ALERT} exact component={AlertListHomeScreen}/>
                    <Route path={ROUTE_START_PENDING} exact component={PendingScreen}/>

                    <Route path={ROUTE_DASHBOARD} exact component={DashboardListScreen}/>
                    <Route path={ROUTE_DASHBOARD_DETAIL} exact component={DashboardDetailScreen} />
                    <Route path={ROUTE_IFRAME} exact component={IFrame} />
                    <Route path={ROUTE_IFRAMES_SCREEN} exact component={IFramesScreen} />

                    <Route path={ROUTE_FORM} exact component={FormListScreen}/>
                    <Route path={ROUTE_FORM_RESULT} exact component={FormResultListScreen}/>
                    <Route path={ROUTE_FORM_RESULT_ADD} exact component={FormFormScreen}/>
                    <Route path={ROUTE_FORM_RESULT_DETAIL} exact component={FormFormScreen}/>

                    <Route path={ROUTE_DOCUMENTATION} exact component={DocumentationListScreen}/>

                    <Route path={ROUTE_ADMIN_CURRENT_USER_DETAIL} exact component={UserFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_USERS} exact component={UserListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_USER_ADD} exact component={UserFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_USER_DETAIL} exact component={UserFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_DASHBOARDS} exact component={DashboardListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_DASHBOARD_DETAIL} exact component={DashboardFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_DASHBOARD_LAYOUT} exact component={DashboardLayoutAdminScreen}/>
                    <Route path={ROUTE_ADMIN_DASHBOARD_ADD} exact component={DashboardFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_FORMS} exact component={FormListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_FORM_ADD} exact component={FormCreateAdminScreen}/>
                    <Route path={ROUTE_ADMIN_FORM_DETAIL} exact component={FormCreateAdminScreen}/>
                    <Route path={ROUTE_ADMIN_FORM_RESULT} exact component={FormResultListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_FORM_RESULT_DETAIL} exact component={FormFormScreen}/>

                    <Route path={ROUTE_ADMIN_ALERTS} exact component={AlertListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_ALERT_ADD} exact component={AlertFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_ALERT_DETAIL} exact component={AlertFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_LOG} exact component={LogListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_LOG_DETAIL} exact component={LogUserAdminScreen}/>

                    <Route path={ROUTE_ADMIN_EXPLOITATIONS} exact component={ExploitationListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_EXPLOITATION_DETAIL} exact component={ExploitationFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_EXPLOITATION_ADD} exact component={ExploitationFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_USER_GROUPS} exact component={UserGroupListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_USER_GROUP_DETAIL} exact component={UserGroupFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_USER_GROUP_ADD} exact component={UserGroupFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_ZONES} exact component={ZoneListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_ZONE_DETAIL} exact component={ZoneFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_ZONE_ADD} exact component={ZoneFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_DOCUMENTS} exact component={DocumentListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_DOCUMENT_DETAIL} exact component={DocumentFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_DOCUMENT_ADD} exact component={DocumentFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_BUSINESS_TYPES} exact component={BusinessTypeListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_BUSINESS_TYPE_DETAIL} exact component={BusinessTypeFormAdminScreen}/>
                    <Route path={ROUTE_ADMIN_BUSINESS_TYPE_ADD} exact component={BusinessTypeFormAdminScreen}/>

                    <Route path={ROUTE_ADMIN_ALERT_NOTIFICATIONS} exact component={NotificationListAdminScreen}/>
                    <Route path={ROUTE_ADMIN_ALERT_NOTIFICATION_DETAIL} exact component={NotificationFormAdminScreen}/>

                    <Route path={ROUTE_LINK_DETAIL} exact component={LinkDetailAdminScreen}/>
                    <Route path={ROUTE_LINK_ADD} exact component={LinkCreateAdminScreen}/>
                    <Route path={ROUTE_LINKS} exact component={LinkListAdminScreen}/>

                    <Route path={ROUTE_APP_LAUNCHER} exact component={AppLauncherListScreen}/>

                    <Route path={ROUTE_COMPARE_GRAPHICS} exact component={CompareGraphicsScreen}/>

                    <Route path={ROUTE_ALERT_NOTIFICATION_USER_LIST} exact component={AlertNotificationUserScreen}/>

                    <Route path={ROUTE_FORM_RESULT_ALL_LIST} exact component={FormResultAllListScreen}/>
                    <Route path={ROUTE_FORM_RESULT_ALL_DETAIL} exact component={FormResultAllFormScreen}/>
                </div>
            </Router>
        );
    }

}
