import * as React from "react";
import {ReactNode} from "react";
import AppLauncherListDataContainer from "./AppLauncherListDataContainer";
import ScreenCard from "../../../components/screen/ScreenCard";
import I18nUtils from "../../../I18n/I18nUtils";
import {ROUTE_APP_LAUNCHER} from "../../../routing/Routes";
import {MenuIcon} from "../../../Config";
import {TR_LANZADOR_APPS} from "../../../I18n/constants";

export default class AppLauncherListScreen extends React.Component {
    public render(): ReactNode {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_LANZADOR_APPS),
                    url: ROUTE_APP_LAUNCHER,
                    icon: MenuIcon.LAUNCHER
                }
            ]}>
                <AppLauncherListDataContainer/>
            </ScreenCard>
        );
    }
}
 