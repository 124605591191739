import * as React from 'react';
import ExploitationFieldArrayReducer from "./ExploitationFieldArrayReducer";
import {Field, WrappedFieldArrayProps} from "redux-form";
import {ExploitationAutocompleteOptions} from "./FormPermissionSectionAdmin";
import {connect} from "react-redux";
import {ExploitationAutocompleteBuilder, ExploitationSimply} from "../../../../../../model/Exploitation";
import CardFieldArray from "../../../../../../components/card/CardFieldArray";
import I18nUtils from "../../../../../../I18n/I18nUtils";
import {TR_EXPLOTACIONES} from "../../../../../../I18n/constants";
import FormAsyncAutocomplete from "../../../../../../components/form/FormAsyncAutocomplete";
import {AutocompleteOption} from "../../../../../../components/form/FormAutocomplete";
import TaskExploitationList from "../../../../../../ws/exploitation/TaskExploitationList";
import Col from "../../../../../../components/Col";

const mapStateToProps = ExploitationFieldArrayReducer.autoMapToProps();

type Props = WrappedFieldArrayProps<ExploitationAutocompleteOptions> & typeof mapStateToProps;

class ExploitationFieldArray extends React.Component<Props> {

    private onSearchExploitation = (search: string): void => {
        new TaskExploitationList({search}).execute();
    };

    private selectExploitation = (index: number, newExploitation: ExploitationSimply): void => {
        this.props.fields.remove(index);
        this.props.fields.insert(index, [ExploitationAutocompleteBuilder.buildOption(newExploitation)]);
    };

    public render(): React.ReactNode {

        const {fields, exploitationListLoading, exploitationList, meta} = this.props;

        const exploitationOptions = exploitationList.map(
            (exploitation) => ExploitationAutocompleteBuilder.buildOption(exploitation)
        );

        return (
            <CardFieldArray fields={fields}
                            meta={meta}
                            title={''}
                            fieldsHeader={this.renderHeader()}
                            addHandler={() => fields.push([])}
            >
                {fields.map((fieldName: string, index: number) => {
                    return (
                        // @ts-ignore
                        <Field
                            key={index}
                            name={`${fieldName}`}
                            component={FormAsyncAutocomplete}
                            isLoading={exploitationListLoading}
                            options={exploitationOptions}
                            onSearch={(search) => this.onSearchExploitation(search)}
                            onSelected={(selected: Array<AutocompleteOption<ExploitationSimply>>) => {
                                if (selected && selected[0]) {
                                    this.selectExploitation(index, selected[0].data)
                                }
                            }}
                            col={{className: 'm-b--10 m-t--15 p-r-10'}}
                            disabled={false}
                        />
                    )
                })}
            </CardFieldArray>
        )
    }

    private renderHeader = (): React.ReactNode => {
        return (
            <Col className={'m-b-0 m-l-15'}>
                <label>{I18nUtils.tr(TR_EXPLOTACIONES)}</label>
            </Col>
        )
    }
}

export default connect(mapStateToProps)(ExploitationFieldArray) as unknown as React.ComponentType<any>;