import * as React from "react";
import DocumentationListFilterReducer from "./DocumentationListFilterReducer";
import DocumentationListFilterActions from "./DocumentationListFilterActions";
import {DocumentCriteriaProps} from "../../../../ws/document/TaskDocumentList";
import {connect} from "react-redux";
import {CardSectionCollapsed} from "../../../../components/CardSectionCollapsed";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_BUSCANDO,
    TR_BUSCAR,
    TR_EXPLOTACION,
    TR_FECHA_FIN,
    TR_FECHA_INICIO,
    TR_FILTROS,
    TR_NO_SE_HAN_ENCONTRADO_RESULTADOS,
    TR_USUARIO
} from "../../../../I18n/constants";
import Row from "../../../../components/Row";
import FormCol from "../../../../components/form/FormCol";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {debounce} from "lodash";
import FormInputOption from "../../../../components/form/FormInputOption";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";
import TaskUserList from "../../../../ws/user/TaskUserList";

const mapStateToProps = DocumentationListFilterReducer.autoMapToProps();
const mapActionsToProps = DocumentationListFilterActions.autoMapToProps();

interface DocumentationListFilterProps {
    criteriaHandler: (criteria: DocumentCriteriaProps) => void,
    criteria: DocumentCriteriaProps,
}

type Props = DocumentationListFilterProps & typeof mapStateToProps & typeof mapActionsToProps;

class DocumentationListFilter extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearDocumentationListFilterReducer();
    }

    private onFilterList = (newCriteria?: DocumentCriteriaProps): void => {
        const {criteriaHandler, criteria} = this.props;
        criteriaHandler({...criteria, ...newCriteria});
    };

    private onFilterDebounced = debounce(this.onFilterList, 500);

    private onExploitationSearch = (newSearch: string): void => {
        new TaskExploitationList({search: newSearch, limit: 0}).execute();
    };

    private onUserSearch = (newSearch: string): void => {
        new TaskUserList({search: newSearch, limit: 0}).execute();
    };

    private onStartDateSelect = (date?: Date): void => {
        const newCriteria = {start_date: date ? date.toISOString() : undefined};
        this.onFilterDebounced(newCriteria);
    };

    private onEndDateSelect = (date?: Date): void => {
        const newCriteria = {end_date: date ? date.toISOString() : undefined};
        this.onFilterDebounced(newCriteria);
    };

    private getInputOptions = (list: any): FormInputOption[] => {
        return list.map((item) => ({value: item.id, name: item.name}))
    };

    public render(): React.ReactNode {
        const {exploitationList, exploitationLoading, userList, userListLoading} = this.props;
        const searchPlaceholder = I18nUtils.tr(TR_BUSCAR);

        return (
            <CardSectionCollapsed title={I18nUtils.tr(TR_FILTROS)}
                                  className={'card-filters'}
            >
                <Row>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_EXPLOTACION)}</label>
                                <AsyncTypeahead
                                    placeholder={searchPlaceholder}
                                    promptText={searchPlaceholder}
                                    options={this.getInputOptions(exploitationList)}
                                    onChange={(selected) => {
                                        if (selected && selected[0])
                                            this.onFilterDebounced({exploitation_ids: [selected[0].value]})
                                    }}
                                    onInputChange={
                                        (newText: string) => newText === '' && this.onFilterDebounced({exploitation_ids: undefined})
                                    }
                                    labelKey={'name'}
                                    filterBy={() => true}
                                    isLoading={false}
                                    onSearch={this.onExploitationSearch}
                                    searchText={exploitationLoading ?
                                        I18nUtils.tr(TR_BUSCANDO) :
                                        I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                                />
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_USUARIO)}</label>
                                <AsyncTypeahead
                                    placeholder={searchPlaceholder}
                                    promptText={searchPlaceholder}
                                    options={this.getInputOptions(userList)}
                                    onChange={(selected) => {
                                        if (selected && selected[0])
                                            this.onFilterDebounced({user_ids: [selected[0].value]})
                                    }}
                                    onInputChange={
                                        (newText: string) => newText === '' && this.onFilterDebounced({user_ids: undefined})
                                    }
                                    labelKey={'name'}
                                    filterBy={() => true}
                                    isLoading={false}
                                    onSearch={this.onUserSearch}
                                    searchText={userListLoading ?
                                        I18nUtils.tr(TR_BUSCANDO) :
                                        I18nUtils.tr(TR_NO_SE_HAN_ENCONTRADO_RESULTADOS)}
                                />
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FECHA_INICIO)}</label>
                                <input type={'date'} className="form-control"
                                       onChange={(event) => this.onStartDateSelect(event.target.valueAsDate)}/>
                            </div>
                        </div>
                    </FormCol>
                    <FormCol md={3} lg={3}>
                        <div className={'form-group'}>
                            <div className={'form-line'}>
                                <label>{I18nUtils.tr(TR_FECHA_FIN)}</label>
                                <input type={'date'} className="form-control"
                                       onChange={(event) => this.onEndDateSelect(event.target.valueAsDate)}/>
                            </div>
                        </div>
                    </FormCol>
                </Row>

            </CardSectionCollapsed>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(DocumentationListFilter) as unknown as React.ComponentType<DocumentationListFilterProps>;