import * as React from 'react';
import DashboardFormAdminScreenReducer from "./DashboardFormAdminScreenReducer";
import DashboardFormAdminScreenActions from "./DashboardFormAdminScreenActions";
import {RouteComponentProps} from "react-router";
import {BreadcrumbItem} from "../../../../components/breadcrumb/BreadcrumbManager";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_DASHBOARD,
    TR_DASHBOARD_CREADO_CORRECTAMENTE,
    TR_DASHBOARD_MODIFICADO_CORRECTAMENTE,
    TR_DASHBOARDS,
    TR_NUEVO_DASHBOARD
} from "../../../../I18n/constants";
import {
    ROUTE_ADMIN_DASHBOARD_ADD,
    ROUTE_ADMIN_DASHBOARD_DETAIL,
    ROUTE_ADMIN_DASHBOARDS
} from "../../../../routing/Routes";
import {MenuIcon} from "../../../../Config";
import {generateRoute, goToRoute} from "../../../../utils/Router";
import ScreenCard from "../../../../components/screen/ScreenCard";
import {connect} from "react-redux";
import DashboardCardFormAdmin, {DashboardCardFormAdminData} from "./DashboardCardFormAdmin";
import {FormPermissionCardAdminHelpers,} from "../../forms/formdetail/cards/permission/FormPermissionSectionAdmin";
import {PermissionData} from "../../../../model/Permission";
import {DashboardData} from "../../../../model/Dashboard";
import {executeItemTask, loadItemDetailsOrGoBack} from "../../../../utils/FormUtils";
import TaskDashboardEdit from "../../../../ws/dashboard/TaskDashboardEdit";
import TaskDashboardAdd from "../../../../ws/dashboard/TaskDashboardAdd";
import {UserRoleFieldArrayOption} from "../../../../model/UserRole";
import {ReducerBuilder} from "co-redux-builders";
import ExploitationRoleFieldArrayReducer from "../../userGroups/form/ExploitationRoleFieldArrayReducer";
import TaskDashboardGet from "../../../../ws/dashboard/TaskDashboardGet";
import TaskBusinessTypeList from "../../../../ws/businessType/TaskBusinessTypeList";
import TaskZoneList from "../../../../ws/zone/TaskZoneList";

const mapStateToProps = ReducerBuilder.combineReducersAutoMaps(
    DashboardFormAdminScreenReducer.autoMapToProps(),
    ExploitationRoleFieldArrayReducer.autoMapToProps(),
    );

const mapActionsToProps = DashboardFormAdminScreenActions.autoMapToProps();

type DashboardFormAdminScreenProps = RouteComponentProps<{ id: string }>;

type Props = typeof mapStateToProps & DashboardFormAdminScreenProps & typeof mapActionsToProps;

class DashboardFormAdminScreen extends React.Component<Props> {

    public componentWillMount(): void {
        const {id} = this.props.match.params;
        if (id) {
            loadItemDetailsOrGoBack(new TaskDashboardGet(id), this.props);
        }

        new TaskBusinessTypeList({limit: 0}).execute();
        new TaskZoneList({limit: 0}).execute();
    }

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    private onSubmit = (values: DashboardCardFormAdminData): void => {
        const {id: dashboardId} = this.props.match.params;

        const permissionsExploitations = values.exploitations ?
            values.exploitations.map((permissionExploitation) => {
                return permissionExploitation[0].value.toString()
            }) : [];

        const permissionsUsers = values.users ?
            values.users.map((permissionUser) => {
                return permissionUser[0].value.toString()
            }) : [];


        const permissionsRoles = values.roles ?
            values.roles.map((permissionRole: UserRoleFieldArrayOption) => {
                const currentExploitation = permissionRole.exploitation[0];
                return ({
                    role_id: permissionRole.role_id,
                    exploitation_id: currentExploitation ? currentExploitation.value : undefined,
                });
            }) : [];

        const permissions: PermissionData = {
            roles: permissionsRoles,
            exploitations: permissionsExploitations,
            users: permissionsUsers
        };

        const submitData: DashboardData = {
            permissions,
            name: values.name,
            enabled: values.enabled,
            filter_by_zone: values.filter_by_zone || false,
            filter_by_business_type: values.filter_by_business_type || false,
            filter_by_time: values.filter_by_time || false,
            default_zone_id: values.filter_by_zone && values.default_zone || null,
            default_business_type_id: values.filter_by_business_type && values.default_business_type || null,
            items: values.items,
        };

        if (dashboardId) {
            executeItemTask(
                new TaskDashboardEdit(dashboardId, submitData),
                () => goToRoute(ROUTE_ADMIN_DASHBOARDS),
                I18nUtils.tr(TR_DASHBOARD_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskDashboardAdd(submitData),
                () => goToRoute(ROUTE_ADMIN_DASHBOARDS),
                I18nUtils.tr(TR_DASHBOARD_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): React.ReactNode {
        const breadcrumbs: BreadcrumbItem[] = [{
            name: I18nUtils.tr(TR_DASHBOARDS),
            url: ROUTE_ADMIN_DASHBOARDS,
            icon: MenuIcon.DASHBOARD
        }];

        if (this.props.match.params.id) {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_DASHBOARD),
                url: generateRoute(ROUTE_ADMIN_DASHBOARD_DETAIL, {id: this.props.match.params.id}),
                icon: MenuIcon.DASHBOARD
            });
        } else {
            breadcrumbs.push({
                name: I18nUtils.tr(TR_NUEVO_DASHBOARD),
                url: ROUTE_ADMIN_DASHBOARD_ADD,
                icon: MenuIcon.DASHBOARD
            })
        }

        return (
            <ScreenCard breadcrumbs={breadcrumbs}>
                {this.renderForm()}
            </ScreenCard>
        )
    }

    private renderForm(): React.ReactNode {
        const {loading, dashboard, roleList, roleListLoading, zoneList, businessTypeList} = this.props;

        let initialValues: Partial<DashboardCardFormAdminData> | undefined;

        if (dashboard) {
            initialValues = {
                ...FormPermissionCardAdminHelpers.getFormData(dashboard.permissions),
                name: dashboard.name ? dashboard.name : '',
                enabled: dashboard.enabled !== undefined ? dashboard.enabled : true,
                filter_by_zone: dashboard.filter_by_zone,
                filter_by_business_type: dashboard.filter_by_business_type,
                filter_by_time: dashboard.filter_by_time,
                default_zone: dashboard.default_zone ? dashboard.default_zone.id : undefined,
                default_business_type: dashboard.default_business_type ? dashboard.default_business_type.id : undefined,
                items: dashboard.items ? dashboard.items : [],
            }
        } else {
            initialValues = {
                enabled: true
            }
        }

        return (
            <DashboardCardFormAdmin
                initialValues={initialValues}
                onSubmit={this.onSubmit}
                roleList={roleList}
                parentLoading={loading || roleListLoading}
                zoneList={zoneList}
                businessTypeList={businessTypeList}
            />
        )
    }

}

export default connect(mapStateToProps, mapActionsToProps)(DashboardFormAdminScreen as unknown as React.ComponentType<DashboardFormAdminScreenProps>);
