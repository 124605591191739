import * as React from 'react';
import LoggedScreen from "../../../../base/screens/LoggedScreen";
import ScreenContent from "../../../../components/screen/ScreenContent";
import {ROUTE_START_PENDING} from "../../../../routing/Routes";
import Row from "../../../../components/Row";
import Col from "../../../../components/Col";
import PendingListCard from "./PendingListCard";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_PENDIENTES} from "../../../../I18n/constants";

export default class PendingListScreen extends LoggedScreen {

    constructor(props: {}, context: any) {
        super(props, context);
        this.addBreadcrumbScreen(I18nUtils.tr(TR_PENDIENTES), ROUTE_START_PENDING, 'hourglass_full');
    }

    public renderScreen(): React.ReactNode {
        return (
            <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>
                <Row clearfix>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <PendingListCard/>
                    </Col>
                </Row>
            </ScreenContent>
        )
    }
}
