import {ReducerBuilder} from "co-redux-builders";
import Dashboard from "../../../model/Dashboard";
import {PagerBackendProps} from "../../../components/Pager";
import TaskDashboardList from "../../../ws/dashboard/TaskDashboardList";
import DashboardListCardAdminActions from "./DashboardListCardActions";
import TaskDashboardDelete from "../../../ws/dashboard/TaskDashboardDelete";
import Exploitation from "../../../model/Exploitation";
import BusinessType from "../../../model/BusinessType";
import TaskExploitationList from "../../../ws/exploitation/TaskExploitationList";
import TaskBusinessTypeList from "../../../ws/businessType/TaskBusinessTypeList";

interface State {
    loading: boolean,
    dashboards: Dashboard[],
    error: string,
    pager: PagerBackendProps,

    exploitations: Exploitation[],
    exploitationsLoading: boolean,

    businessTypes: BusinessType[],
}

const INITIAL_STATE: State = {
    loading: false,
    dashboards: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1
    },

    exploitations: [],
    exploitationsLoading: false,

    businessTypes: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, exploitationsLoading: true}
    })
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, exploitationsLoading: false}
    })
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            exploitations: payload.data,
        }
    })

    .onEvent(TaskBusinessTypeList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskBusinessTypeList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskBusinessTypeList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskBusinessTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            businessTypes: payload.data,
        }
    })

    .onEvent(TaskDashboardDelete.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskDashboardDelete.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })

    .onEvent(TaskDashboardList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskDashboardList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskDashboardList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskDashboardList.REDUX_ACTION_OBJECT_ON_SUCCESS, ((oldState, payload) => {
        return {
            ...oldState,
            dashboards: payload.data,
            pager: payload.pager || oldState.pager,
            error: ''
        }
    }))

    .onEvent(DashboardListCardAdminActions.clearReducer, ((oldState, payload) => INITIAL_STATE))

    .build()
