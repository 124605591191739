import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import TaskResponse from "../TaskResponse";
import BaseGenericRequest from "../BaseGenericRequest";

interface TaskRecoverPwdRequest {
    email: string,
}

export default class TaskRecoverPwd extends BaseGenericRequest<TaskRecoverPwdRequest, TaskResponse<{}>> {

    private readonly email: string;

    public constructor(email: string) {
        super(METHOD.METHOD_POST, Urls.URL_RECOVER_PWD);
        this.email = email;
    }

    protected getRequest(): TaskRecoverPwdRequest {
        return {
            email: this.email,
        }
    }
}