import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import UserGroup, {UserGroupData} from "../../model/UserGroup";

export default class TaskUserGroupEdit extends BaseGenericRequest<UserGroupData, TaskResponse<UserGroup>> {

    private readonly data: UserGroupData;

    public constructor(userGroupId: string, userGroupData: UserGroupData) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_USER_GROUP_EDIT, {uuid: userGroupId}));
        this.data = userGroupData;
    }

    protected getRequest(): UserGroupData {
        return this.data;
    }
}