import * as React from 'react';
import {Component, ReactNode, SyntheticEvent} from 'react';
import classNames from 'classnames';

export interface ButtonProps {
    text: string,
    onClick: (event: SyntheticEvent<HTMLButtonElement>) => void,
    className?: string,
    block?: boolean,
    disabled?: boolean,
    type?: string,
}

export default class Button extends Component<ButtonProps> {

    public render(): ReactNode {
        const classComponent = classNames('btn', 'waves-effect', this.props.className, { 'btn-block': this.props.block });
        return (
            <button
                className={classComponent}
                onClick={this.props.onClick}
                type={this.props.type || 'button'}
                disabled={this.props.disabled}
            >{this.props.text}</button>
        );
    }

}

