import UserGroup from "../../../../../../model/UserGroup";
import {ReducerBuilder} from "co-redux-builders";
import TaskUserGroupList from "../../../../../../ws/userGroup/TaskUserGroupList";

interface InitialState {
    groupListLoading: boolean,
    groupList: UserGroup[],
}

const INITIAL_STATE: InitialState = {
    groupListLoading: false,
    groupList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskUserGroupList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, groupListLoading: true}
    })
    .onEvent(TaskUserGroupList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, groupListLoading: false}
    })
    .onEvent(TaskUserGroupList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, groupList: payload.data}
    })
    .build();