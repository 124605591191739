import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {Link} from "../../model/Link";
import {TaskCriteriaRequest} from "../TaskCriteriaRequest";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";


type LinkCriteriaProps = TaskCriteriaRequest<{}>;

export default class TaskLinkList extends BaseGenericRequest<LinkCriteriaProps, TaskResponse<Link[]>> {

    private readonly data: LinkCriteriaProps;

    public constructor(criteria: LinkCriteriaProps = {}) {
        super(METHOD.METHOD_POST, Urls.URL_LINK_LIST);
        this.data = criteria;
    }

    protected getRequest(): LinkCriteriaProps {
        return this.data;
    }

}