import {ReducerBuilder} from "co-redux-builders";
import NotificationListFilterActions from "./NotificationListFilterActions";
import {AlertNotification} from "../../../../model/AlertNotification";
import TaskAlertList from "../../../../ws/alert/TaskAlertList";
import {AlertType} from "../../../../model/AlertType";
import Exploitation from "../../../../model/Exploitation";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";
import {UserSimply} from "../../../../model/User";
import TaskUserList from "../../../../ws/user/TaskUserList";
import AlertSource from "../../../../model/AlertSource";
import TaskAlertSourceList from "../../../../ws/alertSource/TaskAlertSourceList";
import TaskAlertNotificationListFilter from "../../../../ws/alertNotification/TaskAlertNotificationListFilter";
import TaskAlertTypeList from "../../../../ws/alertype/TaskAlertTypeList";

interface InitialState {
    alertsLoading: boolean,
    alerts: AlertNotification[],
    alertTypeLoading: boolean,
    alertTypes: AlertType[],
    alertTypeSelectedIds: string[],
    exploitationsLoading: boolean,
    exploitations: Exploitation[],
    usersLoading: boolean,
    users: UserSimply[],
    alertSourcesLoading: boolean,
    alertSources: AlertSource[],
    alertSourceSelectedIds: string[],
}

const INITIAL_STATE: InitialState = {
    alertsLoading: false,
    alerts: [],
    alertTypeLoading: false,
    alertTypes: [],
    alertTypeSelectedIds: [],
    exploitationsLoading: false,
    exploitations: [],
    usersLoading: false,
    users: [],
    alertSourcesLoading: false,
    alertSources: [],
    alertSourceSelectedIds: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskAlertList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => {
        return {...oldState, notificationsLoading: true}
    })
    .onEvent(TaskAlertList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => {
        return {...oldState, notificationsLoading: false}
    })

    .onEvent(TaskAlertList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            TaskAlertList: payload.data,
        }
    })

    .onEvent(TaskAlertTypeList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({
        ...oldState,
        alertTypes: payload.data,
    }))

    .onEvent(TaskAlertSourceList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({
        ...oldState,
        alertSources: payload.data,
    }))

    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({
        ...oldState,
        exploitations: payload.data
    }))

    .onEvent(TaskAlertList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => (
        {...oldState, usersLoading: true}
    ))
    .onEvent(TaskAlertList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => (
        {...oldState, usersLoading: false}
    ))
    .onEvent(TaskUserList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({
        ...oldState,
        users: payload.data
    }))

    .onEvent(TaskAlertNotificationListFilter.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => (
        {...oldState, alertsLoading: true}
    ))
    .onEvent(TaskAlertNotificationListFilter.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => (
        {...oldState, alertsLoading: false}
    ))
    .onEvent(TaskAlertNotificationListFilter.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => ({
        ...oldState,
        alerts: payload.data
    }))

    .onEvent(NotificationListFilterActions.onSelectAlertTypeId, (oldState, payload) => {

        return {
            ...oldState,
            alertTypeSelectedIds: oldState.alertTypeSelectedIds.includes(payload.alertTypeId) ?
                oldState.alertTypeSelectedIds.filter((id) => id !== payload.alertTypeId) : [...oldState.alertTypeSelectedIds, payload.alertTypeId]
        }
    })

    .onEvent(NotificationListFilterActions.onSelectAlertSourceId, (oldState, payload) => {

        return {
            ...oldState,
            alertSourceSelectedIds: oldState.alertSourceSelectedIds.includes(payload.alertSourceId) ?
                oldState.alertSourceSelectedIds.filter((id) => id !== payload.alertSourceId) : [...oldState.alertSourceSelectedIds, payload.alertSourceId]
        }
    })

    .onEvent(NotificationListFilterActions.clearNotificationListFilterReducer, () => INITIAL_STATE)

    .build()