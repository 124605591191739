import {ReducerBuilder} from "co-redux-builders";
import Zone from "../../../../model/Zone";
import TaskZoneList from "../../../../ws/zone/TaskZoneList";
import {PagerBackendProps} from "../../../../components/Pager";
import ZoneListAdminScreenActions from "./ZoneListCardAdminActions";
import TaskZoneDelete from "../../../../ws/zone/TaskZoneDelete";

interface InitialState {
    loading: boolean,
    zones: Zone[],
    error: string,
    pager: PagerBackendProps,
}

const INITIAL_STATE: InitialState = {
    loading: false,
    zones: [],
    error: '',
    pager: {
        current: 1,
        total_pages: 1
    },
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)

    .onEvent(TaskZoneDelete.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskZoneDelete.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })

    .onEvent(TaskZoneList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: true}
    })
    .onEvent(TaskZoneList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState, payload) => {
        return {...oldState, loading: false}
    })
    .onEvent(TaskZoneList.REDUX_ACTION_OBJECT_ON_FAIL, (oldState, payload) => {
        return {...oldState, error: payload}
    })
    .onEvent(TaskZoneList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {
            ...oldState,
            zones: payload.data,
            pager: payload.pager || oldState.pager,
        }
    })

    .onEvent(ZoneListAdminScreenActions.clearReducer, (oldState, payload) => INITIAL_STATE)

    .build()