import React, {Component, ReactNode} from "react";
import {executeItemTask, formStateAutoMapToPropsFactory} from "../../../../../utils/FormUtils";
import {Field, FormErrors, InjectedFormProps, reduxForm} from "redux-form";
import Row from "../../../../../components/Row";
import {connect} from "react-redux";
import {compose} from "redux";
import {goToRoute} from "../../../../../utils/Router";
import {ROUTE_ADMIN_EXPLOITATIONS} from "../../../../../routing/Routes";
import FormCardFooter from "../../../../../components/form/FormCardFooter";
import {
    TR_ALERTA_FECHA_EXTENSION_LIMITE,
    TR_ALERTA_FECHA_FIN_EXTENSION,
    TR_ALERTA_FECHA_FIN_SERVICIO,
    TR_ALERTA_FECHA_FIN_SERVICIO_OBLIGATORIA,
    TR_ALERTA_FECHA_FIRMA_ACTA,
    TR_ALERTA_FECHA_INICIO_EXTENSION,
    TR_ALERTA_FECHA_INICIO_SERVICIO,
    TR_DOCUMENTO_CREADO_CORRECTAMENTE,
    TR_DOCUMENTO_FIRMA_REGISTRO,
    TR_DOCUMENTO_MODIFICADO_CORRECTAMENTE,
    TR_FECHA_FIN_EXTENSION,
    TR_FECHA_FIN_SERVICIO,
    TR_FECHA_FIN_SERVICIO_OBLIGATORIA,
    TR_FECHA_FIRMA_ACTA,
    TR_FECHA_FIRMA_ACTA_OBLIGATORIA,
    TR_FECHA_FIRMA_REGISTRO,
    TR_FECHA_INICIO_EXTENSION,
    TR_FECHA_INICIO_SERVICIO,
    TR_FECHA_LIMITE_PEDIR_EXTENSION
} from "../../../../../I18n/constants";
import I18nUtils from "../../../../../I18n/I18nUtils";
import DatePicker from "../../../../../components/date/DatePicker";
import Alert from "../../../../../model/Alert";
import AlertFormModal from "../../../../../components/modal/AlertFormModal";
import AlertField from "../fields/AlertField";
import DocumentFormModal from "../../../../../components/modal/DocumentFormModal";
import {DocumentFormAdminData} from "../../../documents/form/DocumentFormAdmin";
import DocumentModel, {DocumentModelData} from "../../../../../model/Document";
import TaskDocumentAdd from "../../../../../ws/document/TaskDocumentAdd";
import DocumentField from "../fields/DocumentField";
import {DateHelpers} from "../../../../../utils/DateUtils";
import DateFormatter from "../../../../../utils/DateFormatter";
import {ExploitationAutocompleteBuilder, ExploitationSimply} from "../../../../../model/Exploitation";
import {AutocompleteOption} from "../../../../../components/form/FormAutocomplete";
import {AlertFormAdminData} from "../../../alerts/form/AlertFormAdmin";
import {UserAutocompleteBuilder} from "../../../../../model/User";
import {UserRoleAutocompleteBuilder} from "../../../../../model/UserRole";
import TaskDocumentEdit from "../../../../../ws/document/TaskDocumentEdit";
import ExploitationFormAdminActions from "../ExploitationFormAdminActions";

const FORM_NAME = "ExploitationDateFormAdmin";

interface ExploitationDateFormAdminProps {
    initialValues?: Partial<ExploitationDateFormAdminData>,
    onSubmit: (values: ExploitationDateFormAdminData) => void,
    loading: boolean,
    exploitationData?: AutocompleteOption<ExploitationSimply>,
    onCancelDeleteAlerts: (ids: string []) => void,
    onCancelDeleteDocument: (id: string []) => void
}

export interface ExploitationDateFormAdminData {
    extensionRequestLimitDate: string,
    extensionStartDate: string,
    extensionEndDate: string,
    startServiceDate: string,
    contractEndDate: string,
    contractSigningDate: string,
    recordSigningDate: string

    contractEndDateAlert: Alert,
    recordSigningDateAlert?: Alert,
    startServiceDateAlert?: Alert,
    extensionRequestLimitDateAlert?: Alert,
    extensionStartDateAlert?: Alert,
    extensionEndDateAlert?: Alert,

    recordSigningDocument?: DocumentModel
}

enum DateFields {
    CONTRACT_SIGNING_DATE = "contractSigningDate",
    RECORD_SIGNING_DATE = "recordSigningDate",
    EXTENSION_REQUEST_LIMIT_DATE = "extensionRequestLimitDate",
    EXTENSION_START_DATE = "extensionStartDate",
    EXTENSION_END_DATE = "extensionEndDate",
    START_SERVICE_DATE = "startServiceDate",
    CONTRACT_END_DATE = "contractEndDate",

    RECORD_SIGNING_DATE_ALERT = "recordSigningDateAlert",
    START_SERVICE_DATE_ALERT = "startServiceDateAlert",
    CONTRACT_END_DATE_ALERT = "contractEndDateAlert",
    EXTENSION_REQUEST_LIMIT_DATE_ALERT = "extensionRequestLimitDateAlert",
    EXTENSION_START_DATE_ALERT = "extensionStartDateAlert",
    EXTENSION_END_DATE_ALERT = "extensionEndDateAlert",

    RECORD_SIGNING_DOCUMENT = "recordSigningDocument"
}

interface ExploitationDateFormAdminState {
    showAlertFormModal: boolean,
    fieldName: string,
    alertTitle: string,
    showDocumentFormModal: boolean,
    date?: Date,
    description: string,
    currentAlert?: Alert,
    currentDocument?: DocumentModel,
    toDeleteAlertList: Array<string>,
    toDeleteDocumentList: Array<string>
}

const mapFormStateToProps = formStateAutoMapToPropsFactory<ExploitationDateFormAdminData>(FORM_NAME);

type Props =
    ExploitationDateFormAdminProps
    & typeof mapFormStateToProps
    & InjectedFormProps<ExploitationDateFormAdminData>;

class ExploitationDateFormAdmin extends Component<Props, ExploitationDateFormAdminState> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            showAlertFormModal: false,
            fieldName: DateFields.RECORD_SIGNING_DATE_ALERT,
            alertTitle: I18nUtils.tr(TR_FECHA_FIRMA_ACTA),
            showDocumentFormModal: false,
            date: undefined,
            description: '',
            currentAlert: undefined,
            currentDocument: undefined,
            toDeleteAlertList: [],
            toDeleteDocumentList: []
        }
    }

    private getToDeleteAlertsId = (): string [] => {
        const {formState, initialValues} = this.props;
        const ids: string [] = [];

        if (formState) {
            const {values} = formState;

            const startServiceDateAlertId = values && values.startServiceDateAlert && values.startServiceDateAlert.id || undefined;
            const contractEndDateAlertId = values && values.contractEndDateAlert && values.contractEndDateAlert.id || undefined;
            const extensionStartDateAlertId = values && values.extensionStartDateAlert && values.extensionStartDateAlert.id || undefined;
            const extensionEndDateAlertId = values && values.extensionEndDateAlert && values.extensionEndDateAlert.id || undefined;
            const recordSigningDateAlertId = values && values.recordSigningDateAlert && values.recordSigningDateAlert.id || undefined;
            const extensionRequestLimitDateAlertId = values && values.extensionRequestLimitDateAlert && values.extensionRequestLimitDateAlert.id || undefined;

            if (startServiceDateAlertId && (initialValues.startServiceDateAlert === undefined)) {
                ids.push(startServiceDateAlertId);
            }
            if (contractEndDateAlertId && (initialValues.contractEndDateAlert === undefined)) {
                ids.push(contractEndDateAlertId);
            }
            if (extensionStartDateAlertId && (initialValues.extensionStartDateAlert === undefined)) {
                ids.push(extensionStartDateAlertId);
            }
            if (extensionEndDateAlertId && (initialValues.extensionEndDateAlert === undefined)) {
                ids.push(extensionEndDateAlertId);
            }
            if (recordSigningDateAlertId && (initialValues.recordSigningDateAlert === undefined)) {
                ids.push(recordSigningDateAlertId);
            }
            if (extensionRequestLimitDateAlertId && (initialValues.extensionRequestLimitDateAlert === undefined)) {
                ids.push(extensionRequestLimitDateAlertId);
            }

            const unassignedAlerts = this.state.toDeleteAlertList.filter(alertId => alertId !== startServiceDateAlertId && alertId !== contractEndDateAlertId
                && alertId !== extensionStartDateAlertId && alertId !== extensionEndDateAlertId && alertId !== recordSigningDateAlertId && alertId !== extensionRequestLimitDateAlertId);

            unassignedAlerts.forEach(unassignedAlert => ids.push(unassignedAlert));
        }

        return ids;
    }

    private getToDeleteDocumentsId = (): string [] | undefined => {
        const {formState, initialValues} = this.props;

        const ids: string [] = [];

        if (formState) {
            const {values} = formState;

            const recordSigningDocumentId = values && values.recordSigningDocument && values.recordSigningDocument.id || undefined;

            if (recordSigningDocumentId && (initialValues.recordSigningDocument === undefined)) {
                ids.push(recordSigningDocumentId);
            }

            const unassignedDocuments = this.state.toDeleteDocumentList.filter(documentId => documentId !== recordSigningDocumentId);
            unassignedDocuments.forEach(unassignedDocument => ids.push(unassignedDocument));

            return ids;
        }

        return undefined;
    }

    private handleValuesModalAlert() {
        const {exploitationData} = this.props;
        const {currentAlert} = this.state;

        const defaultValues: Partial<AlertFormAdminData> = {
            name: this.state.alertTitle,
            description: this.state.description,
            date: this.state.date ? DateFormatter.formatInputDate(this.state.date) : DateFormatter.formatInputDate(new Date()),
            roles: [{
                role_id: "3",
                exploitation: exploitationData ? [exploitationData] : []
            }]
        }

        if (currentAlert) {
            return {
                ...defaultValues,
                link: currentAlert.link,
                description: currentAlert.description,
                date: DateFormatter.formatInputDate(currentAlert.datetime),
                time: DateFormatter.formatTime(currentAlert.datetime),
                exploitations: currentAlert.receivers.exploitations.map(exploitation => [ExploitationAutocompleteBuilder.buildOption(exploitation)]) || [],
                users: currentAlert.receivers.users.map(user => [UserAutocompleteBuilder.buildOption(user)]) || [],
                roles: currentAlert.receivers.roles.map((userRole) => UserRoleAutocompleteBuilder.buildFieldArrayOption(userRole)) || [],
                frequency: currentAlert.alert_frequency.id,
                state: currentAlert.alert_state.id,
                email: currentAlert.notify_by_email,
                name: currentAlert.name
            }
        }

        return defaultValues;
    }

    private handleValuesModalDocument() {
        const {currentDocument} = this.state;

        let initialValues: Partial<DocumentFormAdminData> | undefined;

        if (currentDocument) {
            initialValues = {
                exploitation: currentDocument.exploitation ? [ExploitationAutocompleteBuilder.buildOption(currentDocument.exploitation)] : [],
                name: currentDocument.name,
                url: [currentDocument.url]
            }
        }

        return initialValues;
    }

    private handleDefaultExtensionDescription(date: string): string {
        if (date) {
            return "ATENCIÓN: El contrato finaliza el " + DateFormatter.formatDate(date) + ", debe gestionar la solicitud " +
                "correspondiente para solicitar la prórroga actual o las sucesivas";
        }
        return '';
    }

    private handleAddDocument = (values: DocumentFormAdminData): void => {
        const id = this.state.currentDocument ? this.state.currentDocument.id : undefined;

        const submitData: DocumentModelData = {
            name: values.name,
            url: values.url[0],
            exploitation_id: values.exploitation[0].value,
            description: values.description,
        };

        if (id) {
            executeItemTask(
                new TaskDocumentEdit(id, submitData),
                (response) => {
                    this.props.change(DateFields.RECORD_SIGNING_DOCUMENT, response.data);
                    this.setState({showDocumentFormModal: false});
                },
                I18nUtils.tr(TR_DOCUMENTO_MODIFICADO_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskDocumentAdd(submitData),
                (response) => {
                    this.props.change(DateFields.RECORD_SIGNING_DOCUMENT, response.data);
                    this.setState({showDocumentFormModal: false});
                },
                I18nUtils.tr(TR_DOCUMENTO_CREADO_CORRECTAMENTE)
            )
        }
    };

    public render(): ReactNode {
        const {
            pristine, invalid, handleSubmit, onSubmit, initialValues, formState,
            onCancelDeleteAlerts, onCancelDeleteDocument
        } = this.props;

        return (
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Field
                            name={DateFields.CONTRACT_SIGNING_DATE}
                            component={DatePicker}
                            label={I18nUtils.tr(TR_FECHA_FIRMA_ACTA)}
                            obligatory={true}
                            col={{md: 12, lg: 6}}
                        />

                        <Field
                            name={DateFields.RECORD_SIGNING_DATE_ALERT}
                            label={I18nUtils.tr(TR_ALERTA_FECHA_FIRMA_ACTA)}
                            component={AlertField}
                            alert={formState && formState.values ? formState.values.recordSigningDateAlert : undefined}
                            onDeleteAlert={() => {
                                const {formState} = this.props;
                                const alert = formState && formState.values && formState.values.recordSigningDateAlert;
                                const id = alert ? alert.id : undefined;

                                if (id) {
                                    this.setState({toDeleteAlertList: [...this.state.toDeleteAlertList, id]})
                                    ExploitationFormAdminActions.dispatchAddAlertToQueue.asConnectedAction(id);
                                }
                                this.props.change(DateFields.RECORD_SIGNING_DATE_ALERT, null);
                            }}
                            onShowModal={() => this.setState({
                                showAlertFormModal: true,
                                fieldName: DateFields.RECORD_SIGNING_DATE_ALERT,
                                alertTitle: I18nUtils.tr(TR_ALERTA_FECHA_FIRMA_ACTA),
                                currentAlert: formState && formState.values ? formState.values.recordSigningDateAlert : undefined
                            })}

                            col={{md: 6, lg: 6}}
                        />
                    </Row>

                    <Row>
                        <Field
                            name={DateFields.RECORD_SIGNING_DATE}
                            component={DatePicker}
                            label={I18nUtils.tr(TR_FECHA_FIRMA_REGISTRO)}
                            col={{md: 12, lg: 6}}
                        />
                    </Row>

                    <Row>
                        <Field
                            name={DateFields.EXTENSION_REQUEST_LIMIT_DATE}
                            component={DatePicker}
                            label={I18nUtils.tr(TR_FECHA_LIMITE_PEDIR_EXTENSION)}
                            col={{md: 6, lg: 6}}
                        />

                        <Field
                            name={DateFields.EXTENSION_REQUEST_LIMIT_DATE_ALERT}
                            component={AlertField}
                            label={I18nUtils.tr(TR_ALERTA_FECHA_EXTENSION_LIMITE)}
                            alert={formState && formState.values ? formState.values.extensionRequestLimitDateAlert : undefined}
                            onDeleteAlert={() => {
                                const {formState} = this.props;
                                const alert = formState && formState.values && formState.values.extensionRequestLimitDateAlert;
                                const id = alert ? alert.id : undefined;

                                if (id) {
                                    this.setState({toDeleteAlertList: [...this.state.toDeleteAlertList, id]})
                                    ExploitationFormAdminActions.dispatchAddAlertToQueue.asConnectedAction(id);
                                }

                                this.props.change(DateFields.EXTENSION_REQUEST_LIMIT_DATE_ALERT, null)
                            }}
                            onShowModal={() => this.setState({
                                showAlertFormModal: true,
                                fieldName: DateFields.EXTENSION_REQUEST_LIMIT_DATE_ALERT,
                                alertTitle: I18nUtils.tr(TR_ALERTA_FECHA_EXTENSION_LIMITE),
                                date: formState && formState.values && formState.values.extensionRequestLimitDate ? DateHelpers.getXMonthsAgo(4, new Date(formState.values.extensionRequestLimitDate)) : undefined,
                                description: this.handleDefaultExtensionDescription(formState && formState.values ? formState.values.extensionRequestLimitDate : ''),
                                currentAlert: formState && formState.values ? formState.values.extensionRequestLimitDateAlert : undefined
                            })}
                            col={{md: 6, lg: 6}}
                        />

                    </Row>

                    <Row>
                        <Field
                            name={DateFields.START_SERVICE_DATE}
                            component={DatePicker}
                            label={I18nUtils.tr(TR_FECHA_INICIO_SERVICIO)}
                            col={{md: 12, lg: 6}}
                        />

                        <Field
                            name={DateFields.START_SERVICE_DATE_ALERT}
                            component={AlertField}
                            label={I18nUtils.tr(TR_ALERTA_FECHA_INICIO_SERVICIO)}
                            alert={formState && formState.values ? formState.values.startServiceDateAlert : undefined}
                            onDeleteAlert={() => {
                                const {formState} = this.props;
                                const alert = formState && formState.values && formState.values.startServiceDateAlert;
                                const id = alert ? alert.id : undefined;

                                if (id) {
                                    this.setState({toDeleteAlertList: [...this.state.toDeleteAlertList, id]})
                                    ExploitationFormAdminActions.dispatchAddAlertToQueue.asConnectedAction(id);
                                }

                                this.props.change(DateFields.START_SERVICE_DATE_ALERT, null)
                            }}
                            onShowModal={() => this.setState({
                                showAlertFormModal: true,
                                fieldName: DateFields.START_SERVICE_DATE_ALERT,
                                alertTitle: I18nUtils.tr(TR_ALERTA_FECHA_INICIO_SERVICIO),
                                date: formState && formState.values && formState.values.startServiceDate ? DateHelpers.getXWeeksAgo(1, new Date(formState.values.startServiceDate)) : undefined,
                                currentAlert: formState && formState.values ? formState.values.startServiceDateAlert : undefined
                            })}
                            col={{md: 6, lg: 6}}
                        />
                    </Row>

                    <Row>
                        <Field
                            name={DateFields.CONTRACT_END_DATE}
                            component={DatePicker}
                            label={I18nUtils.tr(TR_FECHA_FIN_SERVICIO)}
                            obligatory={true}
                            col={{md: 12, lg: 6}}
                        />

                        <Field
                            name={DateFields.CONTRACT_END_DATE_ALERT}
                            label={I18nUtils.tr(TR_ALERTA_FECHA_FIN_SERVICIO)}
                            component={AlertField}
                            alert={formState && formState.values ? formState.values.contractEndDateAlert : undefined}
                            onDeleteAlert={() => {
                                const {formState} = this.props;
                                const alert = formState && formState.values && formState.values.contractEndDateAlert;
                                const id = alert ? alert.id : undefined;

                                if (id) {
                                    this.setState({toDeleteAlertList: [...this.state.toDeleteAlertList, id]})
                                    ExploitationFormAdminActions.dispatchAddAlertToQueue.asConnectedAction(id);
                                }

                                this.props.change(DateFields.CONTRACT_END_DATE_ALERT, null)
                            }}
                            onShowModal={() => this.setState({
                                showAlertFormModal: true,
                                fieldName: DateFields.CONTRACT_END_DATE_ALERT,
                                alertTitle: I18nUtils.tr(TR_ALERTA_FECHA_FIN_SERVICIO),
                                date: formState && formState.values && formState.values.contractEndDate ? DateHelpers.getXMonthsAgo(4, new Date(formState.values.contractEndDate)) : undefined,
                                description: this.handleDefaultExtensionDescription(formState && formState.values ? formState.values.contractEndDate : ''),
                                currentAlert: formState && formState.values ? formState.values.contractEndDateAlert : undefined
                            })}
                            obligatory={true}
                            col={{md: 6, lg: 6}}
                        />
                    </Row>

                    <Row>
                        <Field
                            name={DateFields.EXTENSION_START_DATE}
                            component={DatePicker}
                            label={I18nUtils.tr(TR_FECHA_INICIO_EXTENSION)}
                            col={{md: 12, lg: 6}}
                        />

                        <Field
                            name={DateFields.EXTENSION_START_DATE_ALERT}
                            component={AlertField}
                            label={I18nUtils.tr(TR_ALERTA_FECHA_INICIO_EXTENSION)}
                            alert={formState && formState.values ? formState.values.extensionStartDateAlert : undefined}
                            onDeleteAlert={() => {
                                const {formState} = this.props;
                                const alert = formState && formState.values && formState.values.extensionStartDateAlert;
                                const id = alert ? alert.id : undefined;

                                if (id) {
                                    this.setState({toDeleteAlertList: [...this.state.toDeleteAlertList, id]})
                                    ExploitationFormAdminActions.dispatchAddAlertToQueue.asConnectedAction(id);
                                }

                                this.props.change(DateFields.EXTENSION_START_DATE_ALERT, null)
                            }}
                            onShowModal={() => this.setState({
                                showAlertFormModal: true,
                                fieldName: DateFields.EXTENSION_START_DATE_ALERT,
                                alertTitle: I18nUtils.tr(TR_ALERTA_FECHA_INICIO_EXTENSION),
                                currentAlert: formState && formState.values ? formState.values.extensionStartDateAlert : undefined
                            })}
                            col={{md: 6, lg: 6}}
                        />
                    </Row>

                    <Row>
                        <Field
                            name={DateFields.EXTENSION_END_DATE}
                            component={DatePicker}
                            label={I18nUtils.tr(TR_FECHA_FIN_EXTENSION)}
                            col={{md: 12, lg: 6}}
                        />

                        <Field
                            name={DateFields.EXTENSION_END_DATE_ALERT}
                            component={AlertField}
                            label={I18nUtils.tr(TR_ALERTA_FECHA_FIN_EXTENSION)}
                            alert={formState && formState.values ? formState.values.extensionEndDateAlert : undefined}
                            onDeleteAlert={() => {
                                const {formState} = this.props;
                                const alert = formState && formState.values && formState.values.extensionEndDateAlert;
                                const id = alert ? alert.id : undefined;

                                if (id) {
                                    this.setState({toDeleteAlertList: [...this.state.toDeleteAlertList, id]})
                                    ExploitationFormAdminActions.dispatchAddAlertToQueue.asConnectedAction(id);
                                }

                                this.props.change(DateFields.EXTENSION_END_DATE_ALERT, null)
                            }}
                            onShowModal={() => this.setState({
                                showAlertFormModal: true,
                                fieldName: DateFields.EXTENSION_END_DATE_ALERT,
                                alertTitle: I18nUtils.tr(TR_ALERTA_FECHA_FIN_EXTENSION),
                                date: formState && formState.values && formState.values.extensionEndDate ? DateHelpers.getXMonthsAgo(4, new Date(formState.values.extensionEndDate)) : undefined,
                                description: this.handleDefaultExtensionDescription(formState && formState.values ? formState.values.extensionEndDate : ''),
                                currentAlert: formState && formState.values ? formState.values.extensionEndDateAlert : undefined
                            })}
                            col={{md: 6, lg: 6}}
                        />
                    </Row>

                    <Row>
                        <Field
                            name={DateFields.RECORD_SIGNING_DOCUMENT}
                            document={formState && formState.values ? formState.values.recordSigningDocument : undefined}
                            label={I18nUtils.tr(TR_DOCUMENTO_FIRMA_REGISTRO)}
                            component={DocumentField}
                            onDeleteDocument={() => {
                                const {formState} = this.props;
                                const document = formState && formState.values && formState.values.recordSigningDocument;
                                const id = document ? document.id : undefined;

                                if (id) {
                                    this.setState({toDeleteDocumentList: [...this.state.toDeleteDocumentList, id]})
                                    ExploitationFormAdminActions.dispatchSetDeleteDocument.asConnectedAction(id);
                                }

                                this.props.change(DateFields.RECORD_SIGNING_DOCUMENT, null);
                            }}
                            showModal={() => this.setState({
                                showDocumentFormModal: true,
                                currentDocument: formState && formState.values ? formState.values.recordSigningDocument : undefined
                            })}
                            col={{md: 12, lg: 6}}
                        />
                    </Row>

                    <FormCardFooter
                        invalid={invalid}
                        pristine={pristine}
                        isUpdate={!!initialValues}
                        cancelHandler={() => {
                            onCancelDeleteAlerts(this.getToDeleteAlertsId());
                            const documents = this.getToDeleteDocumentsId();
                            if (documents) {
                                onCancelDeleteDocument(documents);
                            }
                            goToRoute(ROUTE_ADMIN_EXPLOITATIONS);
                        }}/>
                </form>

                <AlertFormModal
                    onSubmitAlert={(response) => {
                        this.props.change(this.state.fieldName, response.data);
                        this.setState({showAlertFormModal: false});
                    }}
                    show={this.state.showAlertFormModal}
                    onClose={() => this.setState({showAlertFormModal: false, date: undefined, description: ''})}
                    defaultValues={this.handleValuesModalAlert()}
                    id={this.state.currentAlert ? this.state.currentAlert.id : undefined}
                />

                <DocumentFormModal
                    title={I18nUtils.tr(TR_DOCUMENTO_FIRMA_REGISTRO)}
                    onSubmit={this.handleAddDocument}
                    show={this.state.showDocumentFormModal}
                    onClose={() => this.setState({showDocumentFormModal: false})}
                    initialValues={this.handleValuesModalDocument()}
                />
            </>
        );
    }
}

function validate(values: ExploitationDateFormAdminData): FormErrors<ExploitationDateFormAdminData> {
    const errors: FormErrors<ExploitationDateFormAdminData> = {};

    if (!values.contractEndDate) {
        errors.contractEndDate = I18nUtils.tr(TR_FECHA_FIN_SERVICIO_OBLIGATORIA);
    }
    if (!values.contractEndDateAlert) {
        errors.contractEndDateAlert = I18nUtils.tr(TR_ALERTA_FECHA_FIN_SERVICIO_OBLIGATORIA)
    }
    if (!values.contractSigningDate) {
        errors.contractSigningDate = I18nUtils.tr(TR_FECHA_FIRMA_ACTA_OBLIGATORIA)
    }

    return errors;
}

export default compose(
    reduxForm<ExploitationDateFormAdminData, ExploitationDateFormAdminProps>({
        form: FORM_NAME,
        validate,
        enableReinitialize: true
    }),
    connect(mapFormStateToProps as any)
)(ExploitationDateFormAdmin);
