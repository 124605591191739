import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import Exploitation, {ExploitationServicesRequest} from "../../model/Exploitation";

export default class TaskExploitationServicesEdit extends BaseGenericRequest<ExploitationServicesRequest, TaskResponse<Exploitation>> {

    private readonly data: ExploitationServicesRequest;

    public constructor(exploitationId: string, exploitationCharacteristicsData: ExploitationServicesRequest) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_EXPLOITATION_SERVICES_EDIT, {uuid: exploitationId}));
        this.data = exploitationCharacteristicsData;
    }

    protected getRequest(): ExploitationServicesRequest {
        return this.data;
    }
}