import * as React from 'react';
import {Field, FieldArray, WrappedFieldArrayProps} from "redux-form";
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_ENUNCIADO, TR_IDENTIFICADOR_DEL_CAMPO, TR_LIMITE_DE_CARACTERES,
    TR_OBLIGATORIO,
    TR_OPCIONES,
    TR_SELECCIONA_UN_TIPO_DE_CAMPO,
    TR_TEXTO_VALORES, TR_VALOR_MAXIMO, TR_VALOR_MINIMO, TR_VALOR_POR_DEFECTO,
} from "../../../../I18n/constants";
import FormSelect from "../../../../components/form/FormSelect";
import FormSwitch from "../../../../components/form/FormSwitch";
import Row from "../../../../components/Row";
import Card from "../../../../components/card/Card";
import Icon from "../../../../components/Icon";
import FormInput from "../../../../components/form/FormInput";
import {FormFieldTypeValue} from "../../../../model/FormFieldType";
import CardEnumeration from "../components/CardEnumeration";
import {FormResultValue} from "../../../../model/FormResult";
import FormInputOption from "../../../../components/form/FormInputOption";
import FormInputOptionFieldArray from "../components/FormInputOptionFieldArray";
import FormDetailCardAdminReducer from "./FormDetailCardAdminReducer";
import {connect} from "react-redux";

export interface FormFieldCreatorFormData {
    inputKey: string,
    type: string,
    inputStatement: string,
    required: boolean,
    formFieldValues: FormInputOption[],
    switchFirstOption: string
    switchSecondOption: string,
    max: number | undefined,
    min: number | undefined,
    maxLength: number | undefined,
    defaultValue: FormResultValue | undefined,
}

enum FormFieldCreatorFormFields {
    SWITCH_FIRST_OPTION = 'switchFirstOption',
    SWITCH_SECOND_OPTION = 'switchSecondOption',
    STATEMENT = 'inputStatement',
    KEY = 'inputKey',
    VALUES = 'formFieldValues',
    TYPE = 'type',
    MAX = 'max',
    MIN = 'min',
    MAX_LENGTH = 'maxLength',
    REQUIRED = 'required',
    DEFAULT_VALUE = 'defaultValue',
}

interface FormFieldCreatorProps extends WrappedFieldArrayProps<FormFieldCreatorFormData> {
    field: FormFieldCreatorFormData,
    fieldIndex: number,
    fieldName: string,
}

const DragControl = SortableHandle(() => <Icon className={'drag-indicator'} icon={'drag_indicator'}/>);

interface State {
    selectedOption: string,
}

const mapStateToProps = FormDetailCardAdminReducer.autoMapToProps();

type Props = FormFieldCreatorProps & typeof mapStateToProps

class FormFieldCreator extends React.Component<Props, State> {

    public state: State = {selectedOption: FormFieldTypeValue.NONE};

    public render(): React.ReactNode {
        const {field, fieldName, fieldIndex} = this.props;

        return (
            <div key={fieldIndex}>
                <CardEnumeration enumeration={fieldIndex + 1}/>
                <Card className={'drag-cnt'}>
                    <DragControl/>
                    <div className={'drag-sibling'}>
                        {this.renderFieldCreatorHeader(fieldName, fieldIndex)}
                        <Row className={'m-r-15 m-l-5 m-t-0 p-t-0'}>
                            <Field
                                name={`${fieldName}.${FormFieldCreatorFormFields.STATEMENT}`}
                                component={FormInput}
                                placeholder={I18nUtils.tr(TR_ENUNCIADO)}
                                col={{md: 12, lg: 12, className: 'm-t--15 p-t-0 p-l-5'}}
                            />
                        </Row>
                        <Row className={'m-r-15 m-l-5'}>
                            {this.renderFieldCreatorOptions(field.type, fieldName)}
                        </Row>
                    </div>
                </Card>
            </div>
        )
    }

    private renderFieldCreatorHeader(field: string, index: number): React.ReactNode {
        const {fields, formFieldTypeOptions} = this.props;

        return (
            <div>
                <a className={'icon-card-remove'}
                   onClick={() => {
                       fields.remove(index);
                   }}>
                    <Icon icon={'remove_circle'}/>
                </a>

                <Row className={'m-l-5 m-r-15 p-t-15 m-b-0 p-b-0'}>
                    <Field
                        name={`${field}.${FormFieldCreatorFormFields.KEY}`}
                        component={FormInput}
                        col={{md: 4, lg: 4, className: 'm-t--20 p-r-20 m-b-0 p-l-5'}}
                        placeholder={I18nUtils.tr(TR_IDENTIFICADOR_DEL_CAMPO)}
                        maxLength={12}
                    />
                    <Field
                        name={`${field}.${FormFieldCreatorFormFields.TYPE}`}
                        component={FormSelect}
                        options={formFieldTypeOptions}
                        onChange={(event: any) => {
                            this.setState({selectedOption: event.target.value})
                        }}
                        disabledBorderBottom={true}
                        blankOptionText={I18nUtils.tr(TR_SELECCIONA_UN_TIPO_DE_CAMPO)}
                        col={{md: 4, lg: 4, className: 'm-t--15 m-b-0 m-l-0'}}
                    />
                    <Field
                        name={`${field}.${FormFieldCreatorFormFields.REQUIRED}`}
                        component={FormSwitch}
                        labelDisabled={I18nUtils.tr(TR_OBLIGATORIO)}
                        col={{md: 3, lg: 3, className: 'm-t--20 p-r-0 m-r-0 m-b-0'}}
                        disabled={false}
                    />
                </Row>
            </div>
        )
    }

    private renderFieldCreatorOptions(fieldType: string, fieldName: string): React.ReactNode {

        switch (fieldType) {
            case FormFieldTypeValue.SELECT:
            case FormFieldTypeValue.CHECKBOXGROUP:
            case FormFieldTypeValue.RADIOGROUP:
                return <FieldArray
                    name={`${fieldName}.${FormFieldCreatorFormFields.VALUES}`}
                    label={I18nUtils.tr(TR_OPCIONES)}
                    // @ts-ignore
                    component={FormInputOptionFieldArray}
                />;

            case FormFieldTypeValue.TEXT:
            case FormFieldTypeValue.TEXTAREA:
                return this.renderTextOptions(fieldName);

            case FormFieldTypeValue.NUMBER:
                return this.renderNumberOptions(fieldName);

            case FormFieldTypeValue.SWITCH:
                return this.renderSwitchOptions(fieldName);

            default:
                return null;
        }
    }

    private renderTextOptions(fieldName: string): React.ReactNode {
        return (
            <div className={'switch-statement'}>
                <Row className={'m-t--5 m-l-5 m-b-0 p-b-0'}>
                    <Field
                        name={`${fieldName}.${FormFieldCreatorFormFields.MAX_LENGTH}`}
                        label={I18nUtils.tr(TR_LIMITE_DE_CARACTERES)}
                        component={FormInput}
                        type='number'
                        col={{className: 'm-b-0 m-t--15 p-l-5'}}
                    />
                </Row>
            </div>
        );
    }

    private renderNumberOptions(fieldName: string): React.ReactNode {
        const commonProps = {
            component: FormInput,
            type: 'number',
        };

        return (
            <div className={'switch-statement'}>
                <Row className={'m-t--5 m-l-5 m-b-0 p-b-0'}>
                    <Field
                        {...commonProps}
                        name={`${fieldName}.${FormFieldCreatorFormFields.MAX}`}
                        label={I18nUtils.tr(TR_VALOR_MAXIMO)}
                        col={{className: 'm-b-0 m-t--15 p-l-5'}}
                    />
                </Row>
                <Row className={'m-l-5'}>
                    <Field
                        {...commonProps}
                        name={`${fieldName}.${FormFieldCreatorFormFields.MIN}`}
                        label={I18nUtils.tr(TR_VALOR_MINIMO)}
                        col={{className: 'm-b-0 m-t--15 p-l-5'}}
                    />
                </Row>
            </div>
        );
    }

    private renderSwitchOptions(fieldName: string): React.ReactNode {
        const commonProps = {
            component: FormInput,
        };

        return (
            <div>
                <div className={'switch-statement'}>
                    <Row className={'m-t--5 m-l-5 m-b-0 p-b-0'}>
                        <Field name={`${fieldName}.${FormFieldCreatorFormFields.SWITCH_FIRST_OPTION}`}
                               label={I18nUtils.tr(TR_TEXTO_VALORES)}
                               col={{className: 'm-b-0 m-t--15'}}
                               {...commonProps}
                        />
                    </Row>
                    <Row className={'m-l-15'}>
                        <Field name={`${fieldName}.${FormFieldCreatorFormFields.SWITCH_SECOND_OPTION}`}
                               col={{className: 'm-t--15 p-l-5'}}
                               {...commonProps}
                        />
                    </Row>
                </div>
                <div className={'switch-selection'}>
                    <label className={'m-b--15'}>{I18nUtils.tr(TR_VALOR_POR_DEFECTO)}</label>
                    <br/>
                    <Field name={`${fieldName}.${FormFieldCreatorFormFields.DEFAULT_VALUE}`}
                           component={FormSwitch}
                           col={{className: 'm-b-0 m-t--5'}}
                           disabled={false}
                    />
                </div>
            </div>
        );
    }
}

const EnhancedFormFieldCreator = connect(mapStateToProps)(FormFieldCreator as unknown as React.ComponentType<FormFieldCreatorProps>);

export default SortableElement(EnhancedFormFieldCreator);
