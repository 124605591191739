import * as React from 'react';
import {connect} from "react-redux";
import FormResultAllListCardReducer from "./FormResultAllListCardReducer";
import FormResultAllListCardActions from "./FormResultAllListCardActions";
import TaskFormResultListAll from "../../../ws/formResult/TaskFormResultListAll";
import CardList from "../../../components/card/CardList";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_NO_EXISTEN_RESULTADOS_DE_FORMULARIOS, TR_RESULTADOS_DE_FORMULARIOS} from "../../../I18n/constants";
import FormResultAllListTable from "./FormResultAllListTable";
import FormResultListFilter from "./listFilter/FormResultListFilter";

const mapStateToProps = FormResultAllListCardReducer.autoMapToProps();
const mapActionsToProps = FormResultAllListCardActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

class FormResultAllListCard extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearFormResultAllListCardReducer();
    }

    public render() {
        const {formResults, error, formResultsloading, pager, criteria, onSetCriteria} = this.props;

        return (
            <CardList loading={formResultsloading}
                      pager={pager}
                      title={I18nUtils.tr(TR_RESULTADOS_DE_FORMULARIOS)}
                      sort={{column: 'name', desc: true}}
                      TaskList={TaskFormResultListAll}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_RESULTADOS_DE_FORMULARIOS)}}
                      data={formResults}
                      error={error}
                      ItemsTable={FormResultAllListTable}
                      customCriteria={criteria}
                      hideSearcher={true}
            >
                <FormResultListFilter criteria={criteria}
                                      criteriaHandler={onSetCriteria}
                />
            </CardList>
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(FormResultAllListCard as unknown as React.ComponentType<{}>);

