import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import Zone, {ZoneData} from "../../model/Zone";

export default class TaskZoneEdit extends BaseGenericRequest<ZoneData, TaskResponse<Zone>> {

    private readonly data: ZoneData;

    public constructor(zoneId: string, zoneData: ZoneData) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_ZONE_EDIT, {uuid: zoneId}));
        this.data = zoneData;
    }

    protected getRequest(): ZoneData {
        return this.data;
    }
}