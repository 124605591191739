import * as React from "react";
import {connect} from "react-redux";
import HomeReducer from "./HomeReducer";
import HomeActions from "./HomeActions";
import LoggedScreen from "../../base/screens/LoggedScreen";
import Row from "../../components/Row";
import ScreenContent from "../../components/screen/ScreenContent";
import Col from "../../components/Col";
import FormListCard from "../form/list/FormListCard";
import DashboardListCard from "../dashboard/list/DashboardListCard";
import AlertListHomeCard from "../start/alert/list/AlertListHomeCard";
import DocumentationListCard from "../documentation/list/DocumentationListCard";

const mapStateToProps = HomeReducer.autoMapToProps();
const mapActionsToProps = HomeActions.autoMapToProps();

class HomeScreen extends LoggedScreen<typeof mapStateToProps & typeof mapActionsToProps> {

    public renderScreen(): React.ReactNode {
        return (
            <ScreenContent breadcrumbManager={this.getBreadcrumbManager()}>

                {/*<Row clearfix>*/}
                {/*    <InfoBox icon={'playlist_add_check'} text={'FORMULARIOS VALIDADOS'} value={32} color={'#8bc34a'}/>*/}
                {/*    <InfoBox icon={'alarm'} text={'ALERTAS PENDIENTES'} value={4} color={'#FBB907'}/>*/}
                {/*    <InfoBox icon={'warning'} text={'FORMULARIOS RECHAZADOS'} value={1} color={'tomato'}/>*/}
                {/*    <InfoBox icon={'notification_important'} text={'ALERTAS PENDIENTES'} value={9} color={'#607D8B'}/>*/}
                {/*</Row>*/}

                <Row clearfix>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <DashboardListCard showFavorites={true}/>
                        <FormListCard showFavorites={true}/>
                        {/*<PendingListCard/>*/}
                        <DocumentationListCard showFavorites={true}/>
                        <AlertListHomeCard/>
                    </Col>
                </Row>

            </ScreenContent>
        );
    }

}

export default connect(mapStateToProps, mapActionsToProps)(HomeScreen as unknown as React.ComponentType<{}>);
