import * as React from 'react';
import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";

export interface FormSubmitProps extends WrappedFieldProps {
    label?: string;
    disabled?: boolean;
    col: FormColProps
}

export default class FormSubmit extends Component<FormSubmitProps> {

    constructor(props: FormSubmitProps & WrappedFieldProps) {
        super(props);
    }

    public render(): ReactNode {
        const {label, disabled, col} = this.props;
        return (
            <FormCol {...col} >
                <button
                    type="submit"
                    className="btn btn-lg btn-block btn-primary waves-effect form-btn"
                    disabled={disabled || false }
                >{label}</button>
            </FormCol>
        );
    }

}

