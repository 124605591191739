import * as React from 'react';

interface CardEnumerationProps {
    enumeration: number,
}

export default class CardEnumeration extends React.Component<CardEnumerationProps> {

    public render(): React.ReactNode {
        return (
            <div className={'enumeration-card'}>
                {this.props.enumeration}
            </div>
        )
    }
}