import * as React from 'react';
import {Component, ReactNode} from 'react';
import FormCol, {FormColProps} from "./FormCol";
import {WrappedFieldProps} from "redux-form";
import classNames from "classnames";

export interface FormDateProps extends WrappedFieldProps {
    name: string;
    label?: string;
    placeholder?: string;
    type: string;
    disabled?: boolean;
    className?: string;
    col: FormColProps;
    onDateChange?: (value: string | undefined) => void;
    forceErrorMessage?: string,
}

export default class FormDate extends Component<FormDateProps> {

    constructor(props: FormDateProps) {
        super(props);
    }

    private formatStringToDate(dateString: string): string {
        if (!dateString) {
            return ''
        }
        if (dateString.indexOf('/') !== -1) {
            const dateSplit = dateString.split('/');
            return [dateSplit[2], dateSplit[1], dateSplit[0]].join('-');
        } else {
            return dateString;
        }
    }

    public render(): ReactNode {
        const {input, meta, label, placeholder, disabled, col, onDateChange, forceErrorMessage} = this.props;
        const classFormLine = classNames('form-line', {
            focused: meta.touched && meta.error,
            error: meta.touched && meta.error
        });

        return (
            <FormCol {...col} >
                <div className={'form-group'}>
                    <div className={classFormLine}>
                        <label htmlFor="">{label}</label>
                        <input
                            type="date"
                            className="form-control"
                            placeholder={placeholder || 'DD/MM/AAAA'}
                            id={name}
                            {...input}
                            value={this.formatStringToDate(input.value)}
                            disabled={disabled || false}
                            onChange={(event) => {
                                const changedDate = event.target.value === '' ?
                                    this.formatStringToDate(meta.initial) : event.target.value;
                                input.onChange(changedDate)
                                if ( onDateChange ) onDateChange(changedDate || undefined);
                            }}
                        />
                    </div>
                    <label className="error">{meta.touched ? meta.error : ""}</label>
                    {forceErrorMessage && <label className="error">{forceErrorMessage}</label>}
                </div>
            </FormCol>
        );
    }
}
