import * as React from 'react';
import DocumentListCardAdminReducer from "./DocumentListCardAdminReducer";
import {connect} from "react-redux";
import I18nUtils from "../../../../I18n/I18nUtils";
import {
    TR_DOCUMENTACION, TR_ELIMINAR_DOCUMENTOS, TR_NO_EXISTEN_DOCUMENTOS,
} from "../../../../I18n/constants";
import DocumentListTable from "./DocumentListTable";
import CardList from "../../../../components/card/CardList";
import DocumentListAdminScreenActions from "./DocumentListcardAdminActions";
import TaskDocumentList from "../../../../ws/document/TaskDocumentList";
import {ROUTE_ADMIN_DOCUMENT_ADD} from "../../../../routing/Routes";
import TaskDocumentDelete from "../../../../ws/document/TaskDocumentDelete";

const mapStateToProps = DocumentListCardAdminReducer.autoMapToProps();
const mapActionsToProps = DocumentListAdminScreenActions.autoMapToProps();

type Props = typeof mapStateToProps & typeof mapActionsToProps

class DocumentListCardAdmin extends React.Component<Props> {

    public componentWillUnmount(): void {
        this.props.clearReducer();
    }

    public render() {
        const {documents, error, loading, pager} = this.props;

        return (
            <CardList loading={loading}
                      pager={pager}
                      title={I18nUtils.tr(TR_DOCUMENTACION)}
                      sort={{column: 'name'}}
                      TaskList={TaskDocumentList}
                      emptyListOptions={{message: I18nUtils.tr(TR_NO_EXISTEN_DOCUMENTOS)}}
                      data={documents}
                      error={error}
                      ItemsTable={DocumentListTable}
                      deleteOption={{
                          title: I18nUtils.tr(TR_ELIMINAR_DOCUMENTOS),
                          Task: TaskDocumentDelete
                      }}
                      addRoute={ROUTE_ADMIN_DOCUMENT_ADD}
            />
        )
    }
}

export default connect(mapStateToProps, mapActionsToProps)(DocumentListCardAdmin as unknown as React.ComponentType<{}>);

