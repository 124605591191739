import * as i18next from "i18next";
import translationsEs from './translations/translations.es.json';
import translationsEn from './translations/translations.en.json';

i18next
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        lng: 'es',
        // Using simple hardcoded resources for simple example
        resources: {
            es: {
                translation: translationsEs,
            },
            en: {
                translation: translationsEn,
            },
        },
    });

export default i18next;