import {ReducerBuilder} from "co-redux-builders";
import DocumentFormAdminScreenActions from "./DocumentFormAdminScreenActions";
import DocumentModel from "../../../../model/Document";
import Exploitation from "../../../../model/Exploitation";
import TaskDocumentDetail from "../../../../ws/document/TaskDocumentDetail";
import TaskExploitationList from "../../../../ws/exploitation/TaskExploitationList";

interface InitialState {
    loading: boolean,
    document?: DocumentModel,

    exploitationsLoading: boolean,
    exploitationList: Exploitation[],
}

const INITIAL_STATE: InitialState = {
    loading: false,
    document: undefined,

    exploitationsLoading: false,
    exploitationList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskDocumentDetail.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        loading: true
    }))
    .onEvent(TaskDocumentDetail.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        loading: false
    }))
    .onEvent(TaskDocumentDetail.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, document: payload.data};
        })

    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationsLoading: true
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationsLoading: false
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return { ...oldState, exploitationList: payload.data};
        })

    .onEvent(DocumentFormAdminScreenActions.clearReducer, () => INITIAL_STATE)

    .build();