import * as React from "react";
import FormResult from "../../../model/FormResult";
import {OnSortHandler, SortState} from "../../../utils/Sort";
import {generateRoute, goToRoute} from "../../../utils/Router";
import {ROUTE_FORM_RESULT, ROUTE_FORM_RESULT_ALL_DETAIL} from "../../../routing/Routes";
import I18nUtils from "../../../I18n/I18nUtils";
import {TR_EXPLOTACION, TR_FECHA, TR_NOMBRE, TR_USUARIO, TR_VER_ENTRADA} from "../../../I18n/constants";
import Th from "../../../components/table/Th";
import Button from "../../../components/Button";

interface FormResultAllListTableProps {
    data: FormResult[],
    error: string,
    onSort: OnSortHandler,
    sort: SortState,
    renderSelectTd?: (itemId: string) => React.ReactNode,
}

export default class LogListTable extends React.Component<FormResultAllListTableProps> {

    private gotoItemDetail = (formId: string, formResultId: string): void => {
        goToRoute(generateRoute(ROUTE_FORM_RESULT_ALL_DETAIL, {formId, id: formResultId}))
    };

    public render(): React.ReactNode {
        const {data} = this.props;

        return (
            <>
                <table className="table table-hover">
                    <thead>{this.renderTH()}</thead>
                    <tfoot>{this.renderTH()}</tfoot>
                    <tbody>
                    {data && data.map((formResult: FormResult, index) => {
                        return (
                            <tr key={index}
                                className={"tr-cursor"}
                                onClick={() => this.gotoItemDetail(formResult.form_id, formResult.id)}
                            >
                                <td>{formResult.form ? formResult.form.name : ''}</td>
                                <td>{formResult.exploitation ? formResult.exploitation.name : ''}</td>
                                <td>{formResult.datetime && formResult.datetime.length !== 0 ?
                                    new Date(formResult.datetime).toLocaleString() : ''}</td>
                                <td>{formResult.user ? `${formResult.user.name} ${formResult.user.last_name}` : ''}</td>
                                <td>{formResult.form_id && formResult.form_id.length !== 0 ?
                                    <Button
                                        text={I18nUtils.tr(TR_VER_ENTRADA)}
                                        onClick={(event) => {
                                            goToRoute(generateRoute(ROUTE_FORM_RESULT, {formId: formResult.form_id}));
                                            event.stopPropagation();
                                        }}
                                        type={'button'}
                                        block={true}
                                        className={'btn-sm btn-primary'}
                                    /> : ''}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </>
        )
    }

    private renderTH(): React.ReactNode {
        const {sort, onSort, children} = this.props;
        const commonProps = {
            sort,
            onSort,
        };

        return (
            <tr>
                {children}
                <Th text={I18nUtils.tr(TR_NOMBRE)}
                    columnName={'name'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_EXPLOTACION)}
                    columnName={'exploitation'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_FECHA)}
                    columnName={'upload'}
                    {...commonProps}
                />
                <Th text={I18nUtils.tr(TR_USUARIO)}
                    columnName={'user'}
                />
                <Th text={''}
                    columnName={''}
                />
            </tr>
        )
    }
}