import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import {generateRoute} from "../../utils/Router";
import Urls from "../Urls";
import Exploitation, {ExploitationReversionRequest} from "../../model/Exploitation";

export default class TaskExploitationReversionEdit extends BaseGenericRequest<ExploitationReversionRequest, TaskResponse<Exploitation>> {
    private readonly data: ExploitationReversionRequest;

    public constructor(exploitationId: string, exploitationReversionData: ExploitationReversionRequest) {
        super(METHOD.METHOD_PUT, generateRoute(Urls.URL_EXPLOITATION_REVERSION_EDIT, {uuid: exploitationId}));
        this.data = exploitationReversionData;
    }

    protected getRequest(): ExploitationReversionRequest {
        return this.data;
    }
}