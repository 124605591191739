import React, {Component, ReactNode} from "react";
import ScreenCard from "../../../../components/screen/ScreenCard";
import I18nUtils from "../../../../I18n/I18nUtils";
import {TR_LINKS, TR_NUEVO_LINK} from "../../../../I18n/constants";
import {ROUTE_LINK_ADD, ROUTE_LINKS} from "../../../../routing/Routes";
import {MenuIcon} from "../../../../Config";
import LinkCreateAdminDataContainer from "./LinkCreateAdminDataContainer";

export default class LinkCreateAdminScreen extends Component {
    public render(): ReactNode {
        return (
            <ScreenCard breadcrumbs={[
                {
                    name: I18nUtils.tr(TR_LINKS),
                    url: ROUTE_LINKS,
                    icon: MenuIcon.LINK
                },
                {
                    name: I18nUtils.tr(TR_NUEVO_LINK),
                    url: ROUTE_LINK_ADD,
                    icon: MenuIcon.LINK
                }
            ]}>
                <LinkCreateAdminDataContainer/>
            </ScreenCard>
        );
    }
}
 