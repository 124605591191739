import BaseGenericRequest from "../BaseGenericRequest";
import TaskResponse from "../TaskResponse";
import {METHOD} from "co-generic-request";
import Urls from "../Urls";
import Alert, {AlertRequest} from "../../model/Alert";

export default class TaskAlertAdd extends BaseGenericRequest<AlertRequest, TaskResponse<Alert>> {

    private readonly data: AlertRequest;

    public constructor(data: AlertRequest) {
        super(METHOD.METHOD_POST, Urls.URL_ALERT_ADD);
        this.data = data;
    }

    protected getRequest(): AlertRequest {
        return this.data;
    }
}