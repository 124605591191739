import Exploitation from "../../../../../../model/Exploitation";
import {ReducerBuilder} from "co-redux-builders";
import TaskExploitationList from "../../../../../../ws/exploitation/TaskExploitationList";

interface InitialState {
    exploitationListLoading: boolean,
    exploitationList: Exploitation[],
}

const INITIAL_STATE: InitialState = {
    exploitationListLoading: false,
    exploitationList: [],
};

export default ReducerBuilder.newBuilder(INITIAL_STATE)
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_PRE_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationListLoading: true,
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_POST_EXECUTE, (oldState) => ({
        ...oldState,
        exploitationListLoading: false
    }))
    .onEvent(TaskExploitationList.REDUX_ACTION_OBJECT_ON_SUCCESS, (oldState, payload) => {
        return {...oldState, exploitationList: payload.data};
    })
    .build();