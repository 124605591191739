import React, {Component, ReactNode} from "react";
import {Modal} from "react-bootstrap";
import {BaseModalProps} from "../../base/modal/BaseModal";
import AlertFormAdmin, {AlertFormAdminData} from "../../modules/admin/alerts/form/AlertFormAdmin";
import {DateHelpers} from "../../utils/DateUtils";
import Alert, {AlertRequest} from "../../model/Alert";
import {executeItemTask} from "../../utils/FormUtils";
import TaskAlertAdd from "../../ws/alert/TaskAlertAdd";
import I18nUtils from "../../I18n/I18nUtils";
import {
    TR_ALERTA_CREADA_CORRECTAMENTE,
    TR_ALERTA_MODIFICADA_CORRECTAMENTE,
    TR_NUEVA_ALERTA
} from "../../I18n/constants";
import TaskResponse from "../../ws/TaskResponse";
import {UserRoleData} from "../../model/UserRole";
import AlertForm from "../../modules/admin/alerts/AlertForm";
import TaskAlertEdit from "../../ws/alert/TaskAlertEdit";

interface AlertModalProps extends BaseModalProps{
    readonly?: boolean,
    onSubmitAlert?: (response: TaskResponse<Alert>) => void,
    defaultValues?: Partial<AlertFormAdminData>,
    id?: string
}

export default class AlertFormModal extends Component<AlertModalProps> {
    private handleAddAlert = (values: AlertFormAdminData): void => {
        const {onSubmitAlert, id} = this.props;

        const datetimeObject = DateHelpers.getDateFromInputs(values.date, values.time);

        const submitData: AlertRequest = {
            name: values.name,
            description: values.description,
            link: values.link || undefined,
            notify_by_email: values.email || false,
            alert_state_id: values.state,
            datetime: datetimeObject.toISOString(),
            alert_frequency_id: values.frequency,
            alert_scope_id: values.alertScope,
            alert_type_id: values.alertType,
            document_id: values.linkedDocument !== "-1" ? values.linkedDocument : undefined,
            form_id: values.linkedForm !== "-1" ? values.linkedForm : undefined,
            receivers: {
                users: values.users && values.users.length > 0 ? values.users.map(user => user[0].data.id) : [],
                exploitations: values.exploitations && values.exploitations.length > 0 ? values.exploitations.map(exploitation => exploitation[0].data.id) : [],
                roles: values.roles && values.roles.length > 0 ? values.roles.map(roleOption => {
                    const role: UserRoleData = {
                        role_id: roleOption.role_id,
                        exploitation_id: roleOption.exploitation && roleOption.exploitation[0] ? roleOption.exploitation[0].data.id : undefined
                    }
                    return role;
                }) : []
            }
        };

        if (id) {
            executeItemTask(
                new TaskAlertEdit(id, submitData),
                (response) =>{
                    onSubmitAlert ? onSubmitAlert(response) : null
                },
                I18nUtils.tr(TR_ALERTA_MODIFICADA_CORRECTAMENTE)
            )
        } else {
            executeItemTask(
                new TaskAlertAdd(submitData),
                (response) => {
                    onSubmitAlert ? onSubmitAlert(response) : null;
                },
                I18nUtils.tr(TR_ALERTA_CREADA_CORRECTAMENTE)
            );
        }
    };

    public render(): ReactNode {
        const {onClose, show, readonly, defaultValues} = this.props;

        let initialValues: Partial<AlertFormAdminData> = AlertForm.INITIAL_VALUES;

        if (defaultValues) {
            initialValues = {
                ...initialValues,
                ...defaultValues,
            }
        }

        return (
            <Modal onHide={onClose} show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {initialValues.name || I18nUtils.tr(TR_NUEVA_ALERTA)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AlertFormAdmin
                        initialValues={initialValues}
                        onCancel={onClose}
                        parentLoading={false}
                        readonly={readonly}
                        onSubmit={this.handleAddAlert}
                    />
                </Modal.Body>
            </Modal>
        );
    }
}
